import { mdpminlength, usernameminlength } from "./conf"
const text = [
    { // fr
        cn: "Creativ'Network",
        french: 'Français',
        english: 'Anglais',
        italian: 'Italien',
        dutch: 'Néérlandais',
        spanish: 'Espagnol',
        german: 'Allemand',
        usermanual: "Manuel d'utilisation",

        formulaire: 'Formulaire de candidature',
        firstname: 'Prénom',
        lastname: 'Nom de famille',
        email: 'Adresse e-mail',
        birthday: 'Date de naissance',
        phone: 'Téléphone',
        skypename: 'Pseudo Skype',
        whichchatlanguage: 'Pour quelle langue de chat postulez-vous ?',
        timesavailable: 'Horaires disponibles par téléphone ?',
        expchatmod: 'Avez-vous de l\'expérience en modération de chat ?',
        hearabout: 'Comment avez-vous entendu parler de nous ?',
        tellus: 'Parlez-nous un peu de vous et de pourquoi nous devrions vous embaucher !',
        submitapp: 'Soumettre',
        yes: 'Oui',
        no: 'Non',
        friends: 'Amis',
        from: 'De',
        to: 'à',

        required: 'Ce champ est obligatoire',
        invalidemail: 'Email invalide',

        loginfailusername: 'Nom d\'utilisateur nocoabintn reconnu',
        loginfailpass: 'Mot de passe invalide',
        login: 'Connexion',
        username: 'Nom d\'utilisateur',
        password: 'Mot de passe',


        test: 'Formulaire de Test en tant que modérateur de chat en Français ',
        situation: 'Situation',
        send: 'Envoyer',
        qreturn: 'Voulez- vous vraiment quitter le test ?',

        cointint: "Souhaitez-vous travailler à domicile en tant qu'opérateur de chat de rencontres avec",
        coint1: "Une belle rémunération : Gagnez de 0,12€ à 0,15€ par message envoyé avec Creativ'Network",
        coint2: "Travail à distance : Profitez de la flexibilité du travail à domicile.",
        coint3: "Compétences en communication : Améliorez vos qualités d'empathie, de compréhension active et de fluidité dans la communication.",
        coint4: "Équilibre entre vie professionnelle et vie privée : Gérez mieux votre temps grâce à des horaires flexibles.",
        applynow: "Postuler",

        coabint1: "En quoi consiste le métier d",
        coabint2: "?",
        chatoperator: "opérateur de chat",
        coab1: "Entrer en contact avec des personnes seules : De nombreux utilisateurs des plateformes de rencontre recherchent une compagnie ou quelqu'un à qui parler en raison d'un sentiment de solitude. Les opérateurs de chat offrent une oreille attentive et un soutien émotionnel.",
        coab2: "Communication textuelle : Le principal mode de communication consiste à envoyer des messages écrits sur la plateforme. Les opérateurs entretiennent des conversations continues avec les utilisateurs",
        coab3: "Des sujets de conversation variés :Les opérateurs de chat engagent les utilisateurs dans des discussions sur un large éventail de sujets, des sujets quotidiens comme le sport et la météo aux sujets plus personnels et intimes, y compris les rêves, les fantasmes et d'autres domaines d'intérêt.",
        coab4: "Ouverture d'esprit :Ce travail exige souvent une ouverture d'esprit et une absence de jugement, car les utilisateurs peuvent avoir des intérêts, des expériences et des désirs différents.",
        coab5: "Providing Companionship: Operators play a role in providing a sense of companionship and connection to users who may be seeking emotional support or simply want someone to talk to.",
        coab6: "Anonyme : Les opérateurs de chat travailleront de manière 100% anonyme.Notre identité ne sera jamais révélée à qui que ce soit sur les sites de rencontre.",
        coab7: "Indépendance et flexibilité :Les opérateurs de chat travaillent à leur convenance",

        home:"Accueil",
        ourmissions: "Nos missions",
        company: "Valeurs",
        ourcompany: "Nos valeurs",
        ouradvantages: "Nos avantages",
        jobs: "Emplois",

        slog: '"Où la créativité rencontre l\'opportunité"',
        copyright: "©2023-2024 copyright Creativ'Network. Tous droits réservés.",
        foexpl: "Creativ'Network, la plateforme innovante qui met en relation les talents du web avec les métiers d'avenir, façonne le monde numérique. Découvrez votre potentiel, épanouissez-vous dans la modération web, le développement, le marketing, le big data, et plus encore.",
        focontact1: "Adresse principale : 71-75 Shelton Street, Covent Garden, London, WC2H 9JQ",
        focontact2: "Représentant du bureau belge : Roodborstjesstraat 4, Wondelgem 9032",
        focontact3: "contact@creativ-network.net",
        subscribe: "S'inscrire",
        agree1: "Oui, j'accepte la ",
        agree2: "et les",
        agreelink1: "politique de confidentialité",
        agreelink2: "conditions générales d'utilisation.",
        useful: "Liens",
        links: "Utiles",
        oursing: "Notre",
        contact: "Contacts",
        subscribetoour: "S'abonner à notre",
        newsletter: "lettre d'information",

        consents: "Consentement",
        termsAgree1: "Oui, j'accepte la",
        termsAgree2: "et les",
        termsAgreeLink1: "politique de confidentialité",
        termsAgreeLink2: "conditions générales d'utilisation.",

        termsAgreeLink2real: "Termes et conditions générales d'utilisation",
        consentsErr: "Vous devez accepter la politique de confidentialité et les conditions générales d'utilisation pour vous inscrire.",
        
        coscint1: "Comment le salaire de l'",
        coscint2: "est-il calculé ?",
        cosc1: "Rémunération de base par message : L'opérateur reçoit généralement un montant prédéterminé pour chaque message envoyé. La rémunération de base par message envoyé est de 0,12 €.",
        cosc2: "Décompte mensuel des messages : A la fin du mois, le nombre total de messages envoyés par l'opérateur est calculé.",
        cosc3: "Calcul de la rémunération : Les gains de l'opérateur pour le mois sont calculés en multipliant le nombre total de messages par la rémunération de base par message. Par exemple, si vous envoyez 5 000 messages, vous gagnerez 600 € (5 000 messages * 0,12 € par message). Avec Creativ'Network, un opérateur peut facilement gagner 1 200 € par mois avec 10 000 messages envoyés.",
        cosc4: "Prime de performance : dans certains cas, les opérateurs ayant réalisé des performances exceptionnelles peuvent recevoir une prime en plus de leur commission. Cette prime peut être basée sur des critères tels que le nombre de clients satisfaits, le temps de réponse ou les commentaires positifs des utilisateurs.",
        cosc5: "Prime de Noël : Creativ'Network offre une prime de Noël à nos opérateurs qui ont plus de 2 ans d'ancienneté dans l'entreprise.",

        corint1: "Quelles sont les conditions pour être opérateur de chat pour ",
        corint2: "?",
        cor11: "Compétences en français",
        cor12: "La maîtrise du français est indispensable, car les opérateurs de chat sont censés communiquer efficacement avec les utilisateurs en français.",
        cor21: "Limite d'âge",
        cor22: "Les candidats doivent être âgés d'au moins 18 ans pour être éligibles à ce poste.",
        cor31: "Compte bancaire ou compte de paiement",
        cor32: "Vous devez avoir un compte bancaire, PayPal, Wise ou Yoursafe à votre nom. Ce compte est probablement destiné au traitement des paiements.",
        cor41: "Acceptation de la déclaration de confidentialité et des conditions contractuelles",
        cor42: "Les candidats doivent être prêts à accepter et à respecter la déclaration de confidentialité et les conditions contractuelles de Creativ'Network, qui décrivent souvent les responsabilités, les lignes directrices et le code de conduite des opérateurs de chat.",
        cor51: "Responsabilité de l'équipement",
        cor52: "Vous devez disposer de l'équipement nécessaire, notamment d'un ordinateur portable ou d'un PC, d'une connexion internet fiable et d'une source d'électricité et d'internet de secours pour garantir un travail ininterrompu.",
        cor61: "Message minimum requis",
        cor62: "Les opérateurs de chat sont généralement tenus d'envoyer un nombre minimum de messages par semaine. Dans ce cas, un minimum de 250 messages sortants par semaine est attendu.",
        cor71: "Heures hebdomadaires minimales",
        cor72: "Même si vous travaillez de manière indépendante, vous devriez au moins travailler 4 heures par jour et 20 heures par semaine à votre convenance.",
        cor81: "Taxe",
        cor82: "En tant que travailleur indépendant, vous êtes responsable de vos propres impôts.",
        cor91: "Respect des lignes directrices légales et réglementaires :",
        cor92: "Chaque opérateur de chat doit suivre les règles données par le chef d'équipe pendant la formation.",

        coicint1: "De quoi",
        coicint2: "a-t-il besoin pour vérifier votre identité ?",
        coic1: "Vérification de l'âge : Pour diverses raisons, les sites et plateformes de rencontres ont souvent des restrictions d'âge afin d'assurer la conformité avec les réglementations légales. En vérifiant votre identité, Creativ'Network peut s'assurer que vous avez l'âge minimum requis (18 ans) pour utiliser nos services. Cela permet à la plateforme d'adhérer aux normes légales, de protéger les mineurs et de maintenir une communauté responsable et sûre.",
        coic2: "Sécurité et confiance : La vérification de l'identité renforce la sécurité et la fiabilité de la plateforme. Elle garantit aux utilisateurs que Creativ'Network interagit avec des personnes qui sont bien celles qu'elles prétendent être, ce qui est essentiel pour instaurer la confiance et garantir un environnement sûr pour ce poste.",
        coic3: "Documents à vérifier : Pour se conformer aux réglementations légales et s'assurer que les candidats ont l'âge requis, Creativ'Network demandera une vérification de l'âge. Les documents tels que les permis de conduire et les cartes d'identité gouvernementales contiennent généralement des informations sur la date de naissance, ce qui permet de confirmer que vous avez l'âge minimum requis par la plateforme.",

        coasint1: "3 étapes faciles pour postuler au poste chez ",
        coasint2: "",
        coas11: "Candidature facile et test de compétences",
        coas12: ["Commencez par visiter le site officiel ou le portail de carrière de Creativ'Network.", "Trouvez l'offre d'emploi pour le poste d'opérateur de chat de rencontres et cliquez sur 'Postuler'.", "Remplissez le formulaire de candidature, qui comprend vos informations personnelles, vos coordonnées, votre expérience pertinente, votre motivation à travailler avec nous.", "Vous serez invité à compléter un court test de compétences, qui prend généralement environ 10 minutes. Ce test évalue votre capacité à participer à des conversations par chat et à démontrer vos compétences en matière de communication."],
        coas21: "Vérification de l'identité pour la confirmation de l'âge",
        coas22: ["Après avoir complété avec succès la candidature initiale et le test de compétences, vous devrez vous soumettre à une procédure de vérification d'identité pour confirmer votre âge.", "Vous devrez présenter une copie d'une pièce d'identité valide émise par le gouvernement, telle qu'un passeport, un permis de conduire ou une carte d'identité gouvernementale. Ce document est utilisé pour vérifier que vous remplissez les conditions d'âge minimum spécifiées pour le poste."],
        coas31: "Formation en ligne via Skype",
        coas32: ["Une fois votre candidature et votre vérification d'âge approuvées, vous serez convoqué à une session de formation en ligne via Skype.", "Pendant la formation, les chefs d'équipe de Creativ'Network vous donneront des conseils et des instructions sur la manière de remplir efficacement votre rôle d'opérateur de chat de rencontres. Ils aborderont les politiques de l'entreprise, les meilleures pratiques et les stratégies de communication qui vous permettront d'exceller dans votre fonction."],

        coafint: "Formulaire de candidature pour ",
        ppint: ["Chez Creativ-Network, accessible à l'adresse https://creativ-network.net, l'une de nos principales priorités est la protection de la vie privée de nos visiteurs. Ce document de politique de confidentialité contient les types d'informations collectées et enregistrées par Creativ-Network et la manière dont nous les utilisons.", "Si vous avez des questions supplémentaires ou si vous souhaitez obtenir plus d'informations sur notre politique de confidentialité, n'hésitez pas à nous contacter par courrier électronique à l'adresse data@creativ-network.net", "Cette politique de confidentialité s'applique uniquement à nos activités en ligne et est valable pour les visiteurs de notre site web en ce qui concerne les informations qu'ils ont partagées et/ou collectées sur Creativ-Network. Cette politique ne s'applique pas aux informations collectées hors ligne ou via d'autres canaux que ce site web."],
        pp1t: ["1 - Consentement", ["En utilisant notre site web, vous consentez par la présente à notre politique de confidentialité et en acceptez les termes."]],
        pp2t: ["2 - Informations que nous collectons", ["Les informations personnelles qui vous sont demandées, et les raisons pour lesquelles elles vous sont demandées, vous seront précisées au moment où nous vous demanderons de fournir vos informations personnelles.", "Si vous nous contactez directement, nous pouvons recevoir des informations supplémentaires vous concernant, telles que votre nom, votre adresse électronique, votre numéro de téléphone, le contenu du message et/ou des pièces jointes que vous pouvez nous envoyer, ainsi que toute autre information que vous pouvez choisir de fournir.", "Lorsque vous vous inscrivez pour créer un compte, nous pouvons vous demander vos coordonnées, y compris des éléments tels que votre nom, le nom de votre société, votre adresse, votre adresse électronique et votre numéro de téléphone."]],
        pp3t: ["3 - Comment nous utilisons vos informations", ["Nous utilisons les informations que nous recueillons de diverses manières, notamment pour :", "• Fournir, exploiter et maintenir notre site web", "• Améliorer, personnaliser et développer notre site web", "• Comprendre et analyser la manière dont vous utilisez notre site web", "• Développer de nouveaux produits, services, caractéristiques et fonctionnalités", "• Communiquer avec vous, directement ou par l'intermédiaire de l'un de nos partenaires, y compris pour le service clientèle, pour vous fournir des mises à jour et d'autres informations relatives au site web, et à des fins de marketing et de promotion", "• Vous envoyer des courriers électroniques", "• Rechercher et prévenir la fraude"]],
        pp4t: ["4 - Fichiers journaux", ["Creativ-Network suit une procédure standard d'utilisation de fichiers journaux. Ces fichiers enregistrent les visiteurs lorsqu'ils visitent des sites web. Toutes les sociétés d'hébergement le font et cela fait partie de l'analyse des services d'hébergement. Les informations collectées par les fichiers journaux comprennent les adresses de protocole Internet (IP), le type de navigateur, le fournisseur d'accès Internet (FAI), la date et l'heure, les pages de renvoi et de sortie, et éventuellement le nombre de clics. Ces données ne sont pas liées à des informations personnellement identifiables. L'objectif de ces informations est d'analyser les tendances, d'administrer le site, de suivre les mouvements des utilisateurs sur le site et de recueillir des informations démographiques."]],
        pp5t: ["5 - Cookies et balises web", ["Comme tout autre site web, Creativ-Network utilise des 'cookies'. Ces cookies sont utilisés pour stocker des informations, y compris les préférences des visiteurs et les pages du site Web auxquelles le visiteur a accédé ou qu'il a visitées. Ces informations sont utilisées pour optimiser l'expérience des utilisateurs en personnalisant le contenu de nos pages web en fonction du type de navigateur des visiteurs et/ou d'autres informations."]],
        pp6t: ["6 - Politiques de confidentialité des partenaires publicitaires", ["Vous pouvez consulter cette liste pour trouver la politique de confidentialité de chacun des partenaires publicitaires de Creativ-Network.", "Les serveurs publicitaires tiers ou les réseaux publicitaires utilisent des technologies telles que les cookies, le JavaScript ou les balises Web qui sont utilisées dans leurs publicités et liens respectifs qui apparaissent sur Creativ-Network, et qui sont envoyés directement au navigateur des utilisateurs. Ils reçoivent automatiquement votre adresse IP lorsque cela se produit. Ces technologies sont utilisées pour mesurer l'efficacité de leurs campagnes publicitaires et/ou pour personnaliser le contenu publicitaire que vous voyez sur les sites web que vous visitez.", "Notez que Creativ-Network n'a aucun accès ou contrôle sur ces cookies qui sont utilisés par des annonceurs tiers."]],
        pp7t: ["7 - Politiques de confidentialité des tiers", ["La politique de confidentialité de Creativ-Network ne s'applique pas aux autres annonceurs ou sites web. Nous vous conseillons donc de consulter les politiques de confidentialité respectives de ces serveurs publicitaires tiers pour obtenir des informations plus détaillées. Elles peuvent inclure leurs pratiques et des instructions sur la manière de refuser certaines options. Vous trouverez une liste complète de ces politiques de confidentialité et de leurs liens ici : Liens vers les politiques de confidentialité.", "Vous pouvez choisir de désactiver les cookies par le biais des options de votre navigateur. Pour obtenir des informations plus détaillées sur la gestion des cookies avec des navigateurs web spécifiques, vous pouvez consulter les sites web respectifs de ces navigateurs. Que sont les cookies ?"]],
        pp8t: ["8 - Politique de confidentialité de la CCPA (Ne vendez pas mes informations personnelles)", ["En vertu de la CCPA, entre autres droits, les consommateurs californiens ont le droit de :", "Demander à une entreprise qui collecte les données personnelles d'un consommateur de divulguer les catégories et les éléments spécifiques des données personnelles qu'une entreprise a collectées sur les consommateurs.", "Demander qu'une entreprise supprime toutes les données à caractère personnel qu'elle a collectées sur le consommateur.", "Demander qu'une entreprise qui vend les données à caractère personnel d'un consommateur ne vende pas ces données.", "Si vous faites une demande, nous avons un mois pour vous répondre. Si vous souhaitez exercer l'un de ces droits, veuillez nous contacter."]],
        pp9t: ["9 - Politique de confidentialité GDPR (Droits de protection des données)", ["Nous aimerions nous assurer que vous êtes pleinement conscient de tous vos droits en matière de protection des données. Chaque utilisateur a droit aux éléments suivants :", "Le droit d'accès - Vous avez le droit de demander des copies de vos données à caractère personnel. Nous pouvons vous facturer une somme modique pour ce service.", "Le droit de rectification - Vous avez le droit de demander que nous corrigions toute information que vous estimez inexacte. Vous avez également le droit de nous demander de compléter les informations que vous estimez incomplètes.", "Le droit à l'effacement - Vous avez le droit de nous demander d'effacer vos données à caractère personnel, sous certaines conditions.", "Le droit à la limitation du traitement - Vous avez le droit de nous demander de limiter le traitement de vos données à caractère personnel, sous certaines conditions.", "Le droit à la portabilité des données - Vous avez le droit de nous demander de transférer les données que nous avons collectées à une autre organisation ou directement à vous, sous certaines conditions", "Si vous faites une demande, nous avons un mois pour vous répondre. Si vous souhaitez exercer l'un de ces droits, veuillez nous contacter."]],
        pp10t: ["10 - Informations sur les enfants", ["Une autre partie de notre priorité est d'ajouter une protection pour les enfants lors de l'utilisation d'Internet. Nous encourageons les parents et les tuteurs à observer, participer et/ou surveiller et guider leur activité en ligne.", "Creativ-Network ne recueille pas sciemment d'informations personnelles identifiables auprès d'enfants de moins de 13 ans. Si vous pensez que votre enfant a fourni ce type d'informations sur notre site web, nous vous encourageons vivement à nous contacter immédiatement et nous ferons de notre mieux pour retirer rapidement ces informations de notre site web."]],

        lastupdated: "Dernière mise à jour",
        tcint: ["Veuillez lire attentivement les conditions générales suivantes avant d'utiliser le site web de Creativ'Network."],
        tc1t: ["1. Acceptation des conditions", ["En accédant au site web de Creativ'Network ou en l'utilisant, vous acceptez de respecter et d'être lié par les présentes conditions générales. Si vous n'êtes pas d'accord avec une partie de ces conditions, veuillez ne pas utiliser notre site web."]],
        tc2t: ["2. Eligibilité de l'utilisateur", ["Vous devez avoir au moins 18 ans pour utiliser notre site web. En utilisant nos services, vous confirmez que vous remplissez cette condition d'âge."]],
        tc3t: ["3. Utilisation du site web", ["- Vous acceptez d'utiliser le site web en conformité avec toutes les lois et réglementations applicables", "- Vous ne vous livrerez à aucune activité illégale, nuisible ou abusive sur notre plateforme", "- Vous acceptez de ne pas vous faire passer pour d'autres personnes ou de ne pas créer de fausses identités."]],
        tc4t: ["4. Confidentialité et traitement des données", ["Notre politique de confidentialité décrit la manière dont nous collectons, utilisons et protégeons vos données à caractère personnel. En utilisant notre site web, vous acceptez les pratiques en matière de données décrites dans notre politique de confidentialité."]],
        tc5t: ["5. Propriété intellectuelle", ["Tout le contenu de notre site web, y compris les textes, les graphiques, les logos et les logiciels, est protégé par des droits de propriété intellectuelle. Vous ne pouvez pas utiliser, reproduire ou distribuer notre contenu sans notre autorisation explicite."]],
        tc6t: ["6. Contenu généré par l'utilisateur", ["Lorsque vous contribuez à notre plateforme, vous nous accordez une licence non exclusive et libre de droits pour utiliser, modifier et distribuer votre contenu."]],
        tc7t: ["7. Modifications des conditions", ["Nous pouvons mettre à jour les présentes conditions à tout moment. En continuant à utiliser le site web après de telles modifications, vous acceptez les conditions révisées."]],
        tc8t: ["8. Clauses de non-responsabilité et limitations de responsabilité", ["Nous fournissons notre site web  \"en l'état \" et n'offrons aucune garantie quant à son exactitude, sa disponibilité ou ses performances. Nous ne serons pas responsables des dommages directs, indirects ou consécutifs résultant de l'utilisation de notre site web."]],
        tc9t: ["9. Droit applicable et juridiction", ["Les présentes conditions et votre utilisation de notre site web sont régies par les lois du Royaume-Uni. Tout litige sera résolu par les tribunaux du Royaume-Uni."]],
        tc10t: ["10. Informations sur le contact", ["Si vous avez des questions ou des préoccupations concernant ces conditions, veuillez nous contacter à l'adresse suivante : contact@creativ-network.net."]],

        jobsint1: "Choisissez l'emploi qui correspond à vos compétences",
        jobsint2: "Vivez une expérience formidable avec une équipe géniale !",
        jobsat: "Emplois chez",

        hirenow: "RECRUTE MAINTENANT !",
        closed: "FERMÉ !",
        detailsandapply: "Voir les détails et postuler",
        appclosed: "Candidature clôturée",

        jobco: ["Opérateur de chat de rencontres", "Vous êtes passionné(e) par le chat et l'animation de sites et de communautés de rencontres."],
        jobwdev: ["Développeur Web", "Vous êtes passionné(e) par le chat et l'animation de sites de rencontres et de communautés professionnelles."],
        jobtl: ["Chef d'équipe", "Vous êtes passionné(e) par le chat et l'animation de sites de rencontres et de communautés professionnelles."],
        jobdm: ["Spécialiste du marketing numérique", "Vous êtes passionné(e) par le chat et l'animation de sites de rencontres et de communautés professionnelles."],
        jobsup: ["Superviseur", "Vous êtes passionné(e) par le chat et l'animation de sites de rencontres et de communautés professionnelles."],
        jobwdes: ["Concepteur de sites web", "Vous êtes passionné(e) par le chat et l'animation de sites de rencontres et de communautés professionnelles."],
        
        cotest: "Test de l'opérateur de chat",
        cotesttitle: "Test pour devenir Opérateur de Chat de Rencontres chez Creativ'Network",
        cotestint1 : `"Le test Dating Chat Operator de Creativ'Network évalue les compétences en communication, l'empathie et les capacités d'engagement pour des interactions efficaces et respectueuses avec les utilisateurs."`,
        cotestint2: "Prenez quelques instants pour répondre à toutes les situations avant que nous ne poursuivions l'examen de votre candidature.",
        goodluck: "Bonne chance !",
        number: "N°",
        youranswer: "Votre réponse",
        next: "Suivant",
        back: "Retour",
        blog: "Blog",
        stat: "Statistiques",
        at: "Chez ",
        ourstat: ", nos statistiques illustrent parfaitement notre succès et notre croissance. Avec une équipe dévouée, de nombreux projets, un vaste réseau de partenaires et des années d'expérience, nous sommes une force florissante dans l'industrie. Entrons dans les détails pour découvrir notre parcours.",
        team: "Membres de l'équipe",
        projects: "Projets",
        netpart: "Partenaires du réseau",
        years: "Années d'activité",
        testimonial: "Témoignage",
        dedicated:"Des membres dévoués de l'équipe de",
        sharing: ", qui partagent leurs expériences, leurs points de vue et leur enthousiasme pour un lieu de travail enrichissant où la collaboration est florissante.",
        testimonialperson1: ["Marie C", "Opérateur de chat de rencontres", `"Travailler en tant qu'opérateur de chat de rencontre chez Creativ'Network est incroyable. J'ai l'occasion de mettre les gens en contact et de faire une différence dans leur vie chaque jour. C'est à la fois gratifiant et amusant !"`], 
        testimonialperson2: ["Lucas P", "Opérateur de chat de rencontres", `"Creativ'Network est l'endroit idéal pour embrasser votre passion pour aider sans vous sentir seul. L'équipe est fantastique et l'atmosphère est propice à l'entraide. J'adore ce que je fais."`],
        testimonialperson3: ["Sophie M", "Opérateur de chat de rencontres", `"Creativ'Network offre une plateforme unique pour construire des connexions significatives. En tant qu'opératrice de Dating Chat, j'ai l'impression de faire partie de quelque chose de spécial, de discuter avec des gens et de les rendre heureux."`],
        testimonialperson4: ["Alex R", "Développeur Web Front End", `"Creativ'Network me met au défi en tant que développeur web. Les projets innovants et le soutien de l'équipe poussent mes compétences vers de nouveaux sommets. Je suis fier de faire partie de cet environnement technologique dynamique."`],
        testimonialperson5: ["Emma H", "Développeur Web Back End", `"J'ai trouvé le travail de mes rêves en tant que développeur web chez Creativ'Network. L'engagement de l'entreprise en faveur des technologies de pointe et des opportunités de croissance m'inspire et m'enthousiasme."`],
        testimonialperson6: ["Tommy J", "Concepteur de sites web", `"En tant que web designer chez Creativ'Network, j'ai la liberté de créer des plateformes visuellement attrayantes. C'est un endroit inspirant qui valorise l'esthétique du design et l'expérience utilisateur, ce qui rend le travail vraiment agréable."`],

        titlesuffix: "- Creativ-Network",
        titlejobs: "Emplois",
        titlechatoperator: "Opérateur de chat",
        titletest: "Test",
        titlepp: "Politique de confidentialité",
        titleterms: "Conditions d'utilisation",

        wantto: "Envie de devenir des travailleurs épanouis avec ",
        areyou: "Vous êtes passionné(e) par le travail sur le web ? Vous avez de l'expérience dans l'un des domaines suivants : chat, développement web, marketing numérique, étiquetage de données, téléassistance et relations clients, télévente, rédaction, téléprospection, gestion des médias sociaux, RH, comptabilité, webdesign... ? Rejoignez notre communauté dès maintenant !",
        check: 'Consulter tous les emplois et postuler',

        our: 'Nos',
        missions: 'Missions',
        advantages: 'Avantages',


        mission1: "Identification et acquisition de talents : L'entreprise recherche et recrute activement des personnes ayant une expertise dans les domaines liés au web. Cela implique d'identifier et d'attirer les meilleurs talents à partir de diverses sources, notamment les offres d'emploi, les réseaux en ligne et les recommandations.",
        mission2: "Évaluation des compétences et sélection : Creativ'Network évalue rigoureusement les compétences et les qualifications des candidats potentiels afin de s'assurer qu'ils répondent aux exigences spécifiques de l'entreprise pour les postes qu'elle recrute.",
        mission3: "L'adéquation culturelle et l'alignement des valeurs : Au-delà des compétences, l'entreprise évalue les candidats en fonction de leur adéquation aux valeurs et à la culture de l'entreprise afin de favoriser un environnement de travail cohésif et productif.",
        mission4: "Personnalisation des postes : Creativ'Network adapte les postes aux forces et aux compétences des talents recrutés, en veillant à ce que les rôles soient alignés sur leur expertise et leurs intérêts.",
        mission5: "Développement et maintien des talents : L'entreprise s'engage à cultiver et à retenir les talents qu'elle recrute en leur offrant des possibilités de croissance et d'avancement au sein de l'organisation.",
        mission6: "Analyse du marché et stratégie : Creativ'Network suit les tendances du secteur, les références salariales et l'évolution des compétences requises pour rester compétitif sur le marché de l'emploi dans le domaine du web.",
        mission7: "Diversité et inclusion : L'entreprise peut promouvoir activement la diversité et l'inclusion dans son propre processus d'acquisition de talents, en s'efforçant de créer une main-d'œuvre reflétant un large éventail d'origines et de perspectives.",
        mission8: "Collaboration et formation polyvalente : L'entreprise encourage la collaboration au sein de son vivier de talents et offre des possibilités de formation polyvalente, ce qui permet aux employés de diversifier leurs compétences et de contribuer à différents aspects de l'entreprise.",
        mission9: "Adaptation à l'évolution des besoins : Creativ'Network reste flexible et s'adapte aux exigences changeantes des domaines liés au web dans lesquels il opère. Il s'agit notamment d'acquérir de nouveaux talents et de nouvelles compétences au fur et à mesure de l'évolution des technologies et des tendances du secteur.",
        mission10: "Amélioration continue : L'entreprise examine et affine en permanence ses processus de recrutement et de gestion des talents afin de s'assurer qu'ils sont efficaces et efficients.",
        mission11: "Satisfaction et bien-être des employés : Creativ'Network accorde une grande importance à la satisfaction de ses employés, en leur offrant des avantages sociaux, un équilibre entre vie professionnelle et vie privée, et en les aidant à améliorer leur bien-être général.",

        value1 : "Excellence : Nous visons l'excellence dans tout ce que nous faisons, qu'il s'agisse de recruter les meilleurs talents ou de fournir des services exceptionnels à nos clients",        
        value2: "Approche centrée sur le client : Nous plaçons nos clients au cœur de nos opérations, en comprenant leurs besoins uniques et en leur fournissant des solutions sur mesure qui contribuent à leur succès.",
        value3: "L'autonomisation des talents: Nous donnons aux talents du web les moyens de s'épanouir dans leur carrière en les mettant en contact avec des opportunités qui correspondent à leurs compétences et à leurs aspirations.",
        value4 : "Innovation et adaptabilité : Nous embrassons l'innovation et nous nous adaptons au paysage en constante évolution des domaines liés au web, en veillant à rester à la pointe des tendances de l'industrie",        
        value5: "Diversité et inclusion: Nous célébrons la diversité et favorisons un environnement inclusif où des personnes de tous horizons peuvent contribuer à notre succès collectif.",
        value6: "Transparence et intégrité: Nous respectons les normes d'intégrité les plus élevées et entretenons une communication transparente avec nos clients, nos candidats et les membres de notre équipe.",
        value7: "Collaboration et synergie: Nous croyons au pouvoir de la collaboration, en travaillant ensemble pour atteindre des objectifs communs et obtenir des résultats positifs.",
        value8: "Apprentissage continu: Nous encourageons l'apprentissage continu et le développement professionnel, en veillant à ce que notre équipe dispose des compétences et des connaissances les plus récentes.",
        value9: "Expertise sectorielle: En tant qu'experts du secteur, nous offrons des informations précieuses sur le marché de l'emploi dans le domaine du web et partageons nos connaissances avec les clients et les candidats.",
        value10: "Comportement éthique: Nous agissons avec une éthique inébranlable, en traitant tous les individus avec respect, équité et professionnalisme.",
        value11: "Engagement communautaire: Nous nous engageons activement auprès de la communauté professionnelle, en contribuant à sa croissance par le biais du mentorat, de la formation et de la participation à des associations sectorielles.",
        value12: "Impact et responsabilité: Nous assumons la responsabilité de l'impact que nous avons sur les carrières des individus et sur le succès des organisations que nous servons, en nous efforçant d'obtenir des résultats positifs et durables.",

        advantage1:"Des rémunérations compétitives : Offrir une rémunération et des avantages supérieurs à la moyenne ou à la pointe du secteur afin d'attirer et de retenir des talents de haut niveau, ce qui peut permettre à Creativ'Network de se démarquer de ses concurrents.",
        advantage2:"Primes basées sur les performances : La mise en place de structures de primes basées sur les performances peut motiver les employés à se surpasser et à s'assurer qu'ils sont récompensés pour leurs contributions.",
        advantage3:"Plans de rémunération personnalisés : L'adaptation des régimes de rémunération aux compétences, à l'expérience et aux performances de chaque employé peut conduire à une plus grande satisfaction au travail et à une plus grande loyauté.",
        advantage4:"Possibilités d'évolution de carrière : Offrir des possibilités claires d'avancement et de développement de carrière au sein de l'entreprise, ce qui peut attirer des professionnels ambitieux et favoriser la rétention des talents en interne.",
        advantage5:"Programmes de reconnaissance des employés : Reconnaître et récompenser les performances et les réalisations exceptionnelles des employés peut stimuler le moral et augmenter les taux de rétention.",
        advantage6:"Des modalités de travail flexibles : Offrir des options de travail flexibles, telles que le travail à distance ou des horaires flexibles, peut améliorer l'équilibre entre vie professionnelle et vie privée et attirer un plus grand nombre de candidats.",
        advantage7:"Un ensemble complet d'avantages sociaux : Offrir des avantages compétitifs, tels que l'assurance maladie, les plans de retraite et les programmes de bien-être, peut attirer les meilleurs talents et promouvoir le bien-être des employés.",
        advantage8:"Investissement dans la formation et le développement : Le soutien à l'apprentissage continu et à la croissance professionnelle par le biais de programmes de formation et d'opportunités éducatives peut favoriser l'émergence d'une main-d'œuvre hautement qualifiée et motivée.",
        advantage9:"Des pratiques de rémunération transparentes : Le maintien d'une communication transparente sur les pratiques de rémunération et les références salariales peut renforcer la confiance et la transparence dans la gestion du personnel.",
        advantage10:"Programmes d'aide aux employés : Proposer des programmes d'assistance pour les défis personnels et professionnels peut démontrer un engagement en faveur du bien-être des employés et de la satisfaction au travail.",
        advantage11:"Examens des performances et retour d'information : Des évaluations régulières des performances et un retour d'information constructif peuvent aider les employés à comprendre leur progression de carrière et les domaines dans lesquels ils doivent s'améliorer.",
        advantage12:"Analyse du marché pour des salaires compétitifs : Réalisation d'une analyse de marché pour s'assurer que les salaires et les avantages sociaux restent compétitifs et alignés sur les normes du secteur.",
        advantage13:"Structures d'incitation : Développer des structures d'incitation créatives qui récompensent les performances exceptionnelles, l'innovation et les contributions aux objectifs de l'entreprise.",

        blog2: "Vous trouverez ici nos articles",
        ourposts: "Nos articles",
        blog3: "Bonne lecture de nos articles",
        datepub: "15/10/2023",
        months: ["Janvier", "Fevrier", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
        uncategorized: "Sans catégorie",

        blognumber1: ["La route vers un paysage attrayant", "La nouvelle langue commune sera plus simple et plus régulière que les langues européennes existantes. Elle sera aussi simple que l'occidental ; en fait, elle sera occidentale. Pour un Anglais, elle ressemblera à de l'anglais simplifié, comme un ami sceptique de Cambridge m'a dit ce qu'était l'occidental. Les langues européennes font partie de la même famille."], 
        blognumber2: ["Graves problèmes de câbles dans la ville", "La nouvelle langue commune sera plus simple et plus régulière que les langues européennes existantes. Elle sera aussi simple que l'occidental ; en fait, elle sera occidentale. Pour un Anglais, elle ressemblera à de l'anglais simplifié, comme un ami sceptique de Cambridge m'a dit ce qu'était l'occidental. Les langues européennes font partie de la même famille."], 
        blognumber3: ["Montagnes et froid hivernal", "Si plusieurs langues fusionnent, la grammaire de la langue résultante est plus simple et plus régulière que celle des langues individuelles. La nouvelle langue commune sera plus simple et plus régulière que les langues européennes existantes. Elle sera aussi simple que l'occidental ; en fait, elle sera occidentale."], 
        by: "Par",
        noc: "aucun",
        comments: "commentaires",
        comment: "commentaire",
        readmore: "En savoir plus",

        cookiestitle: "Gestion de consentement aux cookies",
        cookiescontent: "La Gestion de consentement aux cookies de Creativ'Network est essentielle pour une expérience en ligne transparente et conforme. Nous sollicitons le consentement des utilisateurs de manière claire, fournissant des informations détaillées sur nos pratiques de cookies. Avec des options de personnalisation, une catégorisation explicite des cookies, et un engagement envers la confidentialité des données, Creativ'Network crée une expérience numérique sûre et respectueuse. Nous nous conformons rigoureusement aux normes de confidentialité, garantissant ainsi la sécurité et la confiance des utilisateurs dans notre plateforme.",
        pref: "Préférences",
        cookiessm1i: "Fonctionnel",
        cookiessm2i: "Statistiques",
        cookiessm3i: "Marketing",
        cookiessm1c: `Le "stockage ou l'accès technique" sur le site Creativ'Network implique l'utilisation de cookies pour améliorer l'expérience utilisateur. Cela inclut la collecte de données techniques essentielles pour le bon fonctionnement du site. Nous sollicitons le consentement des utilisateurs, fournissant des informations transparentes sur ces pratiques, conformément aux normes de confidentialité des données et aux réglementations en vigueur.`,
        cookiessm2c: `Les statistiques dans la gestion de consentement de cookies pour Creativ'Network permettent de recueillir des données analytiques. Elles jouent un rôle crucial en évaluant les habitudes de navigation des utilisateurs, facilitant ainsi l'amélioration continue du site. Ces informations guident les décisions pour optimiser l'expérience utilisateur tout en respectant les préférences de confidentialité, garantissant une plateforme personnalisée et conforme.`,
        cookiessm3c: `La fonction "Marketing" dans la gestion de consentement de cookies sur le site de Creativ'Network permet de personnaliser les publicités en fonction des préférences des utilisateurs. En obtenant leur consentement, nous assurons une expérience publicitaire ciblée et pertinente. Ceci garantit une communication promotionnelle plus efficace tout en respectant les normes de confidentialité des données et les choix individuels des utilisateurs.`,
        savepref: "Enregistrer les préférences",
        accept: "Accepter",
        alwyasactive: "Toujours activé",
        error: "Erreur",
        tags: "Tags : Pas de tags",
        comment1: "Ajouter un commentaire",
        comment2: "Votre adresse e-mail ne sera pas publiée.",
        comment3: "Nom (obligatoire)",
        comment4: "E-Mail ou nom d'utilisateur",
        comment5: "Site web",
        comment6: "Enregistrer mon nom, mon adresse électronique et mon site web dans ce navigateur pour la prochaine fois que je commenterai.",
        comment7: "Commentaire",
        follow: "Suivez-",
        us: "nous",
        creatememberspace: "Création de votre espace membre",
        createmsint11: "Veuillez entrer le code que nous avons envoyé à",
        createmsint12: "afin de valider votre adresse mail.",
        createmsint2: "Le fait de valider votre adresse mail vous permettra de créer automatiquement votre espace membre",
        createmsconsent: "En nous soumettant votre formulaire de candidature, vous acceptez notre",
        confirm: "Confirmer",
        codevalidationerror: "Le code de validation est incorrect",
        confirmpassword: "Confirmer mot de passe",
        passwordlength: `Le mot de passe doit contenir au moins ${mdpminlength} caractères`,
        passwordnotidentical: "Les mots de passes ne sont pas identiques",
        welcomeback: "Bon retour ",
        signinint: "Pour rester en contact avec nous, veuillez utiliser vos informations personnelles pour vous connecter.",
        signin: "Se connecter",
        createaccount: "Créer un compte",
        oruseyourpersonnalmail: "Ou utilisez votre courrier personnel pour l'inscription",
        email2: "Courrier électronique",
        signup: "S'inscrire",
        memberarea: "Espace membre",
        yourmailpseudo: "Votre adresse e-mail / Nom d'utilisateur",
        yourpassword: "Votre mot de passe",
        idorpasswordforgot: "Cliquez ici si vous avez oublié votre mot de passe",
        settingupaccount: "Configurer votre compte",
        settingupaccountint : "Pour créer votre compte, vous devez fournir les informations suivantes :",
        yourfirstname : "Votre prénom",
        yourlastname : "Votre nom de famille",
        yourusername : "Votre nom d'utilisateur",
        yourskypeid : "Votre identifiant skype",
        yourbirthdate : "Votre date de naissance",
        yourgender : "Votre sexe",
        yourphonenumber : "Votre numéro de téléphone",
        yourmailaddress : "Votre adresse électronique",
        appartroad : "Numéro d'appartement / de route",
        yourcitydep : "Votre ville / Département",
        yourpobox : "Votre boîte postale",
        yourcountry : "Votre pays",
        agreepp1: "Avant de soumettre vos informations, vous devez accepter notre",
        agreepp2: "",
        note: "Note",
        notecontent: "Pour activer votre compte, veuillez consulter votre boîte aux lettres électronique",
        createmyaccount: "Créer mon compte",
        male: "Homme",
        female: "Femme",
        other: "Autre",
        mailexists: "Cette adresse mail existe déjà",
        usernameexists: "Ce nom d'utilisateur existe déjà",
        dashboard: "Tableau de bord",
        dashboard1: "Gérez votre compte Creativ'Network. Modifiez vos paramètres et consultez les rapports",
        dashboard2: "Canal de contrôle",

        information: "Vos informations", 
        information1: "Gérer vos informations personnelles",
        information2: "Modifier votre mot de passe",

        yourapplication: "Votre candidature",
        application1: "Télécharger / Modifier votre CV ",
        application2: "Vérifier l'état de votre demande d'emploi",

        integration: "Votre intégration",
        integration1: "Télécharger / modifier les documents requis",
        integration2: "Télécharger / Modifier vos informations de paiement",

        info11 : "Changer votre photo de profil",
        info12 : "Télécharger votre photo à partir du navigateur",
        info13 : "Navigateur",
        info14 : "Télécharger votre photo depuis votre appareil photo",

        save: "Sauvegarder",
        admin: "Administrateur",
        blogger: "Blogueur",
        teamleader: "Chef d'équipe",
        adboard : "Tableau de bord de l'administrateur",
        tldboard : "Tableau de bord du chef d'équipe",
        bdboard : "Tableau de bord du blogueur",
        notifs: 'Notifications',
        logout: 'Déconnexion',
        actualpassword: "Votre mot de passe actuel",
        newpassword: "Votre nouveau mot de passe",
        confirmnewpassword: "Confirmez votre mot de passe",

        app11 : "Téléchargez votre CV",
        app12 : "Téléchargez votre CV à partir d'un navigateur",

        appstatus1: "Formulaire de candidature",
        submittedon:"Soumis le",
        appstatus2: "Test",
        notyet: "Pas encore fait",
        reviewedon: "Examiné le",
        appstatus3: "État d'avancement de l'examen",
        reviewed: "Examiné",
        appstatus4: "Décision de l'administrationn",
        hired: "Félicitations, vous avez été embauché(e), passez à l'étape suivante : votre intégration.",
        nothired: "Nous sommes désolés, mais votre demande n'a pas été acceptée.",
        idtl : "ID du chef d'équipe",
        ida : "ID de l'administrateur",
        idb : "ID du blogueur",
        positionoverview : "Vue d'ensemble du poste",
        adminstaff : "Personnel administratif",
        jobposts : "Offres d'emploi",
        jobapplication : "Candidatures reçues",
        newemptraining : "Formation des nouveaux employés",
        empintegration : "Intégration des employés",
        blogposts : "Articles de blog",
        details : "Détails",
        cnmembers : "Membres de Creativ-Network",
        sn : "Réseau social",
        aas1 : "Liste du personnel administratif",
        aas2 : "Ajouter du personnel administratif",
        aja1 : "Toutes les candidatures",
        aja2 : "Vérifier les détails des candidatures",
        aja3 : "Candidatures retenues",
        anet1 : "Programme de formation",
        anet2 : "Ajouter des documents de formation",
        anet3 : "Agents en attente de connexion",
        anet4 : "Attribution de la connexion",
        aei1 : "Vérification des nouveaux agents",
        abp1 : "Liste des blogueurs",
        abp2 : "Ajouter des blogueurs",
        abp3 : "Liste des billets de blog",
        abp4 : "Ajouter des articles de blog",
        ajp1 : "Liste des offres d'emploi",
        ajp2 : "Ajouter des offres d'emploi",
        acnm1 : "Liste des membres",
        acnm2 : "Ajouter des membres",
        anl1 : "Liste des abonnés",
        anl2 : "Toutes les newsletters",
        anl3 : "Ajouter une newsletter",
        asn1 : "Tous les réseaux sociaux",
        passportcopy: "Copie du passeport, du permis de conduire ou d'une pièce d'identité gouvernementale",
        proofofaddress: "Justificatif de domicile",
        yourselfie: "Votre selfie avec votre carte d'identité",
        freelance: "Contrat de freelance",
        integ1up1: "Téléchargez votre preuve d'identité à partir du navigateur",
        integ1up2: "Téléchargez votre justificatif de domicile à partir d'un navigateur",
        integ1up3: "Téléchargez votre selfie à partir du navigateur",
        integ1up4: "Téléchargez votre selfie depuis votre appareil photo",
        integ1download: "Téléchargez le contrat freelance, signez-le et renvoyez-le ici",
        integ1up5: "Téléchargez votre contrat freelance signé à partir de votre navigateur",
        download: "Télécharger",

        
        paypalaccount: "Compte Paypal",
        yourpaypal: "(Votre adresse email Paypal)",
        wiseaccount: "Compte avisé",
        yourwise: "(Votre adresse électronique)",
        bitesafeaccount: "Compte Yoursafe",
        yourbitesafe: "(Votre adresse e-mail Yoursafe)",
        bankaccount: "Compte bancaire",
        accountholder: "Nom du titulaire du compte:",
        holderaddress: "Adresse du titulaire du compte:",
        banksholder: "Nom de la banque du titulaire :",
        bankaddress : "Adresse de la banque du titulaire: ",
        iban: "IBAN / numéro de compte ",
        swiftcode: "Code Swift / BIC: ",
        year: 'Année',
        month: 'Mois',
        date: 'Date',
        week: 'Semaine',
        today: "Aujourd'hui",
        startingdate : "Date de début",
        closingdate : "Date de clôture",
        adminstafflogin : "Nom d'utilisateur du personnel administratif",
        adminstaffloginpassword : "Mot de passe de connexion du personnel administratif",
        actions : 'Actions',
        suspend : "Suspendre",
        fire : "Bannir",
        candidateid : "ID du candidat",
        createnewadminstaff : "Créer un nouveau personnel administratif",
        language: "Langue",
        edit : "Modifier",
        openingposition : "Position d'ouverture",
        jobtitle : "Titre du poste",
        jobdescription : "Description du poste",
        openclose : "Disponible / Indisponible",
        jobopen: "Disponible",
        jobclose: "Indisponible",
        delete : "Supprimer",
        applicantfirstname : "Prénom du demandeur",
        applicantlastname : "Nom de famille du candidat",
        applicantcountry : "Pays du candidat",
        application : "Candidature",
        checkthapplication : "Vérifier la demande",
        agentfirstname : "Prénom de l'agent",
        agentlastname : "Nom de famille de l'agent",
        agentcountry : "Pays de l'agent",
        agentemail : "Courriel de l'agent",
        agentskypeid : "ID skype de l'agent",
        trainingschedule : "Programme de formation",
        sendconfirmationonemailtoagent : "Envoyer un e-mail de confirmation à l'agent",
        memberopt1 : "Gérez vos informations",
        memberopt2 : "Vérifiez votre demande d'emploi",
        memberopt3 : "Procédez à votre intégration",
        awaitingforlogin : "En attente de connexion",
        awaitingforemailconf : "En attente de l'e-mail de confirmation",
        agentlogin : "Connexion de l'agent",
        freelancecontract : "Contrat de freelance",
        trainingdocumentssigned : "Documents de formation signés",
        proofofresidence : "Preuve de résidence",
        paymentmethod : "Mode de paiement",
        provided : "Fourni",
        notprovided : "Non fourni",
        bank: "Banque",
        wise: "Wise",
        paypal: "Paypal",
        Yoursafe: "Yoursafe",
        activeblogger : "Blogueur actif",
        bloggerfirstname : "Prénom du blogueur",
        bloggerlastname : "Nom de famille du blogueur",
        bloggerloginid : "ID de connexion du blogueur",
        bloggerloginpassword : "Mot de passe de connexion au blogueur",
        blogposttile : "Titre de l'article de blog",
        blogpostcategory : "Catégorie de l'article de blog",
        blogpostlink : "Lien de l'article de blog",
        category: "Catégorie",
        newmembers: "Nouveaux membres",
        allmembers : "Tous les membres",
        inscriptdate : "Date d'inscription",
        memberfirstname : "Prénom du membre",
        memberlastname : "Nom de famille du membre",
        loginpassword : "Mot de passe de connexion",
        emailaddressconfirmed : "Adresse électronique confirmée",
        country : "Pays",
        newsubscribers : "Nouveaux abonnés",
        allsubscribers : "Tous les abonnés",
        subscriptiondate : "Date d'abonnement",
        topic : "Sujet",
        allnewsletters : "Toutes les lettres d'information",
        releasedate : "Date de publication",
        releaselanguage : "Langue de publication",
        linktothenewsletter : "Lien vers la lettre d'information",
        sendtoallsubscribers : "Envoyer à tous les abonnés",

        agentid : "ID de l'agent",
        trainingprocess : "Processus de formation",
        integrationprocess : "Processus d'intégration",
        jobposition : "Poste de travail",
        training: "Formation",
        integrationn: "Intégration",
        inprogress: "En cours",
        done: "Terminé",
        role : "Rôle",
        skypeid : "Skype ID",
        jobid : "Job ID",
        illustrationphoto : "Photo d'illustration",
        location : "Emplacement",
        jobrequirement: "Conditions d'emploi",
        salaryscale : "Échelle des salaires",
        incentives : "Incentives",
        openingdate : "Date d'ouverture",
        telephonenumber : "Numéro de téléphone",
        createnewjobposts : "Créer un nouvel emploi",
        haveexperience : "Ont de l'expérience",
        howtheyfindus : "Comment ils nous ont trouvé",
        motivation : "Motivation",
        testresult : "Résultat du test",
        decision : "Décision",
        hired2: "Engagé",
        rejected : "Rejeté",
        description: "Description",

        name : "Nom",
        document : "Document",
        blogpostid : "ID de l'article de blog",
        bloggerid : "ID du blogueur",
        blogposttitle : "Titre de l'article de blog",
        blogposttext : "Texte de l'article de blog",
        publishnewblogpost : "Publier un nouvel article de blog",
        createnewblogger : "Créer un nouveau blogueur",
        createnewlogin : "Créer un nouveau login",
        addblogpostcategory : "Ajouter une catégorie d'article de blog",
        blogpostcategoryid : "ID de la catégorie de l'article de blog",
        memberid : "ID du membre",
        addnewmemberandsendnotification : "Ajouter un nouveau membre et envoyer une notification",
        newsletterid : "ID de la lettre d'information",
        adminid : "ID Admin",
        newslettercategory : "Catégorie de la lettre d'information",
        newslettertitle : "Titre de la lettre d'information",
        newslettertext : "Texte de la lettre d'information",
        allsubscribersemail : "Adresse électronique de tous les abonnés",
        publishnewnewsletter : "Publier une nouvelle lettre d'information",
        link : "Lien",
        hello : "Bonjour",

        tlintint1: "Vous voulez travailler à domicile en tant que",
        tlintint2: "d'opérateurs de chat de rencontres ?",
        tlint1 : "1. un leadership qui a de l'impact : Vous avez la possibilité de travailler en tant qu'opérateur de chat sur des sites de rencontres",
        tlint2 : "2. possibilité de croissance de l'équipe : Guidez et développez une équipe soudée, favorisant l'épanouissement personnel et professionnel, dans un environnement de travail favorable.",
        tlint3 : "3. contribution innovante : Introduire des solutions créatives, façonner l'avenir des rencontres en ligne et améliorer les services de chat, contribuant ainsi à l'innovation dans le secteur.",
        tlint4 : "4. reconnaissance et accomplissement : Diriger une équipe performante, dépasser les objectifs et gagner en reconnaissance, avec un salaire compétitif et des incitations attrayantes.",
        tlint5 : "5. récompenses pour l'excellence : Bénéficiez d'un bon salaire, de primes lucratives et d'une reconnaissance pour avoir atteint vos objectifs, en ayant un impact significatif chez Creativ'Network.",
        tlabint1: "En quoi consiste le travail d'un",
        tlabint2: "d'opérateurs de chat de rencontres ?",
        tlab1 : "1. le leadership et la coordination : En tant que chef d'équipe, la supervision de tous les opérateurs de chat de rencontres implique de fournir un leadership fort, de guider l'équipe et d'assurer une communication cohésive",
        tlab2 : "2. gestion des performances : Définir et surveiller les mesures de performance, telles que les temps de réponse et la satisfaction des utilisateurs, afin d'obtenir un service de haute qualité et de favoriser une expérience positive pour les utilisateurs.",
        tlab3 : "3. développement de l'équipe : Favoriser la cohésion et la motivation de l'équipe en fournissant des conseils et un encadrement, et en encourageant un environnement de travail propice à l'épanouissement personnel et professionnel",
        tlab4 : "4. innovation dans les services de chat : Introduire des approches créatives pour améliorer les services de chat, se tenir au courant des tendances du secteur et adapter les stratégies pour améliorer l'engagement des utilisateurs",
        tlab5 : "5. assurance qualité : Veiller à ce que les opérateurs maintiennent un niveau de service élevé, répondre aux besoins des utilisateurs et résoudre rapidement les problèmes afin de maintenir une expérience positive et digne de confiance pour les utilisateurs",
        tlab6 : "6. centre de communication : Servir de point central pour la communication entre les opérateurs, la direction et les autres départements, en facilitant la collaboration pour atteindre les objectifs de l'organisation dans le domaine dynamique des rencontres en ligne.",
        tlab7 : "7. planification stratégique : Contribuer à la planification stratégique de l'équipe, en alignant les objectifs sur ceux de l'organisation dans le domaine dynamique des rencontres en ligne.",
        tllsint1: "Quelles sont les compétences de leadership requises pour être",
        tllsint2: "d'opérateurs de chat de rencontres ?",
        tlls11 : "Expérience en matière de leadership",
        tlls12 : "Expérience démontrée en matière de direction et de gestion d'équipes, démontrant des compétences efficaces en matière de leadership et de mentorat",
        tlls21 : "Compétences en matière de communication",
        tlls22 : "Solides compétences en communication orale et écrite pour faciliter une communication claire entre les membres de l'équipe et avec les autres départements",
        tlls31 : "Connaissance du secteur",
        tlls32 : "Familiarité avec le secteur des rencontres en ligne, compréhension de la dynamique des utilisateurs, des tendances et des défis liés à la fourniture de services de chat pour les rencontres",
        tlls41 : "Capacité à résoudre des problèmes",
        tlls42 : "Aptitude avérée à analyser et à résoudre les problèmes de manière efficace, notamment en traitant les questions relatives aux utilisateurs et en maintenant une expérience positive pour ces derniers",
        tlls51 : "Gestion des performances",
        tlls52 : "Expérience de la mise en place, du suivi et de l'optimisation des mesures de performance afin de garantir un service de haute qualité, la satisfaction des utilisateurs et l'efficacité de l'équipe",
        tlls61 : "Adaptabilité et innovation",
        tlls62 : "Capacité à s'adapter aux changements dans le secteur, à introduire des stratégies innovantes et à rester à la pointe des tendances émergentes dans les services de rencontres en ligne",
        tlls71 : "Compétences en matière de développement d'équipe",
        tlls72 : "Capacité avérée à favoriser la croissance de l'équipe, à fournir des conseils et un encadrement, et à créer un environnement de travail positif pour le développement personnel et professionnel",
        tlls81 : "Orientation vers l'assurance de la qualité",
        tlls82 : "Engagement à maintenir un niveau de service élevé, à répondre aux besoins des utilisateurs et à mettre en œuvre des mesures d'assurance qualité pour une expérience positive des utilisateurs",
        tlls91 : "Collaboration et coordination",
        tlls92 : "Solides compétences en matière de collaboration, servant de point central pour la communication et facilitant une coordination efficace entre les opérateurs, la direction et les autres départements",

        tlreint1: "Quelles sont les exigences pour être",
        tlreint2: "pour Creativ'Network ?",
        tlre12 : "Un chef d'équipe Creativ'Network doit maîtriser les principales langues de travail, avoir des compétences multilingues, s'exprimer clairement, comprendre les nuances régionales, avoir une bonne communication écrite et maîtriser les interactions avec les utilisateurs et la collaboration avec d'autres départements",
        tlre22 : "Fixer une limite d'âge de 25 ans et plus pour un chef d'équipe Creativ'Network permet d'assurer un équilibre entre expérience et maturité. Cette politique favorise la diversité des qualités de leadership tout en maintenant une équipe dynamique et efficace",
        tlre42 : "Les chefs d'équipe de Creativ'Network doivent reconnaître et accepter la déclaration de confidentialité et les conditions contractuelles, garantissant ainsi le respect de la protection des données et des obligations contractuelles, et favorisant une relation de travail sûre et transparente",
        tlre61 : "Rapports",
        tlre62 : "Les chefs d'équipe de Creativ'Network compilent des rapports détaillés, résumant les performances de l'équipe, les mesures d'engagement des utilisateurs et les problèmes abordés. Ces rapports guident les décisions stratégiques, identifient les domaines à améliorer et garantissent une prestation de services optimale",
        tlre72 : "Les chefs d'équipe de Creativ'Network s'engagent à travailler au moins 40 heures par semaine, avec une disponibilité occasionnelle le week-end et les jours fériés. Cela permet d'assurer un soutien continu à l'équipe et de maintenir des normes de service optimales",
        tlre81 : "Taxe",
        tlre82 : "En tant que travailleur indépendant, le chef d'équipe de Creativ'Network est responsable de la gestion et du paiement de ses propres impôts. Il doit notamment se conformer aux réglementations fiscales et remplir ses obligations financières dans ses juridictions respectives",
        tlre92 : "Les chefs d'équipe de Creativ'Network veillent au strict respect des directives légales et réglementaires, en favorisant un environnement conforme. Cela inclut le respect de la vie privée des utilisateurs, la protection des données et d'autres réglementations spécifiques à l'industrie afin de maintenir l'intégrité de la plateforme",
        tlic1 : "Vérification de l'âge : Creativ'Network vérifie l'âge des candidats au poste de chef d'équipe (25+) pour s'assurer que les candidats possèdent l'expérience, la maturité et la stabilité essentielles à un leadership efficace. Cette approche est conforme à l'objectif de l'entreprise de disposer de leaders chevronnés ayant fait leurs preuves, contribuant à une gestion d'équipe réussie et favorisant un environnement de travail positif",
        tlic2 : "Sécurité et confiance : La vérification de l'âge des candidats au poste de chef d'équipe de Creativ'Network âgés de plus de 25 ans est mise en œuvre pour renforcer la sécurité et la confiance. En garantissant la conformité à ce critère, la plateforme vise à établir une équipe de direction mature et expérimentée, favorisant un environnement sûr et digne de confiance pour les utilisateurs et les membres de l'équipe.",
        tlas1 : "Commencez par vous rendre sur le site officiel de Creativ'Network ou sur le portail des carrières.|Repérez l'offre d'emploi pour le poste de chef d'équipe et cliquez sur \"Postuler\".|Remplissez le formulaire de candidature, en indiquant vos informations personnelles, vos coordonnées, votre expérience pertinente et votre motivation à travailler avec nous.|Vous serez invité à passer un bref test de compétences, qui dure généralement une dizaine de minutes. Ce test évalue vos compétences en matière de leadership, de communication, de gestion des performances, d'innovation, de développement d'équipe, d'assurance qualité, de collaboration et d'adaptabilité.",
        tlas2: "Après avoir complété avec succès la demande initiale et le test de compétences, vous devrez vous soumettre à une procédure de vérification d'identité pour confirmer votre âge.|Il vous sera demandé de présenter une copie d'une pièce d'identité valide délivrée par l'État, telle qu'un passeport, un permis de conduire ou une carte d'identité gouvernementale. Ce document est utilisé pour vérifier que vous avez l'âge minimum requis pour le poste.",
        tlas3 : "Une fois votre candidature et votre vérification d'âge approuvées, vous serez convoqué à une session de formation en ligne via Skype.|Pendant la formation, les managers de Creativ'Network vous donneront des conseils et des instructions sur la manière de remplir efficacement votre rôle de chef d'équipe. Ils aborderont les politiques de l'entreprise, les meilleures pratiques et les stratégies de leadership et de communication qui vous permettront d'exceller dans votre fonction.",
        appformfor : "Formulaire de candidature au poste de",
        tofilltheapplicationform : "Pour remplir le formulaire de demande, veuillez vous connecter ou vous inscrire sur notre site web",
        supervisor: "Superviseur",
        qualitysupervisor: "Superviseur de la qualité",
        sintint1: "Vous voulez travailler à domicile en tant que",
        sintint2: "d'opérateurs de chat de rencontres ?",
        sint1 : "1. leadership en matière de qualité : Assurer l'excellence des services de chat, en créant des expériences positives pour les utilisateurs et en encourageant des interactions de qualité, tout cela depuis votre domicile.",
        sint2 : "2. une rémunération compétitive : Recevez un salaire généreux, des incitations lucratives et la flexibilité du travail à distance, offrant des récompenses financières pour le maintien de la qualité.",
        sint3 : "3. développement de l'équipe : Encadrer et guider les opérateurs, en favorisant la qualité du service, l'épanouissement personnel et le développement professionnel dans un environnement de travail virtuel",
        sint4 : "4. contributions innovantes : Introduire des stratégies créatives, améliorer les services de chat et contribuer à l'innovation dans le secteur, en garantissant une expérience utilisateur de qualité sur Creativ'Network",
        sint5 : "5. reconnaissance et récompenses : Diriger une équipe de qualité, dépasser les objectifs et être reconnu par un salaire compétitif, pour une carrière à distance épanouissante et gratifiante.",
        sint6 : "6. équilibre entre vie professionnelle et vie privée : Bénéficiez de la flexibilité du travail à domicile et d'un équilibre harmonieux entre vie professionnelle et vie privée tout en garantissant et en améliorant les normes de qualité au sein de Creativ'Network",
        sabint1: "En quoi consiste le travail d'un",
        sabint2: "d'opérateurs de Dating Chat ?",
        sab1 : "1. le contrôle des performances : Les superviseurs de la qualité surveillent étroitement les opérateurs, garantissant le respect des mesures de performance, des temps de réponse et de la satisfaction des utilisateurs, contribuant ainsi à une expérience positive pour les utilisateurs",
        sab2 : "2. retour d'information et formation : Fournir un retour d'information constructif, organiser des sessions de formation et guider les opérateurs afin qu'ils améliorent continuellement leurs compétences en matière de communication, garantissant ainsi des interactions de qualité.",
        sab3 : "3. résolution des problèmes : Répondre rapidement aux préoccupations des utilisateurs, résoudre les problèmes et maintenir un niveau de service élevé afin de garantir une expérience positive pour l'utilisateur",
        sab4 : "4. protocoles d'assurance qualité : Mettre en œuvre et appliquer les protocoles d'assurance qualité, en veillant au respect des normes établies pour les services de chat sur Creativ'Network",
        sab5 : "5. innovation et optimisation : Introduire des stratégies innovantes pour optimiser les services de chat, en restant à la pointe des tendances de l'industrie et en améliorant la qualité globale des interactions avec les utilisateurs.",
        sab6 : "6. collaboration au sein de l'équipe : Favoriser un environnement d'équipe collaboratif, en encourageant la communication et la collaboration entre les opérateurs, en assurant une approche cohésive pour fournir des services de chat de rencontres de haute qualité",
        sskint1: "Quelles sont les compétences requises pour être",
        sskint2: "d'opérateurs de chat de rencontres ?",
        leadership : "Leadership",
        communication : "Communication",
        analyticalskills : "Compétences analytiques",
        pbsolving : "Résolution de problèmes",
        qualityassurance : "Assurance qualité",
        traininganddev: "Formation et développement",
        innovation: "Innovation",
        collab: "Collaboration",
        adaptability: "Adaptabilité",
        ssk1 : "Compétences exceptionnelles en matière de leadership pour guider et inspirer une équipe d'opérateurs de chat, en favorisant un environnement de travail positif et productif",
        ssk2 : "Solides compétences en communication, tant à l'oral qu'à l'écrit, pour fournir des instructions claires, un retour d'information et une formation aux opérateurs, afin de garantir l'efficacité des interactions avec les utilisateurs",
        ssk3 : "Capacité à analyser les mesures de performance, à identifier les domaines à améliorer et à mettre en œuvre des stratégies pour améliorer la qualité globale du service de chat",
        ssk4 : "Aptitude à résoudre les problèmes des utilisateurs et à relever rapidement les défis opérationnels, en maintenant un niveau élevé de service et de satisfaction des utilisateurs",
        ssk5 : "Assurance qualité : Compréhension approfondie des protocoles d'assurance qualité, garantissant le respect constant des normes établies et l'optimisation des services de chat",
        ssk6 : "Formation et développement : Capacité à concevoir et à mener des sessions de formation, à développer les compétences des opérateurs et à favoriser l'amélioration continue de la communication et de la qualité du service",
        ssk7 : "Innovation : Capacité avérée à introduire des stratégies innovantes, à rester à l'avant-garde des tendances du secteur et à optimiser les services de chat dans un environnement dynamique de rencontres en ligne",
        ssk8 : "Collaboration : Des compétences de collaboration efficaces pour favoriser un environnement d'équipe cohésif, encourageant la communication et la collaboration entre les opérateurs pour une prestation de services sans faille",
        ssk9 : "Adaptabilité : Capacité à s'adapter à l'évolution de la dynamique du secteur, aux préférences des utilisateurs et aux nouvelles tendances, afin que les services de chat de Creativ'Network restent à la pointe de l'expérience des rencontres en ligne",
        langproficiency: "Compétences en français",
        agelimit : "Limite d'âge",
        bankaccountpaymentaccount : "Compte bancaire ou compte de paiement",
        acceptanceppterms : "Acceptation de la déclaration de confidentialité et des conditions contractuelles",
        equipmentresponsibility : "Responsabilité de l'équipement",
        reports: "Rapports",
        minweekly: "Heures hebdomadaires minimales",
        tax: "Taxe",
        adhlegalregguidelines : "Respect des lignes directrices légales et réglementaires ",
        sre1 : "Un superviseur de la qualité du réseau Creativ'Network doit maîtriser les principales langues de travail, être multilingue, s'exprimer clairement, comprendre les nuances régionales, avoir une bonne communication écrite et maîtriser les interactions avec les utilisateurs et la collaboration avec d'autres départements",
        sre2 : "La fixation d'une limite d'âge de 25 ans et plus pour un superviseur de la qualité de Creativ'Network peut garantir un équilibre entre l'expérience et la maturité. Cette politique favorise la diversité des qualités de leadership tout en maintenant une équipe dynamique et efficace",
        sre3 : "Vous devez avoir un compte bancaire, PayPal, Wise ou Yoursafe à votre nom. Il s'agit probablement d'un compte pour le traitement des paiements",
        sre4 : "Le superviseur qualité de Creativ'Network doit reconnaître et accepter la déclaration de confidentialité et les conditions contractuelles, afin de garantir le respect de la protection des données et des obligations contractuelles, et de favoriser une relation de travail sûre et transparente",
        sre5 : "Vous devez disposer de l'équipement nécessaire, notamment d'un ordinateur portable ou d'un PC, d'une connexion internet fiable et d'une source d'électricité et d'internet de secours pour garantir un travail ininterrompu",
        sre6 : "Le superviseur de la qualité du réseau Creativ compile des rapports détaillés, résumant les performances de l'équipe, les mesures d'engagement des utilisateurs et les problèmes abordés. Ces rapports guident les décisions stratégiques, identifient les domaines d'amélioration et garantissent une prestation de services optimale",
        sre7 : "Le superviseur de la qualité du réseau Creativ'Network s'engage à travailler au moins 40 heures par semaine, avec une disponibilité occasionnelle le week-end et les jours fériés. Cela permet d'assurer un soutien continu à l'équipe et de maintenir des normes de service optimales",
        sre8 : "En tant que travailleur indépendant, le superviseur qualité de Creativ'Network est responsable de la gestion et du paiement de ses propres impôts. Il doit notamment se conformer aux réglementations fiscales et s'acquitter de ses obligations financières dans ses juridictions respectives",
        sre9 : "Le superviseur de la qualité du réseau Creativ veille au strict respect des directives légales et réglementaires, favorisant ainsi un environnement conforme. Cela inclut le respect de la vie privée des utilisateurs, la protection des données et d'autres réglementations spécifiques à l'industrie afin de maintenir l'intégrité de la plateforme",
        sic1 : "1. vérification de l'âge : Creativ'Network vérifie l'âge des candidats au poste de Superviseur Qualité (25+) pour s'assurer que les candidats possèdent l'expérience, la maturité et la stabilité essentielles à un leadership efficace. Cette approche s'aligne sur l'objectif de l'entreprise qui est de disposer de leaders chevronnés ayant fait leurs preuves, contribuant à une gestion d'équipe réussie et favorisant un environnement de travail positif",
        sic2 : "2. sécurité et confiance : La vérification de l'âge des candidats au poste de superviseur de la qualité de Creativ'Network âgés de plus de 25 ans est mise en œuvre pour renforcer la sécurité et la confiance. En garantissant le respect de ce critère, la plateforme vise à mettre en place une équipe de direction mature et expérimentée, favorisant un environnement sûr et digne de confiance pour les utilisateurs et les membres de l'équipe. ",
        sic3 : "3. documents à vérifier : Pour se conformer aux réglementations légales et s'assurer que les candidats ont l'âge requis, Creativ'Network demandera une vérification de l'âge. Les documents tels que les permis de conduire et les cartes d'identité gouvernementales, les passeports comprennent généralement des informations sur la date de naissance, ce qui les rend aptes à confirmer que vous remplissez les conditions d'âge minimum fixées par Creativ'Network.",   
        
        
        sas3: "Une fois votre candidature et votre vérification d'âge approuvées, vous serez convoqué à une session de formation en ligne via Skype.|Pendant la formation, les chefs d'équipe de Creativ'Network vous donneront des conseils et des instructions sur la manière de remplir efficacement votre rôle de superviseur de la qualité. Ils aborderont les politiques de l'entreprise, les meilleures pratiques et les stratégies de leadership et de communication qui vous permettront d'exceller dans votre fonction.",
        tltext : "Diriger, inspirer et guider les opérateurs de chat, en favorisant des connexions et des expériences exceptionnelles dans le domaine des rencontres en ligne chez Creativ'Network.",
        supervisortext : "Superviser la qualité des services de chat de rencontres chez Creativ'Network. Guider les opérateurs vers l'excellence, en garantissant des expériences positives pour les utilisateurs dans le domaine des rencontres en ligne.",
        webdevtext : "Rejoignez Creativ'Network en tant que développeur web, pour concevoir des solutions numériques innovantes. Contribuez à notre équipe dynamique, qui façonne l'avenir des connexions en ligne",
        digmartext : "Améliorez votre présence en ligne chez Creativ'Network en tant que Digital Marketer. Vous serez responsable de l'engagement, de l'élaboration des stratégies et des initiatives de marketing dynamiques. Rejoignez-nous !",
        webdestext : "Créez des expériences numériques chez Creativ'Network en tant que Web Designer. Donnez vie à votre créativité en créant des sites web visuellement étonnants pour améliorer les connexions en ligne",

        qstesttitle : "Formulaire de test pour superviseur de la qualité",
        suptitle: "Test pour devenir superviseur qualité chez Creativ'Network",
        suptest: "Test du superviseur de la qualité",
        tltest : "Test du chef d'équipe",
        tltestform: "Formulaire de test du chef d'équipe",
        tltesttitle : "Test pour devenir chef d'équipe chez Creativ'Network",
        tltestint1 : "Le test de chef d'équipe de Creativ'Network évalue vos compétences en matière de leadership, de communication, de gestion des performances, d'innovation, de développement d'équipe, d'assurance qualité, de collaboration et d'adaptabilité.",
        suptestint1 : "Le test Quality Supervisor de Creativ'Network évalue vos capacités en matière de leadership, de communication, de résolution de problèmes, d'assurance qualité, de formation, d'innovation, de collaboration et d'adaptabilité.",
        thanktest1: "On vous remercie d'avoir effectué le test",
        finish: "Terminer",
        all: "Tout",
        loginfailed: "Échec de la connexion",
        
        usrnmpttrerr: "Le nom d'utilisateur ne doit pas contenir de caractères spéciaux",
        pobxpttrerr: "La boîte postale ne doit pas contenir de caractères spéciaux",
        phnpttrerr: "Le numéro de téléphone doit être au format international. Exemple: +123456789012",
        skpidpttrerr: "Identifiant skype invalide",
        brthdtpttrerr: "Format de date de naissance invalide",
        usrnmlngtherr: `Le nom d'utilisateur doit contenir au moins ${usernameminlength} caractères`,
        smthngwntwrng: "Un problème s'est produit. Veuillez réessayer",
        failed: "Echec",
        subsuccess : "Abonné avec succès",
        subfailed : "Déjà abonné(e)",
        mlunqerr: "Cette adresse email est déjà utilisée",
        usrnmunqerr: "Ce nom d'utilisateur est déjà utilisé",    
        iderr : "E-mail / Nom d'utilisateur invalide",
        mdperr : "Mot de passe invalide",
        
        mdpnid: "Le nouveau mot de passe et le mot de passe de confirmation ne sont pas identiques",
        curmdperr : "Le mot de passe actuel est incorrecte",
		entryrml: 'Entrez votre adresse e-mail',
        entrynwpss: "Entrez votre nouveau mot de passe",
        rcpcpt: "Retrouvez votre compte",

        chckrsm : "Voir votre CV actuel",
        nyrsm : "Aucun CV n'a encore été fourni",
        chckpi : "Voir votre preuve d'identité actuelle",
        chckpa : "Voir votre justificatif de domicile actuel",
        chcksl : "Voir votre selfie actuel",
        chckc : "Voir votre contrat actuel signé",
        nypi : "Aucune preuve d'identité n'a encore été fournie",
        nypa : "Aucune preuve d'adresse n'a encore été fournie",
        nysl : "Pas de selfie fourni pour l'instant",
        nyc : "Aucun contrat en cours signé n'a encore été fourni",
        rsm : "CV",
        pi : "Preuve d'identité",
        pa : "Justificatif de domicile",
        slfi : "Selfie",
        opnngclsngdterr: "La date d'ouverture doit être antérieure à la date de clôture",
        adddsccsflly: "Ajouté avec succès",
		svsucc : "Informations mises à jour avec succès",
        succ: "Blog créé avec succès",
        cat: "Catégorie invalide",
        accref: "Accès refusé",
        dltd: "Supprimé",
        rstr: "Restaurer",
        see: "Voir", 
        catsuccess : "Catégorie créée avec succès",
        catexst : "Catégorie déjà existante",
        crtadminfrmmbr : "Créer à partir d'un membre existant",
        success: "Succès",
        tktst: "Passer le test",
        expteam : "Avez-vous de l'expérience dans la direction d'équipe ?",
        expsup : "Avez-vous de l'expérience dans la supervision d'opérateurs de chat ?",
        avlbl : "Disponible",
        fllppfrm : "Remplir le formulaire de candidature",
        lljbs : "Tous les emplois",
        pnjbs : "Offres d'emploi",
        dfndt : "Date déterminée",
        indfndt : "Date indéterminée",
nonaut: "Seul l'auteur du blog peut supprimer et restaurer ce blog",

        adminsuccess: " Cette personne est désormais administrateur",
        rolenonaut: "Rôle non autorisé",
        djadmin: "Cette personne est déjà administrateur",
        usrntfnd: "Aucun utilisateur trouvé",
        newbloggersucc: "Cette personne est désormais blogueur",
        djblog: "Cette personne est déjà blogueur",
        nw: "Nouveau",
        bfrsbmttng : "Avant de soumettre",
        upldrsmpdf : "Téléchargez votre CV",
        umstupldyrcv: "Vous devez télécharger votre CV",
       gndr : "Genre",
        citydep : "Ville / Département",
        pobox : "PO BOX",

        crtd : "Créé",
        dcnnctd : "Déconnecté",
        sbntfnd : "Abonné introuvable",    
        sspndd: "Suspendu",
        susp: "Votre compte est actuellement suspendu et sera réactivé le ",
        reak: "",
        svd : "Sauvegardé(s)", 
tstalrddn: "Vous avez déjà postulé pour ce poste",
            gvlgn: "attribuer un login",
                frmtdcnntrv: "Document de formation non trouvé",
        sndmaildocincomp : "Envoyer un courriel à propos des documents incomplets",
        vldtinteg : "Ajouter à la liste de formation",
        nshdtrnng : "Formation terminée",
        cnfgtrnng : "Configurer la formation",
        sndml: "Envoyer un email",
        mrksfnshd: "Marquer comme terminé",
        bn: "E-mail banni",
        cypm: "Choisissez votre mode de paiement",
        aas3 : "Liste des chefs d'équipe",
tllgn : "Login du chef d'équipe",
aas4 : "Ajouter un chef d'équipe",
createnewtl : "Créer un nouveau chef d'équipe",
strthr : "Heure de début",
        dnhr : "Heure de fin",
        chngpay : "Si vous souhaitez modifier vos informations de paiement, veuillez contacter votre chef d'équipe ou votre responsable",
        idm : "ID du gestionnaire",
        mdboard : "Tableau de bord du gestionnaire",
        manager: "Gestionnaire",
        mrk : "Marquer comme lu",
 agntslst: "Liste des agents",
 aas5 : "Liste du gestionnaire",
aas6 : "Ajouter un gestionnaire",
createnewmn : "Créer un nouveau gestionnaire",
pdf : "Seul le format PDF est accepté",
agntnm: "Nom de l'agent",
        agntpass: "Mot de passe de l'agent",
        asn2 : "Ajouter un réseau social",


    },
    { // en
        cn: "Creativ'Network",
        french: 'French',
        english: 'English',
        italian: 'Italian',
        dutch: 'Dutch',
        spanish: 'Spanish',
        german: 'German',
        usermanual: "User Manual",
        formulaire: 'Application form',

        firstname: 'First name',
        lastname: 'Last name',
        email: 'Email address',
        birthday: 'Date of birth',
        phone: 'Phone',
        skypename: 'Skype name',
        whichchatlanguage: 'Which chat language are you applying for?',
        timesavailable: 'Times available via telephone',
        expchatmod: 'Do you have experience in chat moderation?',
        hearabout: 'How did you hear about us?',
        tellus: 'Please tell us something about yourself and why we should hire you!',
        submitapp: 'Submit',
        yes: 'Yes',
        no: 'No',
        friends: 'Friends',
        from: 'From',
        to: 'to',

        required: 'This field is required',
        invalidemail: 'Invalid email',

        loginfailusername: 'Username not recognised',
        loginfailpass: 'Invalid password',
        login: 'Login',
        username: 'Username',
        password: 'Password',
        test: 'English Chat Moderator Test Form',
        situation: 'Situation',
        send: 'Send',
        qreturn: 'Do you really want to quit the test?',

        cointint: 'Want to work at home as a dating chat operator with',
        coint1: "Handsome payout: Earn from 0.12€ to 0.15€ per sent message with Creativ'Network",
        coint2: "Remote Work: Enjoy the flexibility of working from home.",
        coint3: 'Communication Skills: Enhance your quality in empathy, active comprehension and fluency in communication',
        coint4: 'Work-Life Balance: Better manage your time with flexible hours.',
        applynow: 'Apply now',

        coabint1: 'What is a dating',
        coabint2: 'job about?',
        chatoperator: 'chat operator',
        coab1: 'Connecting with Lonely Individuals: Many users on dating platforms are seeking companionship or someone to talk to due to feelings of loneliness. Chat operators offer a listening ear and emotional support.',
        coab2: 'Text-Based Communication: The primary mode of communication is through written text messages on the platform. Operators maintain ongoing conversations with users',
        coab3: 'Varied Conversational Topics: Chat operators engage users in discussions on a wide range of topics, from everyday subjects like sports and weather to more personal and intimate topics, including dreams, fantasies, and other areas of interest.',
        coab4: 'Open-Minded Approach: This job often requires an open-minded and non-judgmental attitude, as users may have diverse interests, experiences, and desires.',
        coab5: 'Providing Companionship: Operators play a role in providing a sense of companionship and connection to users who may be seeking emotional support or simply want someone to talk to.',
        coab6: 'Anonymous: Chat operators will work 100% anonymous. Our identity will never be revealed to anyone in any dating websites',
        coab7: 'Independence and flexibility: Chat operators will work at your own convenience',

        home: 'Home',
        ourmissions: 'Our missions',
        company: "Values",
        ourcompany: 'Our values',
        ouradvantages: 'Our advantages',
        jobs: 'Jobs',

        slog: '"Where creativity meets opportunity"',
        copyright: "©2023-2024 copyright Creativ’Network. All rights reserved.",
        foexpl: "Creativ'Network, the innovative platform connecting web talents with future-focused roles, shaping the digital world. Discover your potential, thrive in web moderation, development, marketing, big data, and more.",
        focontact1: "Main address : 71-75 Shelton Street, Covent Garden, London, WC2H 9JQ",
        focontact2: "Belgium office representative: Roodborstjesstraat 4, Wondelgem 9032",
        focontact3: 'contact@creativ-network.net',
        subscribe: 'Subscribe',
        agree1: 'Yes, I agree with the',
        agree2: 'and',
        agreelink1: 'privacy policy',
        agreelink2: 'terms and conditions.',
        useful: 'Useful',
        links: 'Links',
        oursing: 'Our',
        contact: 'Contact',
        subscribetoour: 'Subscribe to our',
        newsletter: 'Newsletter',

        consents: 'Consent',
        termsAgree1: 'Yes, I agree with the',
        termsAgree2: 'and',
        termsAgreeLink1: 'privacy policy',
        termsAgreeLink2: 'terms and conditions',

        termsAgreeLink2real: "terms and conditions of use",
        consentsErr: 'You must agree the privacy policy and terms and condition to subscribe',
        
        coscint1: 'How will the',
        coscint2: "'s salary be calculated?",
        cosc1: "Base Pay per Message: The operator is typically paid a predetermined amount for each message sent. The base pay per message sent is 0.12€.",
        cosc2: "Monthly Message Count: At the end of the month, the total number of messages sent by the operator is calculated.",
        cosc3: "Payout Calculation: The operator's earnings for the month are calculated by multiplying the total message count by the base pay per message. For instance, if you sent 5,000 messages, you would earn 600€ (5,000 messages * 0.12€ per message). With Creativ'Network, an operator can easily earn per month 1,200€ with 10K sent messages.",
        cosc4: "Performance Bonus: In some cases, operators with exceptional performance may receive a bonus in addition to their commission. The bonus might be based on criteria like the number of satisfied customers, response time, or positive user feedback.",
        cosc5: "Christmas Bonus: As an additional incentive, Creativ'Network offers a Christmas bonus to our operators who have been with the company for more than 2 years.",

        corint1: "What are the requirements to be a chat operator for",
        corint2: "?",
        cor11: "English Proficiency",
        cor12: "Proficiency in English is a requirement, as chat operators are expected to communicate with users in English effectively.",
        cor21: "Age Limit",
        cor22: "Applicants should be at least 18 years old to be eligible for this position.",
        cor31: "Bank Account or Payment Account",
        cor32: "You should have a bank account, PayPal, Wise, or Yoursafe account in your name. This is likely for payment processing purposes.",
        cor41: "Acceptance of Privacy Statement and Contract Terms",
        cor42: "Applicants must be willing to accept and adhere to Creativ'Network's privacy statement and contract terms, which often outline the responsibilities, guidelines, and code of conduct for chat operators.",
        cor51: "Equipment Responsibility",
        cor52: "You are expected to have the necessary equipment, including a laptop or PC, a reliable internet connection, and a backup source of electricity and internet to ensure uninterrupted work.",
        cor61: "Minimum Message Requirement",
        cor62: "Chat operators are typically required to send a minimum number of messages per week. In this case, a minimum of 250 outgoing messages per week is expected.",
        cor71: "Minimum weekly hours",
        cor72: "Even if you work independently, you should at least work 4 hours a day, and 20 hours weekly at your own convenience.",
        cor81: "Tax",
        cor82: "As an independent worker, you shall be responsible for your own taxes.",
        cor91: "Adherence to Legal and Regulatory Guidelines: ",
        cor92: "Every chat operator must follow the rules given by the team leader during the training.",

        coicint1: "What does",
        coicint2: "need to check your identity?",
        coic1: "Age Verification: For various reasons, dating websites and platforms often have age restrictions to ensure compliance with legal regulations. By checking your identity, Creativ'Network can verify that you meet the minimum age requirement ie 18 years old to use our services. This helps the platform adhere to legal standards, protect minors, and maintain a responsible and safe community.",
        coic2: "Security and Trust: Identity verification enhances the security and trustworthiness of the platform. It assures users that Creativ'Network is interacting with individuals who are who they claim to be, which is essential for building trust and ensuring a secure environment for this position.",
        coic3: "Documents for verification: To comply with legal regulations and ensure that candidates are of the appropriate age, Creativ'Network will request age verification. Documents such as driver's licenses and government IDs typically include date of birth information, making them suitable for confirming that you meet the minimum age requirement set by the platform.",

        coasint1: "3 easy steps to apply for the position at",
        coasint2: "",
        coas11: "Easy Application and Skills Test",
        coas12: ["Start by visiting Creativ'Network's official website or career portal.", 'Find the job opening for the dating chat operator position and click "Apply".', "Fill out the application form, which includes providing your personal information, contact details, relevant experience, your motivation to work with us.", "You will be asked to complete a short skills test, typically taking around 10 minutes. This test assesses your ability to engage in chat-based conversations and demonstrate your communication skills."],
        coas21: "ID Verification for Age Confirmation",
        coas22: ["After successfully completing the initial application and skills test, you will be required to go through an ID verification process to confirm your age.", "You will be instructed to submit a copy of a valid government-issued ID, such as a passport, driver's license, or government ID card. This document is used to verify that you meet the minimum age requirement specified for the position."],
        coas31: "Online Training via Skype",
        coas32: ["Once your application and age verification are approved, you will be scheduled for an online training session via Skype.", "During the training, Creativ'Network's team leaders will provide guidance and instructions on how to effectively perform your role as a dating chat operator. They will cover company policies, best practices, and communication strategies to excel in your position."],

        coafint: "Application form for",

        ppint: ["At Creativ-Network, accessible at https://creativ-network.net, one of our main priorities is the privacy of our visitors. This Privacy Policy document contains types of information that is collected and recorded by Creativ-Network and how we use it.", "If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us through email at data@creativ-network.net", "This privacy policy applies only to our online activities and is valid for visitors to our website with regards to the information that they shared and/or collect in Creativ-Network. This policy is not applicable to any information collected offline or via channels other than this website."],
        pp1t: ["1 - Consent", ["By using our website, you hereby consent to our Privacy Policy and agree to its terms."]],
        pp2t: ["2 - Information we collect", ["The personal information that you are asked to provide, and the reasons why you are asked to provide it, will be made clear to you at the point we ask you to provide your personal information.", "If you contact us directly, we may receive additional information about you such as your name, email address, phone number, the contents of the message and/or attachments you may send us, and any other information you may choose to provide.", "When you register for an Account, we may ask for your contact information, including items such as name, company name, address, email address, and telephone number."]],
        pp3t: ["3 - How we use your information", ["We use the information we collect in various ways, including to:", "• Provide, operate, and maintain our website", "• Improve, personalize, and expand our website", "•    Understand and analyze how you use our website", "• Develop new products, services, features, and functionality", "• Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes", "• Send you emails", "• Find and prevent fraud"]],
        pp4t: ["4 - Log Files", ["Creativ-Network follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services' analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users' movement on the website, and gathering demographic information."]],
        pp5t: ["5 - Cookies and Web Beacons", ["Like any other website, Creativ-Network uses ‘cookies'. These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information."]],
        pp6t: ["6 - Advertising Partners Privacy Policies", ["You may consult this list to find the Privacy Policy for each of the advertising partners of Creativ-Network.", "Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on Creativ-Network, which are sent directly to users' browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.", "Note that Creativ-Network has no access to or control over these cookies that are used by third-party advertisers."]],
        pp7t: ["7 - Third-Party Privacy Policies", ["Creativ-Network's Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options. You may find a complete list of these Privacy Policies and their links here: Privacy Policy Links.", "You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers' respective websites. What Are Cookies?"]],
        pp8t: ["8 - CCPA Privacy Policy (Do Not Sell My Personal Information)", ["Under the CCPA, among other rights, California consumers have the right to:", "Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.", "Request that a business delete any personal data about the consumer that a business has collected.", "Request that a business that sells a consumer's personal data, not sell the consumer's personal data.", "If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us."]],
        pp9t: ["9 - GDPR Privacy Policy (Data Protection Rights)", ["We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:", "The right to access – You have the right to request copies of your personal data. We may charge you a small fee for this service.", "The right to rectification – You have the right to request that we correct any information you believe is inaccurate. You also have the right to request that we complete the information you believe is incomplete.", "The right to erasure – You have the right to request that we erase your personal data, under certain conditions.", "The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.", "The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.", "The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.", "If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us."]],
        pp10t: ["10 - Children's Information", ["Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.", "Creativ-Network does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from."]],

        lastupdated: "Last Updated",
        tcint: ["Please carefully review the following Terms and Conditions before using Creativ'Network's website."],
        tc1t: ["1. Acceptance of Terms", ["By accessing or using Creativ'Network's website, you agree to abide by and be bound by these Terms and Conditions. If you do not agree with any part of these terms, please do not use our website."]],
        tc2t: ["2. User Eligibility", ["You must be at least 18 years old to use our website. By using our services, you confirm that you meet this age requirement."]],
        tc3t: ["3. Website Use", ["- You agree to use the website in compliance with all applicable laws and regulations", "- You shall not engage in any illegal, harmful, or abusive activities on our platform", "- You agree not to impersonate others or create false identities."]],
        tc4t: ["4. Privacy and Data Handling", ["Our Privacy Policy outlines how we collect, use, and protect your personal data. By using our website, you consent to the data practices described in our Privacy Policy."]],
        tc5t: ["5. Intellectual Property", ["All content on our website, including text, graphics, logos, and software, is protected by intellectual property rights. You may not use, reproduce, or distribute our content without our explicit permission."]],
        tc6t: ["6. User-Generated Content", ["When you contribute content to our platform, you grant us a non-exclusive, royalty-free license to use, modify, and distribute your content."]],
        tc7t: ["7. Changes to the Terms", ["We may update these Terms and Conditions at any time. Your continued use of the website after such changes indicates your acceptance of the revised terms."]],
        tc8t: ["8. Disclaimers and Limitations of Liability", ["We provide our website \"as is\" and make no warranties or guarantees regarding its accuracy, availability, or performance. We shall not be liable for any direct, indirect, or consequential damages arising from your use of our website."]],
        tc9t: ["9. Governing Law and Jurisdiction", ["These terms and your use of our website are governed by the laws of the United Kingdom. Any disputes shall be resolved in the courts of the United Kingdom."]],
        tc10t: ["10. Contact Information", ["If you have any questions or concerns about these terms, please contact us at contact@creativ-network.net."]],

        jobsint1: "Choose the job that fits to your skills",
        jobsint2: "Live a great experience with an awesome team!",
        jobsat: "Jobs at",

        hirenow: "HIRE NOW!",
        closed: "CLOSED!",
        detailsandapply: "See details and apply",
        appclosed: "Application closed",

        jobco: ["Dating Chat Operator", "You're passionate about chatting and running dating sites and communities job."],
        jobwdev: ["Web Developer", "You're passionate about chatting and running dating sites and communities job."],
        jobtl: ["Team Leader", "You're passionate about chatting and running dating sites and communities job."],
        jobdm: ["Digital Marketer", "You're passionate about chatting and running dating sites and communities job."],
        jobsup: ["Supervisor", "You're passionate about chatting and running dating sites and communities job."],
        jobwdes: ["Web Designer", "You're passionate about chatting and running dating sites and communities job."],
        
        cotest: "Chat Operator Test",
        cotesttitle: "Test to become a Dating Chat Operator at Creativ'Network",
        cotestint1: `"Creativ'Network's Dating Chat Operator test assesses communication skills, empathy, and engagement abilities for effective and respectful user interactions."`,
        cotestint2: "Take a few moments to reply to all situations before we go further on your application.",
        goodluck: "Good Luck!",
        number: "N°",
        youranswer: "Your answer",
        next: "Next",
        back: "Back",
        blog: "Blog",
        stat: 'Statistics',
        at: 'At',
        ourstat: ", our statistics paint a vivid picture of our success and growth. With a dedicated team, numerous projects, a vast network of partners, and years of experience, we are a thriving force in the industry. Let's delve into the details to discover our journey.",
        team: 'Team Members',
        projects: 'Projects',
        netpart: 'Network Partners',
        years: 'Years in business',
        testimonial: 'Testimonial',
        dedicated: 'Dedicated team members at',
        sharing: ', sharing their experiences, insights, and enthusiasm for a rewarding workplace where collaboration thrives.',
        testimonialperson1: ["Marie C", "Dating Chat Operator", `"Working as a Dating Chat operator at Creativ'Network is incredible. I get to connect people and make a difference in their lives every day. It's both rewarding and fun!"`], 
        testimonialperson2: ["Lucas P", "Dating Chat Operator", `"Creativ'Network is the perfect place to embrace your passion for helping not feeling lonely. The team is fantastic, and the atmosphere is supportive. I love what I do."`],
        testimonialperson3: ["Sophie M", "Dating Chat Operator", `"Creativ'Network offers a unique platform to build meaningful connections. As a Dating Chat operator, I feel like I'm part of something special, discussing people and making them happy."`],
        testimonialperson4: ["Alex R", "Web Developer Front End", `"Creativ'Network challenges me as a web developer. The innovative projects and supportive team push my skills to new heights. I'm proud to be part of this dynamic tech environment."`],
        testimonialperson5: ["Emma H", "Web Developer Back End", `"I've found my dream job as a web developer at Creativ'Network. The company's commitment to cutting-edge technology and growth opportunities keeps me inspired and excited."`],
        testimonialperson6: ["Tommy J", "Web Designer", `"As a web designer at Creativ'Network, I have the creative freedom to shape visually appealing platforms. It's an inspiring place that values design aesthetics and user experience, making work truly enjoyable."`],


        titlesuffix: " - Creativ-Network",
        titlejobs: "Jobs",
        titlechatoperator: "Chat operator",
        titletest: "Test ",
        titlepp: "Privacy Policy",
        titleterms: "Terms",

        wantto: "Want to become fulfilled workers with ",
        areyou: "Are you passionate about web work? Do you have experience in any of the following areas: chat, web development, digital marketing, data labeling, teleassistance and customer relations, telesales, copy writing, teleprospecting, social media management, HR, accounts, web design...? Join our community now! ",
        check: 'Check all jobs and apply',

        our: 'Our',
        missions: 'Missions',
        advantages: 'Advantages',


        mission1: 'Talent Identification and Acquisition: The company actively seeks out and recruits individuals with expertise in web-related domains. This involves identifying and attracting top talent from various sources, including job postings, online networks, and referrals.',
        mission2: "Skill Assessment and Screening: Creativ'Network rigorously assesses the skills and qualifications of potential candidates to ensure that they meet the company's specific requirements for the roles they are hiring for.",
        mission3: "Cultural Fit and Values Alignment: Beyond skills, the company assesses candidates for their alignment with the company's values and culture to foster a cohesive and productive work environment.",
        mission4: "Position Customization: Creativ'Network tailors positions to the strengths and skills of the hired talent, ensuring that roles are aligned with their expertise and interests.",
        mission5: "Talent Development and Retention: The company is committed to nurturing and retaining the talent it recruits by providing opportunities for growth and advancement within the organization.",
        mission6: "Market Analysis and Strategy: Creativ'Network keeps a pulse on industry trends, salary benchmarks, and evolving skill requirements to remain competitive in the web-related job market.",
        mission7: "Diversity and Inclusion: The company may actively promote diversity and inclusion in its own talent acquisition process, striving to create a workforce that reflects a wide range of backgrounds and perspectives.",
        mission8: "Collaboration and Cross-Training: The company encourages collaboration among its talent pool and offers opportunities for cross-training, allowing employees to diversify their skill sets and contribute to various aspects of the business.",
        mission9: "Adaptation to Evolving Needs: Creativ'Network remains flexible and adapts to the changing demands of the web-related fields in which it operates. This includes acquiring new talents and skills as technology and industry trends evolve.",
        mission10: "Continual Improvement: The company consistently reviews and refines its recruitment and talent management processes to ensure they are efficient and effective.",
        mission11: "Employee Satisfaction and Well-being: Creativ'Network places a strong emphasis on employee satisfaction, offering benefits, work-life balance, and support to enhance the overall well-being of its workforce.",

        value1: "Excellence: We strive for excellence in all that we do, from recruiting top talent to delivering exceptional services to our clients.",
        value2: "Client-Centric Approach: We put our clients at the heart of our operations, understanding their unique needs and providing tailored solutions that drive their success.",
        value3: "Talent Empowerment: We empower web talents to thrive in their careers by connecting them with opportunities that align with their skills and aspirations.",
        value4: "Innovation and Adaptability: We embrace innovation and adapt to the ever-evolving landscape of web-related fields, ensuring we stay at the forefront of industry trends.",
        value5: "Diversity and Inclusion: We celebrate diversity and foster an inclusive environment where individuals from all backgrounds can contribute to our collective success.",
        value6: "Transparency and Integrity: We uphold the highest standards of integrity and maintain transparent communication with our clients, candidates, and team members.",
        value7: "Collaboration and Synergy: We believe in the power of collaboration, working together to achieve shared goals and drive positive outcomes.",
        value8: "Continuous Learning: We encourage continuous learning and professional development, ensuring our team is equipped with the latest skills and knowledge.",
        value9: "Industry Expertise: We are industry experts, offering valuable insights into the web-related job market and sharing our knowledge with clients and candidates.",
        value10: "Ethical Conduct: We operate with unwavering ethical conduct, treating all individuals with respect, fairness, and professionalism.",
        value11: "Community Engagement: We actively engage with the professional community, contributing to its growth through mentorship, education, and participation in industry associations.",
        value12: "Impact and Responsibility: We take responsibility for the impact we have on the careers of individuals and the success of the organizations we serve, striving for positive, lasting outcomes.",

        advantage1:"Competitive Compensation Packages: Offering above-average or industry-leading compensation and benefits to attract and retain high-quality talent, which can set Creativ'Network apart from competitors.",
        advantage2:"Performance-Based Bonuses: Implementing performance-based bonus structures can motivate employees to excel and ensure they are rewarded for their contributions.",
        advantage3:"Customized Compensation Plans: Tailoring compensation packages to individual employees' skills, experience, and performance can lead to higher job satisfaction and loyalty.",
        advantage4:"Career Growth Opportunities: Providing clear paths for career advancement and development within the company, which can attract ambitious professionals and promote internal talent retention.",
        advantage5:"Employee Recognition Programs: Acknowledging and rewarding outstanding employee performance and achievements can boost morale and increase retention rates.",
        advantage6:"Flexible Work Arrangements: Offering flexible work options, such as remote work or flexible hours, can enhance work-life balance and appeal to a wider range of candidates.",
        advantage7:"Comprehensive Benefits Packages: Providing competitive benefits, such as health insurance, retirement plans, and wellness programs, can attract top talent and promote employee well-being.",
        advantage8:"Investment in Training and Development: Supporting continuous learning and professional growth through training programs and educational opportunities can foster a highly skilled and motivated workforce.",
        advantage9:"Transparent Compensation Practices: Maintaining transparent communication about compensation practices and salary benchmarks can build trust and transparency in personnel management.",
        advantage10:"Employee Assistance Programs: Offering assistance programs for personal and professional challenges can demonstrate a commitment to employee well-being and job satisfaction",
        advantage11:"Performance Reviews and Feedback: Regular performance evaluations and constructive feedback can help employees understand their career progression and areas for improvement.",
        advantage12:"Market Analysis for Competitive Salaries: Conducting market analysis to ensure that salaries and benefits remain competitive and aligned with industry standards.",
        advantage13:"Incentive Structures: Developing creative incentive structures that reward exceptional performance, innovation, and contributions to the company's goals.",
        blog2: "Here, you will find our posts",
        ourposts: "Our posts",
        blog3: "Enjoy reading our posts",
        datepub: "15/10/2023",
        months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
        uncategorized: "Uncategorized",

        blognumber1: ["Road to Eye-catching Landscape", "The new common language will be more simple and regular than the existing European languages. It will be as simple as Occidental; in fact, it will be Occidental. To an English person, it will seem like simplified English, as a skeptical Cambridge friend of mine told me what Occidental is. The European languages are members of the same family."], 
        blognumber2: ["Serious Problems with Cables in CIty", "The new common language will be more simple and regular than the existing European languages. It will be as simple as Occidental; in fact, it will be Occidental. To an English person, it will seem like simplified English, as a skeptical Cambridge friend of mine told me what Occidental is. The European languages are members of the same family."], 
        blognumber3: ["Montain and Winter Cold Weather", "If several languages coalesce, the grammar of the resulting language is more simple and regular than that of the individual languages. The new common language will be more simple and regular than the existing European languages. It will be as simple as Occidental; in fact, it will be Occidental."], 
        by: "By",
        noc: "no",
        comments: "comments",
        comment: "comment",
        readmore: "Read more",

        cookiestitle: "Cookie consent management",
        cookiescontent: "Creativ'Network's Cookie Consent Management is essential for a transparent and compliant online experience. We seek user consent in a clear manner, providing detailed information about our cookie practices. With customization options, explicit cookie categorization, and a commitment to data privacy, Creativ'Network creates a safe and respectful digital experience. We strictly adhere to privacy standards, ensuring user security and trust in our platform.",
        pref: "Preferences",
        cookiessm1i: "Functional",
        cookiessm2i: "Statistics",
        cookiessm3i: "Marketing",
        cookiessm1c: `"Storage or technical access" on the Creativ'Network website involves the use of cookies to improve the user experience. This includes the collection of technical data essential for the proper functioning of the site. We seek the consent of users, providing transparent information about these practices, in accordance with data privacy standards and regulations in force`,
        cookiessm2c: `Statistics in cookie consent management for Creativ'Network enable the collection of analytical data. They play a crucial role in evaluating users' browsing habits, thus facilitating the continuous improvement of the site. This information guides decisions to optimise the user experience while respecting privacy preferences, ensuring a personalised and compliant platform,`,
        cookiessm3c: `The "Marketing" function in the cookie consent management on the Creativ'Network site allows us to personalise advertising according to users' preferences. By obtaining their consent, we ensure a targeted and relevant advertising experience. This ensures more effective promotional communication while respecting data privacy standards and users' individual choices.`,
        savepref: "Save preferences",
        accept: "Accept",
        alwyasactive: "Always active",
        error: "Error",

        tags: "Tags: No tags",
        comment1: "Add a Comment",
        comment2: "Your email address will not be published.",
        comment3: "Name (required)",
        comment4: "E-Mail or username",
        comment5: "Website",
        comment6: "Save my name, email, and website in this browser for the next time I comment.",
        comment7: "Comment",
        follow: "Follow ",
        us: "us",
        creatememberspace: "Creation of your member space",
        createmsint11: "Please enter the code we sent to",
        createmsint12: "to validate your email address.",
        createmsint2: "Validating your email address will automatically create your member area",
        createmsconsent: "By submitting your application form, you agree to our",
        confirm: "Confirm",
        codevalidationerror: "The validation code is incorrect",
        confirmpassword: "Confirm password",
        passwordlength: `The password must contain at least ${mdpminlength} characters`,
        passwordnotidentical: "Passwords are not identical",
        welcomeback: "Welcome back",
        signinint: "To keep connected with us, please use your personal info to login",
        signin: "Sign in",
        createaccount: "Create an account",
        oruseyourpersonnalmail: "Or use your personal mail for registration",
        email2: "E-mail",
        signup: "Sign up",
        memberarea: "Member area",
        yourmailpseudo: "Your e-mail address / Username",
        yourpassword: "Your password",
        idorpasswordforgot: "Click here if you forgot your password",
        settingupaccount: "Setting up your account",
        settingupaccountint: "To create your account, you need to provide the following informations:",
        yourfirstname:  "Your first name",
        yourlastname:  "Your last name",
        yourusername: "Your username",
        yourskypeid: "Your skype ID",
        yourbirthdate: "Your date of birth",
        yourgender: "Your gender",
        yourphonenumber: "Your phone number",
        yourmailaddress: "Your email address",
        appartroad:"Appartment / Road number",
        yourcitydep: "Your city / Department",
        yourpobox: "Your PO BOX",
        yourcountry: "Your country",
        agreepp1: "Before you submit your informations, you need to agree on our",
        agreepp2: "",
        note: "Note",
        notecontent: "To activate your account, please check your mail inbox",
        createmyaccount: "Set up my account",
        male: "Male",
        female: "Female",
        other: "Other",
        mailexists: "This email address already exists",
        usernameexists: "This username already exists",
        dashboard: "Dashboard",
        dashboard1: "Manage your Creativ'Network account. Change your settings and view reports",
        dashboard2: "Control Pannel",

        information: "Your informations", 
        information1: "Manage your personal informations",
        information2: "Change your password",

        yourapplication: "Your Application",
        application1: "Upload / Change your resume ",
        application2: "Check your job application status",

        integration: "Your Integration",
        integration1: "Upload / Change the required documents",
        integration2: "Upload / Change your payment information",

        info11: "Change your profile photo",
        info12: "Upload your photo from browser",
        info13: "Browser",
        info14: "Upload your photo from your camera",

        save: "Save",
        admin: "Administrator",
        blogger: "Blogger",
        teamleader: "Team leader",
        adboard: "Admin dashboard",
        tldboard: "Team leader dashboard",
        bdboard: "Blogger dashboard",
        notifs: 'Notifications',
        logout: 'Log out',
        actualpassword: "Your actual password",
        newpassword: "Your new password",
        confirmnewpassword: "Confirm your password",

        app11:"Upload your resume",
        app12: "Upload your resume from browser",

        appstatus1: "Job application form",
        submittedon:"Submitted on",
        appstatus2: "Test",
        notyet: "Not yet done",
        reviewedon: "Reviewed on",
        appstatus3: "Review status",
        reviewed: "Reviewed",
        appstatus4: "Management decision",
        hired: "Congratulations, you have been hired, please procced to the next step: your integration",
        nothired: "We' re sorry, but your application has not been accepted",
        idtl: 'Team leader ID',
        ida: 'Admin ID',
        idb: 'Blogger ID',
        positionoverview: 'Position overview',
        adminstaff: 'Admin staff',
        jobposts: 'Job posts',
        jobapplication: 'Job application',
        newemptraining: 'New employees training',
        empintegration: 'Employees integration',
        blogposts: 'Blog posts',
        details: 'Details',
        cnmembers: "Creativ-Network members",
        sn: "Social network",
        aas1: "List of admin staff",
        aas2: "Add admin staff",
        aja1: "All job applications",
        aja2: "Check applications details",
        aja3: "Successful applications",
        anet1: "Training schedule",
        anet2: "Add training documents",
        anet3: "Agents awaiting for login",
        anet4: "Login attribution",
        aei1: "New agents check up",
        abp1: "Bloggers list",
        abp2: "Add bloggers",
        abp3: "Blog posts list",
        abp4: "Add blog posts",
        ajp1: "List of job posts",
        ajp2: "Add job posts",
        acnm1: "List of members",
        acnm2: "Add members",
        anl1: "List of subscribers",
        anl2: "All newsletters",
        anl3: "Add newsletter",
        asn1: "All social networks",
        passportcopy: "Passport copy, or driver license, or government ID",
        proofofaddress: "Proof of address",
        yourselfie: "Your selfie with your ID",
        freelance: "Freelance contract",
        integ1up1: "Upload your proof of identity from browser",
        integ1up2: "Upload your proof of address from browser",
        integ1up3: "Upload your selfie from browser",
        integ1up4: "Upload your selfie from your camera",
        integ1download: "Download the freelance contract, sign it and send back here",
        integ1up5: "Upload your signed freelance contract from browser",
        download: "Download",

        paypalaccount: "Paypal account",
        yourpaypal: "(Your  Paypal email address)",
        wiseaccount: "Wise account",
        yourwise: "(Your wise email address)",
        bitesafeaccount: "Yoursafe account",
        yourbitesafe: "(Your Yoursafe email address)",
        bankaccount: "Bank account",
        accountholder: "Account holder name:",
        holderaddress: "Account Holder address:",
        banksholder: "Holder bank name:",
        bankaddress: "Holder bank address:",
        iban: "IBAN / Account number:",
        swiftcode: "Swift code / BIC: ",
        year: 'Year',
        month: 'Month',
        date: 'Date',
        week: 'Week',
        today: 'Today',
        startingdate: 'Starting date',
        closingdate: 'Closing date',
        adminstafflogin: 'Admin staff login',
        adminstaffloginpassword: 'Admin staff login password',
        actions: 'Actions',
        suspend: 'Suspend',
        fire: 'Ban',
        candidateid: 'Candidate ID',
        createnewadminstaff: 'Create new admin staff',
        language: "Language",
        edit: 'Edit',
        openingposition: 'Opening position',
        jobtitle: 'Job title',
        jobdescription: 'Job description',
        openclose: "Open / Close",
        jobopen: "Open",
        jobclose: "Close",
        delete: 'Delete',
        applicantfirstname: 'Applicant first name',
        applicantlastname: 'Applicant last name',
        applicantcountry: 'Applicant country',
        application: 'Application',
        checkthapplication: "Check the application",
        agentfirstname: "Agent first name",
        agentlastname: "Agent last name",
        agentcountry: "Agent country",
        agentemail: "Agent e-mail",
        agentskypeid: "Agent skype ID",
        trainingschedule: "Training schedule",
        sendconfirmationonemailtoagent: "Send confirmation e-mail to agent",
        memberopt1: "Manage your informations",
        memberopt2: "Check your job application",
        memberopt3: "Proceed to your integration",
        awaitingforlogin: "Awaiting for login",
        awaitingforemailconf: "Awaiting for confirmation email",
        agentlogin: "Agent login",
        freelancecontract: "Freelance contract",
        trainingdocumentssigned: "Training documents signed",
        proofofresidence: "Proof of residence",
        paymentmethod: "Payment method",
        provided: "Provided",
        notprovided: "Not provided",
        bank: "Bank",
        wise: "Wise",
        paypal: "Paypal",
        Yoursafe: "Yoursafe",
        activeblogger: "Active blogger",
        bloggerfirstname: "Blogger first name",
        bloggerlastname: "Blogger last name",
        bloggerloginid: "Blogger login ID",
        bloggerloginpassword: "Blogger login password",
        blogposttile: "Blog post title",
        blogpostcategory: "Blog post category",
        blogpostlink: "Blog post link",
        category: "Category",
        newmembers: "New members",
        allmembers: "All members",
        inscriptdate: "Inscription date",
        memberfirstname: "Member first name",
        memberlastname: "Member last name",
        loginpassword: "Login password",
        emailaddressconfirmed: "E-mail address confirmed",
        country: "Country",
        newsubscribers: "New subscribers",
        allsubscribers: "All subscribers",
        subscriptiondate: "Subscription date",
        topic: "Topic",
        allnewsletters: "All newsletters",
        releasedate: "Release date",
        releaselanguage: "Release language",
        linktothenewsletter: "Link to the newsletter",
        sendtoallsubscribers: "Send to all subscribers",

        agentid: "Agent ID",
        trainingprocess: "Training process",
        integrationprocess: "Integration process",
        jobposition: "Job position",
        training: "Training",
        integrationn: "Integration",
        inprogress: "In progress",
        done: "Done",

        role: "Role",
        skypeid: "Skype ID",
        jobid: "Job ID",
        illustrationphoto: "Illustration photo",
        location: "Location",
        jobrequirement: "Job requirement",
        salaryscale: "Salary scale",
        incentives: "Incentives",
        openingdate: "Opening date",
        telephonenumber: "Telephone number",
        createnewjobposts: "Create new job post",
        haveexperience: "Have experience",
        howtheyfindus: "How they find us",
        motivation: "Motivation",
        testresult: "Test result",
        decision: "Decision",
        hired2: "Hired",
        rejected: "Rejected",
        description: "Description",

        name: "Name",
        document: "Document",
        blogpostid: "Blog post ID",
        bloggerid: "Blogger ID",
        blogposttitle: "Blog post title",
        blogposttext: "Blog post text",
        publishnewblogpost: "Publish new blog post",
        createnewblogger: "Create new blogger",
        createnewlogin: "Create new login",
        addblogpostcategory: "Add blog post category",
        blogpostcategoryid: "Blog post category ID",
        memberid: "Member ID",
        addnewmemberandsendnotification: "Add new member and send notification",
        newsletterid: "Newsletter ID",
        adminid: "Admin ID",
        newslettercategory: "Newsletter category",
        newslettertitle: "Newsletter title",
        newslettertext: "Newsletter text",
        allsubscribersemail: "All subscribers e-mail address",
        publishnewnewsletter: "Publish new newsletter",
        link: "Link",
        hello : "Hello",

        tlintint1: "Want to work at home as a",
        tlintint2: "of Dating chat operators?",
        tlint1: "1. Impactful Leadership: Lead a team shaping connections, fostering meaningful relationships, and contributing to a dynamic dating environment.",
        tlint2: "2. Team Growth Opportunity: Guide and develop a cohesive team, fostering personal and professional growth, in a supportive work environment.",
        tlint3: "3. Innovative Contribution: Introduce creative solutions, shape the future of online dating, and enhance chat services, contributing to industry innovation.",
        tlint4: "4. Recognition and Achievement: Lead a successful team, exceed metrics, and gain recognition, with a competitive salary and enticing incentives.",
        tlint5: "5. Rewards for Excellence: Enjoy a good salary, lucrative incentives, and recognition for achieving goals, making a significant impact at Creativ'Network.",
        tlabint1: "What is a",
        tlabint2: "of Dating chat operators job about?",
        tlab1: "1. Leadership and Coordination: As a Team Leader, overseeing all dating chat operators involves providing strong leadership, guiding the team, and ensuring cohesive communication.",
        tlab2: "2. Performance Management: Setting and monitoring performance metrics, such as response times and user satisfaction, to achieve high-quality service and foster a positive user experience.",
        tlab3: "3. Team Development: Nurturing a cohesive and motivated team by providing guidance, mentorship, and fostering a supportive work environment for personal and professional growth.",
        tlab4: "4. Innovation in Chat Services: Introducing creative approaches to enhance chat services, staying abreast of industry trends, and adapting strategies to improve user engagement.",
        tlab5: "5. Quality Assurance: Ensuring operators maintain a high standard of service, addressing user needs, and resolving issues promptly to maintain a positive and trustworthy user experience.",
        tlab6: "6. Communication Hub: Serving as a central point for communication between operators, management, and other departments, facilitating collaboration to achieve organizational goals in the dynamic realm of online dating.",
        tlab7: "7. Strategic Planning: Contribute to strategic planning for the team, aligning goals with organizational objectives in the dynamic field of online dating.",
        tllsint1: "What are the leadership skills to be a",
        tllsint2: "of Dating chat operators?",
        tlls11: "Leadership Experience",
        tlls12: "Demonstrated experience in leading and managing teams, showcasing effective leadership and mentorship skills.",
        tlls21: "Communication Skills",
        tlls22: "Strong verbal and written communication skills to facilitate clear communication among team members and with other departments.",
        tlls31: "Industry Knowledge",
        tlls32: "Familiarity with the online dating industry, understanding user dynamics, trends, and challenges in providing dating chat services",
        tlls41: "Problem-Solving Abilities",
        tlls42: "Proven ability to analyze and solve problems efficiently, especially in addressing user issues and maintaining a positive user experience",
        tlls51: "Performance Management",
        tlls52: "Experience in setting, monitoring, and optimizing performance metrics to ensure high-quality service, user satisfaction, and team efficiency.",
        tlls61: "Adaptability and Innovation",
        tlls62: "Ability to adapt to changes in the industry, introduce innovative strategies, and stay ahead of emerging trends in online dating services.",
        tlls71: "Team Development Skills",
        tlls72: "Proven capability in fostering team growth, providing guidance, mentorship, and creating a positive work environment for personal and professional development.",
        tlls81: "Quality Assurance Focus",
        tlls82: "Commitment to maintaining a high standard of service, addressing user needs, and implementing quality assurance measures for a positive user experience.",
        tlls91: "Collaboration and Coordination",
        tlls92: "Strong collaboration skills, serving as a central point for communication, and facilitating effective coordination between operators, management, and other departments.",

        tlreint1: "What are the requirements to be a",
        tlreint2: "for Creativ'Network?",
        tlre12: "A Creativ'Network Team Leader requires fluency in primary operating languages, multilingual competence, clear articulation, understanding of regional nuances, strong written communication, and proficiency in user interactions and collaboration with other departments.",
        tlre22: "Setting an age limit of 25+ for a Creativ'Network Team Leader can ensure a balance of experience and maturity. This policy promotes diverse leadership qualities while maintaining a dynamic and efficient team.",
        tlre42: "Creativ'Network Team Leaders must acknowledge and accept the Privacy Statement and Contract Terms, ensuring compliance with data protection and contractual obligations, fostering a secure and transparent working relationship.",
        tlre61: "Reports",
        tlre62: "Creativ'Network Team Leaders compile detailed reports, summarizing team performance, user engagement metrics, and issues addressed. These reports guide strategic decisions, identify areas for improvement, and ensure optimal service delivery.",
        tlre72: "Creativ'Network Team Leaders commit to a minimum of 40 weekly hours, with occasional weekend and public holiday availability. This ensures continuous support for the team and maintains optimal service standards.",
        tlre81: "Tax",
        tlre82: "As an independent worker, Creativ'Network Team Leader is responsible for managing and paying his own taxes. This includes compliance with tax regulations and fulfilling financial obligations in his respective jurisdictions.",
        tlre92: "Creativ'Network Team Leaders ensure strict adherence to legal and regulatory guidelines, fostering a compliant environment. This includes upholding user privacy, data protection, and other industry-specific regulations to maintain platform integrity.",
        tlic1: "Age Verification: Creativ'Network checks Team Leader applicants' age (25+) to ensure candidates possess the experience, maturity, and stability essential for effective leadership. This approach aligns with the company's goal of having seasoned leaders with proven track records, contributing to successful team management and fostering a positive working environment.",
        tlic2: "Security and Trust: Age verification for Creativ'Network Team Leader applicants aged 25+ is implemented to enhance security and trust. By ensuring compliance with this criterion, the platform aims to establish a mature and experienced leadership team, fostering a secure and trustworthy environment for both users and team members.",
        tlas1: "Start by visiting Creativ'Network's official website or career portal.|Find the job opening for Team Leader position and click \"Apply\".|Fill out the application form, which includes providing your personal information, contact details, relevant experience, your motivation to work with us.|You will be asked to complete a short skills test, typically taking around 10 minutes. This test assesses your proficiency in leadership, communication, performance management, innovation, team development, quality assurance, collaboration and adaptability.",
        tlas2: "After successfully completing the initial application and skills test, you will be required to go through an ID verification process to confirm your age.|You will be instructed to submit a copy of a valid government-issued ID, such as a passport, driver's license, or government ID card. This document is used to verify that you meet the minimum age requirement specified for the position.",
        tlas3: "Once your application and age verification are approved, you will be scheduled for an online training session via Skype.|During the training, Creativ'Network's Managers will provide guidance and instructions on how to effectively perform your role as a team leader. They will cover company policies, best practices, and leadership and communication strategies to excel in your position.",
        appformfor: "Application form for",
        tofilltheapplicationform: "To fill the application form, please sign in or sign up to register on our website",
        
        supervisor: "Supervisor",
        qualitysupervisor: "Quality Supervisor",
        sintint1: "Want to work at home as a",
        sintint2: "of Dating chat operators?",
        sint1: "1. Quality Leadership: Ensure excellence in chat services, shaping positive user experiences, and fostering quality interactions, all from the convenience of home.",
        sint2: "2. Competitive Compensation: Receive a generous salary, lucrative incentives, and the flexibility of remote work, providing financial rewards for maintaining quality.",
        sint3: "3. Team Development: Mentor and guide operators, fostering quality service, personal growth, and professional development in a virtual work environment.",
        sint4: "4. Innovative Contributions: Introduce creative strategies, enhance chat services, and contribute to industry innovation, ensuring a quality user experience on Creativ'Network.",
        sint5: "5. Recognition and Rewards: Lead a high-quality team, exceed metrics, and gain recognition with a competitive salary, ensuring a fulfilling and rewarding remote career.",
        sint6: "6. Work-Life Balance: Enjoy the flexibility of working from home, achieving a harmonious work-life balance while ensuring and enhancing quality standards at Creativ'Network.",
        sabint1: "What is a",
        sabint2: "of Dating Chat operators job about?",

        sab1: "1. Performance Monitoring: Quality Supervisors closely monitor operators, ensuring adherence to performance metrics, response times, and user satisfaction, contributing to a positive user experience.",
        sab2: "2. Feedback and Training: Provide constructive feedback, conduct training sessions, and guide operators to continually improve their communication skills, ensuring quality interactions.",
        sab3: "3. Issue Resolution: Address user concerns promptly, resolving issues and maintaining a high standard of service to ensure a positive user experience.",
        sab4: "4. Quality Assurance Protocols: Implement and enforce quality assurance protocols, ensuring consistent adherence to established standards for chat services on Creativ'Network.",
        sab5: "5. Innovation and Optimization: Introduce innovative strategies to optimize chat services, staying ahead of industry trends, and enhancing the overall quality of user interactions.",
        sab6: "6. Team Collaboration: Foster a collaborative team environment, encouraging communication and collaboration among operators, ensuring a cohesive approach to delivering high-quality dating chat services.",
        sskint1: "What are the skills to be a",
        sskint2: "of Dating Chat operators?",
        leadership: "Leadership",
        communication: "Communication",
        analyticalskills: "Analytical Skills",
        pbsolving: "Problem-Solving",
        qualityassurance: "Quality Assurance",

        traininganddev: "Training and Development",
        innovation: "Innovation",
        collab: "Collaboration",
        adaptability: "Adaptability",
        ssk1: "Exceptional leadership skills to guide and inspire a team of chat operators, fostering a positive and productive work environment.",
        ssk2: "Strong communication skills, both verbal and written, to provide clear instructions, feedback, and training to operators, ensuring effective user interactions.",
        ssk3: "Ability to analyze performance metrics, identify areas for improvement, and implement strategies to enhance overall chat service quality.",
        ssk4: "Adept at resolving user issues and addressing operational challenges promptly, maintaining a high standard of service and user satisfaction.",
        ssk5: "Quality Assurance: Comprehensive understanding of quality assurance protocols, ensuring consistent adherence to established standards and optimizing chat services.",
        ssk6: "Training and Development: Capability to design and conduct training sessions, developing operators' skills and fostering continuous improvement in communication and service quality.",
        ssk7: "Innovation: Proven ability to introduce innovative strategies, staying ahead of industry trends, and optimizing chat services for a dynamic online dating environment.",
        
        ssk8: "Collaboration: Effective collaboration skills to foster a cohesive team environment, encouraging communication and collaboration among operators for seamless service delivery.",
        ssk9: "Adaptability: Ability to adapt to changing industry dynamics, user preferences, and emerging trends, ensuring Creativ'Network's chat services remain at the forefront of the online dating experience",
        langproficiency: "English Proficiency",
        agelimit: "Age Limit",
        bankaccountpaymentaccount: "Bank Account or Payment Account",
        acceptanceppterms: "Acceptance of Privacy Statement and Contract Terms",
        equipmentresponsibility: "Equipment Responsibility",
        reports: "Reports",
        minweekly: "Minimum weekly hours",
        tax: "Tax",
        adhlegalregguidelines: "Adherence to Legal and Regulatory Guidelines",
        sre1: "A Creativ'Network Quality Supervisor requires fluency in primary operating languages, multilingual competence, clear articulation, understanding of regional nuances, strong written communication, and proficiency in user interactions and collaboration with other departments.",
        sre2: "Setting an age limit of 25+ for a Creativ'Network Quality Supervisor can ensure a balance of experience and maturity. This policy promotes diverse leadership qualities while maintaining a dynamic and efficient team.",
        
        sre3: "You should have a bank account, PayPal, Wise, or Yoursafe account in your name. This is likely for payment processing purposes.",
        sre4: "Creativ'Network Quality Supervisor must acknowledge and accept the Privacy Statement and Contract Terms, ensuring compliance with data protection and contractual obligations, fostering a secure and transparent working relationship.",
        sre5: "You are expected to have the necessary equipment, including a laptop or PC, a reliable internet connection, and a backup source of electricity and internet to ensure uninterrupted work.",
        sre6: "Creativ'Network Quality Supervisor compiles detailed reports, summarizing team performance, user engagement metrics, and issues addressed. These reports guide strategic decisions, identify areas for improvement, and ensure optimal service delivery.",
        sre7: "Creativ'Network Quality Supervisor commits to a minimum of 40 weekly hours, with occasional weekend and public holiday availability. This ensures continuous support for the team and maintains optimal service standards.",
        sre8: "As an independent worker, Creativ'Network Quality Supervisor is responsible for managing and paying his own taxes. This includes compliance with tax regulations and fulfilling financial obligations in his respective jurisdictions.",
        
        sre9: "Creativ'Network Quality Supervisor ensures strict adherence to legal and regulatory guidelines, fostering a compliant environment. This includes upholding user privacy, data protection, and other industry-specific regulations to maintain platform integrity.",
        sic1: "1.	Age Verification: Creativ'Network checks Quality Supervisor applicants' age (25+) to ensure candidates possess the experience, maturity, and stability essential for effective leadership. This approach aligns with the company's goal of having seasoned leaders with proven track records, contributing to successful team management and fostering a positive working environment.",
        sic2: "2.	Security and Trust: Age verification for Creativ'Network Quality Supervisor applicants aged 25+ is implemented to enhance security and trust. By ensuring compliance with this criterion, the platform aims to establish a mature and experienced leadership team, fostering a secure and trustworthy environment for both users and team members. ",
        sic3: "3.	Documents for verification: To comply with legal regulations and ensure that candidates are of the appropriate age, Creativ'Network will request age verification. Documents such as driver's licenses and government IDs, passport typically include date of birth information, making them suitable for confirming that you meet the minimum age requirement set by Creativ'Network.",   
        
        sas3: "Once your application and age verification are approved, you will be scheduled for an online training session via Skype.|During the training, Creativ'Network's Team Leaders will provide guidance and instructions on how to effectively perform your role as a Quality Supervisor. They will cover company policies, best practices, and leadership and communication strategies to excel in your position.",
        tltext: "Lead, inspire, and guide chat operators, fostering exceptional connections and experiences in the realm of online dating at Creativ'Network.",
        supervisortext: "Oversee quality in dating chat services at Creativ'Network. Guide operators to excellence, ensuring positive user experiences in the online dating realm.",

        webdevtext: "Join Creativ'Network as a Web Developer, crafting innovative digital solutions. Contribute to our dynamic team, shaping the future of online connections.",
        digmartext: "Elevate online presence at Creativ'Network as a Digital Marketer. Drive engagement, shape strategies, and contribute to dynamic marketing initiatives. Join us!",
        webdestext: "Shape digital experiences at Creativ'Network as a Web Designer. Bring creativity to life, crafting visually stunning websites for enhanced online connections.",

        qstesttitle: "Quality supervisor test form",
        suptitle: "Test to become a Quality Supervisor at Creativ'Network",
        suptest: "Quality supervisor test",
        tltest: "Team Leader Test",
        tltestform: 'Team Leader Test Form',
        tltesttitle: "Test to become a Team Leader at Creativ'Network",
        tltestint1: "Creativ'Network's Team Leader test assesses your proficiency in leadership, communication, performance management, innovation, team development, quality assurance, collaboration, and adaptability.",
        suptestint1: "Creativ'Network's Quality Supervisor test assesses your abilities in leadership, communication, problem-solving, quality assurance, training, innovation, collaboration, and adaptability.",
        thanktest1: 'Thank you for taking the test',
        finish: "Finish",
        all: "All",
        loginfailed: "Login failed",
        
        usrnmpttrerr: "The username must not contain special characters",
        pobxpttrerr: "The PO box must not contain any special character",
        phnpttrerr: "The telephone number must be in international format. Example: +123456789012",
        skpidpttrerr: "Invalid skype identifier",
        brthdtptrerr: "Invalid date of birth format",
        usrnmlngtherr: `The username must contain at least ${usernameminlength} characters.`,
        smthngwntwrng: "Something went wrong. Please try again",
        failed: "Failed",
        subsuccess: "Successfully subscribed",
        subfailed: "Already subscribed",
        mlunqerr: "This email address is already in use",
        usrnmunqerr: "This username is already in use",
        iderr: "Invalid E-mail / Username",
        mdperr: "Invalid password",
        svsucc: "Informations updated successfully.",
        
        mdpnid: "The new password and the confirmation password are not identical",
        curmdperr: "The current password is incorrect",
        entryrml: 'Enter your e-mail address',
        entrynwpss: 'Enter your new password',
        rcpcpt: 'Retrieve your account',

        chckrsm: "See your current resume",
        nyrsm: "No resume provided yet",
        chckpi: "See your current proof of identity",
        chckpa: "See your current proof of address",
        chcksl: "See your current selfie",
        chckc: "See your current signed current contract",
        nypi: "No proof of identity provided yet",
        nypa: "No proof of address provided yet",
        nysl: "No selfie provided yet",
        nyc: "No signed current contract provided yet",
        rsm: "Resume",
        pi: "Proof of identity",
        pa: "Proof of address",
        slfi: "Selfie",
        opnngclsngdterr: "The opening date must be earlier than the closing date",
        adddsccsflly: "Added succesfully",
        dltd: "Deleted",
        rstr: "Restore",
        see: "See", 

        succ: "Blog created successfully",
        cat: "Category invalid",
        accref: "Access denied",
        lang: "Language not found",

        catsuccess: "Category created successfully",
        catexst: "Already existing category",
        crtadminfrmmbr: "Create from existing member",
        success: "Success",
        tktst: "Take the test",
        expteam: "Do you have experience in team leading?",
        expsup: "Do you have experience in chat operator supervising?",
        avlbl: "Available",
        fllppfrm: "Fill in the application form",
        lljbs: "All jobs",
        pnjbs: "Hiring jobs",
        dfndt: "Date determined",
        indfndt: "Indefinite date",
 nonaut: "Only the blog author can delete and restore this blog",

        adminsuccess: "This person is now an administrator",
        rolenonaut: "Role not authorized",
        djadmin: "This person is already an administrator",
        usrntfnd: "No user found",
        newbloggersucc: "This person is now a blogger",
        djblog: "This person is already a blogger",

        gndr: "Gender",
        citydep: "City / Department",
        pobox: "PO BOX",

        crtd: "Created",
        dcnnctd: "Disconnected",
        sbntfnd: "Subscriber not found",
nw: "New",
        bfrsbmttng: "Before submitting",
        upldrsmpdf: "Upload your resume",
        umstupldyrcv: "You must upload your resume",
        sspndd: "Suspended",
        susp: "Your account is currently suspended and will be reactivated on ", 
        reak: "",
        svd : "Saved",
tstalrddn: "You have already applied for this position",
            gvlgn: "attribute a login",
                frmtdcnntrv: "Training document not found",
        sndmaildocincomp: "Send email about incomplete documents",
        vldtinteg: "Add to training list",
        nshdtrnng: "Finished training",
        cnfgtrnng: "Configure training",
        sndml: "Send e-mail",
        mrksfnshd: "Mark as finished",

                bn: "E-mail banned",
        cypm: "Choose your payment method",
        aas3: "List of Team Leader",
tllgn: "Team Leader login",
aas4: "Add Team Leader",
createnewtl: "Create new team leader",
strthr: "Starting time",
        dnhr: "Ending time",
        chngpay: "If you want to change your payment information, please contact your Team Leader or your Manager.",
        idm: "Manager ID",
        mdboard: "Manager dashboard",
        manager: "Manager",
        mrk: "Mark as read",
agntslst: "List of agents",
aas5: "List of Manager",
aas6: "Add Manager",
createnewmn: "Create new Manager",
pdf: "Only PDF format is accepted",
agntnm: "Agent name",
        agntpass: "Agent password",
        asn2: "Add Social Network",

    
},
    { // it
        
        cn: "Creativ'Network",
        french: 'Francese',
        english: 'Inglese',
        italian: 'Italiano',
        dutch: 'Olandese',
        spanish: 'Spagnolo',
        german: 'Tedesco',
        usermanual: "Manuale dell'utente",
        formulaire: 'Modulo di richiesta',

        firstname: 'Nome',
        lastname: 'Cognome',
        email: 'Indirizzo e-mail',
        birthday: 'Data di nascità',
        phone: 'Telefono',
        skypename: 'Nome Skype',
        whichchatlanguage: 'Per quale lingua ti stai candidando?',
        timesavailable: 'Quando sei raggiungibile telefonicamente?',
        expchatmod: 'Hai esperienza come moderatore di chat?',
        hearabout: 'Come hai saputo di noi?',
        tellus: 'Dicci qualcosa di te e spiegaci perché dovremmo assumerti!',
        submitapp: 'Invia',
        yes: 'Sì',
        no: 'No',
        friends: 'Amici',
        from: 'Dalle',
        to: 'alle',

        required: 'Questo campo è obbligatorio',
        invalidemail: 'E-mail non valida',

        loginfailusername: 'Nome utente non riconosciuto',
        loginfailpass: 'Password non valida',
        login: 'Connessione',
        username: 'Nome utente',
        password: 'Password',
        test: 'Modulo di prova per moderatori di chat italiani',
        situation: 'Situazione',
        send: 'Inviare',
        qreturn: 'Volete davvero abbandonare il test?',

        cointint: "Vuoi lavorare a casa come operatore di chat di incontri con",
        coint1: "Un bel guadagno: Guadagna da 0,12€ a 0,15€ per ogni messaggio inviato con Creativ'Network.",
        coint2: "Lavoro a distanza: Godetevi la flessibilità di lavorare da casa",
        coint3: "Abilità comunicative: Migliorare la qualità dell'empatia, della comprensione attiva e della fluidità della comunicazione.",
        coint4: "Equilibrio vita-lavoro: Gestite meglio il vostro tempo con orari flessibili.",
        applynow: "Candidarsi ora",

        coabint1: "In cosa consiste il lavoro di",
        coabint2: "per incontri?",
        chatoperator: "operatore di chat",
        coab1: "Connettersi con persone sole: Molti utenti delle piattaforme di incontri cercano compagnia o qualcuno con cui parlare a causa della solitudine. Gli operatori di chat offrono ascolto e supporto emotivo.",
        coab2: "Comunicazione testuale: La modalità principale di comunicazione è rappresentata dai messaggi di testo scritti sulla piattaforma. Gli operatori mantengono conversazioni continue con gli utenti",
        coab3: "Argomenti di conversazione variegati: Gli operatori di chat coinvolgono gli utenti in discussioni su un'ampia gamma di argomenti, da quelli quotidiani come lo sport e il meteo a quelli più personali e intimi, come sogni, fantasie e altre aree di interesse.",
        coab4: "Approccio aperto: Questo lavoro richiede spesso un atteggiamento aperto e non giudicante, poiché gli utenti possono avere interessi, esperienze e desideri diversi.",
        coab5: "Fornire compagnia: Gli operatori svolgono un ruolo importante nel fornire un senso di compagnia e di connessione agli utenti che possono essere alla ricerca di un sostegno emotivo o semplicemente vogliono qualcuno con cui parlare.",
        coab6: "Anonimo: Gli operatori di chat lavoreranno in modo anonimo al 100%. La nostra identità non sarà mai rivelata a nessuno in nessun sito di incontri.",
        coab7: "Indipendenza e flessibilità: Gli operatori di chat lavoreranno a vostro piacimento",

        home:"Pagina iniziale",
        ourmissions: "Le nostre missioni",
        company: "Valori",
        ourcompany: "I nostri valori",
        ouradvantages: "I nostri vantaggi",
        jobs: "Offerte di lavoro",

        slog: `"Dove la creatività incontra l'opportunità"`,
        copyright: "©2023-2024 copyright Creativ'Network. Tutti i diritti riservati.",
        foexpl: "Creativ'Network, la piattaforma innovativa che mette in contatto i talenti del web con ruoli orientati al futuro, dando forma al mondo digitale. Scoprite il vostro potenziale, prosperate nella moderazione web, nello sviluppo, nel marketing, nei big data e altro ancora.",
        focontact1: "Indirizzo principale: 71-75 Shelton Street, Covent Garden, Londra, WC2H 9JQ",
        focontact2: "Rappresentante dell'ufficio in Belgio: Roodborstjesstraat 4, Wondelgem 9032",
        focontact3: "contact@creativ-network.net",
        subscribe: "Abbonarsi",
        agree1: "Sì, accetto l'",
        agree2: "e i",
        agreelink1: "Informativa sulla privacy",
        agreelink2: "termini e condizioni.",
        useful: "Utile",
        links: "Links",
        oursing: "Nostro",
        contact: "Contatto",
        subscribetoour: "Iscriviti al nostro",
        newsletter: "Newsletter",

        consents: "Consenso",
        termsAgree1: "Sì, accetto l'",
        termsAgree2: "e i",
        termsAgreeLink1: "Informativa sulla privacy",
        termsAgreeLink2: "termini e condizioni.",

        termsAgreeLink2real: "termini e condizioni d'uso",
        consentsErr: "Per iscriversi è necessario accettare l'informativa sulla privacy e i termini e le condizioni",
        
        coscint1: "Come verrà calcolato lo stipendio del",
        coscint2: "?",
        cosc1: "Paga base per messaggio: L'operatore viene generalmente pagato con un importo predeterminato per ogni messaggio inviato. La paga base per messaggio inviato è di 0,12 euro",
        cosc2: "Conteggio mensile dei messaggi: Alla fine del mese, viene calcolato il numero totale di messaggi inviati dall'operatore.",
        cosc3: "Calcolo del payout: I guadagni dell'operatore per il mese sono calcolati moltiplicando il numero totale di messaggi per la retribuzione base per messaggio. Ad esempio, se si inviano 5.000 messaggi, si guadagnano 600€ (5.000 messaggi * 0,12€ per messaggio). Con Creativ'Network, un operatore può facilmente guadagnare 1.200 € al mese con 10.000 messaggi inviati",
        cosc4: "Bonus di rendimento: in alcuni casi, gli operatori con prestazioni eccezionali possono ricevere un bonus in aggiunta alla loro commissione. Il bonus potrebbe essere basato su criteri come il numero di clienti soddisfatti, il tempo di risposta o il feedback positivo degli utenti.",
        cosc5: "Bonus natalizio: come ulteriore incentivo, Creativ'Network offre un bonus natalizio ai nostri operatori che sono in azienda da più di 2 anni",

        corint1: "Quali sono i requisiti per essere un operatore di chat",
        corint2: "?",
        cor11: "Conoscenza dell'italiano",
        cor12: "La conoscenza dell'italiano è un requisito fondamentale, in quanto gli operatori di chat devono comunicare efficacemente con gli utenti in italiano.",
        cor21: "Limite di età",
        cor22: "I candidati devono avere almeno 18 anni per essere idonei a questa posizione",
        cor31: "Conto bancario o conto di pagamento",
        cor32: "Dovete avere un conto bancario, PayPal, Wise o Yoursafe a vostro nome. Questo probabilmente per l'elaborazione dei pagamenti",
        cor41: "Accettazione dell'Informativa sulla privacy e delle Condizioni contrattuali",
        cor42: "I candidati devono essere disposti ad accettare e aderire alla dichiarazione sulla privacy e ai termini contrattuali di Creativ'Network, che spesso delineano le responsabilità, le linee guida e il codice di condotta per gli operatori di chat",
        cor51: "Responsabilità dell'attrezzatura",
        cor52: "L'utente deve disporre dell'attrezzatura necessaria, tra cui un computer portatile o un PC, una connessione Internet affidabile e una fonte di backup di elettricità e Internet per garantire un lavoro ininterrotto",
        cor61: "Requisiti minimi per i messaggi",
        cor62: "Gli operatori di chat sono in genere tenuti a inviare un numero minimo di messaggi a settimana. In questo caso, è previsto un minimo di 250 messaggi in uscita a settimana",
        cor71: "Orario minimo settimanale",
        cor72: "Anche se si lavora in modo indipendente, si dovrebbe lavorare almeno 4 ore al giorno e 20 ore settimanali a proprio piacimento",
        cor81: "Tassa",
        cor82: "In quanto lavoratori indipendenti, sarete responsabili delle vostre tasse",
        cor91: "Rispetto delle linee guida legali e normative: ",
        cor92: "Ogni operatore di chat deve seguire le regole impartite dal team leader durante la formazione",

        coicint1: "Che cosa",
        coicint2: "è necessario verificare la vostra identità?",
        coic1: "Verifica dell'età: Per vari motivi, i siti e le piattaforme di incontri hanno spesso restrizioni di età per garantire il rispetto delle norme legali. Controllando la tua identità, Creativ'Network può verificare che tu soddisfi il requisito minimo di età di 18 anni per utilizzare i nostri servizi. Questo aiuta la piattaforma a rispettare gli standard legali, a proteggere i minori e a mantenere una comunità responsabile e sicura",
        coic2: "Sicurezza e fiducia: La verifica dell'identità aumenta la sicurezza e l'affidabilità della piattaforma. Assicura agli utenti che Creativ'Network sta interagendo con persone che sono chi dichiarano di essere, il che è essenziale per costruire la fiducia e garantire un ambiente sicuro per questa posizione.",
        coic3: "Documenti per la verifica: Per rispettare le norme di legge e garantire che i candidati abbiano l'età adeguata, Creativ'Network richiederà una verifica dell'età. Documenti come la patente di guida e i documenti d'identità governativi includono in genere informazioni sulla data di nascita, il che li rende adatti a confermare che si soddisfa il requisito minimo di età stabilito dalla piattaforma.",

        coasint1: "3 semplici passi per candidarsi alla posizione presso",
        coasint2: "",
        coas11: "Candidatura facile e test delle competenze",
        coas12: ["Inizia visitando il sito web ufficiale di Creativ'Network o il portale delle carriere", "Trova l'offerta di lavoro per la posizione di operatore di chat per incontri e clicca su \"Candidati\"", "Compila il modulo di candidatura, che include i tuoi dati personali, i dettagli di contatto, l'esperienza pertinente e la tua motivazione a lavorare con noi", "Ti verrà chiesto di completare un breve test di abilità, che in genere dura circa 10 minuti. Questo test valuta la vostra capacità di impegnarvi in conversazioni basate sulla chat e di dimostrare le vostre abilità comunicative."],
        coas21: "Verifica dell'identità per la conferma dell'età",
        coas22: ["Dopo aver completato con successo la domanda iniziale e il test di abilità, vi verrà richiesto di sottoporvi a un processo di verifica dell'identità per confermare la vostra età", "Vi verrà chiesto di presentare una copia di un documento d'identità valido rilasciato dal governo, come un passaporto, una patente di guida o una carta d'identità governativa. Questo documento viene utilizzato per verificare che il candidato soddisfi i requisiti minimi di età previsti per la posizione."],
        coas31: "Formazione online via Skype",
        coas32: ["Una volta approvata la tua candidatura e la verifica dell'età, ti verrà programmata una sessione di formazione online via Skype", "Durante la formazione, i team leader di Creativ'Network forniranno indicazioni e istruzioni su come svolgere efficacemente il tuo ruolo di operatore di chat per incontri. Verranno illustrate le politiche aziendali, le migliori pratiche e le strategie di comunicazione per eccellere nella vostra posizione"],

        coafint: "Modulo di candidatura per",

        ppint: ["In Creativ-Network, accessibile all'indirizzo https://creativ-network.net, una delle nostre principali priorità è la privacy dei nostri visitatori. Il presente documento sull'Informativa sulla privacy contiene i tipi di informazioni raccolte e registrate da Creativ-Network e il modo in cui le utilizziamo", "Se avete ulteriori domande o desiderate maggiori informazioni sulla nostra Informativa sulla privacy, non esitate a contattarci via e-mail all'indirizzo data@creativ-network.net", "Questa Informativa sulla privacy si applica solo alle nostre attività online ed è valida per i visitatori del nostro sito web per quanto riguarda le informazioni che hanno condiviso e/o raccolto in Creativ-Network. Questa politica non è applicabile a qualsiasi informazione raccolta offline o tramite canali diversi da questo sito web."],
        pp1t: ["1 - Consenso", ["Utilizzando il nostro sito web, l'utente acconsente alla nostra Informativa sulla privacy e ne accetta i termini."]],
        pp2t: ["2 - Informazioni che raccogliamo", ["I dati personali che vi vengono richiesti e i motivi per cui vi vengono richiesti vi saranno chiariti nel momento in cui vi chiediamo di fornire i vostri dati personali.", "Se ci contattate direttamente, potremmo ricevere ulteriori informazioni su di voi, come il vostro nome, l'indirizzo e-mail, il numero di telefono, il contenuto del messaggio e/o degli allegati che ci inviate e qualsiasi altra informazione che decidiate di fornirci", "Quando vi registrate per un Account, potremmo chiedervi le vostre informazioni di contatto, tra cui elementi come nome, nome dell'azienda, indirizzo, indirizzo e-mail e numero di telefono"]],
        pp3t:  ["3 - Come utilizziamo le vostre informazioni", ["Utilizziamo le informazioni che raccogliamo in vari modi, tra cui per: ", "- Fornire, gestire e mantenere il nostro sito web", "- Migliorare, personalizzare ed espandere il nostro sito web", "- Capire e analizzare come utilizzate il nostro sito web", "- Sviluppare nuovi prodotti, servizi, caratteristiche e funzionalità", "- Comunicare con voi, direttamente o attraverso uno dei nostri partner, anche per il servizio clienti, per fornirvi aggiornamenti e altre informazioni relative al sito web, e per scopi di marketing e promozionali", "- Inviarvi e-mail", "- Trovare e prevenire frodi"]],
        pp4t: ["4 - File di log", ["Creativ-Network segue una procedura standard di utilizzo dei file di log. Questi file registrano i visitatori quando visitano i siti web. Tutte le società di hosting lo fanno e fanno parte dell'analisi dei servizi di hosting. Le informazioni raccolte dai file di log includono gli indirizzi IP (Internet Protocol), il tipo di browser, l'ISP (Internet Service Provider), la data e l'ora, le pagine di riferimento/uscita ed eventualmente il numero di clic. Questi dati non sono collegati ad alcuna informazione di identificazione personale. Lo scopo delle informazioni è quello di analizzare le tendenze, amministrare il sito, seguire i movimenti degli utenti sul sito e raccogliere informazioni demografiche"]],
        pp5t: ["5 - Cookies and Web Beacons", ["Like any other website, Creativ-Network uses ‘cookies'. These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information."]],
        pp6t: ["6 - Informativa sulla privacy dei partner pubblicitari", ["È possibile consultare questo elenco per trovare l'informativa sulla privacy di ciascuno dei partner pubblicitari di Creativ-Network.", "I server pubblicitari di terze parti o le reti pubblicitarie utilizzano tecnologie come cookie, JavaScript o Web Beacon che vengono utilizzate nelle rispettive pubblicità e nei link che appaiono su Creativ-Network, che vengono inviati direttamente al browser degli utenti. In questo caso, ricevono automaticamente l'indirizzo IP dell'utente. Queste tecnologie vengono utilizzate per misurare l'efficacia delle loro campagne pubblicitarie e/o per personalizzare i contenuti pubblicitari visualizzati sui siti web visitati", "Si noti che Creativ-Network non ha accesso o controllo su questi cookie utilizzati da inserzionisti terzi"]],
        pp7t: ["7 - Informativa sulla privacy di terze parti", ["L'Informativa sulla privacy di Creativ-Network non si applica ad altri inserzionisti o siti web. Pertanto, vi consigliamo di consultare le rispettive politiche sulla privacy di questi server pubblicitari di terze parti per informazioni più dettagliate. Queste possono includere le loro pratiche e le istruzioni su come rinunciare a determinate opzioni. L'elenco completo delle politiche sulla privacy e dei relativi link è disponibile qui: Link all'informativa sulla privacy.", "È possibile scegliere di disabilitare i cookie attraverso le opzioni del proprio browser. Per informazioni più dettagliate sulla gestione dei cookie con specifici browser web, è possibile consultare i rispettivi siti web. Cosa sono i cookie?"]],
        pp8t: ["8 - CCPA Privacy Policy (Do Not Sell My Personal Information)", ["In base al CCPA, tra gli altri diritti, i consumatori californiani hanno il diritto di:", "Richiedere che un'azienda che raccoglie i dati personali di un consumatore divulghi le categorie e i dati personali specifici che l'azienda ha raccolto sui consumatori", "Chiedere che un'azienda cancelli tutti i dati personali raccolti sul consumatore", "Chiedere che un'azienda che vende i dati personali di un consumatore non li venda", "Se fate una richiesta, abbiamo un mese di tempo per rispondervi. Se desiderate esercitare uno di questi diritti, contattateci"]],
        pp9t:  ["9 - Informativa sulla privacy GDPR (Diritti di protezione dei dati)", ["Vorremmo assicurarci che l'utente sia pienamente consapevole di tutti i suoi diritti di protezione dei dati. Ogni utente ha i seguenti diritti:", "Diritto di accesso - L'utente ha il diritto di richiedere copie dei propri dati personali. Potremmo addebitare all'utente un piccolo costo per questo servizio", "Diritto di rettifica - L'utente ha il diritto di richiedere la correzione di qualsiasi informazione che ritenga inesatta. Avete inoltre il diritto di richiedere che vengano completate le informazioni che ritenete incomplete", "Diritto alla cancellazione - Avete il diritto di richiedere che vengano cancellati i vostri dati personali, a determinate condizioni", "Diritto alla limitazione del trattamento - Avete il diritto di richiedere che venga limitato il trattamento dei vostri dati personali, a determinate condizioni", "Diritto di opporsi al trattamento - Avete il diritto di opporvi al trattamento dei vostri dati personali da parte nostra, a determinate condizioni", "Diritto alla portabilità dei dati - Avete il diritto di richiedere il trasferimento dei dati da noi raccolti a un'altra organizzazione o direttamente a voi, a determinate condizioni", "Se fate una richiesta, abbiamo un mese di tempo per rispondervi. Se desiderate esercitare uno di questi diritti, contattateci"]],
        pp10t: ["10 - Informazioni sui bambini", ["Un'altra parte della nostra priorità è l'aggiunta di protezione per i bambini durante l'utilizzo di Internet. Incoraggiamo i genitori e i tutori a osservare, partecipare e/o monitorare e guidare la loro attività online", "Creativ-Network non raccoglie consapevolmente alcuna informazione di identificazione personale da bambini di età inferiore a 13 anni. Se pensate che vostro figlio abbia fornito questo tipo di informazioni sul nostro sito web, vi invitiamo caldamente a contattarci immediatamente e faremo del nostro meglio per rimuovere prontamente tali informazioni"]],

        lastupdated: "Ultimo aggiornamento",
        tcint: ["Si prega di leggere attentamente i seguenti Termini e Condizioni prima di utilizzare il sito web di Creativ'Network"],
        tc1t: ["1. Accettazione dei termini", ["Accedendo o utilizzando il sito web di Creativ'Network, l'utente accetta di rispettare ed essere vincolato dai presenti Termini e condizioni. Se non si è d'accordo con una parte di questi termini, si prega di non utilizzare il nostro sito web."]],
        tc2t: ["2. Idoneità dell'utente", ["Per utilizzare il nostro sito web è necessario avere almeno 18 anni. Utilizzando i nostri servizi, l'utente conferma di soddisfare questo requisito di età."]],
        tc3t: ["3. Utilizzo del sito web", ["- L'utente accetta di utilizzare il sito web in conformità con tutte le leggi e i regolamenti applicabili", "- L'utente non si impegna in attività illegali, dannose o abusive sulla nostra piattaforma", "- L'utente accetta di non impersonare altri o creare false identità"]],
        tc4t: ["4. Privacy e trattamento dei dati", ["La nostra Informativa sulla privacy illustra come raccogliamo, utilizziamo e proteggiamo i vostri dati personali. Utilizzando il nostro sito web, l'utente acconsente alle pratiche di trattamento dei dati descritte nella nostra Informativa sulla privacy."]],
        tc5t: ["5. Proprietà intellettuale", ["Tutti i contenuti del nostro sito web, compresi testi, grafica, loghi e software, sono protetti da diritti di proprietà intellettuale. Non è consentito utilizzare, riprodurre o distribuire i nostri contenuti senza la nostra esplicita autorizzazione."]],
        tc6t: ["6. Contenuto generato dall'utente", ["Quando contribuite al contenuto della nostra piattaforma, ci concedete una licenza non esclusiva ed esente da royalty per l'utilizzo, la modifica e la distribuzione del vostro contenuto."]],
        tc7t: ["7. Modifiche ai termini", ["Possiamo aggiornare questi termini e condizioni in qualsiasi momento. L'uso continuato del sito Web dopo tali modifiche indica l'accettazione dei termini rivisti."]],
        tc8t: ["8. Disclaimer e limitazioni di responsabilità", [`Forniamo il nostro sito web "così com'è" e non forniamo alcuna garanzia in merito alla sua accuratezza, disponibilità o prestazioni. Non saremo responsabili di alcun danno diretto, indiretto o consequenziale derivante dall'uso del nostro sito web.`]],
        tc9t: ["9. Legge applicabile e giurisdizione", ["Questi termini e l'uso del nostro sito web sono regolati dalle leggi del Regno Unito. Eventuali controversie saranno risolte dai tribunali del Regno Unito."]],
        tc10t: ["10. Informazioni di contatto", ["Se avete domande o dubbi su questi termini, contattateci all'indirizzo contact@creativ-network.net."]],

        jobsint1: "Scegli il lavoro più adatto alle tue competenze",
        jobsint2: "Scegli il lavoro più adatto alle tue competenze",
        jobsat: "Lavoro presso",

        hirenow: "ASSUMERE ORA!",
        closed: "CHIUSO!",
        detailsandapply: "Vedere i dettagli e candidarsi",
        appclosed: "Candidatura chiusa",

        jobco:  ["Operatore di chat di incontri", "Sei appassionato di chat e di gestione di siti e community di incontri"],
        jobwdev: ["Sviluppatore web", "Sei appassionato di chat e di gestione di siti e community di incontri"],
        jobtl: ["Team Leader", "Sei appassionato di chat e gestione di siti e community di incontri"],
        jobdm: ["Digital Marketer", "Sei appassionato di chat e gestione di siti e community di incontri"],
        jobsup: ["Supervisore", "Sei appassionato di chat e gestione di siti e community di incontri"],
        jobwdes: ["Web Designer", "Sei appassionato di chat e gestione di siti e community di incontri"],
        
        cotest: "Test dell'operatore di chat",
        cotesttitle: "est per diventare operatore di chat di incontri presso Creativ'Network",
        cotestint1: `"Il test per diventare operatore di chat di incontri di Creativ'Network valuta le capacità di comunicazione, l'empatia e le abilità di coinvolgimento per interazioni efficaci e rispettose con gli utenti."`,
        cotestint2: "Prendetevi qualche momento per rispondere a tutte le situazioni prima di procedere con la vostra candidatura",
        goodluck: "Buona fortuna!",
        number: "N°",
        youranswer: "La tua risposta",
        next: "Prossimo",
        back: "Indietro",
        blog: "Blog",
        stat: "Statistiche",
        at: "At",
        ourstat: ", le nostre statistiche dipingono un quadro vivido del nostro successo e della nostra crescita. Con un team dedicato, numerosi progetti, una vasta rete di partner e anni di esperienza, siamo una forza fiorente nel settore. Approfondiamo i dettagli per scoprire il nostro percorso",
        team: "Membri del team",
        projects: "Progetti",
        netpart: "Partner della rete",
        years: "Anni di attività",
        testimonial: "Testimonial",
        dedicated:"Membri del team dedicati",
        sharing: " condividendo le loro esperienze, le loro intuizioni e il loro entusiasmo per un luogo di lavoro gratificante dove la collaborazione prospera.",
        testimonialperson1: ["Marie C", "Operatore di chat di incontri", `"Lavorare come operatore di chat di incontri presso Creativ'Network è incredibile. Ogni giorno posso mettere in contatto le persone e fare la differenza nelle loro vite. È gratificante e divertente!"`], 
        testimonialperson2: ["Lucas P", "Dating Chat Operator", `"Creativ'Network è il posto perfetto per abbracciare la tua passione per aiutare a non sentirsi soli. Il team è fantastico e l'atmosfera è di supporto. Amo quello che faccio."`],
        testimonialperson3: ["Sophie M", "Operatore di chat di incontri", `"Creativ'Network offre una piattaforma unica per creare connessioni significative. Come operatrice di chat di incontri, mi sento parte di qualcosa di speciale, che discute con le persone e le rende felici."`],
        testimonialperson4: ["Alex R", "Web Developer Front End", `"Creativ'Network mi mette alla prova come sviluppatore web. I progetti innovativi e il supporto del team spingono le mie capacità verso nuovi traguardi. Sono orgoglioso di far parte di questo ambiente tecnologico dinamico."`],
        testimonialperson5: ["Emma H", "Web Developer Back End", `"Ho trovato il lavoro dei miei sogni come sviluppatore web presso Creativ'Network. L'impegno dell'azienda per la tecnologia all'avanguardia e le opportunità di crescita mi rendono sempre ispirata ed entusiasta."`],
        testimonialperson6: ["Tommy J", "Web Designer", `"Come web designer presso Creativ'Network, ho la libertà creativa di creare piattaforme visivamente accattivanti. È un luogo stimolante che valorizza l'estetica del design e l'esperienza dell'utente, rendendo il lavoro davvero piacevole."`],

        titlesuffix: " - Creativ-Network",
        titlejobs: "Jobs",
        titlechatoperator:  "Operatore di chat",
        titletest: "Test ",
        titlepp: "Informativa sulla privacy",
        titleterms: "Termini",

        wantto: "Volete diventare lavoratori soddisfatti con ",
        areyou: "Siete appassionati di lavoro sul web? Avete esperienza in uno dei seguenti settori: chat, sviluppo web, digital marketing, data labeling, teleassistenza e relazioni con i clienti, televendite, copy writing, teleprospecting, social media management, HR, contabilità, web design...? ? Unisciti subito alla nostra comunità! ",
        check: 'Controlla tutte le offerte di lavoro e candidati',

        our: 'Nostro',
        missions: 'Missioni',
        advantages: 'Vantaggi',


        mission1: "Identificazione e acquisizione di talenti: L'azienda cerca e recluta attivamente persone con esperienza nei settori legati al web. Ciò comporta l'identificazione e l'attrazione dei migliori talenti da varie fonti, tra cui gli annunci di lavoro, le reti online e le segnalazioni",
        mission2: "Valutazione e screening delle competenze: Creativ'Network valuta rigorosamente le competenze e le qualifiche dei potenziali candidati per garantire che soddisfino i requisiti specifici dell'azienda per i ruoli per cui vengono assunti",
        mission3:  "Adattamento culturale e allineamento dei valori: Al di là delle competenze, l'azienda valuta i candidati in base al loro allineamento con i valori e la cultura aziendale per favorire un ambiente di lavoro coeso e produttivo",
        mission4: "Personalizzazione delle posizioni: Creativ'Network adatta le posizioni ai punti di forza e alle competenze dei talenti assunti, assicurando che i ruoli siano allineati con le loro competenze e interessi",
        mission5:  "Sviluppo e fidelizzazione dei talenti: L'azienda si impegna a coltivare e trattenere i talenti che assume, offrendo opportunità di crescita e avanzamento all'interno dell'organizzazione",
        mission6: "Analisi di mercato e strategia: Creativ'Network tiene sotto controllo le tendenze del settore, i benchmark salariali e l'evoluzione delle competenze richieste per rimanere competitiva nel mercato del lavoro legato al web",
        mission7: "Diversità e inclusione: L'azienda può promuovere attivamente la diversità e l'inclusione nel proprio processo di acquisizione dei talenti, sforzandosi di creare una forza lavoro che rifletta un'ampia gamma di background e prospettive",
        mission8: "Collaborazione e formazione trasversale: L'azienda incoraggia la collaborazione tra i propri talenti e offre opportunità di formazione trasversale, consentendo ai dipendenti di diversificare le proprie competenze e di contribuire a vari aspetti dell'attività",
        mission9: "Adattamento alle esigenze in evoluzione: Creativ'Network rimane flessibile e si adatta alle mutevoli esigenze dei settori web in cui opera. Ciò include l'acquisizione di nuovi talenti e competenze in base all'evoluzione della tecnologia e delle tendenze del settore",
        mission10: "Miglioramento continuo: L'azienda rivede e perfeziona costantemente i propri processi di reclutamento e gestione dei talenti per garantirne l'efficienza e l'efficacia",
        mission11: "Soddisfazione e benessere dei dipendenti: Creativ'Network pone una forte enfasi sulla soddisfazione dei dipendenti, offrendo benefit, equilibrio tra lavoro e vita privata e supporto per migliorare il benessere generale della sua forza lavoro",

        value1: "Eccellenza: Puntiamo all'eccellenza in tutto ciò che facciamo, dal reclutamento dei migliori talenti alla fornitura di servizi eccezionali ai nostri clienti",
        value2: "Approccio centrato sul cliente: Mettiamo i nostri clienti al centro delle nostre attività, comprendendo le loro esigenze uniche e fornendo soluzioni su misura che favoriscono il loro successo",
        value3: "Valorizzazione dei talenti: Diamo ai talenti del web la possibilità di prosperare nella loro carriera mettendoli in contatto con le opportunità che si allineano con le loro capacità e aspirazioni",
        value4: "Innovazione e adattabilità: Abbracciamo l'innovazione e ci adattiamo al panorama in continua evoluzione dei settori legati al web, assicurandoci di rimanere all'avanguardia delle tendenze del settore",
        value5: "Diversità e inclusione: Celebriamo la diversità e promuoviamo un ambiente inclusivo in cui individui di ogni provenienza possano contribuire al nostro successo collettivo",
        value6:  "Trasparenza e integrità: Sosteniamo i più alti standard di integrità e manteniamo una comunicazione trasparente con i nostri clienti, candidati e membri del team",
        value7: "Collaborazione e sinergia: Crediamo nel potere della collaborazione, lavorando insieme per raggiungere obiettivi condivisi e ottenere risultati positivi",
        value8: "Apprendimento continuo: Incoraggiamo l'apprendimento continuo e lo sviluppo professionale, assicurando che il nostro team sia dotato delle competenze e delle conoscenze più recenti",
        value9: "Competenza nel settore: Siamo esperti del settore e offriamo preziose intuizioni sul mercato del lavoro legato al web, condividendo le nostre conoscenze con clienti e candidati",
        value10: "Condotta etica: Operiamo con una condotta etica incrollabile, trattando tutti gli individui con rispetto, equità e professionalità",
        value11: "Impegno nella comunità: Ci impegniamo attivamente nella comunità professionale, contribuendo alla sua crescita attraverso il mentoring, la formazione e la partecipazione ad associazioni di settore",
        value12: "Impatto e responsabilità: Ci assumiamo la responsabilità dell'impatto che abbiamo sulle carriere dei singoli e sul successo delle organizzazioni che serviamo, impegnandoci per ottenere risultati positivi e duraturi",

        advantage1:"Pacchetti retributivi competitivi: Offrire compensi e benefit superiori alla media o leader del settore per attrarre e trattenere talenti di alta qualità, in grado di distinguere Creativ'Network dalla concorrenza",
        advantage2:"Bonus basati sui risultati: L'implementazione di strutture di bonus basate sulle prestazioni può motivare i dipendenti a eccellere e garantire che siano ricompensati per i loro contributi",
        advantage3:"Piani retributivi personalizzati: La personalizzazione dei pacchetti retributivi in base alle competenze, all'esperienza e alle prestazioni dei singoli dipendenti può portare a una maggiore soddisfazione e fedeltà sul lavoro",
        advantage4:"Opportunità di crescita professionale: Fornire percorsi chiari per l'avanzamento di carriera e lo sviluppo all'interno dell'azienda, che possono attrarre professionisti ambiziosi e promuovere la fidelizzazione dei talenti interni",
        advantage5:"Programmi di riconoscimento dei dipendenti: Riconoscere e premiare le prestazioni e i risultati eccellenti dei dipendenti può aumentare il morale e incrementare i tassi di fidelizzazione",
        advantage6: "Modalità di lavoro flessibili: L'offerta di opzioni di lavoro flessibili, come il lavoro a distanza o l'orario flessibile, può migliorare l'equilibrio tra lavoro e vita privata e attirare un maggior numero di candidati",
        advantage7:"Pacchetti di benefit completi: L'offerta di benefit competitivi, come l'assicurazione sanitaria, i piani pensionistici e i programmi di benessere, può attrarre i migliori talenti e promuovere il benessere dei dipendenti",
        advantage8:"Investimento in formazione e sviluppo: Sostenere l'apprendimento continuo e la crescita professionale attraverso programmi di formazione e opportunità educative può favorire una forza lavoro altamente qualificata e motivata",
        advantage9:"Pratiche retributive trasparenti: Mantenere una comunicazione trasparente sulle pratiche retributive e sui benchmark salariali può creare fiducia e trasparenza nella gestione del personale",
        advantage10:"Programmi di assistenza ai dipendenti: L'offerta di programmi di assistenza per le sfide personali e professionali può dimostrare l'impegno verso il benessere e la soddisfazione dei dipendenti",
        advantage11:"Valutazioni delle prestazioni e feedback: Valutazioni regolari delle prestazioni e feedback costruttivi possono aiutare i dipendenti a comprendere la loro progressione di carriera e le aree di miglioramento",
        advantage12:"Analisi di mercato per stipendi competitivi: Conduzione di analisi di mercato per garantire che stipendi e benefit rimangano competitivi e allineati agli standard del settore",
        advantage13:"Strutture di incentivazione: Sviluppo di strutture di incentivazione creative che premiano le prestazioni eccezionali, l'innovazione e il contributo agli obiettivi dell'azienda",
        blog2: "Qui troverete i nostri post",
        ourposts: "I nostri post",
        blog3: "Godetevi la lettura dei nostri post",
        datepub: "15/10/2023",
        months: ["gennaio", "febbraio", "marzo", "aprile", "maggio", "giugno", "luglio", "agosto", "settembre", "ottobre", "novembre", "dicembre"],
        uncategorized: "Senza categoria",

        blognumber1: ["Strada per un paesaggio accattivante", "La nuova lingua comune sarà più semplice e regolare delle lingue europee esistenti. Sarà semplice come l'Occidentale; anzi, sarà Occidentale. A un inglese sembrerà un inglese semplificato, come un mio amico scettico di Cambridge mi ha detto che cos'è l'Occidentale. Le lingue europee fanno parte della stessa famiglia."], 
        blognumber2: ["Gravi problemi con i cavi in città", "La nuova lingua comune sarà più semplice e regolare delle lingue europee esistenti. Sarà semplice come l'Occidentale; anzi, sarà Occidentale. A un inglese sembrerà un inglese semplificato, come un mio amico scettico di Cambridge mi ha detto che cos'è l'Occidentale. Le lingue europee fanno parte della stessa famiglia."], 
        blognumber3: ["Montagna e freddo invernale", "Se diverse lingue si fondono, la grammatica della lingua risultante è più semplice e regolare di quella delle singole lingue. La nuova lingua comune sarà più semplice e regolare delle lingue europee esistenti. Sarà semplice come l'Occidentale; anzi, sarà Occidentale."], 

        by: "da",
        noc: "nessun",
        comments: "commenti",
        comment: "commento",
        readmore: "Per saperne di più",

        cookiestitle: "Gestione del consenso ai cookie",
        cookiescontent: "La politica di consenso sui cookie di Creativ'Network è essenziale per un'esperienza online trasparente e conforme. Cerchiamo di ottenere il consenso degli utenti in modo chiaro, fornendo informazioni dettagliate sulle nostre pratiche relative ai cookie. Grazie alle opzioni di personalizzazione, alla categorizzazione esplicita dei cookie e all'impegno per la privacy dei dati, Creativ'Network crea un'esperienza digitale sicura e rispettosa. Ci atteniamo rigorosamente agli standard di privacy, garantendo la sicurezza degli utenti e la fiducia nella nostra piattaforma",
        pref: "Preferenze",
        cookiessm1i: "Funzionale",
        cookiessm2i: "Statistiche",
        cookiessm3i: "Marketing",
        cookiessm1c: `"Storage o accesso tecnico" sul sito web di Creativ'Network comporta l'uso di cookie per migliorare l'esperienza dell'utente. Ciò include la raccolta di dati tecnici essenziali per il corretto funzionamento del sito. Chiediamo il consenso degli utenti, fornendo informazioni trasparenti su queste pratiche, in conformità con gli standard e le normative vigenti in materia di privacy`,
        cookiessm2c: `Le statistiche nella gestione del consenso dei cookie per Creativ'Network consentono di raccogliere dati analitici. Svolgono un ruolo fondamentale nella valutazione delle abitudini di navigazione degli utenti, facilitando così il miglioramento continuo del sito. Queste informazioni guidano le decisioni per ottimizzare l'esperienza dell'utente nel rispetto delle preferenze di privacy, garantendo una piattaforma personalizzata e conforme`,
        cookiessm3c: `La funzione "Marketing" nella gestione del consenso dei cookie sul sito Creativ'Network ci permette di personalizzare la pubblicità in base alle preferenze degli utenti. Ottenendo il loro consenso, garantiamo un'esperienza pubblicitaria mirata e pertinente. Ciò garantisce una comunicazione promozionale più efficace, nel rispetto delle norme sulla privacy dei dati e delle scelte individuali degli utenti.`,
        savepref: "Salva le preferenze",
        accept: "Accetta",
        alwyasactive: "Sempre attivo",
        error: "Errore",
        tags: "Tag: Nessun tag",
        comment1: "Aggiungi un commento",
        comment2: "Il tuo indirizzo email non sarà pubblicato.",
        comment3: "Nome (obbligatorio)",
        comment4: "E-mail o nome utente",
        comment5: "Sito web",
        comment6: "Salva il mio nome, la mia email e il mio sito web in questo browser per la prossima volta che commenterò.",
        comment7: "Commento",
        follow: "Seguici",
        us: "",
        creatememberspace: "Creazione dell'area riservata ai membri",
        createmsint11: "Inserisci il codice che abbiamo inviato a",
        createmsint12: "per convalidare il tuo indirizzo e-mail.",
        createmsint2: "La convalida del tuo indirizzo e-mail creerà automaticamente la tua area membri",
        createmsconsent: "Inviando il modulo di iscrizione, si accetta il nostro",
        confirm: "Confermare",
        codevalidationerror: "Il codice di convalida non è corretto",
        confirmpassword: "Confermare la password",
        passwordlength: `La password deve contenere almeno ${mdpminlength} caratteri`,
        passwordnotidentical: "Le password non sono identiche",
        welcomeback: "Bentornato",
        signinint: "Per rimanere in contatto con noi, utilizzate i vostri dati personali per effettuare il login.",
        signin: "Accedi",
        createaccount: "Creare un account",
        oruseyourpersonnalmail: "Oppure utilizzate la vostra mail personale per la registrazione",
        email2: "E-mail",
        signup: "Iscriviti",
        memberarea: "Area soci",
        yourmailpseudo: "Il vostro indirizzo e-mail / Nome utente",
        yourpassword: "La vostra password",
        idorpasswordforgot: "Clicca qui se hai dimenticato la password",
        settingupaccount: "Impostazione dell'account",
        settingupaccountint: "Per creare il tuo account, devi fornire le seguenti informazioni:",
        yourfirstname: "Il tuo nome",
        yourlastname: "Il tuo cognome",
        yourusername: "Il tuo nome utente",
        yourskypeid: "Il tuo ID skype",
        yourbirthdate: "La tua data di nascita",
        yourgender: "Il tuo sesso",
        yourphonenumber: "Il tuo numero di telefono",
        yourmailaddress: "Il vostro indirizzo e-mail",
        appartroad: "Numero di appartamento/strada",
        yourcitydep: "La vostra città / Dipartimento",
        yourpobox: "La vostra casella postale",
        yourcountry: "Il vostro Paese",
        agreepp1: "Prima di inviare le informazioni, è necessario accettare il nostro",
        agreepp2: "",
        note: "Nota",
        notecontent: "Per attivare il tuo account, controlla la tua casella di posta elettronica",
        createmyaccount: "Crea il mio account",
        male: "Maschio",
        female: "Femmina",
        other: "Altro",
        mailexists: "Questo indirizzo e-mail esiste già",
        usernameexists: "Questo nome utente esiste già",
        dashboard: "Cruscotto",
        dashboard1: "Gestite il vostro account Creativ'Network. Modificare le impostazioni e visualizzare i rapporti",        
        dashboard2: "Canale di controllo",

        information: "Le vostre informazioni", 
        information1: "Gestire le informazioni personali",
        information2: "Cambiare la password",
        
        yourapplication: "La vostra domanda",
        application1: "Caricare/modificare il curriculum",
        application2: "Controllare lo stato di avanzamento della domanda di lavoro",
        
        integration: "La vostra integrazione",
        integration1: "Caricare/modificare i documenti richiesti",
        integration2: "Caricare/modificare le informazioni di pagamento",

        info11: "Cambia la foto del tuo profilo",
        info12: "Carica la tua foto dal browser",
        info13: "Browser",
        info14: "Carica la foto dalla fotocamera",

        save: "Risparmiare",
        admin: "Amministratore",
        blogger: "Blogger",
        teamleader: "Leader del team",
        adboard: "Cruscotto amministratore",
        tldboard: "Cruscotto del team leader",
        bdboard: "Cruscotto del blogger",
        notifs: 'Notifiche',
        logout: 'Disconnessione',
        actualpassword: "La vostra password attuale",
        newpassword: "La vostra nuova password",
        confirmnewpassword: "Confermare la password",

        app11: "Carica il tuo curriculum",
        app12: "Carica il tuo curriculum dal browser",

        appstatus1: "Modulo di richiesta",
        submittedon:"Presentato il",
        appstatus2: "Test",
        notyet: "Non ancora fatto",
        reviewedon: "Recensito il",
        appstatus3: "Stato di revisione",
        reviewed: "Recensito",
        appstatus4: "Decisione della direzione",
        hired: "Congratulazioni, siete stati assunti, passate alla fase successiva: l'integrazione.",
        nothired: "Siamo spiacenti, ma la sua domanda non è stata accettata.",
        idtl: "ID caposquadra",
        ida: 'ID amministratore',
        idb: "ID blogger",
        positionoverview: "Panoramica della posizione",
        adminstaff: "Personale amministrativo",
        jobposts: "Posti di lavoro",
        jobapplication: "Domanda di lavoro",
        newemptraining: "Formazione dei nuovi dipendenti",
        empintegration: "Integrazione dei dipendenti",
        blogposts: "Messaggi del blog",
        details: "Dettagli",
        cnmembers: "Membri Creativ-Network",
        sn: "Rete sociale",
        aas1: "Elenco del personale amministrativo",
        aas2: "Aggiungi personale amministrativo",
        aja1: "Tutte le candidature",
        aja2: "Controlla i dettagli delle candidature",
        aja3: "Candidature accolte",
        anet1: "Programma di formazione",
        anet2: "Aggiungi documenti di formazione",
        anet3: "Agenti in attesa di login",
        anet4: "Attribuzione del login",
        aei1: "Check up nuovi agenti",
        abp1: "Elenco blogger",
        abp2: "Aggiungi blogger",
        abp3: "Elenco dei post del blog",
        abp4: "Aggiungi post del blog",
        ajp1: "Elenco dei post di lavoro",
        ajp2: "Aggiungi post di lavoro",
        acnm1: "Elenco dei membri",
        acnm2: "Aggiungi membri",
        anl1: "Elenco degli iscritti",
        anl2: "Tutte le newsletter",
        anl3: "Aggiungi newsletter",
        asn1: "Tutti i social network",
        passportcopy: "Copia del passaporto, o della patente di guida, o del documento di identità governativo",
        proofofaddress: "Prova di indirizzo",
        yourselfie: "Il vostro selfie con il vostro documento d'identità",
        freelance: "Contratto freelance",
        integ1up1: "Caricare il documento d'identità dal browser",
        integ1up2: "Caricare la prova di indirizzo dal browser",
        integ1up3: "Carica il tuo selfie dal browser",
        integ1up4: "Carica il tuo selfie dalla fotocamera",
        integ1download: "Scarica il contratto freelance, firmalo e rispediscilo qui",
        integ1up5: "Caricare il contratto freelance firmato dal browser",
        download: "Scaricare",

        paypalaccount: "Conto Paypal",
        yourpaypal: "(Your  Paypal email address)",
        wiseaccount: "Conto Wise",
        yourwise: "(Il tuo indirizzo email Wise)",
        bitesafeaccount: "Conto Yoursafe",
        yourbitesafe: "(Il tuo indirizzo email Yoursafe)",
        bankaccount: "Conto bancario",
        accountholder: "Nome del titolare del conto:",
        holderaddress: "Indirizzo del titolare del conto:",
        banksholder: "Nome della banca titolare:",
        bankaddress: "Indirizzo della banca titolare: ",
        iban: "IBAN / Numero di conto:",
        swiftcode: "Codice swift / BIC: ",
        year: 'Anno',
        month: 'Mese',
        date: 'Data',
        week: 'Settimana',
        today: 'Oggi',
        startingdate: 'Data di inizio',
        closingdate: 'Data di chiusura',
        adminstafflogin: 'Login del personale amministrativo',
        adminstaffloginpassword: 'Password di accesso del personale amministrativo',
        actions: 'Azioni',
        suspend: 'Sospendi',
        fire: 'Divieto',
        candidateid: 'ID candidato',
        createnewadminstaff: 'Crea nuovo personale amministrativo',
        language: "Lingua",
        edit: 'Modifica',
        openingposition: "Posizione di apertura",
        jobtitle : "Titolo del lavoro",
        jobdescription : "Descrizione del lavoro",
        openclose: "Disponibile / Non disponibile",
        jobopen: "Disponibile",
        jobclose: "Non disponibile",
        delete: "Cancella",
        applicantfirstname: "Nome del richiedente",
        applicantlastname: "Cognome del richiedente",
        applicantcountry: "Paese del richiedente",
        application: "Applicazione",
        checkthapplication: "Controlla la domanda",
        agentfirstname: "Nome dell'agente",
        agentlastname: "Cognome dell'agente",
        agentcountry: "Paese dell'agente",
        agentemail: "e-mail dell'agente",
        agentskypeid: "ID skype dell'agente",
        trainingschedule: "Programma di formazione",
        sendconfirmationonemailtoagent: "Invia e-mail di conferma all'agente",
        memberopt1: "Gestisci le tue informazioni",
        memberopt2: "Controlla la tua candidatura",
        memberopt3: "Procedere all'integrazione",
        awaitingforlogin: "In attesa del login",
        awaitingforemailconf: "In attesa dell'e-mail di conferma",
        agentlogin: "Accesso agente",
        freelancecontract: "Contratto freelance",
        trainingdocumentssigned: "Documenti di formazione firmati",
        proofofresidence: "Prova di residenza",
        paymentmethod: "Metodo di pagamento",
        provided: "Fornito",
        notprovided: "Non fornito",
        bank: "Banca",
        wise: "Wise",
        paypal: "Paypal",
        Yoursafe: "Yoursafe",
        activeblogger: "Blogger attivo",
        bloggerfirstname: "Nome del blogger",
        bloggerlastname: "Cognome del blogger",
        bloggerloginid: "ID di accesso del blogger",
        bloggerloginpassword: "Password di accesso al blog",
        blogposttile: "Titolo del post del blog",
        blogpostcategory: "Categoria del post del blog",
        blogpostlink: "Link al post del blog",
        category: "Categoria",
        newmembers: "Nuovi membri",
        allmembers: "Tutti i membri",
        inscriptdate: "Data di iscrizione",
        memberfirstname: "Nome del membro",
        memberlastname: "Cognome del membro",
        loginpassword: "Password di accesso",
        emailaddressconfirmed: "Indirizzo e-mail confermato",
        country: "Paese",
        newsubscribers: "Nuovi abbonati",
        allsubscribers: "Tutti gli abbonati",
        subscriptiondate: "Data di sottoscrizione",
        topic: "Argomento",
        allnewsletters: "Tutte le newsletter",
        releasedate: "Data di rilascio",
        releaselanguage: "Lingua di pubblicazione",
        linktothenewsletter: "Link alla newsletter",
        sendtoallsubscribers: "Invia a tutti gli abbonati",

        agentid: "ID agente",
        trainingprocess: "Processo di formazione",
        integrationprocess: "Processo di integrazione",
        jobposition: "Posizione lavorativa",
        training: "Formazione",
        integrationn: "Integrazione",
        inprogress: "In corso",
        done: "Fatto",
        role : "Ruolo",
        skypeid : "ID Skype",
        jobid: "ID lavoro",
        illustrationphoto: "Foto illustrativa",
        location: "Posizione",
        jobrequirement: "Condizioni di impiego",
        salaryscale: "Scala salariale",
        incentives: "Incentivi",
        openingdate: "Data di apertura",
        telephonenumber: "Numero di telefono",
        createnewjobposts: "Creare un nuovo annuncio di lavoro",
        haveexperience: "Avere esperienza",
        howtheyfindus: "Come ci hanno trovato",
        motivation: "Motivazione",
        testresult: "Risultato del test",
        decision: "Decisione",
        hired2: "Assunto",
        rejected: "Rifiutato",
        description: "Descrizione",

        name: "Nome",
        document: "Documento",
        blogpostid: "ID del post del blog",
        bloggerid: "ID blogger",
        blogposttitle: "Titolo del post del blog",
        blogposttext: "Testo del post del blog",
        publishnewblogpost: "Pubblica un nuovo post del blog",
        createnewblogger: "Crea nuovo blogger",
        createnewlogin: "Crea un nuovo login",
        addblogpostcategory: "Aggiungi la categoria del post del blog",
        blogpostcategoryid: "ID della categoria del blog",
        memberid: "ID membro",
        addnewmemberandsendnotification: "Aggiungi un nuovo membro e invia una notifica",
        newsletterid: "ID newsletter",
        adminid: "ID amministratore",
        newslettercategory: "Categoria della newsletter",
        newslettertitle: "Titolo della newsletter",
        newslettertext: "Testo della newsletter",
        allsubscribersemail: "Indirizzo e-mail di tutti gli abbonati",
        publishnewnewsletter: "Pubblica una nuova newsletter",
        link: "Link",
        hello : "Ciao",

        tlintint1: "Vuoi lavorare a casa come",
        tlintint2: "di operatori di chat per incontri?",
        tlint1: "1. Leadership d'impatto: Guida un team che crea connessioni, favorisce relazioni significative e contribuisce a un ambiente dinamico di incontri",
        tlint2: "2. Opportunità di crescita del team: Guidare e sviluppare un team coeso, favorendo la crescita personale e professionale, in un ambiente di lavoro di supporto",
        tlint3: "3. Contributo innovativo: Introdurre soluzioni creative, plasmare il futuro degli incontri online e migliorare i servizi di chat, contribuendo all'innovazione del settore",
        tlint4: "4. Riconoscimento e risultati: Guidare un team di successo, superare le metriche e ottenere riconoscimenti, con uno stipendio competitivo e incentivi allettanti",
        tlint5: "5. Premi per l'eccellenza: Godete di un buon stipendio, di incentivi vantaggiosi e di riconoscimenti per il raggiungimento degli obiettivi, con un impatto significativo in Creativ'Network",
        tlabint1: "In cosa consiste il lavoro di",
        tlabint2: "di operatori di chat di incontri?",
        tlab1: "1. Leadership e coordinamento: In qualità di Team Leader, la supervisione di tutti gli operatori di chat di incontri implica una forte leadership, la guida del team e la garanzia di una comunicazione coesa.",
        tlab2: "2. Gestione delle prestazioni: Stabilire e monitorare i parametri di prestazione, come i tempi di risposta e la soddisfazione degli utenti, per ottenere un servizio di alta qualità e promuovere un'esperienza positiva per gli utenti.",
        tlab3: "3. Sviluppo del team: Coltivare un team coeso e motivato fornendo indicazioni, tutoraggio e promuovendo un ambiente di lavoro di supporto per la crescita personale e professionale",
        tlab4: "4. Innovazione nei servizi di chat: Introdurre approcci creativi per migliorare i servizi di chat, rimanere al passo con le tendenze del settore e adattare le strategie per migliorare il coinvolgimento degli utenti",
        tlab5: "5. Garanzia di qualità: Garantire che gli operatori mantengano un elevato standard di servizio, rispondendo alle esigenze degli utenti e risolvendo tempestivamente i problemi per mantenere un'esperienza positiva e affidabile per gli utenti.",
        tlab6: "6. Hub di comunicazione: Servire come punto centrale per la comunicazione tra gli operatori, la direzione e gli altri dipartimenti, facilitando la collaborazione per raggiungere gli obiettivi organizzativi nel regno dinamico degli incontri online",
        tlab7: "7. Pianificazione strategica: Contribuire alla pianificazione strategica del team, allineando gli obiettivi con quelli dell'organizzazione nel dinamico settore degli incontri online",
        tllsint1: "Quali sono le capacità di leadership per essere un",
        tllsint2: "di operatori di chat di incontri?",
        tlls11: "Esperienza di leadership",
        tlls12: "Esperienza dimostrata nella guida e nella gestione di team, con capacità di leadership e mentoring efficaci",
        tlls21: "Capacità di comunicazione",
        tlls22: "Spiccate capacità di comunicazione verbale e scritta per facilitare una comunicazione chiara tra i membri del team e con gli altri reparti",
        tlls31: "Conoscenza del settore",
        tlls32: "Familiarità con il settore degli incontri online, comprensione delle dinamiche degli utenti, delle tendenze e delle sfide nella fornitura di servizi di chat per incontri",
        tlls41: "Capacità di risolvere i problemi",
        tlls42: "Comprovata capacità di analizzare e risolvere i problemi in modo efficiente, in particolare per affrontare i problemi degli utenti e mantenere un'esperienza positiva per gli utenti",
        tlls51: "Gestione delle prestazioni",
        tlls52: "Esperienza nella definizione, nel monitoraggio e nell'ottimizzazione delle metriche di performance per garantire un servizio di alta qualità, la soddisfazione degli utenti e l'efficienza del team",
        tlls61: "Adattabilità e innovazione",
        tlls62: "Capacità di adattarsi ai cambiamenti del settore, di introdurre strategie innovative e di stare al passo con le tendenze emergenti nei servizi di incontri online",
        tlls71: "Capacità di sviluppo del team",
        tlls72: "Capacità comprovata di promuovere la crescita del team, di fornire indicazioni e tutoraggio e di creare un ambiente di lavoro positivo per lo sviluppo personale e professionale",
        tlls81: "Orientamento alla garanzia della qualità",
        tlls82: "Impegno a mantenere un elevato standard di servizio, a soddisfare le esigenze degli utenti e a implementare misure di garanzia della qualità per un'esperienza positiva degli utenti",
        tlls91: "Collaborazione e coordinamento",
        tlls92: "Spiccate doti di collaborazione, fungendo da punto centrale per la comunicazione e facilitando un coordinamento efficace tra operatori, direzione e altri reparti",

        tlreint1: "Quali sono i requisiti per diventare",
        tlreint2: "di Creativ'Network?",
        tlre12: "Un team leader di Creativ'Network deve conoscere le principali lingue operative, avere una competenza multilingue, un'articolazione chiara, una comprensione delle sfumature regionali, una forte comunicazione scritta e una competenza nelle interazioni con gli utenti e nella collaborazione con gli altri dipartimenti.",
        tlre22: "Fissare un limite di età di oltre 25 anni per un Team Leader di Creativ'Network può garantire un equilibrio di esperienza e maturità. Questa politica promuove diverse qualità di leadership, mantenendo un team dinamico ed efficiente",
        tlre42: "I team leader di Creativ'Network devono riconoscere e accettare l'Informativa sulla privacy e le Condizioni contrattuali, garantendo il rispetto della protezione dei dati e degli obblighi contrattuali, favorendo un rapporto di lavoro sicuro e trasparente.",
        tlre61: "Rapporti",
        tlre62: "I team leader di Creativ'Network compilano rapporti dettagliati che riassumono le prestazioni del team, le metriche di coinvolgimento degli utenti e i problemi affrontati. Questi rapporti guidano le decisioni strategiche, identificano le aree di miglioramento e garantiscono la fornitura di servizi ottimali",
        tlre72: "I team leader di Creativ'Network si impegnano per un minimo di 40 ore settimanali, con disponibilità occasionale nei fine settimana e nei giorni festivi. Questo garantisce un supporto continuo al team e il mantenimento di standard di servizio ottimali",
        tlre81: "Tassa",
        tlre82: "In quanto lavoratore indipendente, il Team Leader di Creativ'Network è responsabile della gestione e del pagamento delle proprie tasse. Ciò include il rispetto delle normative fiscali e l'adempimento degli obblighi finanziari nelle rispettive giurisdizioni",
        tlre92: "I Team Leader di Creativ'Network assicurano una stretta aderenza alle linee guida legali e normative, promuovendo un ambiente conforme. Ciò include il rispetto della privacy degli utenti, della protezione dei dati e di altre norme specifiche del settore per mantenere l'integrità della piattaforma.",
        tlic1: "Verifica dell'età: Creativ'Network verifica l'età dei candidati Team Leader (25+) per garantire che i candidati possiedano l'esperienza, la maturità e la stabilità essenziali per una leadership efficace. Questo approccio è in linea con l'obiettivo dell'azienda di avere leader esperti con una comprovata esperienza, che contribuiscano alla gestione di un team di successo e favoriscano un ambiente di lavoro positivo.",
        tlic2: "Sicurezza e fiducia: La verifica dell'età per i candidati Team Leader di Creativ Network di età superiore ai 25 anni viene attuata per migliorare la sicurezza e la fiducia. Garantendo il rispetto di questo criterio, la piattaforma mira a creare un team di leadership maturo ed esperto, favorendo un ambiente sicuro e affidabile sia per gli utenti che per i membri del team",
        tlas1: "Iniziate visitando il sito web ufficiale di Creativ'Network o il portale delle carriere. | Trovate l'offerta di lavoro per la posizione di Team Leader e fate clic su \"Candidati\".|Compilate il modulo di candidatura, che comprende i vostri dati personali, i recapiti, le esperienze rilevanti e la motivazione a lavorare con noi.|Vi verrà chiesto di completare un breve test delle competenze, che in genere dura circa 10 minuti. Questo test valuta le vostre capacità di leadership, comunicazione, gestione delle prestazioni, innovazione, sviluppo del team, garanzia di qualità, collaborazione e adattabilità.",
        tlas2: "Dopo aver completato con successo la domanda iniziale e il test di abilità, vi sarà richiesto di sottoporvi a un processo di verifica dell'identità per confermare la vostra età.|I candidati dovranno presentare una copia di un documento d'identità valido rilasciato dal governo, come un passaporto, una patente di guida o una carta d'identità governativa. Questo documento serve a verificare che il candidato soddisfi i requisiti minimi di età previsti per la posizione.",
        tlas3: "Una volta approvata la candidatura e la verifica dell'età, verrà programmata una sessione di formazione online via Skype.|Durante la formazione, i manager di Creativ'Network forniranno indicazioni e istruzioni su come svolgere efficacemente il proprio ruolo di team leader. Verranno illustrate le politiche aziendali, le migliori pratiche e le strategie di leadership e comunicazione per eccellere nella vostra posizione.",
        appformfor: "Modulo di candidatura per",
        tofilltheapplicationform: "Per compilare il modulo di candidatura, effettuare l'accesso o registrarsi sul nostro sito web",
        supervisor: "Supervisore",
        qualitysupervisor: "Supervisore della qualità",
        sintint1: "Vuoi lavorare a casa come",
        sintint2: "di operatori di chat di incontri?",
        sint1: "1. Leadership della qualità: Garantire l'eccellenza dei servizi di chat, dando forma a esperienze positive per gli utenti e promuovendo interazioni di qualità, il tutto comodamente da casa",
        sint2: "2. Compenso competitivo: Riceverete uno stipendio generoso, incentivi vantaggiosi e la flessibilità del lavoro a distanza, che vi ricompenserà finanziariamente per il mantenimento della qualità",
        sint3: "3. Sviluppo del team: Mentore e guida degli operatori, favorendo la qualità del servizio, la crescita personale e lo sviluppo professionale in un ambiente di lavoro virtuale.",
        sint4: "4. Contributi innovativi: Introdurre strategie creative, migliorare i servizi di chat e contribuire all'innovazione del settore, garantendo un'esperienza utente di qualità su Creativ'Network",
        sint5: "5. Riconoscimento e premi: Guidare un team di alta qualità, superare le metriche e ottenere un riconoscimento con uno stipendio competitivo, assicurando una carriera a distanza soddisfacente e gratificante",
        sint6: "6. Equilibrio vita-lavoro: Godetevi la flessibilità di lavorare da casa, raggiungendo un equilibrio armonioso tra lavoro e vita privata e garantendo e migliorando gli standard di qualità di Creativ'Network",
        sabint1: "In cosa consiste il lavoro di",
        sabint2: "degli operatori di chat di incontri?",
        sab1: "1. Monitoraggio delle prestazioni: I supervisori della qualità controllano da vicino gli operatori, assicurando il rispetto dei parametri di prestazione, i tempi di risposta e la soddisfazione degli utenti, contribuendo a un'esperienza positiva per gli utenti",
        sab2: "2. Feedback e formazione: Forniscono feedback costruttivi, conducono sessioni di formazione e guidano gli operatori a migliorare continuamente le loro capacità di comunicazione, assicurando interazioni di qualità",
        sab3: "3. Risoluzione dei problemi: Rispondere tempestivamente alle preoccupazioni degli utenti, risolvendo i problemi e mantenendo un elevato standard di servizio per garantire un'esperienza positiva agli utenti.",
        sab4: "4. Protocolli di garanzia della qualità: Implementare e applicare i protocolli di garanzia della qualità, assicurando il rispetto costante degli standard stabiliti per i servizi di chat su Creativ'Network",
        sab5: "5. Innovazione e ottimizzazione: Introdurre strategie innovative per ottimizzare i servizi di chat, rimanendo al passo con le tendenze del settore e migliorando la qualità complessiva delle interazioni con gli utenti.",
        sab6: "6. Collaborazione di squadra: Promuovere un ambiente di squadra collaborativo, incoraggiando la comunicazione e la collaborazione tra gli operatori, assicurando un approccio coeso alla fornitura di servizi di chat di incontri di alta qualità",
        sskint1: "Quali sono le competenze per essere un",
        sskint2: "di operatori di chat di incontri?",
        leadership: "Leadership",
        communication: "Comunicazione",
        analyticalskills: "Capacità analitiche",
        pbsolving: "Risoluzione dei problemi",
        qualityassurance: "Garanzia di qualità",
        traininganddev: "Formazione e sviluppo",
        innovation: "Innovazione",
        collab: "Collaborazione",
        adaptability: "Adattabilità",
        ssk1: "Eccezionali capacità di leadership per guidare e ispirare un team di operatori di chat, favorendo un ambiente di lavoro positivo e produttivo",
        ssk2: "Spiccate capacità di comunicazione, sia verbale che scritta, per fornire istruzioni chiare, feedback e formazione agli operatori, garantendo interazioni efficaci con gli utenti",
        ssk3: "Capacità di analizzare le metriche delle prestazioni, identificare le aree di miglioramento e implementare strategie per migliorare la qualità complessiva del servizio di chat.",
        ssk4: "Abilità nel risolvere i problemi degli utenti e nell'affrontare tempestivamente le sfide operative, mantenendo un elevato standard di servizio e di soddisfazione degli utenti.",
        ssk5: "Garanzia di qualità: Comprensione completa dei protocolli di garanzia della qualità, per garantire un'aderenza costante agli standard stabiliti e ottimizzare i servizi di chat",
        ssk6: "Formazione e sviluppo: Capacità di progettare e condurre sessioni di formazione, sviluppando le competenze degli operatori e promuovendo il miglioramento continuo della comunicazione e della qualità del servizio",
        ssk7: "Innovazione: Capacità comprovata di introdurre strategie innovative, di stare al passo con le tendenze del settore e di ottimizzare i servizi di chat per un ambiente dinamico di incontri online",
        ssk8: "Collaborazione: Capacità di collaborazione efficace per promuovere un ambiente di squadra coeso, incoraggiando la comunicazione e la collaborazione tra gli operatori per la fornitura di servizi senza soluzione di continuità",
        ssk9: "Adattabilità: Capacità di adattarsi alle mutevoli dinamiche del settore, alle preferenze degli utenti e alle tendenze emergenti, garantendo che i servizi di chat di Creativ'Network rimangano all'avanguardia nell'esperienza degli incontri online",
        langproficiency: "Conoscenza dell'italiano",
        agelimit: "Limite di età",
        bankaccountpaymentaccount: "Conto bancario o conto di pagamento",
        acceptanceppterms: "Accettazione dell'Informativa sulla privacy e delle Condizioni contrattuali",
        equipmentresponsibility: "Responsabilità dell'apparecchiatura",
        reports: "Rapporti",
        minweekly: "Ore settimanali minime",
        tax: "Imposta",
        adhlegalregguidelines: "Adesione alle linee guida legali e normative",
        sre1: "Un supervisore della qualità della rete Creativ's richiede una conoscenza fluente delle principali lingue operative, competenza multilingue, articolazione chiara, comprensione delle sfumature regionali, forte comunicazione scritta e competenza nelle interazioni con gli utenti e nella collaborazione con altri dipartimenti.",
        sre2: "Stabilire un limite di età di oltre 25 anni per un supervisore della qualità di Creativ'Network può garantire un equilibrio di esperienza e maturità. Questa politica promuove diverse qualità di leadership, mantenendo un team dinamico ed efficiente",
        sre3: "Dovresti avere un conto bancario, PayPal, Wise o Yoursafe a tuo nome. Questo probabilmente per l'elaborazione dei pagamenti",
        sre4: "Il supervisore della qualità della rete di Creativ deve riconoscere e accettare l'Informativa sulla privacy e i Termini del contratto, garantendo il rispetto della protezione dei dati e degli obblighi contrattuali, favorendo un rapporto di lavoro sicuro e trasparente.",
        sre5: "Il candidato deve disporre dell'attrezzatura necessaria, tra cui un computer portatile o un PC, una connessione Internet affidabile e una fonte di backup di elettricità e Internet per garantire un lavoro ininterrotto.",
        sre6: "Il supervisore della qualità della rete di Creativ redige rapporti dettagliati che riassumono le prestazioni del team, le metriche di coinvolgimento degli utenti e i problemi affrontati. Questi rapporti guidano le decisioni strategiche, identificano le aree di miglioramento e garantiscono la fornitura di servizi ottimali",
        sre7: "Il supervisore della qualità di Creativ'Network si impegna per un minimo di 40 ore settimanali, con disponibilità occasionale nei fine settimana e nei giorni festivi. Questo garantisce un supporto continuo al team e il mantenimento di standard di servizio ottimali",
        sre8: "In quanto lavoratore indipendente, il supervisore della qualità di Creativ'Network è responsabile della gestione e del pagamento delle proprie tasse. Ciò include la conformità alle normative fiscali e l'adempimento degli obblighi finanziari nelle rispettive giurisdizioni",
        sre9: "Il supervisore della qualità della rete di Creativ assicura una stretta aderenza alle linee guida legali e normative, promuovendo un ambiente conforme. Ciò include il rispetto della privacy degli utenti, della protezione dei dati e di altre norme specifiche del settore per mantenere l'integrità della piattaforma.",
        sic1: "1. Verifica dell'età: Creativ'Network verifica l'età dei candidati Quality Supervisor (25+) per garantire che i candidati possiedano l'esperienza, la maturità e la stabilità essenziali per una leadership efficace. Questo approccio è in linea con l'obiettivo dell'azienda di avere leader esperti con una comprovata esperienza, che contribuiscano al successo della gestione del team e favoriscano un ambiente di lavoro positivo.",
        sic2: "2. Sicurezza e fiducia: La verifica dell'età per i candidati al ruolo di supervisore della qualità di Creativ di età superiore ai 25 anni viene effettuata per aumentare la sicurezza e la fiducia. Garantendo la conformità a questo criterio, la piattaforma mira a creare un team di leadership maturo ed esperto, favorendo un ambiente sicuro e affidabile sia per gli utenti che per i membri del team. ",
        sic3: "3. Documenti per la verifica: Per rispettare le norme di legge e garantire che i candidati siano maggiorenni, Creativ'Network richiederà una verifica dell'età. Documenti come patenti di guida e documenti d'identità governativi, passaporti includono tipicamente informazioni sulla data di nascita, il che li rende adatti a confermare il possesso dei requisiti minimi di età stabiliti da Creativ'Network.",   
        
        
        sas3: "Una volta approvata la domanda e la verifica dell'età, verrà programmata una sessione di formazione online via Skype.|Durante la formazione, i team leader di Creativ'Network forniranno indicazioni e istruzioni su come svolgere efficacemente il proprio ruolo di supervisore della qualità. Verranno illustrate le politiche aziendali, le migliori pratiche e le strategie di leadership e comunicazione per eccellere nella vostra posizione.",
        tltext: "Guidare, ispirare e guidare gli operatori di chat, favorendo connessioni ed esperienze eccezionali nel regno degli incontri online di Creativ'Network.",
        supervisortext: "Supervisionare la qualità dei servizi di chat per incontri di Creativ'Network. Guida gli operatori verso l'eccellenza, assicurando esperienze positive agli utenti nel mondo degli incontri online.",

        webdevtext: "Unisciti a Creativ'Network come sviluppatore web, creando soluzioni digitali innovative. Contribuisci al nostro team dinamico, dando forma al futuro delle connessioni online",
        digmartext: "Eleva la presenza online di Creativ'Network come Digital Marketer. Guidate il coinvolgimento, date forma alle strategie e contribuite alle iniziative di marketing dinamico. Unisciti a noi!",
        webdestext: "Dai forma alle esperienze digitali in Creativ'Network come Web Designer. Dai vita alla creatività, realizzando siti web di grande impatto visivo per migliorare le connessioni online",

        qstesttitle: "Modulo per il test del supervisore della qualità",
        suptitle: "Test per diventare Supervisore della qualità presso Creativ'Network",
        suptest: "Test del supervisore della qualità",
        tltest: "Test del leader di squadra",
        tltestform: 'Modulo di prova per caposquadra',
        tltesttitle: "Test per diventare Team Leader presso Creativ'Network",
        tltestint1: "Il test Team Leader di Creativ'Network valuta la vostra capacità di leadership, comunicazione, gestione delle prestazioni, innovazione, sviluppo del team, garanzia di qualità, collaborazione e adattabilità.",
        suptestint1: "Il test Quality Supervisor di Creativ'Network valuta le vostre capacità di leadership, comunicazione, risoluzione dei problemi, assicurazione della qualità, formazione, innovazione, collaborazione e adattabilità.",
        thanktest1: "Grazie per aver partecipato al test",
        finish: "Finitura",
        all: "Tutti",
        loginfailed: "Accesso non riuscito",
        
        usrnmpttrerr: "Il nome utente non deve contenere caratteri speciali",
        pobxpttrerr: "La casella postale non deve contenere caratteri speciali",
        phnpttrerr: "Il numero di telefono deve essere in formato internazionale. Esempio: +123456789012",
        skpidpttrerr: "Identificatore skype non valido",
        brthdtptrerr: "Formato della data di nascita non valido",
        usrnmlngtherr: `Il nome utente deve contenere almeno ${usernameminlength} caratteri.`,
        smthngwntwrng: "Qualcosa è andato storto. Riprovare",
        failed: "Fallimento",
        subsuccess: "Sottoscritto con successo",
        subfailed: "Già iscritto",
        mlunqerr: "Questo indirizzo e-mail è già in uso",
        usrnmunqerr: "Questo nome utente è già in uso",     
        iderr: "E-mail/nome utente non valido",
        mdperr: "Password non valida",
        svsucc: "Informazioni aggiornate con successo",
        
        mdpnid: "La nuova password e la password di conferma non sono identiche",
        curmdperr: "La password corrente non è corretta",
        entryrml: "Inserisci il tuo indirizzo e-mail",
        entrynwpss: "Inserire la nuova password",
        rcpcpt: "Recupera il tuo account",

        chckrsm: "Vedi il tuo curriculum attuale",
        nyrsm: "Non è stato ancora fornito alcun curriculum",
        chckpi: "Vedi il tuo documento d'identità attuale",
        chckpa: "Vedi la tua prova di indirizzo attuale",
        chcksl: "Vedi il tuo selfie attuale",
        chckc: "Vedi il tuo contratto attuale firmato",
        nypi: "Non è stata ancora fornita alcuna prova di identità",
        nypa: "Non è ancora stata fornita una prova dell'indirizzo",
        nysl: "Non è ancora stato fornito un selfie",
        nyc: "Non è ancora stato fornito un contratto firmato",
        rsm: "CV",
        pi: "Prova di identità",
        pa: "Prova di indirizzo",
        slfi: "Selfie",
        opnngclsngdterr: "La data di apertura deve essere precedente alla data di chiusura.",
        adddsccsflly: "Aggiunto con successo",
        dltd: "Soppresso",
        rstr: "Ripristino",
        see: "Vedi", 

        succ: "Blog creato con successo",
        cat: "Categoria non valida",
        accref: "Accesso negato",
        lang: "Lingua non trovata",

        catsuccess: "Categoria creata con successo",
        catexst: "Categoria già esistente",
        crtadminfrmmbr: "Crea da membro esistente",
        success: "Il successo",
        tktst: "Fare il test",
        expteam: "Ha esperienza nella conduzione di un team?",
        expsup: "Ha esperienza nella supervisione di operatori di chat?",
        avlbl: "Disponibile",
        fllppfrm: "Compilare il modulo di domanda",
        lljbs: "Tutti i lavori",
        pnjbs: "Assunzioni",
        dfndt: "Data determinata",
        indfndt: "Data indeterminata",

        nonaut: "Solo l'autore del blog può cancellare e ripristinare questo blog",
        adminsuccess: "Questa persona è ora un amministratore",
        rolenonaut: "Ruolo non autorizzato",
        djadmin: "Questa persona è già un amministratore",
        usrntfnd: "Nessun utente trovato",
        newbloggersucc: "Questa persona è ora un blogger",
        djblog: "Questa persona è già un blogger",
        nw: "Nuovo",
        bfrsbmttng: "Prima di presentare",
        upldrsmpdf: "Carica il tuo curriculum",
        umstupldyrcv: "È necessario caricare il proprio cv",   
    
        gndr: "Género",
        citydep: "Città / Dipartimento",
        pobox: "CASELLA POSTALE",

        crtd: "Creato",
        dcnnctd: "Disconnesso",
        sbntfnd: "Abbonato non trovato",    
        sspndd: "Sospeso",
        susp: "Il vostro conto è attualmente sospeso e sarà riattivato il ", 
        reak: "",
        svd: "Salvati",
tstalrddn: "Ti sei già candidato per questa posizione",
        gvlgn: "attributo un login",
        frmtdcnntrv: "Documento di formazione non trovato",
        sndmaildocincomp: "Invia un'e-mail sui documenti incompleti",
        vldtinteg: "Aggiungi all'elenco di formazione",
        nshdtrnng: "Formazione terminata",
        cnfgtrnng: "Configura l'allenamento",
        sndml: "Inviare e-mail",
        mrksfnshd: "Contrassegnare come finito",

        bn: "E-mail vietata",
        cypm: "Scegliere il metodo di pagamento",
        aas3: "Elenco dei capisquadra",
tllgn: "Login del caposquadra",
aas4: "Aggiungi caposquadra",
createnewtl: "Crea un nuovo caposquadra",
strthr: "Ora di inizio",
        dnhr: "Ora di fine",
        chngpay: "Se desidera modificare i suoi dati di pagamento, contatti il suo Team Leader o il suo Manager",
        idm: "ID gestore",
        mdboard: "Cruscotto manager",
        manager: "Manager",
        mrk: "Segna come letto",
agntslst: "Elenco degli agenti",
aas5: "Elenco dei manager",
aas6: "Aggiungi gestore",
createnewmn: "Crea nuovo manager",
pdf: "Si accetta solo il formato PDF",
        agntnm: "Nome agente",
        agntpass: "Password agente",
        asn2: "Aggiungi rete sociale",


    },
        
    { // nl
        cn: "Creativ'Network",
        french: 'Frans',
        english: 'Engels',
        italian: 'Italiaans',
        dutch: 'Nederlands',
        spanish: 'Spaans',
        german: 'Duitse',
        usermanual: "Gebruikershandleiding",
        welcome: 'Welkom bij Creativ Network',
        jobdesc: 'Functieomschrijving',
        jobav: 'Voordelen van de functie',
        formulaire: 'Aanvraagformulier',

        firstname: 'Voornaam',
        lastname: 'Achternaam',
        email: 'E-mailadres',
        birthday: 'Geboortedatum',
        phone: 'Telefoon',
        skypename: 'Skype naam',
        whichchatlanguage: 'Voor welke chat-taal solliciteert u?',
        timesavailable: 'Wanneer bent u telefonisch bereikbaar?',
        expchatmod: 'Heb je ervaring met het modereren van chats?',
        hearabout: 'Hoe heeft u ons gevonden?',
        tellus: 'Vertel ons iets over uzelf en waarom we u zouden moeten aannemen!',
        submitapp: 'Stuur in',
        yes: 'Ja',
        no: 'Nee',
        friends: 'Vrienden',
        from: 'Van',
        to: 'tot',

        required: 'Dit veld is verplicht',
        invalidemail: 'Ongeldige e-mail',

        loginfailusername: 'Gebruikersnaam niet herkend',
        loginfailpass: 'Ongeldig wachtwoord',
        login: 'Aansluiting',
        username: 'Gebruikersnaam',
        password: 'Wachtwoord',
        test: 'Nederlands Chat Moderator Testformulier',
        situation: 'Situatie',
        send: 'Stuur',
        qreturn: 'Wil je echt stoppen met de test?',

        cointint: "Wil thuis werken als dating chat operator met",
        coint1: "Mooie uitbetaling: Verdien van € 0,12 tot € 0,15 per verzonden bericht met Creativ'Network",
        coint2: "Werk op afstand: Geniet van de flexibiliteit van thuiswerken.",
        coint3: "Communicatievaardigheden: Verbeter je kwaliteit in empathie, actief begrip en vloeiendheid in communicatie",
        coint4: "Balans werk-privé: Beheer uw tijd beter met flexibele uren",
        applynow: "Solliciteer nu",

        coabint1: "Wat is een afspraakje?",
        coabint2: "baan over?",
        chatoperator: "chatoperator",
        coab1: "In contact komen met eenzame mensen: Veel gebruikers op datingplatforms zoeken gezelschap of iemand om mee te praten vanwege gevoelens van eenzaamheid. Chatoperators bieden een luisterend oor en emotionele steun.", 
        coab2: "Tekstgebaseerde communicatie: De belangrijkste manier van communicatie is via geschreven tekstberichten op het platform. Exploitanten voeren voortdurend gesprekken met gebruikers",
        coab3:"Gevarieerde gespreksonderwerpen: Chatoperators betrekken gebruikers in discussies over een breed scala aan onderwerpen, van alledaagse onderwerpen zoals sport en het weer tot meer persoonlijke en intieme onderwerpen, zoals dromen, fantasieën en andere interessegebieden.",
        coab4:"Open benadering: Deze baan vereist vaak een ruimdenkende en niet-oordelende houding, omdat gebruikers verschillende interesses, ervaringen en wensen kunnen hebben.",
        coab5: "Gezelschap bieden: Exploitanten spelen een rol in het bieden van een gevoel van gezelschap en verbondenheid aan gebruikers die op zoek zijn naar emotionele steun of gewoon iemand willen om mee te praten",
        coab6:"Anoniem: Chatoperators werken 100% anoniem. Onze identiteit zal nooit worden onthuld aan iemand in een dating websites",
        coab7:"Onafhankelijkheid en flexibiliteit: Chatoperators werken wanneer het jou uitkomt",

        home:"Home",
        ourmissions: "Onze missies",
        company: "Waarden",
        ourcompany: "Onze waarden",
        ouradvantages: "Onze voordelen",
        jobs: "banen",

        slog: '"Waar creativiteit en kansen elkaar ontmoeten"',
        copyright: "©2023-2024 copyright Creativ’Network. Alle rechten voorbehouden.",
        foexpl: "Creativ'Network, het innovatieve platform dat webtalent verbindt met toekomstgerichte functies die de digitale wereld vormgeven. Ontdek je potentieel, bloei op in webmoderatie, ontwikkeling, marketing, big data en meer.",
        focontact1: "Hoofdadres : 71-75 Shelton Street, Covent Garden, Londen, WC2H 9JQ",
        focontact2: "Belgium office representative: Roodborstjesstraat 4, Wondelgem 9032",
        focontact3: "contact@creativ-network.net",
        subscribe: "Aanmelden",
        agree1: "Ja, ik ga akkoord met het",
        agree2: "en de ",
        agreelink1: "privacybeleid",
        agreelink2: "algemene voorwaarden",
        useful: "Handige",
        links: "koppelingen",
        oursing: "ons",
        contact: "contact",
        subscribetoour: "Abonneer u op onze ",
        newsletter: "nieuwsbrief",

        consents: "Toestemming",
        termsAgree1: "Ja, ik ga akkoord met het",
        termsAgree2: "en de ",
        termsAgreeLink1: "privacybeleid",
        termsAgreeLink2: "algemene voorwaarden",

        termsAgreeLink2real: "gebruiksvoorwaarden",
        consentsErr: "U moet akkoord gaan met het privacybeleid en de algemene voorwaarden om u te abonneren",
        
        coscint1: "Hoe wordt het salaris van het ",
        coscint2: "berekend?",
        cosc1: "Basisloon per bericht: De operator krijgt meestal een vooraf bepaald bedrag voor elk verzonden bericht. De basisvergoeding per verzonden bericht is 0,12€.",
        cosc2: "Maandelijkse berichtentelling: Aan het einde van de maand wordt het totale aantal berichten berekend dat door de operator is verzonden.",        
        cosc3: "Berekening uitbetaling: De verdiensten van de operator voor de maand worden berekend door het totale aantal berichten te vermenigvuldigen met de basisbetaling per bericht. Als je bijvoorbeeld 5.000 berichten verstuurt, verdien je 600€ (5.000 berichten * 0,12€ per bericht). Met Creativ'Network kan een operator gemakkelijk 1.200€ per maand verdienen met 10K verzonden berichten.",
        cosc4: "Prestatiebonus: In sommige gevallen ontvangen operators met uitzonderlijke prestaties een bonus bovenop hun commissie. De bonus kan gebaseerd zijn op criteria zoals het aantal tevreden klanten, de responstijd of positieve feedback van gebruikers.",
        cosc5: "Kerstbonus: Als extra stimulans biedt Creativ'Network een kerstbonus aan onze operators die langer dan 2 jaar bij het bedrijf werken.",

        corint1: "Wat zijn de vereisten om chat operator te worden bij",
        corint2: "?",
        cor11: "Vaardigheid in het Nederlands",
        cor12: "Beheersing van de Nederlandse taal is een vereiste, omdat van chatoperators wordt verwacht dat ze effectief in het Nederlands met gebruikers communiceren.",
        cor21: "Leeftijdsgrens",
        cor22: "Sollicitanten moeten ten minste 18 jaar oud zijn om in aanmerking te komen voor deze functie.",
        cor31: "Bankrekening of betaalrekening",
        cor32: "Je moet een bankrekening, PayPal, Wise of Yoursafe account op je naam hebben. Dit is waarschijnlijk voor het verwerken van betalingen.",
        cor41: "Aanvaarding van privacyverklaring en contractvoorwaarden",
        cor42: "Kandidaten moeten bereid zijn om de privacyverklaring en contractvoorwaarden van Creativ'Network te accepteren en na te leven, waarin vaak de verantwoordelijkheden, richtlijnen en gedragscode voor chatoperators worden beschreven.",
        cor51: "Verantwoordelijkheid apparatuur",
        cor52: "Er wordt van je verwacht dat je beschikt over de benodigde apparatuur, waaronder een laptop of pc, een betrouwbare internetverbinding en een back-upbron voor elektriciteit en internet om ononderbroken werk te garanderen.",
        cor61: "Minimale berichtvereisten",
        cor62: "Chatoperators zijn meestal verplicht om een minimum aantal berichten per week te versturen. In dit geval wordt een minimum van 250 uitgaande berichten per week verwacht.",
        cor71: "Minimum aantal uren per week",
        cor72: "Zelfs als je zelfstandig werkt, moet je minstens 4 uur per dag werken en 20 uur per week wanneer het jou uitkomt.",
        cor81: "Belasting",
        cor82: "Als zelfstandige ben je verantwoordelijk voor je eigen belastingen.",
        cor91: "Naleving van wettelijke en reglementaire richtlijnen:",
        cor92: "Elke chatoperator moet de regels volgen die de teamleider tijdens de training heeft gegeven.",

        coicint1: "Wat heeft",
        coicint2: "nodig om jouw identiteit te controleren?",
        coic1: "Leeftijdscontrole: Om verschillende redenen hebben datingwebsites en -platforms vaak leeftijdsbeperkingen om te voldoen aan wettelijke voorschriften. Door je identiteit te controleren, kan Creativ'Network verifiëren dat je voldoet aan de minimumleeftijd van 18 jaar om gebruik te maken van onze diensten. Dit helpt het platform zich te houden aan de wettelijke normen, minderjarigen te beschermen en een verantwoordelijke en veilige gemeenschap te onderhouden.",        
        coic2: "Veiligheid en vertrouwen: Identiteitsverificatie vergroot de veiligheid en betrouwbaarheid van het platform. Het verzekert gebruikers ervan dat Creativ'Network communiceert met individuen die zijn wie ze beweren te zijn, wat essentieel is voor het opbouwen van vertrouwen en het garanderen van een veilige omgeving voor deze functie.",
        coic3: "Documenten voor verificatie: Om te voldoen aan wettelijke voorschriften en ervoor te zorgen dat kandidaten de juiste leeftijd hebben, vraagt Creativ'Network om leeftijdsverificatie. Documenten zoals rijbewijzen en identiteitsbewijzen van de overheid bevatten meestal informatie over de geboortedatum, waardoor ze geschikt zijn om te bevestigen dat je voldoet aan de minimumleeftijd die is ingesteld door het platform.",

        coasint1: "3 eenvoudige stappen om te solliciteren naar de functie bij ",
        coasint2: "",
        coas11: "Eenvoudige sollicitatie en vaardigheidstest",
        coas12: ["Begin met een bezoek aan de officiële website of het carrièreportaal van Creativ'Network.", 'Zoek de vacature voor de functie van datingchatoperator en klik op "Solliciteren".', "Vul het sollicitatieformulier in met je persoonlijke gegevens, contactgegevens, relevante ervaring en je motivatie om bij ons te komen werken.", "Je wordt gevraagd een korte vaardigheidstest te maken, die meestal ongeveer 10 minuten duurt. Deze test beoordeelt je vermogen om chatgesprekken te voeren en je communicatievaardigheden te demonstreren."],
        coas21: "ID-verificatie voor leeftijdsbevestiging",
        coas22: ["Nadat u de eerste sollicitatie en vaardigheidstest met succes hebt afgerond, moet u een ID-verificatie ondergaan om uw leeftijd te bevestigen.", "U wordt gevraagd een kopie van een geldig door de overheid uitgegeven identiteitsbewijs te overleggen, zoals een paspoort, rijbewijs of identiteitskaart van de overheid. Dit document wordt gebruikt om te controleren of u voldoet aan de minimumleeftijd voor de functie."],
        coas31: "Online training via Skype",
        coas32: ["Zodra je sollicitatie en leeftijdscontrole zijn goedgekeurd, word je ingepland voor een online trainingssessie via Skype.", "Tijdens de training geven de teamleiders van Creativ'Network begeleiding en instructies over hoe je je rol als datingchatoperator effectief kunt uitvoeren. Ze behandelen het bedrijfsbeleid, best practices en communicatiestrategieën om uit te blinken in je functie."],

        coafint: "Aanvraagformulier voor",

        ppint: ["Bij Creativ-Network, bereikbaar via https://creativ-network.net, is de privacy van onze bezoekers een van onze belangrijkste prioriteiten. Dit privacybeleidsdocument bevat soorten informatie die worden verzameld en vastgelegd door Creativ-Network en hoe we deze gebruiken.", "Als u aanvullende vragen heeft of meer informatie wenst over ons privacybeleid, aarzel dan niet om contact met ons op te nemen via e-mail op data@creativ-network.net", "Dit privacybeleid is alleen van toepassing op onze online activiteiten en geldt voor bezoekers van onze website met betrekking tot de informatie die zij delen en/of verzamelen in Creativ-Network. Dit beleid is niet van toepassing op informatie die offline of via andere kanalen dan deze website wordt verzameld."],
        pp1t: ["1 - Toestemming", ["Door onze website te gebruiken, stemt u in met ons privacybeleid en gaat u akkoord met de voorwaarden."]],
        pp2t: ["2 - Informatie die we verzamelen", ["De persoonlijke informatie die u wordt gevraagd te verstrekken en de redenen waarom u wordt gevraagd deze te verstrekken, zullen u duidelijk worden gemaakt op het moment dat we u vragen uw persoonlijke informatie te verstrekken. ", "Als u rechtstreeks contact met ons opneemt, kunnen we aanvullende informatie over u ontvangen, zoals uw naam, e-mailadres, telefoonnummer, de inhoud van het bericht en/of de bijlagen die u ons stuurt, en alle andere informatie die u wilt verstrekken.", "Wanneer u zich registreert voor een account, kunnen we vragen om uw contactgegevens, inclusief items zoals naam, bedrijfsnaam, adres, e-mailadres en telefoonnummer."]],
        pp3t: ["3 - Hoe we uw informatie gebruiken", ["We gebruiken de informatie die we verzamelen op verschillende manieren, onder andere om: ", "• Onze website aan te bieden, te beheren en te onderhouden", "• Onze website te verbeteren, te personaliseren en uit te breiden", "• Te begrijpen en te analyseren hoe u onze website gebruikt", "• Nieuwe producten, diensten, functies en functionaliteiten te ontwikkelen", "• Met u te communiceren, rechtstreeks of via een van onze partners, onder andere voor klantenservice, om u updates en andere informatie met betrekking tot de website te verstrekken en voor marketing- en promotiedoeleinden", "• E-mails sturen", "• Fraude opsporen en voorkomen"]],
        pp4t: ["4 - Logbestanden", ["Creativ-Network maakt standaard gebruik van logbestanden. Deze bestanden registreren bezoekers wanneer zij websites bezoeken. Alle hostingbedrijven doen dit en maken deel uit van de analytics van hostingdiensten. De informatie die door logbestanden wordt verzameld, omvat internet protocol (IP)-adressen, browsertype, Internet Service Provider (ISP), datum- en tijdstempel, verwijzende/uitgaande pagina's en mogelijk het aantal kliks. Deze zijn niet gekoppeld aan informatie die persoonlijk identificeerbaar is. Het doel van de informatie is het analyseren van trends, het beheren van de site, het volgen van de bewegingen van gebruikers op de website en het verzamelen van demografische informatie."]],
        pp5t: ["5 - Cookies en webbakens", ["Net als elke andere website maakt Creativ-Network gebruik van 'cookies'. Deze cookies worden gebruikt om informatie op te slaan, waaronder bezoekersvoorkeuren en de pagina's op de website die de bezoeker heeft bezocht. De informatie wordt gebruikt om de gebruikerservaring te optimaliseren door de inhoud van onze webpagina's aan te passen op basis van het browsertype en/of andere informatie van bezoekers."]],
        pp6t: ["6 - Advertentiepartners Privacybeleid", ["U kunt deze lijst raadplegen om het Privacybeleid te vinden voor elk van de advertentiepartners van Creativ-Network.","Advertentieservers van derden of advertentienetwerken maken gebruik van technologieën zoals cookies, JavaScript of Web Beacons die worden gebruikt in hun respectieve advertenties en links die worden weergegeven op Creativ-Network, die rechtstreeks naar de browser van de gebruiker worden verzonden. Zij ontvangen automatisch uw IP-adres wanneer dit gebeurt. Deze technologieën worden gebruikt om de effectiviteit van hun reclamecampagnes te meten en/of om de reclame-inhoud die u ziet op websites die u bezoekt te personaliseren.", "Merk op dat Creativ-Network geen toegang heeft tot of controle heeft over deze cookies die worden gebruikt door externe adverteerders."]],
        pp7t: ["7 - Privacybeleid van derden", ["Creativ-Network's Privacybeleid is niet van toepassing op andere adverteerders of websites. We raden u daarom aan het respectieve Privacybeleid van deze externe advertentieservers te raadplegen voor meer gedetailleerde informatie. Het kan hun praktijken en instructies over hoe de opt-out van bepaalde opties. Een volledige lijst van deze privacybeleidsregels en hun links vindt u hier: Privacy Policy Links.", "U kunt ervoor kiezen om cookies uit te schakelen via uw individuele browseropties. Meer gedetailleerde informatie over cookiebeheer met specifieke webbrowsers vindt u op de respectievelijke websites van de browsers. Wat zijn cookies?"]],
        pp8t: ["8 - Privacybeleid CCPA (Mijn persoonlijke gegevens niet verkopen)", ["Onder de CCPA hebben consumenten in Californië, naast andere rechten, het recht om:", "Te verzoeken dat een bedrijf dat persoonlijke gegevens van een consument verzamelt, de categorieën en specifieke stukken persoonlijke gegevens bekendmaakt die een bedrijf over consumenten heeft verzameld. ", "Verzoeken dat een bedrijf alle persoonlijke gegevens verwijdert die een bedrijf over de consument heeft verzameld.", "Verzoeken dat een bedrijf dat persoonlijke gegevens van een consument verkoopt, de persoonlijke gegevens van de consument niet verkoopt.", "Als u een verzoek indient, hebben we een maand de tijd om op uw verzoek te reageren. Als u een van deze rechten wilt uitoefenen, neem dan contact met ons op."]],
        pp9t: ["9 - Privacybeleid GDPR (Rechten gegevensbescherming)", ["We willen ervoor zorgen dat je volledig op de hoogte bent van al je rechten op het gebied van gegevensbescherming. Elke gebruiker heeft recht op het volgende:","Recht op toegang - U hebt het recht om kopieën van uw persoonlijke gegevens op te vragen. We kunnen u een kleine vergoeding in rekening brengen voor deze service.", "Het recht op rectificatie - U heeft het recht om ons te verzoeken alle informatie te corrigeren waarvan u denkt dat deze onjuist is. Je hebt ook het recht om te vragen dat we de informatie aanvullen die volgens jou onvolledig is.", "Het recht op wissen - Je hebt het recht om te vragen dat we je persoonlijke gegevens wissen, onder bepaalde voorwaarden.", "Het recht op beperking van de verwerking - Je hebt het recht om te vragen dat we de verwerking van je persoonlijke gegevens beperken, onder bepaalde voorwaarden. ", "Het recht om bezwaar te maken tegen verwerking - U heeft het recht om bezwaar te maken tegen onze verwerking van uw persoonsgegevens, onder bepaalde voorwaarden.", "Het recht op gegevensoverdraagbaarheid - U heeft het recht om te verzoeken dat wij de gegevens die wij hebben verzameld overdragen aan een andere organisatie, of rechtstreeks aan u, onder bepaalde voorwaarden.", "Als u een verzoek indient, hebben wij een maand de tijd om u te antwoorden. Als u een van deze rechten wilt uitoefenen, neem dan contact met ons op."]],
        pp10t: ["10 - Informatie voor kinderen", ["Een ander deel van onze prioriteit is het toevoegen van bescherming voor kinderen tijdens het gebruik van het internet. We moedigen ouders en voogden aan om hun online activiteiten te observeren, eraan deel te nemen en/of ze te bewaken en te begeleiden.", "Creativ-Network verzamelt niet bewust persoonlijk identificeerbare informatie van kinderen jonger dan 13. Als u denkt dat uw kind dit soort informatie op onze website heeft verstrekt, raden we u ten zeerste aan onmiddellijk contact met ons op te nemen en zullen we ons best doen dergelijke informatie onmiddellijk te verwijderen."]],

        lastupdated: "Laatst bijgewerkt",
        tcint: ["Lees de volgende algemene voorwaarden zorgvuldig door voordat u de website van Creativ'Network gebruikt."],
        tc1t: ["1. Aanvaarding van de voorwaarden", ["Door de website van Creativ'Network te bezoeken of te gebruiken, gaat u akkoord met en bent u gebonden aan deze voorwaarden. Als u niet akkoord gaat met enig deel van deze voorwaarden, maak dan geen gebruik van onze website."]],
        tc2t: ["2. Geschiktheid van de gebruiker", ["U moet ten minste 18 jaar oud zijn om onze website te gebruiken. Door onze diensten te gebruiken, bevestigt u dat u aan deze leeftijdseis voldoet."]],
        tc3t: ["3. Websitegebruik", ["- U stemt ermee in de website te gebruiken in overeenstemming met alle toepasselijke wet- en regelgeving", "- U zult zich niet bezighouden met illegale, schadelijke of beledigende activiteiten op ons platform", "- U stemt ermee in zich niet voor te doen als anderen of valse identiteiten aan te maken."]],
        tc4t: ["4. Privacy en gegevensverwerking", ["In ons privacybeleid wordt beschreven hoe wij uw persoonlijke gegevens verzamelen, gebruiken en beschermen. Door onze website te gebruiken, stemt u in met de gegevenspraktijken beschreven in ons Privacybeleid."]],
        tc5t: ["5. Intellectueel eigendom", ["Alle inhoud op onze website, inclusief tekst, afbeeldingen, logo's en software, wordt beschermd door intellectuele eigendomsrechten. U mag onze inhoud niet gebruiken, reproduceren of distribueren zonder onze uitdrukkelijke toestemming."]],
        tc6t: ["6. Door gebruikers gegenereerde inhoud", ["Wanneer u inhoud bijdraagt aan ons platform, verleent u ons een niet-exclusieve, royaltyvrije licentie om uw inhoud te gebruiken, te wijzigen en te verspreiden."]],
        tc7t: ["7. Wijzigingen in de voorwaarden", ["We kunnen deze voorwaarden en bepalingen te allen tijde bijwerken. Als u de website na dergelijke wijzigingen blijft gebruiken, geeft dit aan dat u de herziene voorwaarden accepteert."]],
        tc8t: ["8. Disclaimers en aansprakelijkheidsbeperkingen", ["Wij bieden onze website aan \"zoals deze is\" en geven geen garanties of waarborgen met betrekking tot de nauwkeurigheid, beschikbaarheid of prestaties ervan. Wij zijn niet aansprakelijk voor directe, indirecte of gevolgschade die voortvloeit uit uw gebruik van onze website."]],
        tc9t: ["9. Toepasselijk recht en bevoegde rechtbank", ["Op deze voorwaarden en uw gebruik van onze website is het recht van het Verenigd Koninkrijk van toepassing. Eventuele geschillen zullen worden beslecht door de rechtbanken van het Verenigd Koninkrijk."]],
        tc10t: ["10. Contactinformatie", ["Als u vragen of opmerkingen hebt over deze voorwaarden, kunt u contact met ons opnemen via contact@creativ-network.net."]],
        jobsint1: "Kies de baan die bij je vaardigheden past",
        jobsint2: "Beleef een geweldige ervaring met een geweldig team!",
        jobsat: "Banen bij",

        hirenow: "NU AANWERVEN!",
        closed: "GESLOTEN!",
        detailsandapply: "Bekijk de details en solliciteer",
        appclosed: "Aanvraag gesloten",

        jobco: ["Dating Chat Operator", "Je hebt een passie voor chatten en het runnen van datingsites en communities baan."],
        jobwdev: ["Webontwikkelaar", "Je hebt een passie voor chatten en het runnen van datingsites en communities job."],
        jobtl: ["Teamleider", "Je hebt een passie voor chatten en het runnen van datingsites en communities baan."],
        jobdm: ["Digital Marketeer", "Je hebt een passie voor chatten en het runnen van datingsites en communities baan."],
        jobsup: ["Supervisor", "Je hebt een passie voor chatten en het runnen van datingsites en communities job."],
        jobwdes: ["Webdesigner", "Je hebt een passie voor chatten en het runnen van datingsites en communities job."],
        
        cotest: "Chat Operator-test",
        cotesttitle: "Test om Dating Chat Operator te worden bij Creativ'Network",
        cotestint1: `"De Dating Chat Operator-test van Creativ'Network beoordeelt communicatievaardigheden, empathie en betrokkenheid voor effectieve en respectvolle gebruikersinteracties."`,
        cotestint2: "Neem even de tijd om alle situaties te beantwoorden voordat we verder gaan met je sollicitatie.",
        goodluck: "Veel succes!",
        number: "N°",
        youranswer: "Uw antwoord",
        next: "Volgende",
        back: "Terug",
        blog: "Blog",
        stat: "Statistieken",
        at: "Bij",
        ourstat: " geven onze statistieken een levendig beeld van ons succes en onze groei. Met een toegewijd team, talloze projecten, een uitgebreid netwerk van partners en jarenlange ervaring zijn we een bloeiende kracht in de industrie. Laten we in de details duiken om onze reis te ontdekken.",
        team: "Teamleden",
        projects: "Projecten",
        netpart: "Netwerkpartners",
        years: "Jaren in bedrijf",
        testimonial: "Getuigenis",
        dedicated:"Toegewijde teamleden bij",
        sharing: ", die hun ervaringen, inzichten en enthousiasme delen voor een lonende werkplek waar samenwerking bloeit.",
        testimonialperson1: ["Marie C", "Dating Chat Operator", `"Werken als Dating Chat operator bij Creativ'Network is ongelooflijk. Ik kan elke dag mensen met elkaar in contact brengen en een verschil maken in hun leven. Het is zowel lonend als leuk!"`], 
        testimonialperson2: ["Lucas P", "Dating Chat Operator", `"Creativ'Network is de perfecte plek om je passie voor het helpen je niet eenzaam te voelen te omarmen. Het team is fantastisch en de sfeer is ondersteunend. Ik hou van wat ik doe."`],
        testimonialperson3: ["Sophie M", "Dating Chat Operator", `"Creativ'Network biedt een uniek platform om betekenisvolle connecties op te bouwen. Als Dating Chat operator heb ik het gevoel dat ik deel uitmaak van iets speciaals, mensen bespreek en gelukkig maak."`],
        testimonialperson4: ["Alex R", "Webontwikkelaar Front End", `"Creativ'Network daagt mij uit als webontwikkelaar. De innovatieve projecten en het ondersteunende team stuwen mijn vaardigheden naar nieuwe hoogten. Ik ben er trots op deel uit te maken van deze dynamische technische omgeving."`],
        testimonialperson5: ["Emma H", "Webontwikkelaar Back End", `"Ik heb mijn droombaan gevonden als webontwikkelaar bij Creativ'Network. De toewijding van het bedrijf aan vooruitstrevende technologie en groeimogelijkheden houden me geïnspireerd en enthousiast."`],
        testimonialperson6: ["Tommy J", "Webdesigner", `"Als webdesigner bij Creativ'Network heb ik de creatieve vrijheid om visueel aantrekkelijke platforms vorm te geven. Het is een inspirerende plek die waarde hecht aan ontwerpesthetiek en gebruikerservaring, waardoor het werk echt plezierig is."`],

        titlesuffix: "- Creativ-Network",
        titlejobs: "Jobs",
        titlechatoperator: "Chatoperator",
        titletest: "Test",
        titlepp: "Privacybeleid",
        titleterms: "Voorwaarden",

        wantto: "Wil je tevreden werknemers worden met",
        areyou: "Heb je een passie voor webwerk? Heb je ervaring in een van de volgende domeinen: chat, webontwikkeling, digitale marketing, data labeling, teleassistentie en klantenrelaties, telesales, copy writing, teleprospecting, social media management, HR, boekhouding, webdesign...? ? Word nu lid van onze community! ",
        check: 'Bekijk alle vacatures en solliciteer',

        our: 'Onze',
        missions: 'Missies',
        advantages: 'Voordelen',


        mission1: 'Identificatie en werving van talent: Het bedrijf zoekt en werft actief personen met expertise in webgerelateerde domeinen. Dit omvat het identificeren en aantrekken van toptalent uit verschillende bronnen, waaronder vacatures, online netwerken en doorverwijzingen.',
        mission2: "Beoordeling van vaardigheden en screening: Creativ'Network beoordeelt de vaardigheden en kwalificaties van potentiële kandidaten nauwkeurig om er zeker van te zijn dat ze voldoen aan de specifieke vereisten van het bedrijf voor de functies waarvoor ze worden aangenomen.",
        mission3: "Afstemming op cultuur en waarden: Naast vaardigheden beoordeelt het bedrijf kandidaten op hun afstemming op de waarden en cultuur van het bedrijf om een samenhangende en productieve werkomgeving te bevorderen.",
        mission4: "Aanpassing van functies: Creativ'Network stemt functies af op de sterke punten en vaardigheden van het ingehuurde talent, zodat de functies aansluiten bij hun expertise en interesses.",
        mission5: "Ontwikkeling en behoud van talent: Het bedrijf zet zich in om het talent dat het aanwerft te koesteren en te behouden door groei- en doorgroeimogelijkheden binnen de organisatie te bieden.",
        mission6: "Marktanalyse en strategie: Creativ'Network houdt de trends in de sector, de salarisbenchmarks en de veranderende vaardigheidseisen in de gaten om concurrerend te blijven op de webgerelateerde banenmarkt.",
        mission7: "Diversiteit en inclusie: Het bedrijf kan diversiteit en inclusie actief promoten in het eigen proces van talentacquisitie, door te streven naar een personeelsbestand dat een breed scala aan achtergronden en perspectieven weerspiegelt.",
        mission8: "Samenwerking en kruistraining: Het bedrijf stimuleert samenwerking tussen talenten en biedt mogelijkheden voor cross-training, zodat werknemers hun vaardigheden kunnen diversifiëren en kunnen bijdragen aan verschillende aspecten van het bedrijf.",
        mission9: "Aanpassing aan veranderende behoeften: Creativ'Network blijft flexibel en past zich aan aan de veranderende eisen van de webgerelateerde gebieden waarin het actief is. Dit houdt ook in dat we nieuwe talenten en vaardigheden verwerven naarmate de technologie en de trends in de sector evolueren.",
        mission10: "Voortdurende verbetering: Het bedrijf herziet en verfijnt consequent zijn wervings- en talentmanagementprocessen om ervoor te zorgen dat ze efficiënt en effectief zijn.",
        mission11: "Tevredenheid en welzijn van werknemers: Creativ'Network legt sterk de nadruk op de tevredenheid van haar werknemers en biedt voordelen, een goed evenwicht tussen werk en privéleven en ondersteuning om het algemene welzijn van haar werknemers te verbeteren.",

        value1: "Uitmuntendheid: We streven naar uitmuntendheid in alles wat we doen, van het werven van toptalent tot het leveren van uitzonderlijke diensten aan onze klanten.",
        value2: "Klantgerichte aanpak: Wij stellen onze klanten centraal in onze activiteiten, begrijpen hun unieke behoeften en bieden oplossingen op maat die hun succes vergroten.",
        value3: "Empowerment van talent: We stellen webtalenten in staat om te groeien in hun carrière door ze in contact te brengen met kansen die aansluiten bij hun vaardigheden en ambities.",
        value4: "Innovatie en aanpassingsvermogen: We omarmen innovatie en passen ons aan het voortdurend veranderende landschap van webgerelateerde velden aan, zodat we in de voorhoede blijven van trends in de sector.",
        value5: "Diversiteit en inclusie: We vieren diversiteit en koesteren een inclusieve omgeving waarin individuen met alle achtergronden kunnen bijdragen aan ons collectieve succes.",
        value6: "Transparantie en integriteit: We hanteren de hoogste integriteitsnormen en communiceren transparant met onze klanten, kandidaten en teamleden.",
        value7: "Samenwerking en synergie: Wij geloven in de kracht van samenwerking, samenwerken om gezamenlijke doelen te bereiken en positieve resultaten te behalen.",
        value8: "Voortdurend leren: We moedigen continu leren en professionele ontwikkeling aan, zodat ons team is uitgerust met de nieuwste vaardigheden en kennis.",
        value9: "Industrie-expertise: Wij zijn industrie-experts, bieden waardevolle inzichten in de webgerelateerde banenmarkt en delen onze kennis met klanten en kandidaten.",
        value10: "Ethisch gedrag: We werken met onwrikbaar ethisch gedrag en behandelen alle individuen met respect, eerlijkheid en professionaliteit.",
        value11: "Betrokkenheid bij de gemeenschap: We zijn actief betrokken bij de professionele gemeenschap en dragen bij aan de groei ervan door mentorschap, onderwijs en deelname aan brancheverenigingen.",
        value12: "Impact en verantwoordelijkheid: We nemen verantwoordelijkheid voor de impact die we hebben op de carrières van individuen en het succes van de organisaties die we van dienst zijn en streven naar positieve, blijvende resultaten.",
        
        advantage1:"Concurrerende beloningspakketten: Het aanbieden van bovengemiddelde of toonaangevende vergoedingen en secundaire arbeidsvoorwaarden om talent van hoge kwaliteit aan te trekken en te behouden, waardoor Creativ'Network zich kan onderscheiden van concurrenten.",
        advantage2:"Op prestaties gebaseerde bonussen: Het implementeren van prestatiegerelateerde bonusstructuren kan werknemers motiveren om uit te blinken en ervoor zorgen dat ze worden beloond voor hun bijdragen.",
        advantage3:"Aangepaste compensatieplannen: Het afstemmen van beloningspakketten op de vaardigheden, ervaring en prestaties van individuele werknemers kan leiden tot meer werktevredenheid en loyaliteit.",
        advantage4:"Carrièremogelijkheden: Duidelijke paden bieden voor loopbaanontwikkeling en ontplooiing binnen het bedrijf, wat ambitieuze professionals kan aantrekken en het intern behouden van talent kan bevorderen.",
        advantage5:"Erkenningsprogramma's voor werknemers: Het erkennen en belonen van uitstekende prestaties van werknemers kan het moreel opkrikken en de retentie verhogen.",
        advantage6:"Flexibele werkregelingen: Het aanbieden van flexibele werkopties, zoals werken op afstand of flexibele uren, kan de balans tussen werk en privé verbeteren en een breder scala aan kandidaten aanspreken.",
        advantage7:"Uitgebreide secundaire arbeidsvoorwaarden: Het aanbieden van concurrerende secundaire arbeidsvoorwaarden, zoals ziektekostenverzekeringen, pensioenplannen en welzijnsprogramma's, kan toptalent aantrekken en het welzijn van werknemers bevorderen.",
        advantage8:"Investeren in opleiding en ontwikkeling: Het ondersteunen van continu leren en professionele groei door middel van opleidingsprogramma's en onderwijsmogelijkheden kan een hoog opgeleid en gemotiveerd personeelsbestand bevorderen.",
        advantage9:"Transparante beloningspraktijken: Het onderhouden van transparante communicatie over compensatiepraktijken en salarisbenchmarks kan vertrouwen en transparantie in personeelsmanagement opbouwen.",
        advantage10:"Bijstandsprogramma's voor werknemers: Het aanbieden van hulpprogramma's voor persoonlijke en professionele uitdagingen kan aantonen dat men zich inzet voor het welzijn van werknemers en werktevredenheid.",
        advantage11:"Beoordelingsgesprekken en feedback: Regelmatige prestatie-evaluaties en constructieve feedback kunnen werknemers helpen inzicht te krijgen in hun loopbaanontwikkeling en gebieden die voor verbetering vatbaar zijn.",
        advantage12:"Marktanalyse voor concurrerende salarissen: Uitvoeren van marktanalyses om ervoor te zorgen dat salarissen en secundaire arbeidsvoorwaarden concurrerend blijven en op één lijn liggen met de industrienormen.",
        advantage13:"Beloningsstructuren: Het ontwikkelen van creatieve beloningsstructuren die uitzonderlijke prestaties, innovatie en bijdragen aan de doelstellingen van het bedrijf belonen.",

        blog2: "Hier vindt u onzeposts",
        ourposts: "Onze berichten",
        blog3: "Veel plezier met het lezen van onze berichten",
        datepub: "15/10/2023",
        months: ["Januari", "Februari", "Maart", "April", "Mei", "Juni", "Juli", "Augustus", "September", "Oktober", "November", "December"],
        uncategorized: "Ongecategoriseerd",

        blognumber1: ["Strada per un paesaggio accattivante", "La nuova lingua comune sarà più semplice e regolare delle lingue europee esistenti. Sarà semplice come l'Occidentale; anzi, sarà Occidentale. A un inglese sembrerà un inglese semplificato, come un mio amico scettico di Cambridge mi ha detto che cos'è l'Occidentale. Le lingue europee fanno parte della stessa famiglia."], 
        blognumber2: ["Gravi problemi con i cavi in città", "La nuova lingua comune sarà più semplice e regolare delle lingue europee esistenti. Sarà semplice come l'Occidentale; anzi, sarà Occidentale. A un inglese sembrerà un inglese semplificato, come un mio amico scettico di Cambridge mi ha detto che cos'è l'Occidentale. Le lingue europee fanno parte della stessa famiglia."], 
        blognumber3: ["Montagna e freddo invernale", "Se diverse lingue si fondono, la grammatica della lingua risultante è più semplice e regolare di quella delle singole lingue. La nuova lingua comune sarà più semplice e regolare delle lingue europee esistenti. Sarà semplice come l'Occidentale; anzi, sarà Occidentale."], 
        by: "door",
        noc: "geen",
        comments: "opmerkingen",
        comment: "opmerkingen",
        readmore: "Meer lezen",

        cookiestitle: "Cookie toestemmingsbeheer",
        cookiescontent: "Creativ'Network's Cookie Consent Management is essentieel voor een transparante en compliant online ervaring. We vragen gebruikers op een duidelijke manier om toestemming en geven gedetailleerde informatie over onze cookie-praktijken. Met aanpassingsopties, expliciete cookiecategorisering en een toewijding aan gegevensprivacy creëert Creativ'Network een veilige en respectvolle digitale ervaring. We houden ons strikt aan de privacynormen en garanderen de veiligheid van de gebruiker en het vertrouwen in ons platform.",
        pref: "Voorkeuren",
        cookiessm1i: "Functioneel",
        cookiessm2i: "Statistieken",
        cookiessm3i: "Marketing",
        cookiessm1c: `"Opslaan of technische toegang" op de website van Creativ'Network omvat het gebruik van cookies om de gebruikerservaring te verbeteren. Dit omvat het verzamelen van technische gegevens die essentieel zijn voor de goede werking van de site. Wij vragen de toestemming van de gebruikers en verschaffen transparante informatie over deze praktijken, in overeenstemming met de geldende normen en voorschriften inzake gegevensbescherming`,
        cookiessm2c: `Statistieken in het cookie-toestemmingsbeheer voor Creativ'Network maken het mogelijk analytische gegevens te verzamelen. Ze spelen een cruciale rol bij het evalueren van het surfgedrag van gebruikers, waardoor de site voortdurend kan worden verbeterd. Deze informatie vormt de basis voor beslissingen om de gebruikerservaring te optimaliseren met inachtneming van de privacyvoorkeuren, waardoor een gepersonaliseerd en compliant platform wordt gegarandeerd`,
        cookiessm3c: `Met de functie "Marketing" in het cookie-toestemmingsbeheer op de site van Creativ'Network kunnen we advertenties personaliseren op basis van de voorkeuren van de gebruikers. Door hun toestemming te vragen, zorgen we voor een gerichte en relevante reclame-ervaring. Dit zorgt voor effectievere promotionele communicatie terwijl de normen voor gegevensprivacy en de individuele keuzes van gebruikers worden gerespecteerd.`,
        savepref: "Voorkeuren opslaan",
        accept: "Accepteren",
        alwyasactive: "Altijd actief",
        error: "Fout",

        tags: "Tags: Geen tags",
        comment1: "Een commentaar toevoegen",
        comment2: "Uw e-mailadres wordt niet gepubliceerd.",
        comment3: "Naam (verplicht)",
        comment4: "E-mail of gebruikersnaam",
        comment5: "Website",
        comment6: "Sla mijn naam, e-mail en website op in deze browser voor de volgende keer dat ik reageer.",
        comment7: "Commentaar",
        follow: "Volg ",
        us: "ons",
        creatememberspace: "Creatie van je ledenruimte",
        createmsint11: "Voer de code in die we naar",
        createmsint12: "hebben gestuurd om je e-mailadres te valideren.",
        createmsint2: "Door uw e-mailadres te valideren, wordt uw ledengebied automatisch aangemaakt",
        createmsconsent: "Door het indienen van uw aanvraagformulier, gaat u akkoord met onze",
        confirm: "Bevestig",
        codevalidationerror: "De validatiecode is onjuist",
        confirmpassword: "Wachtwoord bevestigen",
        passwordlength: `Het wachtwoord moet minstens ${mdpminlength} tekens bevatten`,
        passwordnotidentical: "Wachtwoorden zijn niet identiek",
        welcomeback: "Welkom terug",
        signinint: "Om met ons in contact te blijven, kunt u uw persoonlijke gegevens gebruiken om in te loggen",
        signin: "Aanmelden",
        createaccount: "Een account aanmaken",
        oruseyourpersonnalmail: "Of gebruik je persoonlijke e-mail voor registratie",
        email2: "E-mail",
        signup: "Aanmelden",
        memberarea: "Ledenruimte",
        yourmailpseudo: "Je e-mailadres / Gebruikersnaam",
        yourpassword: "Uw wachtwoord",
        idorpasswordforgot: "Klik hier als u uw wachtwoord bent vergeten",
        settingupaccount: "Uw account instellen",
        settingupaccountint: "Om je account aan te maken, moet je de volgende informatie opgeven:",
        yourfirstname:  "Je voornaam",
        yourlastname:  "Je achternaam",
        yourusername: "Uw gebruikersnaam",
        yourskypeid: "Uw skype-ID",
        yourbirthdate: "Je geboortedatum",
        yourgender: "Uw geslacht",
        yourphonenumber: "Uw telefoonnummer",
        yourmailaddress: "Uw e-mailadres",
        appartroad:"Appartement / huisnummer",
        yourcitydep: "Uw stad / afdeling",
        yourpobox: "Uw postbus",
        yourcountry: "Uw land",
        agreepp1: "Voordat u uw informatie indient, moet u akkoord gaan met onze",
        agreepp2: "",
        note: "Opmerking",
        notecontent: "Om uw account te activeren, controleer uw e-mail inbox",
        createmyaccount: "Mijn account aanmaken",
        male: "Mannelijk ",
        female: "Vrouwelijk",
        other: "Andere",
        mailexists: "Dit e-mailadres bestaat al",
        usernameexists: "Deze gebruikersnaam bestaat al",
        dashboard: "Dashboard",
        dashboard1: "Beheer je Creativ'Network-account. Je instellingen wijzigen en rapporten bekijken",
        dashboard2: "Bedieningskanaal",

        information: "Jouw informatie", 
        information1: "Beheer je persoonlijke informatie",
        information2: "Wijzig je wachtwoord",

        yourapplication: "Uw sollicitatie",
        application1: "Upload / Wijzig je cv ",
        application2: "Controleer de status van je sollicitatie",

        integration: "Uw integratie",
        integration1: "De vereiste documenten uploaden / wijzigen",
        integration2: "Betalingsgegevens uploaden / wijzigen",

        info11: "Je profielfoto wijzigen",
        info12: "Upload je foto vanuit browser",
        info13: "Browser",
        info14: "Upload uw foto vanaf uw camera",

        save: "Sla",
        admin: "Beheerder",
        blogger: "Blogger",
        teamleader: "Teamleider",
        adboard: "Admin dashboard",
        tldboard: "Teamleider dashboard",
        bdboard: "Blogger dashboard",
        notifs: 'Meldingen',
        logout: 'Afmelden',
        actualpassword: "Je huidige wachtwoord",
        newpassword: "Uw nieuwe wachtwoord",
        confirmnewpassword: "Bevestig uw wachtwoord",

        app11:"Upload uw cv",
        app12:"Upload uw cv vanuit browser",

        appstatus1: "Aanvraagformulier",
        submittedon:"Ingediend op",
        appstatus2: "Test",
        notyet: "Nog niet gedaan",
        reviewedon: "Beoordeeld op",
        appstatus3: "Herzieningsstatus",
        reviewed: "Beoordeelde",
        appstatus4: "Beslissing van het management",
        hired: "Gefeliciteerd, u bent aangenomen. Ga nu naar de volgende stap: uw integratie.",
        nothired: "Het spijt ons, maar uw aanvraag is niet geaccepteerd.",
        idtl: "Teamleider-ID",
        ida: "Admin-ID",
        idb: "Blogger ID",
        positionoverview: "Functieoverzicht",
        adminstaff: "Administratief personeel",
        jobposts: "Jobposts",
        jobapplication: "Sollicitatie",
        newemptraining: "Training nieuwe medewerkers",
        empintegration: "Integratie werknemers",
        blogposts: "Blogposts",
        details: "Details",
        cnmembers: "Creativ-Network-leden",
        sn: "Sociaal netwerk",
        aas1: "Lijst van administratief personeel",
        aas2: "Voeg administratief personeel toe",
        aja1: "Alle sollicitaties",
        aja2: "Details sollicitaties controleren",
        aja3: "Geslaagde sollicitaties",
        anet1: "Trainingsschema",
        anet2: "Trainingsdocumenten toevoegen",
        anet3: "Agenten in afwachting van login",
        anet4: "Login toewijzing",
        aei1: "Nieuwe agenten check up",
        abp1: "Bloggers lijst",
        abp2: "Bloggers toevoegen",
        abp3: "Lijst met blogberichten",
        abp4: "Blog posts toevoegen",
        ajp1: "Lijst met vacatureteksten",
        ajp2: "Job posts toevoegen",
        acnm1: "Lijst met leden",
        acnm2: "Leden toevoegen",
        anl1: "Lijst van abonnees",
        anl2: "Alle nieuwsbrieven",
        anl3: "Nieuwsbrief toevoegen",
        asn1: "Alle sociale netwerken",

        passportcopy: "Kopie paspoort, of rijbewijs, of ID van de overheid",
        proofofaddress: "Bewijs van adres",
        yourselfie: "Je selfie met je ID",
        freelance: "Freelance contract",
        integ1up1: "Upload je identiteitsbewijs vanuit je browser",
        integ1up2: "Upload je bewijs van adres vanuit je browser",
        integ1up3: "Upload je selfie vanuit je browser",
        integ1up4: "Upload je selfie vanaf je camera",
        integ1download: "Download het freelance contract, onderteken het en stuur het hier terug",
        integ1up5: "Upload uw ondertekende freelance contract vanuit uw browser",
        download: "Downloaden",

        
        paypalaccount: "Paypal-account",
        yourpaypal: "(Uw Paypal e-mailadres)",
        wiseaccount: "Wijs account",
        yourwise: "(Je wijs e-mail adres)",
        bitesafeaccount: "Yoursafe account",
        yourbitesafe: "(Je Yoursafe e-mailadres)",
        bankaccount: "Bank rekening",
        accountholder: "Naam rekeninghouder:",
        holderaddress: "Adres rekeninghouder:",
        banksholder: "Naam houder bank:",
        bankaddress: "Adres bank houder: ",
        iban: "IBAN / Rekeningnummer:",
        swiftcode: "Swiftcode / BIC: ",
        year: 'Jaar',
        month: 'Maand',
        date: 'Datum',
        week: 'Week',
        today: 'Vandaag',
        startingdate: 'Begindatum',
        closingdate: 'Einddatum',
        adminstafflogin: 'Beheerders login',
        adminstaffloginpassword: "Wachtwoord beheerderslogin",
        actions: 'Acties',
        suspend: 'Opschorten',
        fire: 'Verbod',
        candidateid: 'Kandidaat-ID',
        language: "Taal",
        edit: 'Wijzigen',
        openingposition: 'Openingspositie',
        jobtitle: "Functie",
        jobdescription : "Functieomschrijving",
        openclose: "Beschikbaar / Niet beschikbaar",
        jobopen: "Beschikbaar",
        jobclose: "Niet beschikbaar",
        delete: "Verwijderen",
        applicantfirstname: 'Voornaam aanvrager',
        applicantlastname: 'Achternaam aanvrager',
        applicantcountry: 'Land aanvrager',
        application: 'Aanvraag',
        checkthapplication: "Controleer de aanvraag",
        agentfirstname: "Voornaam van de aanvrager",
        agentlastname: "Achternaam agent",
        agentcountry: "Land van de agent",
        agentemail: "E-mail agent",
        agentskypeid: "Skype ID van de agent",
        trainingschedule: "Trainingsschema",
        sendconfirmationonemailtoagent: "Bevestigingse-mail verzenden naar agent",
        memberopt1: "Beheer uw informatie",
        memberopt2: "Controleer uw sollicitatie",
        memberopt3: "Ga verder met uw integratie",
        awaitingforlogin: "In afwachting van aanmelding",
        awaitingforemailconf: "In afwachting van bevestigingsmail",
        agentlogin: "Agent login",
        freelancecontract: "Freelancecontract",
        trainingdocumentssigned: "Opleidingsdocumenten ondertekend",
        proofofresidence: "Bewijs van woonplaats",
        paymentmethod: "Betalingsmethode",
        provided: "Verstrekt",
        notprovided: "Niet verstrekt",
        bank: "Bank",
        wise: "Wise",
        paypal: "Paypal",
        Yoursafe: "Yoursafe",
        activeblogger: "Actieve blogger",
        bloggerfirstname: "Voornaam blogger",
        bloggerlastname: "Achternaam blogger",
        bloggerloginid: "Blogger inlog-ID",
        bloggerloginpassword: "Blogger login wachtwoord",
        blogposttile: "Blogpost titel",
        blogpostcategory: "Blogpostcategorie",
        blogpostlink: "Link naar blogpost",
        category: "Categorie",
        newmembers: "Nieuwe leden",
        allmembers: "Alle leden",
        inscriptdate: "Inschrijvingsdatum",
        memberfirstname: "Voornaam lid",
        memberlastname: "Achternaam lid",
        loginpassword: "Login wachtwoord",
        emailaddressconfirmed: "E-mailadres bevestigd",
        country: "Land",
        newsubscribers: "Nieuwe abonnees",
        allsubscribers: "Alle abonnees",
        subscriptiondate: "Abonnementsdatum",
        topic: "Onderwerp",
        allnewsletters: "Alle nieuwsbrieven",
        releasedate: "Releasedatum",
        releaselanguage: "Taal van uitgave",
        linktothenewsletter: "Link naar de nieuwsbrief",
        sendtoallubscribers: "Verzenden naar alle abonnees",

        agentid: "Agent ID",
        trainingprocess: "Trainingsproces",
        integrationprocess: "Integratieproces",
        jobposition: "Functie",
        training: "Opleiding",
        integrationn: "Integratie",
        inprogress: "In uitvoering",
        done: "Gereed",
        role : "Rol",
        skypeid: "Skype-ID",
        createnewadminstaff: "Creëer een nieuw administratief medewerker",
        jobid: "Job-ID",
        illustrationphoto: "Illustratie foto",
        location: "Locatie",
        jobrequirement: "Arbeidsvoorwaarden",
        salaryscale: "Salarisschaal",
        incentives: "Incentives",
        openingdate: "Openingsdatum",
        telephonenumber: "Telefoonnummer",
        createnewjobposts: "Nieuwe vacature aanmaken",
        haveexperience: "Ervaring hebben",
        howtheyfindus: "Hoe ze ons gevonden hebben",
        motivation: "Motivatie",
        testresult: "Testresultaat",
        decision: "Beslissing",
        hired2: "Aangeworven",
        rejected: "Afgewezen",
        description: "Beschrijving",

        name: "Naam",
        document: "Document",
        blogpostid: "Blogpost ID",
        bloggerid: "Blogger ID",
        blogposttitle: "Titel blogbericht",
        blogposttext: "Blogpost tekst",
        publishnewblogpost: "Nieuw blogbericht publiceren",
        createnewblogger: "Nieuwe blogger aanmaken",
        createnewlogin: "Nieuwe login aanmaken",
        addblogpostcategory: "Blogpostcategorie toevoegen",
        blogpostcategoryid: "Blogpostcategorie ID",
        memberid: "Lid ID",
        addnewmemberandsendnotification: "Nieuw lid toevoegen en notificatie versturen",
        newsletterid: "Nieuwsbrief-ID",
        adminid: "Admin ID",
        newslettercategory: "Nieuwsbrief categorie",
        newslettertitle: "Titel nieuwsbrief",
        newslettertext: "Nieuwsbrief tekst",
        allsubscribersemail: "E-mailadres van alle abonnees",
        publishnewnewsletter: "Nieuwe nieuwsbrief publiceren",
        link: "Link",
        hello : "Hallo",

        tlintint1: "Wil je thuis werken als",
        tlintint2: "van datingchatoperators?",
        tlint1: "1. Effectief leiderschap: Geef leiding aan een team dat verbindingen vormgeeft, betekenisvolle relaties stimuleert en bijdraagt aan een dynamische datingomgeving.",
        tlint2: "2. Mogelijkheid tot teamgroei: Begeleid en ontwikkel een hecht team, stimuleer persoonlijke en professionele groei in een ondersteunende werkomgeving.",
        tlint3: "3. Innovatieve bijdrage: Creatieve oplossingen introduceren, de toekomst van online dating vormgeven en chatdiensten verbeteren, bijdragen aan innovatie in de branche.",
        tlint4: "4. Erkenning en prestatie: Geef leiding aan een succesvol team, overtref de statistieken en krijg erkenning, met een concurrerend salaris en verleidelijke incentives.",
        tlint5: "5. Beloningen voor uitmuntendheid: Geniet van een goed salaris, lucratieve incentives en erkenning voor het bereiken van doelen, waardoor je een significante impact hebt bij Creativ'Network.",
        tlabint1: "Wat houdt de functie van",
        tlabint2: "datingchatoperators in?",
        tlab1: "1. Leiderschap en coördinatie: Als Teamleider houd je toezicht op alle dating chat operators door sterk leiderschap te bieden, het team aan te sturen en te zorgen voor een samenhangende communicatie.",
        tlab2: "2. Prestatiemanagement: Instellen en bewaken van prestatiecijfers, zoals responstijden en gebruikerstevredenheid, om service van hoge kwaliteit te bereiken en een positieve gebruikerservaring te bevorderen.",
        tlab3: "3. Teamontwikkeling: Een hecht en gemotiveerd team onderhouden door begeleiding, mentorschap en het stimuleren van een ondersteunende werkomgeving voor persoonlijke en professionele groei.",
        tlab4: "4. Innovatie in chatservices: Creatieve benaderingen introduceren om chatdiensten te verbeteren, op de hoogte blijven van trends in de sector en strategieën aanpassen om de betrokkenheid van gebruikers te verbeteren.",
        tlab5: "5. Kwaliteitsborging: Ervoor zorgen dat operators een hoge servicestandaard handhaven, inspelen op de behoeften van gebruikers en problemen snel oplossen om een positieve en betrouwbare gebruikerservaring te behouden.",
        tlab6: "6. Communicatiehub: Dienen als een centraal punt voor communicatie tussen exploitanten, management en andere afdelingen, het vergemakkelijken van samenwerking om organisatorische doelen te bereiken in het dynamische rijk van online dating.",
        tlab7: "7. Strategische planning: Bijdragen aan de strategische planning voor het team, doelen afstemmen op de doelstellingen van de organisatie op het dynamische gebied van online dating.",
        tllsint1: "Wat zijn de leiderschapsvaardigheden om een",
        tllsint2: "van Dating chatoperators te zijn?",
        tlls11: "Leiderschapservaring",
        tlls12: "Aantoonbare ervaring in het leiden en managen van teams, met effectieve leiderschaps- en mentorvaardigheden.",
        tlls21: "Communicatieve vaardigheden",
        tlls22: "Sterke mondelinge en schriftelijke communicatievaardigheden om duidelijke communicatie tussen teamleden en met andere afdelingen te vergemakkelijken.",
        tlls31: "Kennis industrie",
        tlls32: "Bekendheid met de online datingindustrie, begrip van gebruikersdynamiek, trends en uitdagingen bij het aanbieden van datingchatdiensten",
        tlls41: "probleemoplossend vermogen",
        tlls42: "Aantoonbare vaardigheid om problemen efficiënt te analyseren en op te lossen, vooral bij het aanpakken van gebruikersproblemen en het behouden van een positieve gebruikerservaring",
        tlls51: "Prestatiemanagement",
        tlls52: "Ervaring met het instellen, bewaken en optimaliseren van prestatiemaatstaven om service van hoge kwaliteit, gebruikerstevredenheid en teamefficiëntie te garanderen.",
        tlls61: "Aanpassingsvermogen en innovatie",
        tlls62: "Het vermogen om zich aan te passen aan veranderingen in de branche, innovatieve strategieën te introduceren en opkomende trends in online datingdiensten voor te blijven.",
        tlls71: "Team Development Skills",
        tlls72: "Bewezen vermogen om teamgroei te stimuleren, begeleiding en mentorschap te bieden en een positieve werkomgeving te creëren voor persoonlijke en professionele ontwikkeling.",
        tlls81: "Focus op kwaliteitsborging",
        tlls82: "Toewijding aan het handhaven van een hoge servicestandaard, het inspelen op de behoeften van de gebruiker en het implementeren van maatregelen voor kwaliteitsborging voor een positieve gebruikerservaring.",
        tlls91: "Samenwerking en coördinatie",
        tlls92: "Sterke samenwerkingsvaardigheden, fungeren als centraal punt voor communicatie en effectieve coördinatie faciliteren tussen operators, management en andere afdelingen.",

        tlreint1: "Aan welke eisen moet een",
        tlreint2: "van Creativ'Network voldoen?",
        tlre12: "Een teamleider van Creativ'Network moet de primaire werktalen vloeiend beheersen, meertalig zijn, duidelijk kunnen articuleren, begrip hebben van regionale nuances, goed kunnen schrijven en vaardig zijn in gebruikersinteracties en samenwerking met andere afdelingen.",
        tlre22: "Het instellen van een leeftijdsgrens van 25+ voor een teamleider bij Creativ'Network kan zorgen voor een evenwicht tussen ervaring en volwassenheid. Dit beleid bevordert diverse leiderschapskwaliteiten en houdt tegelijkertijd een dynamisch en efficiënt team in stand.",
        tlre42: "Teamleiders van Creativ'Network moeten de privacyverklaring en contractvoorwaarden erkennen en aanvaarden, zodat ze voldoen aan de gegevensbescherming en contractuele verplichtingen en een veilige en transparante werkrelatie bevorderen.",
        tlre61: "Rapporten",
        tlre62: "De teamleiders van Creativ'Network stellen gedetailleerde rapporten op met een overzicht van de prestaties van het team, de mate waarin gebruikers betrokken zijn en de problemen die zijn aangepakt. Deze rapporten geven richting aan strategische beslissingen, identificeren verbeterpunten en zorgen voor een optimale dienstverlening.",
        tlre72: "De teamleiders van Creativ'Network werken minimaal 40 uur per week en zijn af en toe beschikbaar in het weekend en op feestdagen. Dit garandeert een continue ondersteuning van het team en een optimale dienstverlening.",
        tlre81: "Belasting",
        tlre82: "Als zelfstandige is Creativ's Team Leader verantwoordelijk voor het beheren en betalen van zijn eigen belastingen. Dit omvat het naleven van belastingregels en het voldoen aan financiële verplichtingen in zijn respectievelijke rechtsgebieden.",
        tlre92: "Teamleiders van Creativ'Network zorgen ervoor dat ze zich strikt houden aan de richtlijnen van wet- en regelgeving en bevorderen een omgeving die voldoet aan de wet- en regelgeving. Dit omvat het naleven van de privacy van gebruikers, gegevensbescherming en andere branchespecifieke regels om de integriteit van het platform te handhaven.",
        tlic1: "Leeftijdscontrole: Creativ'Network controleert de leeftijd van sollicitanten voor Teamleaders (25+) om er zeker van te zijn dat kandidaten beschikken over de ervaring, volwassenheid en stabiliteit die essentieel is voor effectief leiderschap. Deze aanpak komt overeen met het doel van het bedrijf om doorgewinterde leiders te hebben met een bewezen staat van dienst, die bijdragen aan succesvol teammanagement en een positieve werkomgeving bevorderen.",
        tlic2: "Veiligheid en vertrouwen: Leeftijdscontrole voor sollicitanten van 25+ voor Creativ'Network Team Leader is geïmplementeerd om de veiligheid en het vertrouwen te vergroten. Door naleving van dit criterium te garanderen, wil het platform een volwassen en ervaren team van teamleiders samenstellen en een veilige en betrouwbare omgeving creëren voor zowel gebruikers als teamleden.",
        tlas1: "Begin met het bezoeken van de officiële website of het carrièreportaal van Creativ'Network.|Zoek de vacature voor de functie van Teamleider en klik op \"Solliciteer\".|Vul het sollicitatieformulier in, waarin je onder andere je persoonlijke gegevens, contactgegevens, relevante ervaring en je motivatie om bij ons te werken invult.|Je wordt gevraagd een korte vaardigheidstest af te leggen, die meestal ongeveer 10 minuten duurt. Deze test beoordeelt je vaardigheid in leiderschap, communicatie, prestatiemanagement, innovatie, teamontwikkeling, kwaliteitsborging, samenwerking en aanpassingsvermogen.",
        tlas2: "Nadat je de eerste aanvraag en vaardigheidstest met succes hebt afgerond, moet je een ID-verificatie ondergaan om je leeftijd te bevestigen.|Je wordt gevraagd om een kopie van een geldig, door de overheid uitgegeven identiteitsbewijs te overleggen, zoals een paspoort, rijbewijs of identiteitskaart van de overheid. Dit document wordt gebruikt om te controleren of je voldoet aan de minimumleeftijd voor de functie.",
        tlas3: "Zodra je aanvraag en leeftijdscontrole zijn goedgekeurd, word je ingepland voor een online trainingssessie via Skype.|Tijdens de training geven de managers van Creativ'Network begeleiding en instructies over hoe je je rol als teamleider effectief kunt uitvoeren. Ze behandelen het bedrijfsbeleid, best practices en leiderschaps- en communicatiestrategieën om uit te blinken in je functie.",
        appformfor: "Sollicitatieformulier voor",
        tofilltheapplicationform: "Om het sollicitatieformulier in te vullen, moet u zich aanmelden of registreren op onze website",
        supervisor: "Toezichthouder",
        qualitysupervisor: "Toezichthouder kwaliteit",
        sintint1: "Wil je thuis werken als",
        sintint2: "van Dating chat operators?",
        sint1: "1. Kwaliteitsleiderschap: Zorg voor uitmuntendheid in chatdiensten, vormgeven van positieve gebruikerservaringen en bevorderen van kwaliteitsinteracties, allemaal vanuit het gemak van thuis.",
        sint2: "2. Concurrerende vergoeding: Ontvang een royaal salaris, lucratieve incentives en de flexibiliteit van werken op afstand, waardoor je financieel beloond wordt voor het handhaven van kwaliteit.",
        sint3: "3. Teamontwikkeling: Mentor en begeleider van operators, stimuleren van kwaliteitsservice, persoonlijke groei en professionele ontwikkeling in een virtuele werkomgeving",
        sint4: "4. Innovatieve bijdragen: Creatieve strategieën introduceren, chatdiensten verbeteren en bijdragen aan innovaties in de branche, zodat de gebruikerservaring op Creativ'Network van hoge kwaliteit is.",
        sint5: "5. Erkenning en beloning: Geef leiding aan een team van hoge kwaliteit, overtref de statistieken en krijg erkenning met een concurrerend salaris, zodat je een bevredigende en lonende carrière op afstand kunt opbouwen.",
        sint6: "6. Balans werk-privé: Geniet van de flexibiliteit van thuiswerken en een harmonieuze balans tussen werk en privé, terwijl je de kwaliteitsnormen bij Creativ'Network waarborgt en verbetert.",
        sabint1: "Wat houdt de functie van",
        sabint2: "of Dating Chat operators in?",
        sab1: "1. Prestatiemonitoring: Quality Supervisors houden nauwlettend toezicht op operators en zorgen ervoor dat ze zich houden aan de prestatiecriteria, responstijden en gebruikerstevredenheid, wat bijdraagt aan een positieve gebruikerservaring.",
        sab2: "2. Feedback en training: Constructieve feedback geven, trainingssessies uitvoeren en operators begeleiden om hun communicatievaardigheden voortdurend te verbeteren, zodat interacties van hoge kwaliteit zijn.",
        sab3: "3. Oplossen van problemen: Problemen van gebruikers direct aanpakken, problemen oplossen en een hoge servicestandaard handhaven om een positieve gebruikerservaring te garanderen.",
        sab4: "4. Protocollen voor kwaliteitsborging: Protocollen voor kwaliteitsborging implementeren en handhaven, zodat de vastgestelde normen voor chatdiensten op Creativ'Network consequent worden nageleefd.",
        sab5: "5. Innovatie en optimalisatie: Innovatieve strategieën introduceren om chatdiensten te optimaliseren, trends in de branche voor te blijven en de algehele kwaliteit van gebruikersinteracties te verbeteren.", 
        sab6: "6. Team samenwerking: Bevorder een collaboratieve teamomgeving, stimuleer communicatie en samenwerking tussen operators, en zorg voor een samenhangende aanpak voor het leveren van dating chatdiensten van hoge kwaliteit.",
        sskint1: "Wat zijn de vaardigheden om een",
        sskint2: "van Dating Chat-operators te zijn?",
        leadership: "Leiderschap",
        communication: "Communicatie",
        analyticalskills: "Analytische vaardigheden",
        pbsolving: "Problemen oplossen",
        qualityassurance: "Kwaliteitsborging",
        traininganddev: "Training en ontwikkeling",
        innovation: "Innovatie",
        collab: "Samenwerking",
        adaptability: "Aanpassingsvermogen",
        ssk1: "Uitzonderlijke leiderschapsvaardigheden om een team van chatoperators te leiden en te inspireren, en een positieve en productieve werkomgeving te bevorderen.",
        ssk2: "Sterke communicatieve vaardigheden, zowel mondeling als schriftelijk, om duidelijke instructies, feedback en training te geven aan operators, waardoor effectieve gebruikersinteracties worden gegarandeerd.",
        ssk3: "In staat om prestatiecijfers te analyseren, gebieden voor verbetering te identificeren en strategieën te implementeren om de algehele kwaliteit van chatservices te verbeteren.",
        ssk4: "Bedreven in het snel oplossen van gebruikersproblemen en het aanpakken van operationele uitdagingen, waarbij een hoge servicestandaard en gebruikerstevredenheid wordt gehandhaafd",
        ssk5: "Kwaliteitsborging: Uitgebreide kennis van kwaliteitsborgingsprotocollen, waardoor consistente naleving van vastgestelde normen wordt gewaarborgd en chatservices worden geoptimaliseerd.",
        ssk6: "Training en ontwikkeling: Het vermogen om trainingssessies te ontwerpen en uit te voeren, de vaardigheden van operators te ontwikkelen en voortdurende verbetering van communicatie en servicekwaliteit te stimuleren.",
        ssk7: "Innovatie: Bewezen vermogen om innovatieve strategieën te introduceren, trends in de branche voor te blijven en chatdiensten te optimaliseren voor een dynamische online datingomgeving.",
        ssk8: "Samenwerking: Effectieve samenwerkingsvaardigheden om een samenhangende teamomgeving te bevorderen, waarbij communicatie en samenwerking tussen operators wordt aangemoedigd voor een naadloze dienstverlening.",
        ssk9: "Aanpassingsvermogen: Het vermogen om zich aan te passen aan de veranderende dynamiek van de branche, gebruikersvoorkeuren en opkomende trends, zodat de chatdiensten van Creativ'Network toonaangevend blijven op het gebied van online dating.",
        langproficiency: "Nederlandse taalvaardigheid",
        agelimit: "Leeftijdsgrens",
        bankaccountpaymentaccount: "Bankrekening of betaalrekening",
        acceptanceppterms: "Aanvaarding van privacyverklaring en contractvoorwaarden",
        equipmentresponsibility: "Verantwoordelijkheid apparatuur",
        reports: "Rapporten",
        minweekly: "Minimale wekelijkse uren",
        tax: "Belasting",
        adhlegalregguidelines: "Naleving van wettelijke en reglementaire richtlijnen",
        sre1: "Een Creativ'Network Quality Supervisor moet de primaire werktalen vloeiend beheersen, meertalig zijn, duidelijk kunnen articuleren, begrip hebben van regionale nuances, goed kunnen schrijven en vaardig zijn in gebruikersinteracties en samenwerking met andere afdelingen.",
        sre2: "Het instellen van een leeftijdsgrens van 25+ voor een Creativ'Network Quality Supervisor kan zorgen voor een balans van ervaring en volwassenheid. Dit beleid bevordert diverse leiderschapskwaliteiten en houdt tegelijkertijd een dynamisch en efficiënt team in stand.",
        sre3: "Je zou een bankrekening, PayPal, Wise of Yoursafe account op je naam moeten hebben. Dit is waarschijnlijk voor de betalingsverwerking.",
        sre4: "Creativ's Network Quality Supervisor moet de privacyverklaring en contractvoorwaarden erkennen en accepteren, zodat de gegevensbescherming en contractuele verplichtingen worden nageleefd en een veilige en transparante werkrelatie wordt bevorderd.",
        sre5: "Er wordt van je verwacht dat je beschikt over de benodigde apparatuur, waaronder een laptop of pc, een betrouwbare internetverbinding en een back-up bron van elektriciteit en internet om ononderbroken werk te garanderen.",
        sre6: "Creativ's Network Quality Supervisor stelt gedetailleerde rapporten op met een samenvatting van de teamprestaties, statistieken over de betrokkenheid van gebruikers en behandelde problemen. Deze rapporten dienen als leidraad voor strategische beslissingen, identificeren verbeteringsgebieden en zorgen voor een optimale dienstverlening.",
        sre7: "Creativ'Network Quality Supervisor is minimaal 40 uur per week beschikbaar, met af en toe beschikbaarheid in het weekend en op feestdagen. Dit zorgt voor een continue ondersteuning van het team en handhaaft optimale servicenormen.",
        sre8: "Als zelfstandige is Creativ'Network Quality Supervisor verantwoordelijk voor het beheren en betalen van zijn eigen belastingen. Dit omvat het naleven van belastingregels en het voldoen aan financiële verplichtingen in zijn respectievelijke rechtsgebieden.",
        sre9: "Creativ's Network Quality Supervisor zorgt voor een strikte naleving van de wet- en regelgeving en bevordert een omgeving die voldoet aan de regels. Dit omvat het handhaven van de privacy van gebruikers, gegevensbescherming en andere branchespecifieke regels om de integriteit van het platform te behouden.",
        sic1: "1. Leeftijdscontrole: Creativ'Network controleert de leeftijd van sollicitanten voor Quality Supervisor (25+) om er zeker van te zijn dat kandidaten beschikken over de ervaring, volwassenheid en stabiliteit die essentieel is voor effectief leiderschap. Deze aanpak komt overeen met het doel van het bedrijf om doorgewinterde leiders te hebben met een bewezen staat van dienst, die bijdragen aan succesvol teammanagement en een positieve werkomgeving bevorderen.",
        sic2: "2. Veiligheid en vertrouwen: Leeftijdscontrole voor sollicitanten van 25+ voor Creativ's Network Quality Supervisor wordt toegepast om de veiligheid en het vertrouwen te vergroten. Door naleving van dit criterium te garanderen, streeft het platform naar een volwassen en ervaren leiderschapsteam, dat een veilige en betrouwbare omgeving creëert voor zowel gebruikers als teamleden. ",
        sic3: "3. Documenten voor verificatie: Om te voldoen aan wettelijke voorschriften en ervoor te zorgen dat kandidaten de juiste leeftijd hebben, vraagt Creativ'Network om leeftijdsverificatie. Documenten zoals rijbewijzen en paspoorten van de overheid bevatten doorgaans informatie over de geboortedatum, waardoor ze geschikt zijn om te bevestigen dat je voldoet aan de minimumleeftijd die Creativ'Network heeft ingesteld.",   
        
        
        sas3: "Zodra je sollicitatie en leeftijdscontrole zijn goedgekeurd, word je ingepland voor een online trainingssessie via Skype.|Tijdens de training geven de teamleiders van Creativ'Network begeleiding en instructies over hoe je je rol als Quality Supervisor effectief kunt uitvoeren. Ze behandelen het bedrijfsbeleid, best practices en leiderschaps- en communicatiestrategieën om uit te blinken in je functie.",
        tltext: "Geef leiding, inspiratie en begeleiding aan chatoperators en stimuleer uitzonderlijke verbindingen en ervaringen op het gebied van online dating bij Creativ'Network.",
        supervisortext: "Houd toezicht op de kwaliteit van dating chatdiensten bij Creativ'Network. Leid operators naar uitmuntendheid en zorg voor positieve gebruikerservaringen op het gebied van online dating.",

        webdevtext: "Sluit je aan bij Creativ'Network als webontwikkelaar en creëer innovatieve digitale oplossingen. Draag bij aan ons dynamische team en geef vorm aan de toekomst van online verbindingen.",
        digmartext: "Verhoog je online aanwezigheid bij Creativ'Network als Digital Marketeer. Stimuleer betrokkenheid, geef vorm aan strategieën en draag bij aan dynamische marketinginitiatieven. Sluit je bij ons aan!",
        webdestext: "Geef vorm aan digitale ervaringen bij Creativ'Network als webdesigner. Breng creativiteit tot leven, maak visueel verbluffende websites voor verbeterde online connecties.",

        qstesttitle: "Quality supervisor testformulier",
        suptitle: "Test om Quality Supervisor te worden bij Creativ'Network",
        suptest: "Kwaliteit supervisor test",

        tltest: "Teamleidertest",
        tltestform: ' Testformulier teamleider',
        tltesttitle: "Test om teamleider te worden bij Creativ'Network",
        tltestint1: "De Teamleiderstest van Creativ'Network beoordeelt je vaardigheid in leiderschap, communicatie, prestatiemanagement, innovatie, teamontwikkeling, kwaliteitsborging, samenwerking en aanpassingsvermogen.",
        suptestint1: "De Quality Supervisor-test van Creativ'Network beoordeelt je vaardigheden op het gebied van leiderschap, communicatie, probleemoplossing, kwaliteitsborging, training, innovatie, samenwerking en aanpassingsvermogen.",
        thanktest1: "Bedankt voor het maken van de test",
        finish: "Afwerking",
        all: "Alle",
        loginfailed: "Inloggen mislukt",
        
        usrnmpttrerr: "De gebruikersnaam mag geen speciale tekens bevatten",
        pobxpttrerr: "De postbus mag geen speciale tekens bevatten",
        phnpttrerr: "Het telefoonnummer moet een internationaal formaat hebben. Voorbeeld: +123456789012",
        skpidpttrerr: "Ongeldige skype-identifier",
        brthdtptrerr: "Ongeldig formaat geboortedatum",
        usrnmlngtherr: `De gebruikersnaam moet minstens ${usernameminlength} tekens bevatten.`,
        smthngwntwrng: "Er is iets fout gegaan. Probeer het opnieuw",
        failed: "Falen",   
        subsuccess: "Met succes ingeschreven",
        subfailed: "Al ingeschreven",
        mlunqerr: "Dit e-mailadres is al in gebruik",
        usrnmunqerr: "Deze gebruikersnaam is al in gebruik",
        iderr: "Ongeldige e-mail / gebruikersnaam",
        mdperr: "Ongeldig wachtwoord",
        svsucc: "Informatie succesvol bijgewerkt.",
        
        mdpnid: "Het nieuwe wachtwoord en het bevestigingswachtwoord zijn niet identiek",
        curmdperr: "Het huidige wachtwoord is onjuist",
        entryrml: "Voer uw e-mailadres in",
        entrynwpss: 'Voer uw nieuwe wachtwoord in',
        rcpcpt: "Uw account ophalen",

        chckrsm: "Bekijk uw huidige cv",
        nyrsm: "Nog geen cv geleverd",
        chckpi: "Zie uw huidige identiteitsbewijs",
        chckpa: "Bekijk uw huidige bewijs van adres",
        chcksl: "Bekijk uw huidige selfie",
        chckc: "Zie uw huidige ondertekende huidige contract",
        nypi: "Nog geen identiteitsbewijs verstrekt",
        nypa: "Nog geen bewijs van adres geleverd",
        nysl: "Nog geen selfie verstrekt",
        nyc: "Nog geen getekend lopend contract aangeleverd",
        rsm: "CV",
        pi: "Identiteitsbewijs",
        pa: "Bewijs van adres",
        slfi: "Selfie",
        opnngclsngdterr: "De openingsdatum moet eerder zijn dan de sluitingsdatum",
        adddsccsflly: "Succesvol toegevoegd",
        dltd: "Verwijderd",
        rstr: "Herstel",
        see: "Zie", 

        succ: "Blog succesvol aangemaakt",
        cat: "Categorie ongeldig",
        accref: "Toegang geweigerd",
        lang: "Taal niet gevonden",

        catsucces: "Categorie succesvol aangemaakt",
        catexst: "Reeds bestaande categorie",
        crtadminfrmmbr: "Van bestaand lid maken",
        success: "Succes",
        tktst: "Doe de test",
        expteam: "Heb je ervaring in het leiden van een team?",
        expsup: "Heb je ervaring in het begeleiden van chatoperators?",
        avlbl: "Beschikbaar",
        fllppfrm: "Vul het aanvraagformulier in",
        lljbs: "Alle banen",
        pnjbs: "Vacatures in dienst",
        dfndt: "Bepaalde datum",
        indfndt: "Onbepaalde datum",

nonaut: "Alleen de auteur van het blog kan dit blog verwijderen en herstellen",
adminsuccess: "Deze persoon is nu een beheerder",
        rolenonaut: "Rol niet geautoriseerd",
        djadmin: "Deze persoon is al een beheerder",
        usrntfnd: "Geen gebruiker gevonden",
        newbloggersucc: "Deze persoon is nu een blogger",
        djblog: "Deze persoon is al een blogger",
        nw: "Nieuw",
        bfrsbmttng: "Voor het indienen",
        upldrsmpdf: "Upload uw cv",
        umstupldyrcv: "Je moet je cv uploaden" ,   
    
        gndr: "Geslacht",
        citydep: "Stad / Afdeling",
        pobox: "POSTBUS",
        crtd: "Aangemaakt",
        dcnnctd: "Disconnected",
        sbntfnd: "Abonnee niet gevonden",       
        sspndd: "Geschorst",
        susp: "Je account is momenteel opgeschort en zal worden gereactiveerd op ", 
        reak: "",
        svd: "Opgeslagen",
tstalrddn: "Je hebt al gesolliciteerd op deze functie",
        gvlgn: "kenmerk een login",
        frmtdcnntrv: "Trainingsdocument niet gevonden",
        sndmaildocincomp: "E-mail verzenden over onvolledige documenten",
        vldtinteg: "Toevoegen aan trainingslijst",
        nshdtrnng: "Training beëindigd",
        cnfgtrnng: "Training configureren",
        sndml: "Stuur e-mail",
        mrksfnshd: "Markeren als voltooid",
        bn: "E-mail verboden",
        cypm: "Kies uw betalingsmethode",
        aas3: "Lijst van teamleiders ",
        tllgn: "Login teamleider",
        aas4: "Teamleider toevoegen",
        createnewtl: "Nieuwe teamleider maken",
         strthr: "Begintijd",
        dnhr: "Eindtijd",
        chngpay: "Als u uw betalingsgegevens wilt wijzigen, neem dan contact op met uw Teamleider of uw Manager.",
        idm: "Manager ID",
        mdboard: "Manager dashboard",
        manager: "Manager",
        mrk: "Markeer als gelezen",
agntslst: "Lijst van agenten",
aas5: "Lijst van managers",
aas6: "Manager toevoegen",
createnewmn: "Nieuwe Manager aanmaken",
pdf: "Alleen PDF-indeling wordt geaccepteerd",
        agntnm: "Naam agent",
        agntpass: "Wachtwoord agent",
        asn2: "Sociaal netwerk toevoegen",

    },
        
        
    { // es
       cn: "Creativ'Network",
        french: 'Francés',
        english: 'Inglés',
        italian: 'Italiano',
        dutch: 'Holandés',
        spanish: 'Español',
        german: 'Alemán',
        usermanual: "Manual del usuario",

        formulaire: 'Formulario de candidatura',
        firstname: 'Nombre de pila',
        lastname: 'Apellido',
        email: 'Dirección de correo electrónico',
        birthday: 'Fecha de nacimiento',
        phone: 'Teléfono',
        skypename: 'Usuario de Skype',
        whichchatlanguage: 'A qué idioma deseas aplicar?',
        timesavailable: 'A que hora estás disponible vía telefónica?',
        expchatmod: 'Tienes experiencia moderando chats?',
        hearabout: '¿Como supiste de nosotros?',
        tellus: 'Por favor cuéntanos un poco de ti y por qué deberíamos contratarte!',
        submitapp: 'Enviar',
        yes: 'Si',
        no: 'No',
        friends: 'Amigos',
        from: 'De',
        to: 'a',

        required: 'Este campo es obligatorio',
        invalidemail: 'Correo electrónico no válido',

        loginfailusername: 'Nombre de usuario no reconocido',
        loginfailpass: 'Contraseña no válida',
        login: 'Conexión',
        username: 'Nombre de usuario',
        password: 'Contraseña',
        test: 'Formulario de prueba de moderador de chat en español',
        situation: 'Situación',
        send: 'Enviar',
        qreturn: '¿De verdad quieres dejar la prueba?',

        cointint: "¿Quieres trabajar en casa como un operador de chat de citas con",
        coint1: "Bonito pago: Gana de 0,12€ a 0,15€ por mensaje enviado con Creativ'Network",
        coint2: "Trabajo a distancia: Disfruta de la flexibilidad de trabajar desde casa",
        coint3: 'Habilidades de comunicación: Mejora tu calidad en empatía, comprensión activa y fluidez en la comunicación',
        coint4: 'Equilibrio trabajo-vida privada: Gestiona mejor tu tiempo con horarios flexibles',
        applynow: 'Solicitar ahora',

        coabint1: "¿En qué consiste el trabajo de ",
        coabint2: "para citas?",
        chatoperator: "operador de chat",
        coab1: "Conectar con personas solitarias: Muchos usuarios de plataformas de citas buscan compañía o alguien con quien hablar debido a sentimientos de soledad. Los operadores de chat ofrecen un oído atento y apoyo emocional.",
        coab2: "Comunicación basada en texto: El principal modo de comunicación es a través de mensajes de texto escritos en la plataforma. Los operadores mantienen conversaciones continuas con los usuarios.",
        coab3: "Temas de conversación variados: Los operadores de chat entablan conversaciones con los usuarios sobre una amplia gama de temas, desde asuntos cotidianos como deportes y el tiempo hasta temas más personales e íntimos, como sueños, fantasías y otras áreas de interés.",
        coab4: "Enfoque abierto: Este trabajo suele requerir una actitud abierta y sin prejuicios, ya que los usuarios pueden tener intereses, experiencias y deseos diversos.",
        coab5: "Proporcionar compañía: Los operadores desempeñan un papel a la hora de proporcionar una sensación de compañía y conexión a los usuarios que pueden estar buscando apoyo emocional o simplemente quieren alguien con quien hablar",
        coab6: 'Anónimo: Los operadores de chat trabajarán de forma 100% anónima. Nuestra identidad nunca será revelada a nadie en ningún sitio web de citas',
        coab7: "Independencia y flexibilidad: Los operadores de chat trabajarán a su conveniencia",

        home:"Inicio",
        ourmissions: "Nuestras misiones",
        company: "Valores",
        ourcompany: "Nuestros valores",
        ouradvantages: "Nuestras ventajas",
        jobs: "Empleos",

        slog: '"Donde la creatividad se encuentra con la oportunidad"',
        copyright: "©2023-2024 copyright Creativ'Network. Todos los derechos reservados",
        foexpl: "Creativ'Network, la plataforma innovadora que conecta talentos web con puestos orientados al futuro, dando forma al mundo digital. Descubre tu potencial, prospera en moderación web, desarrollo, marketing, big data y mucho más",
        focontact1: "Dirección principal: 71-75 Shelton Street, Covent Garden, Londres, WC2H 9JQ",
        focontact2: "Representante de la oficina de Bélgica: Roodborstjesstraat 4, Wondelgem 9032",
        focontact3: "contact@creativ-network.net",
        subscribe: "suscríbase",
        agree1: "Sí, estoy de acuerdo con la",
        agree2: "y los ",
        agreelink1: " política de privacidad",
        agreelink2: "términos y condiciones",
        useful: "útiles",
        links: "Enlaces",
        oursing: "Nuestro",
        contact: "contacto",
        subscribetoour: "Suscríbase a nuestro",
        newsletter: "boletín",

        consents: "Consentimiento",
        termsAgree1: "Sí, estoy de acuerdo con la",
        termsAgree2: "y los ",
        termsAgreeLink1: "política de privacidad",
        termsAgreeLink2: "términos y condiciones",
        termsAgreeLink2real: "condiciones de uso",
        consentsErr: "Debe aceptar la política de privacidad y los términos y condiciones para suscribirse.",
        
        coscint1: "¿Cómo se calculará el salario de",
        coscint2: "?",
        cosc1: "Pago base por mensaje: El operador suele cobrar una cantidad predeterminada por cada mensaje enviado. El pago base por mensaje enviado es de 0,12€.",
        cosc2: "Recuento mensual de mensajes: Al final del mes, se calcula el número total de mensajes enviados por el operador.",
        cosc3: "Cálculo del pago: Las ganancias del operador para el mes se calculan multiplicando el recuento total de mensajes por el pago base por mensaje. Por ejemplo, si envía 5.000 mensajes, ganará 600 euros (5.000 mensajes * 0,12 euros por mensaje). Con Creativ'Network, un operador puede ganar fácilmente al mes 1.200€ con 10K mensajes enviados.",
        cosc4: "Bonificación por rendimiento: En algunos casos, los operadores con un rendimiento excepcional pueden recibir una bonificación además de su comisión. La bonificación podría basarse en criterios como el número de clientes satisfechos, el tiempo de respuesta o los comentarios positivos de los usuarios.",
        cosc5: "Bonificación de Navidad: Como incentivo adicional, Creativ'Network ofrece una bonificación de Navidad a nuestros operadores que lleven más de 2 años en la empresa.",

        corint1: "¿Cuáles son los requisitos para ser operador de chat de ",
        corint2: "?",
        cor11: "Dominio del español",
        cor12: "El dominio del español es un requisito, ya que se espera que los operadores de chat se comuniquen con los usuarios en español de forma eficaz.",
        cor21: "Límite de edad",
        cor22: "Los candidatos deben tener al menos 18 años para poder optar a este puesto.",
        cor31: "Cuenta bancaria o cuenta de pago",
        cor32: "Deberías tener una cuenta bancaria, PayPal, Wise o Yoursafe a tu nombre. Es probable que sea para procesar pagos.",
        cor41: "Acceptance of the confidentiality statement and contractual conditions",
        cor42: "Los solicitantes deben estar dispuestos a aceptar y adherirse a la declaración de privacidad y a las condiciones contractuales de Creativ'Network, que a menudo describen las responsabilidades, directrices y código de conducta de los operadores de chat.",
        cor51: "Responsabilidad de los equipos",
        cor52: "Se espera que dispongas del equipo necesario, incluido un ordenador portátil o PC, una conexión fiable a internet y una fuente de reserva de electricidad e internet para garantizar un trabajo ininterrumpido.",
        cor61: "Requisito de mensaje mínimo",
        cor62: "Normalmente se exige a los operadores de chat que envíen un número mínimo de mensajes a la semana. En este caso, se espera un mínimo de 250 mensajes salientes por semana.",
        cor71: "Horas semanales mínimas",
        cor72: "Aunque trabajes por tu cuenta, deberías trabajar al menos 4 horas al día y 20 horas semanales a tu conveniencia.",
        cor81: "Impuesto",
        cor82: "Como trabajador autónomo, será responsable de sus propios impuestos.",
        cor91: "Cumplimiento de las directrices legales y reglamentarias: ",
        cor92: "Todo operador de chat debe seguir las normas que le indique el jefe de equipo durante la formación.",

        coicint1: "¿Qué necesita",
        coicint2: " para comprobar su identidad?",
        coic1: "Verificación de edad: Por diversas razones, los sitios web y las plataformas de citas suelen tener restricciones de edad para garantizar el cumplimiento de la normativa legal. Al comprobar tu identidad, Creativ'Network puede verificar que cumples el requisito de edad mínima, es decir, 18 años, para utilizar nuestros servicios. Esto ayuda a la plataforma a cumplir las normas legales, proteger a los menores y mantener una comunidad responsable y segura.",
        coic2: "Seguridad y confianza: La verificación de la identidad mejora la seguridad y la fiabilidad de la plataforma. Garantiza a los usuarios que Creativ'Network está interactuando con personas que son quienes dicen ser, lo cual es esencial para generar confianza y garantizar un entorno seguro para este puesto.",
        coic3: "Documentos para la verificación: Para cumplir con la normativa legal y garantizar que los candidatos tienen la edad adecuada, Creativ'Network solicitará la verificación de la edad. Documentos como el carné de conducir o el DNI suelen incluir información sobre la fecha de nacimiento, por lo que son adecuados para confirmar que cumples el requisito de edad mínima establecido por la plataforma.",

        coasint1: "3 sencillos pasos para solicitar el puesto en",
        coasint2: "",
        coas11: "Solicitud sencilla y prueba de aptitudes",
        coas12: ["Empieza por visitar la página web oficial o el portal de empleo de Creativ'Network", "Busca la oferta de empleo para el puesto de operador de chat para citas y haz clic en \"Solicitar\"", "Rellena el formulario de solicitud, que incluye tus datos personales, información de contacto, experiencia relevante y tu motivación para trabajar con nosotros", "Se te pedirá que completes una breve prueba de aptitudes, que suele durar unos 10 minutos. Esta prueba evalúa su capacidad para participar en conversaciones basadas en el chat y demostrar sus habilidades comunicativas"],
        coas21: "Verificación de identidad para confirmación de edad",
        coas22: ["Después de completar con éxito la solicitud inicial y la prueba de aptitudes, se le pedirá que pase por un proceso de verificación de identidad para confirmar su edad", "Se le pedirá que presente una copia de un documento de identidad válido emitido por el gobierno, como un pasaporte, permiso de conducir o tarjeta de identificación del gobierno. Este documento se utiliza para verificar que cumple el requisito de edad mínima especificado para el puesto"],
        coas31: "Formación en línea a través de Skype",
        coas32: ["Una vez aprobadas tu solicitud y la verificación de tu edad, se te programará una sesión de formación en línea a través de Skype", "Durante la formación, los jefes de equipo de Creativ'Network te proporcionarán orientación e instrucciones sobre cómo desempeñar eficazmente tu función como operador de chat para citas. Cubrirán las políticas de la empresa, las mejores prácticas y estrategias de comunicación para sobresalir en su posición"],

        coafint: "Formulario de solicitud de",
        ppint: ["En Creativ-Network, accesible en https://creativ-network.net, una de nuestras principales prioridades es la privacidad de nuestros visitantes. Este documento de política de privacidad contiene los tipos de información que recoge y registra Creativ-Network y cómo la utilizamos", "Si tiene alguna pregunta adicional o necesita más información sobre nuestra política de privacidad, no dude en ponerse en contacto con nosotros a través del correo electrónico data@creativ-network.net", "Esta política de privacidad se aplica únicamente a nuestras actividades en línea y es válida para los visitantes de nuestro sitio web en lo que respecta a la información que comparten y/o recogen en Creativ-Network. Esta política no es aplicable a ninguna información recopilada fuera de línea o a través de canales distintos de este sitio web."],
        pp1t: ["1 - Consentimiento", ["Al utilizar nuestro sitio web, usted acepta nuestra Política de privacidad y está de acuerdo con sus términos"]],
        pp2t: ["2 - Información que recopilamos", ["La información personal que se le pide que proporcione, y las razones por las que se le pide que la proporcione, se le aclararán en el momento en que le pidamos que proporcione su información personal. ", "Si se pone en contacto con nosotros directamente, es posible que recibamos información adicional sobre usted, como su nombre, dirección de correo electrónico, número de teléfono, el contenido del mensaje y/o los archivos adjuntos que nos envíe y cualquier otra información que decida facilitarnos", "Cuando se registre en una Cuenta, es posible que le pidamos su información de contacto, incluidos datos como su nombre, nombre de la empresa, dirección, dirección de correo electrónico y número de teléfono."]],
        pp3t: ["3 - Cómo utilizamos su información", ["Utilizamos la información que recopilamos de varias maneras, entre ellas para: ", "• Proporcionar, operar y mantener nuestro sitio web", "• Mejorar, personalizar y ampliar nuestro sitio web", "• Comprender y analizar cómo utiliza nuestro sitio web", "• Desarrollar nuevos productos, servicios, características y funcionalidad", "• Comunicarnos con usted, ya sea directamente o a través de uno de nuestros socios, incluso para el servicio al cliente, para proporcionarle actualizaciones y otra información relacionada con el sitio web, y para fines de marketing y promoción", "• Enviarle correos electrónicos", "• Encontrar y prevenir el fraude."]],
        pp4t: ["4 - Archivos de registro", ["Creativ-Network sigue un procedimiento estándar de utilización de archivos de registro. Estos archivos registran a los visitantes cuando visitan los sitios web. Todas las empresas de alojamiento lo hacen y forman parte de los análisis de los servicios de alojamiento. La información recogida por los archivos de registro incluye direcciones de protocolo de Internet (IP), tipo de navegador, proveedor de servicios de Internet (ISP), sello de fecha y hora, páginas de referencia/salida y, posiblemente, el número de clics. Estos datos no están vinculados a ninguna información que permita la identificación personal. El propósito de la información es analizar tendencias, administrar el sitio, rastrear el movimiento de los usuarios en el sitio web y recopilar información demográfica."]],
        pp5t: ["5 - Cookies y Web Beacons", ["Como cualquier otro sitio web, Creativ-Network utiliza 'cookies'. Estas cookies se utilizan para almacenar información, incluidas las preferencias de los visitantes y las páginas del sitio web a las que el visitante accedió o visitó. La información se utiliza para optimizar la experiencia de los usuarios personalizando el contenido de nuestra página web en función del tipo de navegador de los visitantes y/u otra información."]],
        pp6t: ["6 - Políticas de Privacidad de los Socios Publicitarios", ["Puedes consultar esta lista para conocer la Política de Privacidad de cada uno de los socios publicitarios de Creativ-Network.", "Los servidores o redes publicitarias de terceros utilizan tecnologías como cookies, JavaScript o Web Beacons que se emplean en sus respectivos anuncios y enlaces que aparecen en Creativ-Network, los cuales se envían directamente al navegador de los usuarios. Cuando esto ocurre, reciben automáticamente su dirección IP. Estas tecnologías se utilizan para medir la eficacia de sus campañas publicitarias y/o para personalizar el contenido publicitario que usted ve en los sitios web que visita", "Tenga en cuenta que Creativ-Network no tiene acceso ni control sobre estas cookies que son utilizadas por terceros anunciantes."]],
        pp7t: ["7 - Políticas de privacidad de terceros", ["La Política de privacidad de Creativ-Network no se aplica a otros anunciantes o sitios web. Por lo tanto, le aconsejamos que consulte las respectivas Políticas de Privacidad de estos servidores de anuncios de terceros para obtener información más detallada. Puede incluir sus prácticas e instrucciones sobre cómo optar por no participar en determinadas opciones. Puede encontrar una lista completa de estas Políticas de Privacidad y sus enlaces aquí: Privacy Policy Links.", "Puede optar por desactivar las cookies a través de las opciones individuales de su navegador. Para conocer información más detallada sobre la gestión de cookies con navegadores web específicos, puede encontrarla en las respectivas páginas web de los navegadores. ¿Qué son las cookies?"]],
        pp8t: ["8 - Política de privacidad de la CCPA (No venda mis datos personales)", ["En virtud de la CCPA, entre otros derechos, los consumidores de California tienen derecho a:", "Solicitar que una empresa que recopile datos personales de un consumidor revele las categorías y los elementos específicos de los datos personales que una empresa ha recopilado sobre los consumidores", "Solicitar que una empresa elimine cualquier dato personal sobre el consumidor que una empresa haya recopilado", "Solicitar que una empresa que venda datos personales de un consumidor no venda datos personales del consumidor." , "Solicitar que una empresa elimine cualquier dato personal sobre el consumidor que una empresa haya recopilado", "Solicitar que una empresa que venda datos personales de un consumidor, no venda los datos personales del consumidor", "Si realiza una solicitud, disponemos de un mes para responderle. Si desea ejercer alguno de estos derechos, póngase en contacto con nosotros."]],
        pp9t: ["9 - Política de privacidad GDPR (Derechos de protección de datos)", ["Queremos asegurarnos de que conoce perfectamente todos sus derechos de protección de datos. Todo usuario tiene derecho a lo siguiente:", "Derecho de acceso - Tiene derecho a solicitar copias de sus datos personales. Puede que le cobremos una pequeña cantidad por este servicio", "Derecho de rectificación - Tiene derecho a solicitar que corrijamos cualquier información que considere inexacta. También tiene derecho a solicitar que completemos la información que considere incompleta.", "Derecho de supresión - Tiene derecho a solicitar que borremos sus datos personales, en determinadas condiciones.", "Derecho a restringir el tratamiento - Tiene derecho a solicitar que restrinjamos el tratamiento de sus datos personales, en determinadas condiciones. ", "Derecho a oponerse al tratamiento - Tiene derecho a oponerse al tratamiento de sus datos personales, en determinadas condiciones", "Derecho a la portabilidad de los datos - Tiene derecho a solicitar que transfiramos los datos que hemos recopilado a otra organización, o directamente a usted, en determinadas condiciones", "Si realiza una solicitud, disponemos de un mes para responderle. Si desea ejercer alguno de estos derechos, póngase en contacto con nosotros."]],
        pp10t: ["10 - Información para niños", ["Otra parte de nuestra prioridad es añadir protección para los niños mientras usan Internet. Animamos a los padres y tutores a que observen, participen y/o supervisen y guíen su actividad en línea", "Creativ-Network no recopila a sabiendas ningún tipo de información personal identificable de niños menores de 13 años. Si cree que su hijo ha proporcionado este tipo de información en nuestro sitio web, le recomendamos encarecidamente que se ponga en contacto con nosotros inmediatamente y haremos todo lo posible para eliminar rápidamente dicha información de."]],

        lastupdated: "Última actualización",
        tcint: ["Por favor, lea atentamente los siguientes Términos y Condiciones antes de utilizar el sitio web de Creativ'Network."],
        tc1t: ["1. Aceptación de los términos", ["Al acceder o utilizar el sitio web de Creativ'Network, usted acepta cumplir y estar obligado por estos Términos y Condiciones. Si no está de acuerdo con alguna parte de estas condiciones, no utilice nuestro sitio web."]],
        tc2t: ["2. Elegibilidad del usuario", ["Debes tener al menos 18 años para utilizar nuestro sitio web. Al utilizar nuestros servicios, confirmas que cumples este requisito de edad."]],
        tc3t: ["3. Uso del sitio web", ["- Usted se compromete a utilizar el sitio web de conformidad con todas las leyes y reglamentos aplicables", "- Usted no participará en ninguna actividad ilegal, perjudicial o abusiva en nuestra plataforma", "- Usted se compromete a no suplantar a otros ni crear identidades falsas."]],
        tc4t: ["4. Privacidad y tratamiento de datos", ["Nuestra Política de Privacidad describe cómo recogemos, utilizamos y protegemos sus datos personales. Al utilizar nuestro sitio web, usted acepta las prácticas relativas a los datos descritas en nuestra Política de Privacidad."]],
        tc5t: ["5. Propiedad intelectual", ["Todo el contenido de nuestro sitio web, incluidos textos, gráficos, logotipos y software, está protegido por derechos de propiedad intelectual. No puede utilizar, reproducir o distribuir nuestro contenido sin nuestro permiso explícito."]],
        tc6t: ["6. Contenido generado por el usuario", ["Cuando contribuyes con contenido a nuestra plataforma, nos concedes una licencia no exclusiva y libre de regalías para usar, modificar y distribuir tu contenido."]],
        tc7t: ["7. Cambios en las Condiciones", ["Podemos actualizar estas Condiciones en cualquier momento. Su uso continuado del sitio web después de dichos cambios indica su aceptación de los términos revisados."]],
        tc8t: ["8. Renuncias y limitaciones de responsabilidad", ["Proporcionamos nuestro sitio web \"tal cual\" y no garantizamos su exactitud, disponibilidad o funcionamiento. No seremos responsables de ningún daño directo, indirecto o consecuente derivado del uso de nuestro sitio web"]],
        tc9t: ["9. Ley aplicable y jurisdicción", ["Estos términos y su uso de nuestro sitio web se rigen por las leyes del Reino Unido. Cualquier disputa se resolverá en los tribunales del Reino Unido"]],
        tc10t: ["10. Información de contacto", ["Si tiene alguna pregunta o duda sobre estas condiciones, póngase en contacto con nosotros en contact@creativ-network.net."]],

        jobsint1: "Elige el trabajo que se adapte a tus habilidades",
        jobsint2: "Live a great experience with an amazing team!",
        jobsat: "Empleo en ",

        hirenow: "¡CONTRATA AHORA!",
        closed: "CERRADO",
        detailsandapply: "Ver detalles y solicitar",
        appclosed: "Solicitud cerrada",

        jobco: ["Operador de chat de citas", "Te apasiona el chat y el trabajo de administrador de sitios y comunidades de citas."],
        jobwdev: ["Desarrollador web", "Te apasiona el chat y el trabajo en sitios y comunidades de citas."],
        jobtl: ["Jefe de equipo", "Te apasiona el chat y el trabajo en sitios y comunidades de citas."],
        jobdm: ["Comercializador digital", "Te apasiona el chat y el trabajo de administrador de sitios y comunidades de citas."],
        jobsup: ["Supervisor", "Te apasiona el chat y el trabajo en sitios y comunidades de citas."],
        jobwdes: ["Diseñador web", "Te apasiona el chat y el trabajo en sitios y comunidades de citas."],
        
        cotest: "Prueba de operador de chat",
        cotesttitle: "Prueba para ser operador de chat de citas en Creativ'Network",
        cotestint1: `"El test de Operador de Chat para Citas de Creativ'Network evalúa las habilidades comunicativas, la empatía y la capacidad de compromiso para una interacción eficaz y respetuosa con el usuario."`,
        cotestint2: "Tómese unos minutos para responder a todas las situaciones antes de seguir adelante con su solicitud.",
        goodluck: "Buena suerte.",
        number: "N°",
        youranswer: "Su respuesta",
        next: "Siguiente",
        back: "Volver",
        blog: "Blog",
        stat: "Estadísticas",
        at: "En",
        ourstat: ", nuestras estadísticas reflejan claramente nuestro éxito y crecimiento. Con un equipo entregado, numerosos proyectos, una amplia red de socios y años de experiencia, somos una fuerza pujante en el sector. Profundicemos en los detalles para descubrir nuestra trayectoria.",
        team: "Miembros del equipo",
        projects: "Proyectos",
        netpart: "Socios de la red",
        years: "Años de actividad",
        testimonial: "Testimonio",
        dedicated:"Los miembros del equipo de",
        sharing: "comparten sus experiencias, conocimientos y entusiasmo por un lugar de trabajo gratificante en el que prospera la colaboración.",
        testimonialperson1: ["Marie C", "Operadora de chat para citas", `"Trabajar como operador de chat para citas en Creativ'Network es increíble. Tengo la oportunidad de conectar con gente y marcar la diferencia en sus vidas cada día. Es gratificante y divertido."`], 
        testimonialperson2: ["Lucas P", "Operadora de chat para citas", `"Creativ'Network es el lugar perfecto para abrazar tu pasión por ayudar sin sentirte solo. El equipo es fantástico y el ambiente es de apoyo. Me encanta lo que hago."`],
        testimonialperson3: ["Sophie M", "Operadora de chat para citas", `"Creativ'Network ofrece una plataforma única para crear conexiones significativas. Como operador de Dating Chat, siento que formo parte de algo especial, hablando con la gente y haciéndola feliz."`],
        testimonialperson4: ["Alex R", "Desarrollador Web Front End", `"Creativ'Network me supone un reto como desarrollador web. Los proyectos innovadores y el apoyo del equipo hacen que mis habilidades alcancen nuevas cotas. Estoy orgulloso de formar parte de este dinámico entorno tecnológico."`],
        testimonialperson5: ["Emma H", "Desarrollador Web Back End", `"He encontrado el trabajo de mis sueños como desarrollador web en Creativ'Network. El compromiso de la empresa con la tecnología punta y las oportunidades de crecimiento me mantienen inspirado y entusiasmado."`],
        testimonialperson6: ["Tommy J", "Diseñador web", `"Como diseñador web en Creativ'Network, tengo libertad creativa para dar forma a plataformas visualmente atractivas. Es un lugar inspirador que valora la estética del diseño y la experiencia del usuario, lo que hace que el trabajo sea realmente agradable."`],

        titlesuffix: " - Creativ-Network",
        titlejobs: "Empleo",
        titlechatoperator: "Operador de chat",
        titletest: "Prueba",
        titlepp: "Política de privacidad",
        titleterms: "Términos",

        wantto: "Quieren convertirse en trabajadores realizados con ",
        areyou: "¿Te apasiona el trabajo web? ¿Tienes experiencia en alguna de las siguientes áreas: chat, desarrollo web, marketing digital, etiquetado de datos, teleasistencia y relación con el cliente, televenta, copy writing, teleprospección, gestión de redes sociales, RRHH, contabilidad, diseño web...? ? ¡Únete ya a nuestra comunidad! ",        check: 'Check all jobs and apply',

        our: 'Nuestras',
        missions: 'misiones',
        advantages: 'Ventajas',


        mission1: 'Identificación y adquisición de talentos: La empresa busca y contrata activamente a personas con experiencia en dominios relacionados con la web. Esto implica identificar y atraer a los mejores talentos de diversas fuentes, incluidos anuncios de empleo, redes en línea y referencias.',
        mission2: "Evaluación y selección de aptitudes: Creativ'Network evalúa rigurosamente las aptitudes y cualificaciones de los candidatos potenciales para garantizar que cumplen los requisitos específicos de la empresa para las funciones que están contratando.",
        mission3: "Adecuación cultural y alineación de valores: Más allá de las aptitudes, la empresa evalúa a los candidatos por su alineación con los valores y la cultura de la empresa para fomentar un entorno de trabajo cohesionado y productivo.",
        mission4: "Personalización de puestos: Creativ'Network adapta los puestos a los puntos fuertes y habilidades del talento contratado, garantizando que las funciones se ajustan a su experiencia e intereses.",
        mission5: "Desarrollo y retención del talento: La empresa se compromete a cultivar y retener el talento que contrata ofreciendo oportunidades de crecimiento y avance dentro de la organización.",
        mission6: "Análisis y estrategia de mercado: Creativ'Network mantiene el pulso de las tendencias del sector, las referencias salariales y la evolución de los requisitos de cualificación para seguir siendo competitivos en el mercado laboral relacionado con la web.",
        mission7: "Diversidad e inclusión: La empresa puede promover activamente la diversidad y la inclusión en su propio proceso de adquisición de talento, esforzándose por crear una plantilla que refleje una amplia gama de orígenes y perspectivas.",
        mission8: "Colaboración y formación cruzada: La empresa fomenta la colaboración entre sus talentos y ofrece oportunidades de formación cruzada, lo que permite a los empleados diversificar sus competencias y contribuir a diversos aspectos del negocio.",
        mission9: "Adaptación a la evolución de las necesidades: Creativ'Network se mantiene flexible y se adapta a las demandas cambiantes de los campos relacionados con la web en los que opera. Esto incluye la adquisición de nuevos talentos y competencias a medida que evolucionan la tecnología y las tendencias del sector.",
        mission10: "Mejora continua: La empresa revisa y perfecciona constantemente sus procesos de contratación y gestión del talento para garantizar su eficiencia y eficacia.",
        mission11: "Satisfacción y bienestar de los empleados: Creativ'Network hace especial hincapié en la satisfacción de los empleados, ofreciéndoles prestaciones, conciliación de la vida laboral y personal y apoyo para mejorar el bienestar general de su plantilla.",

        value1: "Excelencia: Nos esforzamos por alcanzar la excelencia en todo lo que hacemos, desde la contratación de los mejores talentos hasta la prestación de servicios excepcionales a nuestros clientes.",
        value2: "Enfoque centrado en el cliente: Ponemos a nuestros clientes en el centro de nuestras operaciones, entendiendo sus necesidades únicas y proporcionando soluciones a medida que impulsan su éxito.",
        value3: "Potenciación del talento: Potenciamos a los talentos de la web para que prosperen en sus carreras profesionales poniéndolos en contacto con oportunidades que se ajusten a sus habilidades y aspiraciones.",
        value4: "Innovación y adaptabilidad: Adoptamos la innovación y nos adaptamos al panorama en constante evolución de los campos relacionados con la web, garantizando que nos mantenemos a la vanguardia de las tendencias del sector.",
        value5: "Diversidad e inclusión: Celebramos la diversidad y fomentamos un entorno integrador en el que personas de todos los orígenes puedan contribuir a nuestro éxito colectivo.",
        value6: "Transparencia e integridad: Defendemos los más altos estándares de integridad y mantenemos una comunicación transparente con nuestros clientes, candidatos y miembros del equipo.",
        value7: "Colaboración y sinergia: Creemos en el poder de la colaboración, trabajando juntos para lograr objetivos compartidos e impulsar resultados positivos.",
        value8: "Aprendizaje continuo: Fomentamos el aprendizaje continuo y el desarrollo profesional, garantizando que nuestro equipo esté equipado con las últimas habilidades y conocimientos.",
        value9: "Experiencia en el sector: Somos expertos en el sector, ofrecemos una valiosa perspectiva del mercado laboral relacionado con la web y compartimos nuestros conocimientos con clientes y candidatos.",
        value10: "Conducta ética: Actuamos con una conducta ética inquebrantable, tratando a todas las personas con respeto, imparcialidad y profesionalidad.",
        value11: "Compromiso con la comunidad: Nos comprometemos activamente con la comunidad profesional, contribuyendo a su crecimiento mediante la tutoría, la educación y la participación en asociaciones del sector.",
        value12: "Impacto y responsabilidad: Asumimos la responsabilidad del impacto que tenemos en las carreras profesionales de las personas y en el éxito de las organizaciones a las que servimos, esforzándonos por obtener resultados positivos y duraderos.",

        advantage1:"Paquetes retributivos competitivos: Ofrecer compensaciones y beneficios por encima de la media o líderes en el sector para atraer y retener talento de alta calidad, lo que puede diferenciar a Creativ'Network de sus competidores.",
        advantage2:"Bonificaciones basadas en el rendimiento: Implantar estructuras de bonificaciones basadas en el rendimiento puede motivar a los empleados a sobresalir y garantizar que se les recompensa por sus contribuciones.",
        advantage3:"Planes de retribución personalizados: Adaptar los paquetes retributivos a las competencias, la experiencia y el rendimiento de cada empleado puede aumentar la satisfacción laboral y la fidelidad.",
        advantage4:"Oportunidades de crecimiento profesional: Ofrecer vías claras de promoción y desarrollo profesional dentro de la empresa, lo que puede atraer a profesionales ambiciosos y promover la retención interna del talento.",
        advantage5:"Programas de reconocimiento de empleados: Reconocer y recompensar el rendimiento y los logros sobresalientes de los empleados puede levantar la moral y aumentar los índices de retención.",
        advantage6:"Modalidades de trabajo flexibles: Ofrecer opciones de trabajo flexibles, como trabajo a distancia u horarios flexibles, puede mejorar el equilibrio entre la vida laboral y personal y atraer a un abanico más amplio de candidatos.",
        advantage7:"Amplios paquetes de beneficios: Ofrecer prestaciones competitivas, como seguro médico, planes de jubilación y programas de bienestar, puede atraer a los mejores talentos y promover el bienestar de los empleados.",
        advantage8:"Inversión en formación y desarrollo: Apoyar el aprendizaje continuo y el crecimiento profesional mediante programas de formación y oportunidades educativas puede fomentar una mano de obra altamente cualificada y motivada.",
        advantage9:"Prácticas de compensación transparentes: Mantener una comunicación transparente sobre las prácticas de compensación y los puntos de referencia salariales puede generar confianza y transparencia en la gestión de personal.",
        advantage10:"Programas de asistencia al empleado: Ofrecer programas de asistencia para retos personales y profesionales puede demostrar un compromiso con el bienestar de los empleados y la satisfacción laboral",
        advantage11:"Evaluaciones del rendimiento y feedback: Las evaluaciones periódicas del rendimiento y los comentarios constructivos pueden ayudar a los empleados a comprender su progresión profesional y sus áreas de mejora.",
        advantage12:"Análisis de mercado para salarios competitivos: Realización de análisis de mercado para garantizar que los salarios y las prestaciones sigan siendo competitivos y se ajusten a las normas del sector.",
        advantage13:"Estructuras de incentivos: Desarrollo de estructuras de incentivos creativas que recompensen el rendimiento excepcional, la innovación y las contribuciones a los objetivos de la empresa.",
        
        blog2: "Aquí encontrarás nuestrospuestos",
        ourposts: "Nuestros posts",
        blog3: "Disfruta leyendo nuestros posts",
        datepub: "15/10/2023",
        months: ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"],
        uncategorized: "Sin categoría",

        blognumber1: ["Strada per un paesaggio accattivante", "La nuova lingua comune sarà più semplice e regolare delle lingue europee esistenti. Sarà semplice come l'Occidentale; anzi, sarà Occidentale. A un inglese sembrerà un inglese semplificato, come un mio amico scettico di Cambridge mi ha detto che cos'è l'Occidentale. Le lingue europee fanno parte della stessa famiglia."], 
        blognumber2: ["Gravi problemi con i cavi in città", "La nuova lingua comune sarà più semplice e regolare delle lingue europee esistenti. Sarà semplice come l'Occidentale; anzi, sarà Occidentale. A un inglese sembrerà un inglese semplificato, come un mio amico scettico di Cambridge mi ha detto che cos'è l'Occidentale. Le lingue europee fanno parte della stessa famiglia."], 
        blognumber3: ["Montagna e freddo invernale", "Se diverse lingue si fondono, la grammatica della lingua risultante è più semplice e regolare di quella delle singole lingue. La nuova lingua comune sarà più semplice e regolare delle lingue europee esistenti. Sarà semplice come l'Occidentale; anzi, sarà Occidentale."], 
        by: "por",
        noc: "sin",
        comments: "comentarios",
        comment: "comentarios",
        readmore: "Seguir leyendo",

        cookiestitle: "Gestión del consentimiento de cookies",
        cookiescontent: "La gestión del consentimiento de cookies de Creativ'Network es esencial para una experiencia en línea transparente y conforme a las normas. Buscamos el consentimiento del usuario de una manera clara, proporcionando información detallada sobre nuestras prácticas de cookies. Con opciones de personalización, categorización explícita de cookies y un compromiso con la privacidad de los datos, Creativ'Network crea una experiencia digital segura y respetuosa. Nos adherimos estrictamente a las normas de privacidad, garantizando la seguridad del usuario y la confianza en nuestra plataforma",
        pref: "Preferencias",
        cookiessm1i: "Funcional",
        cookiessm2i: "Estadísticas",
        cookiessm3i: "Marketing",
        cookiessm1c: `"Almacenamiento o acceso técnico" en el sitio web de Creativ'Network implica el uso de cookies para mejorar la experiencia del usuario. Esto incluye la recopilación de datos técnicos esenciales para el correcto funcionamiento del sitio. Buscamos el consentimiento de los usuarios, proporcionando información transparente sobre estas prácticas, de conformidad con las normas y reglamentos vigentes sobre privacidad de datos`,
        cookiessm2c: `Las estadísticas en la gestión del consentimiento de cookies para Creativ'Network permiten recopilar datos analíticos. Desempeñan un papel crucial en la evaluación de los hábitos de navegación de los usuarios, facilitando así la mejora continua del sitio. Esta información orienta las decisiones para optimizar la experiencia del usuario respetando las preferencias de privacidad, garantizando una plataforma personalizada y conforme a las normas`,
        cookiessm3c: `La función "Marketing" en la gestión del consentimiento de cookies en el sitio Creativ'Network nos permite personalizar la publicidad según las preferencias de los usuarios. Al obtener su consentimiento, garantizamos una experiencia publicitaria específica y pertinente. Esto garantiza una comunicación promocional más eficaz respetando las normas de privacidad de datos y las elecciones individuales de los usuarios`,
        savepref: "Guardar preferencias",
        accept: "Aceptar",
        alwyasactive: "Siempre activo",
        error: "Error",
        
        tags: "Etiquetas: Sin etiquetas",
        comment1: "Añadir un comentario",
        comment2: "Su dirección de correo electrónico no será publicada.",
        comment3: "Nombre (obligatorio)",
        comment4: "Correo electrónico o nombre de usuario",
        comment5: "Sitio web",
        comment6: "Guardar mi nombre, correo electrónico y sitio web en este navegador para la próxima vez que comente.",
        comment7: "Comentario",
        follow: "Síguenos",
        us: "",
        creatememberspace: "Creación de su espacio miembro",
        createmsint11: "Introduzca el código que hemos enviado a",
        createmsint12: "para validar su dirección de correo electrónico.",
        createmsint2: "Al validar su dirección de correo electrónico se creará automáticamente su zona de miembro",
        createmsconsent: "Al enviar su solicitud, acepta nuestra",
        confirm: "Confirme",
        codevalidationerror: "El código de validación es incorrecto",
        confirmpassword: "Confirmar contraseña",
        passwordlength: `La contraseña debe contener al menos ${mdpminlength} caracteres`,
        passwordnotidentical: "Las contraseñas no son idénticas",
        welcomeback: "Bienvenido de nuevo",
        signinint: "Para mantenerte en contacto con nosotros, utiliza tus datos personales para iniciar sesión",
        signin: "Iniciar sesión",
        createaccount: "Crear una cuenta",
        oruseyourpersonnalmail: "O utilice su correo personal para registrarse",
        email2: "Correo electrónico",
        signup: "Inscríbete",
        memberarea: "Area de miembros",
        yourmailpseudo: "Su dirección de correo electrónico / Nombre de usuario",
        yourpassword: "Tu contraseña",
        idorpasswordforgot: "Haga clic aquí si ha olvidado su contraseña",
        settingupaccount: "Configurando tu cuenta",
        settingupaccountint: "Para crear su cuenta, debe proporcionar la siguiente información:",
        yourfirstname: "Tu nombre",
        yourlastname: "Tu apellido",
        yourusername: "Tu nombre de usuario",
        yourskypeid: "Tu ID de skype",
        yourbirthdate: "Tu fecha de nacimiento",
        yourgender: "Tu género",
        yourphonenumber: "Tu número de teléfono",
        yourmailaddress: "Tu dirección de correo electrónico",
        appartroad: "Número de apartamento / calle",
        yourcitydep: "Tu ciudad / Departamento",
        yourpobox: "Su apartado de correos",
        yourcountry: "Su país",
        agreepp1: "Antes de enviar su información, debe aceptar nuestro ",
        agreepp2: "",
        note: "Nota",
        notecontent: "Para activar su cuenta, consulte su buzón de correo electrónico",
        createmyaccount: "Crear mi cuenta",
        male: "Masculino",
        female: "Femenino",
        other: "Otros",
        mailexists: "Esta dirección de correo electrónico ya existe",
        usernameexists: "Este nombre de usuario ya existe",
        dashboard: "Cuadro de mandos",
        dashboard1: "Gestione su cuenta Creativ'Network. Modifique su configuración y consulte los informes",
        dashboard2: "Panel de control",

        information: "Su información", 
        information1: "Gestione su información personal",
        information2: "Cambiar la contraseña",

        yourapplication: "Su solicitud",
        application1: "Cargar / Modificar su currículum",
        application2: "Compruebe el estado de su solicitud de empleo",

        integration: "Su integración",
        integration1: "Cargar / Modificar los documentos requeridos",
        integration2: "Cargar / Modificar sus datos de pago",        

        info11: "Cambia tu foto de perfil",
        info12: "Sube tu foto desde el navegador",
        info13: "Navegador",
        info14: "Subir tu foto desde la cámara",

        save: "Salvar",
        admin: "Administrador",
        blogger: "Blogger",
        teamleader: "Jefe de equipo",
        adboard: "Panel de administración",
        tldboard: "Panel del jefe de equipo",
        bdboard: "Cuadro de mandos de blogger",
        notifs: 'Notificaciones',
        logout: 'Cerrar sesión',
        actualpassword: "Tu contraseña actual",
        newpassword: "Tu nueva contraseña",
        confirmnewpassword: "Confirma tu contraseña",

        app11: "Sube tu currículum",
        app12: "Sube tu currículum desde el navegador",

        appstatus1: "Formulario de solicitud",
        submittedon:"Presentado el",
        appstatus2: "Prueba",
        notyet: "Aún no está hecho",
        reviewedon: "Revisado el",
        appstatus3: "Estado de revisión",
        reviewed: "Revisado",
        appstatus4: "Decisión de la dirección",
        hired: "Enhorabuena, ha sido contratado, por favor proceda al siguiente paso: su integración",
        nothired: "Lo sentimos, pero su solicitud no ha sido aceptada.",
        idtl: "ID del jefe de equipo",
        ida: "ID de administrador",
        idb: "ID de blogger",
        positionoverview: 'Visión general del puesto',
        adminstaff: "Personal de administración",
        jobposts: "Puestos de trabajo",
        jobapplication: "Solicitud de empleo",
        newemptraining: "Formación de nuevos empleados",
        empintegration: "Integración de empleados",
        blogposts: "Entradas de blog",
        details: "Detalles",
        cnmembers: "Miembros de la Creativ-Network",
        sn: "Red social",
        aas1: "Lista de personal administrativo",
        aas2: "Añadir personal de administración",
        aja1: "Todas las solicitudes de empleo",
        aja2: "Comprobar detalles de las solicitudes",
        aja3: "Solicitudes aceptadas",
        anet1: "Programa de formación",
        anet2: "Añadir documentos de formación",
        anet3: "Agentes en espera de iniciar sesión",
        anet4: "Atribución de inicio de sesión",
        aei1: "Comprobación de nuevos agentes",
        abp1: "Lista de blogueros",
        abp2: "Añadir bloggers",
        abp3: "Lista de entradas de blog",
        abp4: "Añadir entradas de blog",
        ajp1: "Lista de puestos de trabajo",
        ajp2: "Añadir puestos de trabajo",
        acnm1: "Lista de miembros",
        acnm2: "Añadir miembros",
        anl1: "Lista de abonados",
        anl2: "Todos los boletines",
        anl3: "Añadir boletín",
        asn1: "Todas las redes sociales",
        passportcopy: "Copia del pasaporte, carné de conducir o documento de identidad oficial",
        proofofaddress: "Justificante de domicilio",
        yourselfie: "Tu selfie con tu DNI",
        freelance: "Contrato autónomo",
        integ1up1: "Cargue su documento de identidad desde el navegador",
        integ1up2: "Cargue su justificante de domicilio desde el navegador",
        integ1up3: "Sube tu selfie desde el navegador",
        integ1up4: "Sube tu selfie desde la cámara",
        integ1download: "Descargue el contrato de autónomo, fírmelo y envíelo aquí",
        integ1up5: "Sube tu contrato de autónomo firmado desde el navegador",
        download: "Descargar",

        paypalaccount: "Cuenta Paypal",
        yourpaypal: "(Su dirección de correo electrónico de Paypal)",
        wiseaccount: "Cuenta Wise",
        yourwise: "(Tu dirección de correo electrónico de Wise)",
        bitesafeaccount: "Cuenta Yoursafe",
        yourbitesafe: "(Tu dirección de correo electrónico de Yoursafe)",
        bankaccount: "Cuenta bancaria",
        accountholder: "Nombre del titular de la cuenta:",
        holderaddress: "Dirección del titular de la cuenta:",
        banksholder: "Nombre del banco titular:",
        bankaddress: "Dirección del banco titular: ",
        iban: "IBAN / Número de cuenta:",
        swiftcode: "Código Swift / BIC: ",
        year: 'Año',
        month: 'Mes',
        date: 'Fecha',
        week: 'Semana',
        today: 'Hoy',
        startingdate: 'Fecha de inicio',
        closingdate: 'Fecha de cierre',
        adminstafflogin: 'Nombre de usuario del personal de administración',
        adminstaffloginpassword: 'Contraseña de acceso del personal administrativo',
        actions: 'Acciones',
        suspend: 'Suspender',
        fire: 'Prohibir',
        candidateid: 'ID del candidato',
        createnewadminstaff: 'Crear nuevo personal admin',
        language: "Idioma",
        edit: "Modificar",
        openingposition: "Puesto vacante",
        jobtitle : "Título del puesto",
        jobdescription : "Descripción del puesto",
        openclose: "Disponible / No disponible",
        jobopen: "Disponible",
        jobclose: "No disponible",
        delete: "Borrar",
        applicantfirstname: 'Nombre del solicitante',
        applicantlastname: "Apellido del solicitante",
        applicantcountry: 'País del solicitante',
        application: 'Solicitud',
        checkthapplication: "Comprobar la solicitud",
        agentfirstname: "Nombre del agente",
        agentlastname: "Apellido del agente",
        agentcountry: "País del agente",
        agentemail: "Correo electrónico del agente",
        agentskypeid: "ID de skype del agente",
        trainingschedule: "Programa de formación",
        sendconfirmationonemailtoagent: "Enviar correo electrónico de confirmación al agente",
        memberopt1: "Gestiona tu información",
        memberopt2: "Compruebe su solicitud de empleo",
        memberopt3: "Proceda a su integración",
        awaitingforlogin: "Esperando inicio de sesión",
        awaitingforemailconf: "Esperando correo de confirmación",
        agentlogin: "Inicio de sesión de agente",
        freelancecontract: "Contrato de autónomo",
        trainingdocumentssigned: "Documentos de formación firmados",
        proofofresidence: "Prueba de residencia",
        paymentmethod: "Forma de pago",
        provided: "Proporcionado",
        notprovided: "No proporcionado",
        bank: "Banco",
        wise: "Wise",
        paypal: "Paypal",
        Yoursafe: "Yoursafe",
        activeblogger: "Blogger activo",
        bloggerfirstname: "Nombre del blogger",
        bloggerlastname: "Apellido del blogger",
        bloggerloginid: "ID de inicio de sesión de blogger",
        bloggerloginpassword: "Contraseña de inicio de sesión de blogger",
        blogposttile: "Título de la entrada del blog",
        blogpostcategory: "Categoría de la entrada del blog",
        blogpostlink: "Enlace de la entrada del blog",
        category: "Categoría",
        newmembers: "Nuevos miembros",
        allmembers: "Todos los miembros",
        inscriptdate: "Fecha de inscripción",
        memberfirstname: "Nombre del miembro",
        memberlastname: "Apellido del miembro",
        loginpassword: "Contraseña de inicio de sesión",
        emailaddressconfirmed: "Dirección de correo electrónico confirmada",
        country: "País",
        newsubscribers: "Nuevos suscriptores",
        allsubscribers: "Todos los suscriptores",
        subscriptiondate: "Fecha de suscripción",
        topic: "Tema",
        allnewsletters: "Todos los boletines",
        releasedate: "Fecha de publicación",
        releaselanguage: "Idioma de publicación",
        linktothenewsletter: "Enlace al boletín",
        sendtoallsubscribers: "Enviar a todos los suscriptores",

        agentid: "ID agente",
        trainingprocess: "Proceso de formación",
        integrationprocess: "Proceso de integración",
        jobposition: "Puesto de trabajo",
        training: "Formación",
        integrationn: "Integración",
        inprogress: "En curso",
        done: "Hecho",
        role : "Rol",
        skypeid : "ID de Skype",
        jobid: "ID del puesto",
        illustrationphoto: "Foto de ilustración",
        location: "Ubicación",
        jobrequirement: "Condiciones de empleo",
        salaryscale: "Escala salarial",
        incentives: "Incentivos",
        openingdate: "Fecha de apertura",
        telephonenumber: "Número de teléfono",
        createnewjobposts: "Crear un nuevo puesto de trabajo",
        haveexperience: "Tener experiencia",
        howtheyfindus: "Cómo nos han encontrado",
        motivation: "Motivación",
        testresult: "Resultado de la prueba",
        decision: "Decisión",
        hired2: "Contratado",
        rejected: "Rechazado",
        description: "Descripción",

        name: "Nombre",
        document: "Documento",
        blogpostid: "ID de entrada de blog",
        bloggerid: "ID del blogger",
        blogposttitle: "Título de la entrada del blog",
        blogposttext: "Texto de la entrada del blog",
        publishnewblogpost: "Publicar nueva entrada de blog",
        createnewblogger: "Crear nuevo blogger",
        createnewlogin: "Crear nuevo inicio de sesión",
        addblogpostcategory: "Añadir categoría de entrada de blog",
        blogpostcategoryid: "ID de categoría de entrada de blog",
        memberid: "ID de miembro",
        addnewmemberandsendnotification: "Añadir nuevo miembro y enviar notificación",
        newsletterid: "ID del boletín",
        adminid: "Admin ID",
        newslettercategory: "Categoría del boletín",
        newslettertitle: "Título del boletín",
        newslettertext: "Texto del boletín",
        allsubscribersemail: "Dirección de correo electrónico de todos los suscriptores",
        publishnewnewsletter: "Publicar nuevo boletín",
        link: "Enlace",
        hello : "Hola",

        tlintint1: "¿Quieres trabajar desde casa como",
        tlintint2: "de operadores de chat para citas?",
        tlint1: "1. Liderazgo impactante: Dirige un equipo que da forma a conexiones, fomenta relaciones significativas y contribuye a un entorno dinámico de citas",
        tlint2: "2. Oportunidad de crecimiento del equipo: Guiar y desarrollar un equipo cohesionado, fomentando el crecimiento personal y profesional, en un entorno de trabajo de apoyo.",
        tlint3: "3. Contribución innovadora: Introducir soluciones creativas, dar forma al futuro de las citas online y mejorar los servicios de chat, contribuyendo a la innovación del sector.",
        tlint4: "4. Reconocimiento y logros: Liderar un equipo de éxito, superar métricas y obtener reconocimiento, con un salario competitivo y atractivos incentivos.",
        tlint5: "5. Recompensas a la excelencia: Disfruta de un buen salario, incentivos lucrativos y reconocimiento por alcanzar objetivos, causando un impacto significativo en Creativ'Network.",
        tlabint1: "¿En qué consiste el trabajo de",
        tlabint2: "de operadores de chat para citas?",
        tlab1: "1. Liderazgo y Coordinación: Como Líder de Equipo, supervisar a todos los operadores de chat para citas implica proporcionar un fuerte liderazgo, guiar al equipo y garantizar una comunicación cohesiva",
        tlab2: "2. Gestión del Rendimiento: Establecer y supervisar las métricas de rendimiento, como los tiempos de respuesta y la satisfacción del usuario, para lograr un servicio de alta calidad y fomentar una experiencia de usuario positiva.",
        tlab3: "3. Desarrollo del equipo: Nutrir un equipo cohesionado y motivado proporcionando orientación, tutoría y fomentando un entorno de trabajo propicio para el crecimiento personal y profesional.",
        tlab4: "4. Innovación en los servicios de chat: Introducir enfoques creativos para mejorar los servicios de chat, mantenerse al día de las tendencias del sector y adaptar estrategias para mejorar la participación de los usuarios.",
        tlab5: "5. Garantía de calidad: Garantizar que los operadores mantienen un alto nivel de servicio, atendiendo a las necesidades de los usuarios y resolviendo los problemas con prontitud para mantener una experiencia de usuario positiva y digna de confianza.",
        tlab6: "6. Centro de comunicación: Servir como punto central para la comunicación entre los operadores, la dirección y otros departamentos, facilitando la colaboración para lograr los objetivos de la organización en el dinámico ámbito de las citas online.",
        tlab7: "7. Planificación estratégica: Contribuir a la planificación estratégica del equipo, alineando las metas con los objetivos de la organización en el dinámico ámbito de las citas en línea.",
        tllsint1: "¿Cuáles son las aptitudes de liderazgo para ser",
        tllsint2: "de operadores de chat para citas?",
        tlls11: "Experiencia de liderazgo",
        tlls12: "Experiencia demostrada en liderar y gestionar equipos, mostrando habilidades efectivas de liderazgo y tutoría",
        tlls21: "Habilidades de comunicación",
        tlls22: "Fuertes habilidades de comunicación verbal y escrita para facilitar una comunicación clara entre los miembros del equipo y con otros departamentos",
        tlls31: "Conocimiento del sector",
        tlls32: "Familiaridad con el sector de las citas en línea, comprensión de la dinámica de los usuarios, tendencias y retos en la prestación de servicios de chat para citas",
        tlls41: "Capacidad para resolver problemas",
        tlls42: "Capacidad demostrada para analizar y resolver problemas de manera eficiente, especialmente para abordar los problemas de los usuarios y mantener una experiencia de usuario positiva",
        tlls51: "Gestión del rendimiento",
        tlls52: "Experiencia en el establecimiento, seguimiento y optimización de las métricas de rendimiento para garantizar un servicio de alta calidad, la satisfacción del usuario y la eficiencia del equipo",
        tlls61: "Adaptabilidad e innovación",
        tlls62: "Capacidad para adaptarse a los cambios en la industria, introducir estrategias innovadoras y mantenerse a la vanguardia de las tendencias emergentes en los servicios de citas en línea",
        tlls71: "Habilidades para el desarrollo de equipos",
        tlls72: "Capacidad demostrada para fomentar el crecimiento del equipo, proporcionar orientación, tutoría y crear un entorno de trabajo positivo para el desarrollo personal y profesional",
        tlls81: "Enfoque de garantía de calidad",
        tlls82: "Compromiso con el mantenimiento de un alto nivel de servicio, la atención a las necesidades de los usuarios y la aplicación de medidas de garantía de calidad para una experiencia de usuario positiva",
        tlls91: "Colaboración y coordinación",
        tlls92: "Fuertes habilidades de colaboración, sirviendo como punto central para la comunicación, y facilitando la coordinación efectiva entre los operadores, la dirección y otros departamentos",

        tlreint1: "¿Cuáles son los requisitos para ser",
        tlreint2: "de Creativ'Network?",
        tlre12: "Un jefe de equipo de Creativ'Network debe dominar los principales idiomas de trabajo, ser multilingüe, expresarse con claridad, comprender los matices regionales, tener una buena comunicación escrita y dominar las interacciones con los usuarios y la colaboración con otros departamentos",
        tlre22: "Establecer un límite de edad de más de 25 años para un Jefe de Equipo Creativ'Network puede garantizar un equilibrio entre experiencia y madurez. Esta política promueve diversas cualidades de liderazgo a la vez que mantiene un equipo dinámico y eficiente",
        tlre42: "Los Líderes de Equipo de Creativ'Network deben conocer y aceptar la Declaración de Privacidad y las Condiciones Contractuales, garantizando el cumplimiento de las obligaciones contractuales y de protección de datos, fomentando una relación de trabajo segura y transparente.",
        tlre61: "Informes",
        tlre62: "Los jefes de equipo de Creativ'Network elaboran informes detallados que resumen el rendimiento del equipo, las métricas de participación de los usuarios y los problemas abordados. Estos informes orientan las decisiones estratégicas, identifican áreas de mejora y garantizan una prestación de servicios óptima",
        tlre72: "Los jefes de equipo de Creativ'Network se comprometen a un mínimo de 40 horas semanales, con disponibilidad ocasional los fines de semana y festivos. Esto garantiza un apoyo continuo al equipo y mantiene unos niveles de servicio óptimos",
        tlre81: "Tax",
        tlre82: "Como trabajador independiente, Creativ'Network Team Leader es responsable de gestionar y pagar sus propios impuestos. Esto incluye el cumplimiento de la normativa fiscal y el cumplimiento de las obligaciones financieras en sus respectivas jurisdicciones",
        tlre92: "Los Líderes de Equipo de Creativ'Network garantizan el estricto cumplimiento de las directrices legales y reglamentarias, fomentando un entorno de cumplimiento. Esto incluye la defensa de la privacidad de los usuarios, la protección de datos y otras normativas específicas del sector para mantener la integridad de la plataforma",
        tlic1: "Verificación de edad: Creativ'Network comprueba la edad de los candidatos a Jefe de Equipo (25+) para garantizar que los candidatos poseen la experiencia, madurez y estabilidad esenciales para un liderazgo eficaz. Este enfoque se alinea con el objetivo de la empresa de contar con líderes experimentados con un historial probado, que contribuyan al éxito de la gestión de equipos y fomenten un entorno de trabajo positivo",
        tlic2: "Seguridad y confianza: La verificación de la edad de los candidatos a Jefe de Equipo de Creativ'Network mayores de 25 años se realiza para mejorar la seguridad y la confianza. Al garantizar el cumplimiento de este criterio, la plataforma pretende establecer un equipo de liderazgo maduro y experimentado, fomentando un entorno seguro y digno de confianza tanto para los usuarios como para los miembros del equipo",
        tlas1: "Para empezar, visite el sitio web oficial o el portal de empleo de Creativ'Network.|Busque la oferta de empleo para el puesto de Jefe de equipo y haga clic en \"Solicitar\".|Rellena el formulario de solicitud, que incluye tu información personal, datos de contacto, experiencia relevante y motivación para trabajar con nosotros.|Se le pedirá que realice una breve prueba de aptitudes, que suele durar unos 10 minutos. Esta prueba evalúa su capacidad de liderazgo, comunicación, gestión del rendimiento, innovación, desarrollo de equipos, garantía de calidad, colaboración y adaptabilidad.",
        tlas2: "Después de completar con éxito la solicitud inicial y la prueba de aptitud, se le pedirá que pase por un proceso de verificación de identidad para confirmar su edad.|Se le pedirá que presente una copia de un documento de identidad válido emitido por el gobierno, como un pasaporte, permiso de conducir o tarjeta de identificación gubernamental. Este documento se utiliza para verificar que cumple el requisito de edad mínima especificado para el puesto.",
        tlas3: "Una vez aprobadas tu solicitud y la verificación de tu edad, se te programará una sesión de formación en línea a través de Skype.|Durante la formación, los Directores de Creativ'Network te proporcionarán orientación e instrucciones sobre cómo desempeñar eficazmente tu función como jefe de equipo. Se tratarán las políticas de la empresa, las mejores prácticas y las estrategias de liderazgo y comunicación para destacar en tu puesto.",
        appformfor: "Formulario de solicitud de",
        tofilltheapplicationform: "Para rellenar el formulario de solicitud, inicie sesión o regístrese en nuestro sitio web",
        supervisor: "Supervisor",
        qualitysupervisor: "Supervisor de Calidad",
        sintint1: "¿Quieres trabajar en casa como",
        sintint2: "de operadores de chat de citas?",
        sint1: "1. Liderazgo de calidad: Garantizar la excelencia en los servicios de chat, dando forma a experiencias positivas de los usuarios y fomentando interacciones de calidad, todo ello desde la comodidad del hogar.",
        sint2: "2. Compensación competitiva: Recibir un salario generoso, incentivos lucrativos y la flexibilidad del trabajo remoto, proporcionando recompensas económicas por mantener la calidad.",
        sint3: "3. Desarrollo del equipo: Mentorizar y guiar a los operadores, fomentando la calidad del servicio, el crecimiento personal y el desarrollo profesional en un entorno de trabajo virtual.",
        sint4: "4. Contribuciones innovadoras: Introducir estrategias creativas, mejorar los servicios de chat y contribuir a la innovación del sector, garantizando una experiencia de usuario de calidad en Creativ'Network.",
        sint5: "5. Reconocimiento y recompensas: Dirija un equipo de alta calidad, supere las métricas y obtenga reconocimiento con un salario competitivo, garantizando una carrera remota satisfactoria y gratificante.",
        sint6: "6. Conciliación de la vida laboral y personal: Disfruta de la flexibilidad de trabajar desde casa, logrando un equilibrio armonioso entre la vida laboral y personal mientras aseguras y mejoras los estándares de calidad en Creativ'Network.",
        sabint1: "¿En qué consiste el trabajo de",
        sabint2: "de operadores de chat de citas?",
        sab1: "1. Supervisión del rendimiento: Los supervisores de calidad controlan de cerca a los operadores, garantizando el cumplimiento de las métricas de rendimiento, los tiempos de respuesta y la satisfacción de los usuarios, contribuyendo a una experiencia de usuario positiva.",
        sab2: "2. Comentarios y formación: Proporcionan comentarios constructivos, llevan a cabo sesiones de formación y guían a los operadores para que mejoren continuamente sus habilidades de comunicación, garantizando interacciones de calidad.",
        sab3: "3. Resolución de problemas: Abordar las preocupaciones de los usuarios con prontitud, resolviendo los problemas y manteniendo un alto nivel de servicio para garantizar una experiencia positiva de los usuarios.", 
        sab4: "4. Protocolos de garantía de calidad: Aplicar y hacer cumplir los protocolos de garantía de calidad, asegurando el cumplimiento coherente de las normas establecidas para los servicios de chat en Creativ'Network",
        sab5: "5. Innovación y optimización: Introducir estrategias innovadoras para optimizar los servicios de chat, manteniéndose a la vanguardia de las tendencias del sector y mejorando la calidad general de las interacciones de los usuarios.",
        sab6: "6. Colaboración en equipo: Fomentar un entorno de colaboración en equipo, fomentando la comunicación y la colaboración entre los operadores, asegurando un enfoque cohesivo para la prestación de servicios de chat de citas de alta calidad.",
        sskint1: "¿Cuáles son las aptitudes para ser",
        sskint2: "de operadores de chat para citas?",
        leadership: "Liderazgo",
        communication: "Comunicación",
        analyticalskills: "Capacidad de análisis",
        pbsolving: "Resolución de problemas",
        qualityassurance: "Garantía de calidad",
        traininganddev: "Formación y Desarrollo",
        innovation: "Innovación",
        collab: "Colaboración",
        adaptability: "Adaptabilidad",
        ssk1: "Capacidad de liderazgo excepcional para guiar e inspirar a un equipo de operadores de chat, fomentando un entorno de trabajo positivo y productivo",
        ssk2: "Fuertes habilidades de comunicación, tanto verbal como escrita, para proporcionar instrucciones claras, retroalimentación y capacitación a los operadores, asegurando interacciones efectivas con los usuarios",
        ssk3: "Capacidad para analizar las métricas de rendimiento, identificar áreas de mejora e implementar estrategias para mejorar la calidad general del servicio de chat",
        ssk4: "Destreza para resolver los problemas de los usuarios y hacer frente a los retos operativos con prontitud, manteniendo un alto nivel de servicio y satisfacción de los usuarios",
        ssk5: "Garantía de calidad: Conocimiento exhaustivo de los protocolos de garantía de calidad, garantizando el cumplimiento coherente de las normas establecidas y optimizando los servicios de chat.",
        ssk6: "Formación y desarrollo: Capacidad para diseñar e impartir sesiones de formación, desarrollando las habilidades de los operadores y fomentando la mejora continua de la comunicación y la calidad del servicio.",
        ssk7: "Innovación: Capacidad demostrada para introducir estrategias innovadoras, adelantándose a las tendencias del sector y optimizando los servicios de chat para un entorno dinámico de citas en línea.",
        ssk8: "Colaboración: Habilidades efectivas de colaboración para fomentar un entorno de equipo cohesionado, fomentando la comunicación y la colaboración entre los operadores para una prestación de servicios sin fisuras.",
        ssk9: "Adaptabilidad: Capacidad para adaptarse a la dinámica cambiante de la industria, las preferencias de los usuarios y las tendencias emergentes, asegurando que los servicios de chat de Creativ'Network permanezcan a la vanguardia de la experiencia de citas en línea",
        langproficiency: "Dominio del español",
        agelimit: "Límite de edad",
        bankaccountpaymentaccount: "Cuenta bancaria o cuenta de pago",
        acceptanceppterms: "Aceptación de la declaración de privacidad y las condiciones contractuales",
        equipmentresponsibility: "Responsabilidad del equipo",
        reports: "Informes",
        minweekly: "Mínimo de horas semanales",
        tax: "Impuesto",
        adhlegalregguidelines: "Cumplimiento de las directrices legales y reglamentarias",
        sre1: "Para ser Supervisor de Calidad de Creativ'Network se requiere dominio de los principales idiomas de trabajo, competencia multilingüe, articulación clara, comprensión de los matices regionales, gran capacidad de comunicación escrita y dominio de las interacciones con los usuarios y de la colaboración con otros departamentos",
        sre2: "Establecer un límite de edad de 25 años o más para un Supervisor de Calidad de Creativ'Network puede garantizar un equilibrio de experiencia y madurez. Esta política promueve diversas cualidades de liderazgo a la vez que mantiene un equipo dinámico y eficiente",
        sre3: "Deberías tener una cuenta bancaria, PayPal, Wise o Yoursafe a tu nombre. Esto es probablemente a efectos de procesamiento de pagos",
        sre4: "El Supervisor de Calidad de Creativ'Network debe conocer y aceptar la Declaración de Privacidad y las Condiciones del Contrato, garantizando el cumplimiento de las obligaciones contractuales y de protección de datos, fomentando una relación de trabajo segura y transparente",
        sre5: "Se espera que disponga del equipo necesario, incluido un ordenador portátil o PC, una conexión a Internet fiable y una fuente de reserva de electricidad e Internet para garantizar un trabajo ininterrumpido",
        sre6: "El supervisor de calidad de Creativ'Network elabora informes detallados que resumen el rendimiento del equipo, las métricas de participación de los usuarios y los problemas abordados. Estos informes orientan las decisiones estratégicas, identifican áreas de mejora y garantizan una prestación óptima del servicio",
        sre7: "El Supervisor de Calidad de Creativ'Network se compromete a un mínimo de 40 horas semanales, con disponibilidad ocasional los fines de semana y festivos. Esto garantiza un apoyo continuo al equipo y mantiene unos niveles de servicio óptimos",
        sre8: "Como trabajador independiente, Creativ'Network Quality Supervisor es responsable de gestionar y pagar sus propios impuestos. Esto incluye el cumplimiento de la normativa fiscal y el cumplimiento de las obligaciones financieras en sus respectivas jurisdicciones",
        sre9: "El supervisor de calidad de Creativ'Network garantiza el estricto cumplimiento de las directrices legales y normativas, fomentando un entorno conforme a la normativa. Esto incluye la defensa de la privacidad de los usuarios, la protección de datos y otras normativas específicas del sector para mantener la integridad de la plataforma",
        sic1: "1. Verificación de edad: Creativ'Network comprueba la edad de los aspirantes a Supervisor de Calidad (25+) para garantizar que los candidatos poseen la experiencia, madurez y estabilidad esenciales para un liderazgo eficaz. Este enfoque se alinea con el objetivo de la empresa de contar con líderes experimentados con un historial probado, que contribuyan al éxito de la gestión de equipos y fomenten un entorno de trabajo positivo",
        sic2: "2. Seguridad y confianza: La verificación de la edad de los candidatos a Supervisor de Calidad de Creativ'Network mayores de 25 años se lleva a cabo para mejorar la seguridad y la confianza. Al garantizar el cumplimiento de este criterio, la plataforma pretende establecer un equipo de liderazgo maduro y experimentado, fomentando un entorno seguro y de confianza tanto para los usuarios como para los miembros del equipo. ",
        sic3: "3. Documentos de verificación: Para cumplir con la normativa legal y garantizar que los candidatos tienen la edad adecuada, Creativ'Network solicitará la verificación de la edad. Los documentos como el carné de conducir, el carné de identidad o el pasaporte suelen incluir información sobre la fecha de nacimiento, por lo que son adecuados para confirmar que cumples el requisito de edad mínima establecido por Creativ'Network",   
        
        
        sas3: "Una vez aprobadas su solicitud y la verificación de su edad, se le programará una sesión de formación en línea a través de Skype.|Durante la formación, los jefes de equipo de Creativ'Network te proporcionarán orientación e instrucciones sobre cómo desempeñar eficazmente tu función como supervisor de calidad. Ellos cubrirán las políticas de la empresa, las mejores prácticas y estrategias de liderazgo y comunicación para sobresalir en su posición.",
        tltext: "Dirigir, inspirar y guiar a los operadores de chat, fomentando conexiones y experiencias excepcionales en el ámbito de las citas online en Creativ'Network.",
        supervisortext: "Supervisar la calidad de los servicios de chat para citas en Creativ'Network. Guiar a los operadores hacia la excelencia, garantizando experiencias positivas a los usuarios en el ámbito de las citas en línea.",


        webdevtext: "Únete a Creativ'Network como desarrollador web para crear soluciones digitales innovadoras. Contribuye a nuestro dinámico equipo, dando forma al futuro de las conexiones en línea",
        digmartext: "Mejora tu presencia online en Creativ'Network como vendedor digital. Impulsa el compromiso, diseña estrategias y contribuye a iniciativas de marketing dinámicas. Únete a nosotros",
        webdestext: "Crea experiencias digitales en Creativ'Network como diseñador web. Da vida a la creatividad, creando sitios web visualmente impresionantes para mejorar las conexiones en línea",

        qstesttitle: "Formulario del test de supervisor de calidad",
        suptitle: "Prueba para ser Supervisor de Calidad en Creativ'Network",
        suptest: "Prueba de supervisor de calidad",
        tltest: "Prueba del líder del equipo",
        tltestform: ' Formulario de prueba de jefe de equipo',
        tltesttitle: "Prueba para ser jefe de equipo en Creativ'Network",
        tltestint1: "El test de líder de equipo de Creativ'Network evalúa tus competencias en liderazgo, comunicación, gestión del rendimiento, innovación, desarrollo de equipos, garantía de calidad, colaboración y adaptabilidad.",
        suptestint1: "El test de Supervisor de Calidad de Creativ'Network evalúa tus capacidades de liderazgo, comunicación, resolución de problemas, garantía de calidad, formación, innovación, colaboración y adaptabilidad.",
        thanktest1: "Gracias por hacer la prueba",
        finish: "Terminar",
        all: "Todos",
        loginfailed: "Error de inicio de sesión",
        
        usrnmpttrerr: "El nombre de usuario no debe contener caracteres especiales",
        pobxpttrerr: "El apartado de correos no debe contener caracteres especiales",
        phnpttrerr: "El número de teléfono debe tener formato internacional. Ejemplo: +123456789012",
        skpidpttrerr: "Identificador de skype no válido",
        brthdtptrerr: "Formato de fecha de nacimiento no válido",
        usrnmlngtherr: `El nombre de usuario debe contener al menos ${usernameminlength} caracteres.`,
        smthngwntwrng: "Algo ha ido mal. Vuelva a intentarlo",
        failed: "Fallo",  
        subsuccess: "Suscrito con éxito",
        subfailed: "Ya suscrito",
        mlunqerr: "Esta dirección de correo electrónico ya está en uso",
        usrnmunqerr: "Este nombre de usuario ya está en uso",
        iderr: "E-mail / Nombre de usuario no válido",
        mdperr: "Contraseña no válida",
        svsucc: "Información actualizada con éxito",
        
        mdpnid: "La nueva contraseña y la contraseña de confirmación no son idénticas",
        curmdperr: "La contraseña actual es incorrecta",
        entryrml: 'Introduzca su dirección de correo electrónico',
        entrynwpss: 'Introduzca su nueva contraseña',
        rcpcpt: 'Recupere su cuenta',

        chckrsm: "Vea su currículum actual",
        nyrsm: "Aún no se ha proporcionado ningún currículum",
        chckpi: "Vea su documento de identidad actual",
        chckpa: "Vea su comprobante de domicilio actual",
        chcksl: "Vea su selfie actual",
        chckc: "Vea su contrato actual firmado",
        nypi: "Aún no se ha presentado ninguna prueba de identidad",
        nypa: "Aún no se ha facilitado la dirección",
        nysl: "No selfie provided yet",
        nyc: "Aún no se ha firmado el contrato",
        rsm: "CV",
        pi: "Prueba de identidad",
        pa: "Justificante de domicilio",
        slfi: "Selfie",
        opnngclsngdterr: "La fecha de apertura debe ser anterior a la de cierre",
        adddsccsflly: "Añadido con éxito",
        dltd: "Suprimido",
        rstr: "Restaurar",
        see: "Véase", 

        succ: "Blog creado con éxito",
        cat: "Categoría inválida",
        accref: "Acceso denegado",
        lang: "Idioma no encontrado",

        catsuccess: "Categoría creada con éxito",
        catexst: "Categoría ya existente",
        crtadminfrmmbr: "Crear a partir de miembro existente",
        success: "Éxito",
        tktst: "Haga la prueba",
        expteam: "¿Tienes experiencia en dirección de equipos?",
        expsup: "¿Tienes experiencia en la supervisión de operadores de chat?",
        avlbl: "Disponible",
        fllppfrm: "Rellene el formulario de solicitud",
        lljbs: "Todos los empleos",
        pnjbs: "Ofertas de empleo",
        dfndt: "Fecha determinada",
        indfndt: "Fecha indefinida",

        nonaut: "Sólo el autor del blog puede eliminar y restaurar este blog",
        adminsuccess: "Esta persona es ahora un administrador",
        rolenonaut: "Rol no autorizado",
        djadmin: "Esta persona ya es administrador",
        usrntfnd: "No se ha encontrado ningún usuario",
        newbloggersucc: "Esta persona ya es blogger",

        nw: "Nuevo",
        bfrsbmttng: "Antes de presentar",
        upldrsmpdf: "Sube tu currículum",
        umstupldyrcv: "Debe cargar su CV" ,  
    
        gndr: "Género",
        citydep: "Ciudad / Departamento",
        pobox: "APARTADO DE CORREOS",

        crtd: "Creado",
        dcnnctd: "Desconectado",
        sbntfnd: "Abonado no encontrado", 
        sspndd: "Suspendido",
        susp: "Su cuenta está actualmente suspendida y se reactivará el ", 
        reak: "",
        svd: "Guardado",
tstalrddn: "Ya ha presentado su candidatura para este puesto",
        gvlgn: "atributo a login",
        frmtdcnntrv: "Documento de formación no encontrado",
        sndmaildocincomp: "Enviar correo electrónico sobre documentos incompletos",
        vldtinteg: "Añadir a la lista de formación",
        nshdtrnng: "Entrenamiento finalizado",
        cnfgtrnng: "Configurar entrenamiento",
        sndml: "Enviar correo electrónico",
        mrksfnshd: "Marcar como finalizado",
        bn: "Correo electrónico prohibido",
        cypm: "Elija su forma de pago",
        aas3: "Lista de jefes de equipo",
tllgn: "Lista de Líderes de Equipo",
aas4: "Añadir jefe de equipo",
createnewtl: "Crear nuevo jefe de equipo",
strthr: "Hora de inicio",
        dnhr: "Hora de finalización",
        chngpay: "Si desea cambiar su información de pago, póngase en contacto con su jefe de equipo o su gestor",
        idm: "ID de gestor",
        mdboard: "Cuadro de mandos del gestor",
        manager: "Gerente",
        mrk: "Marcar como leído",
agntslst: "Lista de agentes",
aas5: "Lista de Gestores",
aas6: "Añadir Gestor",
createnewmn: "Crear nuevo Gestor",
pdf: "Sólo se acepta el formato PDF",
agntnm: "Nombre del agente",
        agntpass: "Contraseña del agente",
        asn2: "Añadir red social",

    },
   
        
        
    { // de
        cn: "Creativ'Network",
        french: 'Französisch',
        english: 'English',
        italian: 'Italienisch',
        dutch: 'Niederländisch',
        spanish: 'Spanisch',
        german: 'Deutsch',
        usermanual: "Benutzerhandbuch",
        formulaire: 'Bewerbungsformular',

        firstname: 'Vorname',
        lastname: 'Nachname',
        email: 'E-Mail-Adresse',
        birthday: 'Geburtsdatum',
        phone: 'Telefon',
        skypename: 'Skype Name',
        whichchatlanguage: 'Für welche Chat-Sprache bewirbst du dich? ',
        timesavailable: 'Wann bist du telefonisch gut zu erreichen?',
        expchatmod: 'Hast du Erfahrung in der Chat-Moderation?',
        hearabout: 'Wie bist du auf uns aufmerksam geworden?',
        tellus: 'Bitte erzähle uns etwas über dich und warum wir dich einstellen sollten!',
        submitapp: 'Einreichen',
        yes: 'Ja',
        no: 'Nein',
        friends: 'Freunde',
        from: 'Von',
        to: 'bis',

        required: 'Dieses Feld ist obligatorisch',
        invalidemail: 'Ungültige E-Mail',

        loginfailusername: 'Benutzername nicht erkannt',
        loginfailpass: 'Ungültiges Passwort',
        login: 'Verbindung',
        username: 'Name des Nutzers',
        password: 'Passwort',
        test: 'Testformular als Chat-Moderator auf Deutsch',
        situation: 'Situation',
        send: 'Senden',
        qreturn: 'Möchten Sie den Test wirklich verlassen?',

        cointint: "Willst du von zu Hause aus als Dating-Chat-Operator arbeiten mit",
        coint1: "Tolle Auszahlung: Verdienen Sie zwischen 0,12€ und 0,15€ pro gesendeter Nachricht mit Creativ'Network",
        coint2: "Fernarbeit: Genießen Sie die Flexibilität, von zu Hause aus zu arbeiten.",
        coint3: "Kommunikationsfähigkeiten: Verbessern Sie Ihre Qualität in Empathie, aktivem Verstehen und flüssiger Kommunikation",
        coint4: "Work-Life-Balance: Verwalten Sie Ihre Zeit besser mit flexiblen Arbeitszeiten.",
        applynow: "Jetzt bewerben",

        coabint1: "Was ist ein Dating",
        coabint2: "Job über?",
        chatoperator: "Chat-Betreiber",
        coab1: "Kontaktaufnahme mit einsamen Menschen: Viele Nutzer von Dating-Plattformen sind auf der Suche nach Gesellschaft oder einem Gesprächspartner, weil sie sich einsam fühlen. Chat-Betreiber bieten ein offenes Ohr und emotionale Unterstützung",
        coab2: "Textgestützte Kommunikation: Die primäre Kommunikationsform sind schriftliche Textnachrichten auf der Plattform. Die Betreiber unterhalten sich laufend mit den Nutzern",
        coab3: "Vielfältige Gesprächsthemen: Die Chat-Betreiber verwickeln die Nutzer in Diskussionen über eine breite Palette von Themen, von alltäglichen Themen wie Sport und Wetter bis hin zu persönlicheren und intimeren Themen, einschließlich Träumen, Fantasien und anderen Interessengebieten",
        coab4: "Aufgeschlossener Ansatz: Diese Tätigkeit erfordert oft eine aufgeschlossene und vorurteilsfreie Haltung, da die Nutzer unterschiedliche Interessen, Erfahrungen und Wünsche haben können.",
        coab5: "Geselligkeit bieten: Die Betreiber spielen eine Rolle dabei, den Nutzern, die vielleicht emotionale Unterstützung suchen oder einfach nur jemanden zum Reden brauchen, ein Gefühl von Gesellschaft und Verbindung zu vermitteln",
        coab6: "Anonym: Die Chat-Operatoren arbeiten zu 100 % anonym. Unsere Identität wird niemals auf einer Dating-Website preisgegeben",
        coab7: "Unabhängigkeit und Flexibilität: Die Chat-Operatoren arbeiten nach Ihren eigenen Vorstellungen",

        home:"Startseite",
        ourmissions: "Unsere Missionen",
        company: "Werte",
        ourcompany: "Unsere Werte",
        ouradvantages: "Unsere Vorteile",
        jobs: "Arbeitsplätze",

        slog: '"Wo Kreativität auf Chancen trifft"',
        copyright: "©2023-2024 copyright Creativ'Network. All rights reserved.",
        foexpl: "Creativ'Network, die innovative Plattform, die Web-Talente mit zukunftsweisenden Aufgaben verbindet und die digitale Welt gestaltet. Entdecken Sie Ihr Potenzial und entwickeln Sie sich in den Bereichen Webmoderation, Entwicklung, Marketing, Big Data und mehr",
        focontact1: "Hauptadresse: 71-75 Shelton Street, Covent Garden, London, WC2H 9JQ",
        focontact2: "Vertreter des belgischen Büros: Roodborstjesstraat 4, Wondelgem 9032",
        focontact3: "contact@creativ-network.net",
        subscribe: "Abonnieren",
        agree1: "Ja, ich bin mit dem einverstanden",
        agree2: "und",
        agreelink1: "Datenschutzpolitik",
        agreelink2: "Allgemeinen Geschäftsbedingungen",
        useful: "Nützliches",
        links: "Links",
        oursing: "Unser",
        contact: "Kontakt",
        subscribetoour: "Abonnieren Sie unser",
        newsletter: "Newsletter",

        consents: "Einverständnis",
        termsAgree1: "Ja, ich stimme den",
        termsAgree2: "und den",
        termsAgreeLink1: "Datenschutzpolitik",
        termsAgreeLink2: "Allgemeinen Geschäftsbedingungen",

        termsAgreeLink2real: "Nutzungsbedingungen",
        consentsErr: "Sie müssen der Datenschutzrichtlinie und den Nutzungsbedingungen zustimmen, um sich anzumelden",
        
        coscint1: "Wie wird das Gehalt des ",
        coscint2: "berechnet?",
        cosc1: "Grundgehalt pro Nachricht: Der Betreiber erhält in der Regel einen festgelegten Betrag für jede versandte Nachricht. Der Grundlohn pro versendeter Nachricht beträgt 0,12 €.",
        cosc2: "Monatliche Nachrichtenanzahl: Am Ende des Monats wird die Gesamtzahl der vom Betreiber versendeten Nachrichten berechnet.",
        cosc3: "Auszahlungsberechnung: Der Verdienst des Betreibers für den Monat wird berechnet, indem die Gesamtzahl der Nachrichten mit dem Grundbetrag pro Nachricht multipliziert wird. Wenn Sie zum Beispiel 5.000 Nachrichten verschickt haben, verdienen Sie 600€ (5.000 Nachrichten * 0,12€ pro Nachricht). Mit Creativ'Network kann ein Operator mit 10.000 versendeten Nachrichten leicht 1.200 € pro Monat verdienen",
        cosc4: "Leistungsbonus: In einigen Fällen können Betreiber mit außergewöhnlichen Leistungen zusätzlich zu ihrer Provision einen Bonus erhalten. Der Bonus kann auf Kriterien wie der Anzahl zufriedener Kunden, der Reaktionszeit oder positivem Nutzerfeedback beruhen",
        cosc5: "Weihnachtsgeld: Als zusätzlichen Anreiz bietet Creativ'Network unseren Betreibern, die länger als 2 Jahre im Unternehmen sind, ein Weihnachtsgeld an.",

        corint1: "Was sind die Voraussetzungen, um Chat-Operator zu",
        corint2: "werden?",
        cor11: "Deutsch-Kenntnisse",
        cor12: "Deutschkenntnisse sind eine Voraussetzung, da von den Chat-Betreibern erwartet wird, dass sie mit den Nutzern effektiv auf Deutsch kommunizieren.",
        cor21: "Altersgrenze",
        cor22: "Bewerber sollten mindestens 18 Jahre alt sein, um für diese Stelle in Frage zu kommen",
        cor31: "Bankkonto oder Zahlungskonto",
        cor32: "Sie sollten ein Bankkonto, PayPal-, Wise- oder Yoursafe-Konto auf Ihren Namen haben. Dies dient wahrscheinlich der Zahlungsabwicklung.",
        cor41: "Akzeptanz der Datenschutzerklärung und der Vertragsbedingungen",
        cor42: "Bewerber müssen bereit sein, die Datenschutzerklärung und die Vertragsbedingungen von Creativ'Network zu akzeptieren und einzuhalten, in denen häufig die Verantwortlichkeiten, Richtlinien und der Verhaltenskodex für Chat-Betreiber beschrieben werden.",
        cor51: "Verantwortung für die Ausrüstung",
        cor52: "Es wird von Ihnen erwartet, dass Sie über die notwendige Ausrüstung verfügen, einschließlich eines Laptops oder PCs, einer zuverlässigen Internetverbindung und einer Backup-Strom- und Internetquelle, um eine unterbrechungsfreie Arbeit zu gewährleisten.",
        cor61: "Mindestanforderung für Nachrichten",
        cor62: "Chat-Betreiber müssen in der Regel eine Mindestanzahl von Nachrichten pro Woche versenden. In diesem Fall wird ein Minimum von 250 ausgehenden Nachrichten pro Woche erwartet.",
        cor71: "Mindestwochenstunden",
        cor72: "Auch wenn Sie selbständig arbeiten, sollten Sie mindestens 4 Stunden pro Tag und 20 Stunden pro Woche nach eigenem Ermessen arbeiten",
        cor81: "Steuer",
        cor82: "Als Selbständiger sind Sie für Ihre Steuern selbst verantwortlich.",
        cor91: "Einhaltung der gesetzlichen und behördlichen Richtlinien:",
        cor92: "Jeder Chat-Operator muss sich an die Regeln halten, die der Teamleiter während der Schulung vermittelt.",

        coicint1: "Was benötigt ",
        coicint2: "zur Überprüfung Ihrer Identität?",
        coic1: "Altersüberprüfung: Aus verschiedenen Gründen haben Dating-Websites und -Plattformen oft Altersbeschränkungen, um die Einhaltung gesetzlicher Vorschriften zu gewährleisten. Durch die Überprüfung Ihrer Identität kann Creativ'Network sicherstellen, dass Sie das Mindestalter von 18 Jahren erreicht haben, um unsere Dienste zu nutzen. Dies hilft der Plattform, die rechtlichen Standards einzuhalten, Minderjährige zu schützen und eine verantwortungsbewusste und sichere Community aufrechtzuerhalten.",
        coic2: "Sicherheit und Vertrauen: Die Identitätsprüfung erhöht die Sicherheit und Vertrauenswürdigkeit der Plattform. Sie versichert den Nutzern, dass Creativ'Network mit Personen interagiert, die die sind, die sie vorgeben zu sein, was für den Aufbau von Vertrauen und die Gewährleistung einer sicheren Umgebung für diese Position unerlässlich ist.",
        coic3: "Dokumente zur Überprüfung: Um die gesetzlichen Bestimmungen einzuhalten und sicherzustellen, dass die Kandidaten das entsprechende Alter haben, wird Creativ'Network eine Altersverifizierung verlangen. Dokumente wie Führerscheine und staatliche Ausweise enthalten in der Regel Angaben zum Geburtsdatum und sind daher geeignet, um zu bestätigen, dass Sie das von der Plattform festgelegte Mindestalter erfüllen.",

        coasint1: "3 einfache Schritte, um sich für eine Stelle bei",
        coasint2: "zu bewerben",
        coas11: "Einfache Bewerbung und Eignungstest",
        coas12: ["Beginnen Sie damit, die offizielle Website oder das Karriereportal von Creativ'Network zu besuchen.", "Suchen Sie die Stellenausschreibung für die Position des Dating-Chat-Operators und klicken Sie auf \"Bewerben\".", "Füllen Sie das Bewerbungsformular aus, in dem Sie Ihre persönlichen Daten, Kontaktdaten, relevante Erfahrungen und Ihre Motivation, mit uns zu arbeiten, angeben.", "Sie werden gebeten, einen kurzen Kompetenztest zu absolvieren, der in der Regel etwa 10 Minuten dauert. In diesem Test wird Ihre Fähigkeit bewertet, sich an Gesprächen im Chat zu beteiligen und Ihre Kommunikationsfähigkeiten unter Beweis zu stellen."],
        coas21: "ID-Überprüfung zur Altersbestätigung",
        coas22: ["Nach erfolgreichem Abschluss der Erstanmeldung und des Geschicklichkeitstests müssen Sie einen Ausweisverifizierungsprozess durchlaufen, um Ihr Alter zu bestätigen.", "Sie werden aufgefordert, eine Kopie eines gültigen, von der Regierung ausgestellten Ausweises vorzulegen, z. B. eines Reisepasses, Führerscheins oder Personalausweises. Anhand dieses Dokuments wird überprüft, ob Sie das für die Stelle vorgeschriebene Mindestalter erreichen."],
        coas31: "Online-Schulung über Skype",
        coas32: ["Sobald Ihre Bewerbung und die Altersverifizierung genehmigt sind, werden Sie für eine Online-Schulung über Skype angemeldet.", "Während der Schulung werden die Teamleiter von Creativ'Network Sie anleiten und Ihnen zeigen, wie Sie Ihre Rolle als Dating-Chat-Operator effektiv ausüben können. Sie werden die Unternehmensrichtlinien, die besten Praktiken und Kommunikationsstrategien behandeln, um in Ihrer Position zu glänzen."],

        coafint: "Bewerbungsformular für",

        ppint:  ["Bei Creativ-Network, erreichbar unter https://creativ-network.net, ist eine unserer wichtigsten Prioritäten die Privatsphäre unserer Besucher. Diese Datenschutzrichtlinie enthält die Arten von Informationen, die von Creativ-Network gesammelt und aufgezeichnet werden, und wie wir sie verwenden.", "Wenn Sie weitere Fragen haben oder mehr Informationen über unsere Datenschutzrichtlinie benötigen, zögern Sie nicht, uns per E-Mail an data@creativ-network.net zu kontaktieren", "Diese Datenschutzrichtlinie gilt nur für unsere Online-Aktivitäten und für die Besucher unserer Website in Bezug auf die Informationen, die sie an Creativ-Network weitergeben und/oder sammeln. Diese Richtlinie gilt nicht für Informationen, die offline oder über andere Kanäle als diese Website gesammelt werden."],
        pp1t: ["1 - Zustimmung", ["Durch die Nutzung unserer Website erklären Sie sich mit unserer Datenschutzrichtlinie einverstanden und stimmen deren Bedingungen zu."]],
        pp2t: ["2 - Informationen, die wir sammeln", ["Die persönlichen Daten, um die Sie gebeten werden, und die Gründe, warum Sie um diese Daten gebeten werden, werden Ihnen an dem Punkt mitgeteilt, an dem wir Sie um die Angabe Ihrer persönlichen Daten bitten.", "Wenn Sie sich direkt mit uns in Verbindung setzen, erhalten wir möglicherweise zusätzliche Informationen über Sie, wie z. B. Ihren Namen, Ihre E-Mail-Adresse, Ihre Telefonnummer, den Inhalt der Nachricht und/oder der Anhänge, die Sie uns senden, sowie andere Informationen, die Sie uns zur Verfügung stellen möchten.", "Wenn Sie sich für ein Konto registrieren, fragen wir Sie möglicherweise nach Ihren Kontaktinformationen, einschließlich Angaben wie Name, Firmenname, Adresse, E-Mail-Adresse und Telefonnummer."]],
        pp3t: ["3 - Wie wir Ihre Daten verwenden", ["Wir verwenden die von uns erfassten Daten auf verschiedene Weise, unter anderem um: ", "- Unsere Website bereitzustellen, zu betreiben und zu pflegen", "- Unsere Website zu verbessern, zu personalisieren und zu erweitern", "- Zu verstehen und zu analysieren, wie Sie unsere Website nutzen", "- Neue Produkte, Dienstleistungen, Merkmale und Funktionen zu entwickeln", "- Mit Ihnen zu kommunizieren, entweder direkt oder über einen unserer Partner, einschließlich für den Kundendienst, um Ihnen Aktualisierungen und andere Informationen in Bezug auf die Website zukommen zu lassen, sowie für Marketing- und Werbezwecke", "- Ihnen E-Mails zu senden", "- Betrug zu erkennen und zu verhindern"]],
        pp4t: ["4 - Log Files", ["Creativ-Network verwendet standardmäßig Log Files. Diese Dateien protokollieren die Besuche von Besuchern auf Websites. Alle Hosting-Unternehmen tun dies und es ist Teil der Analyse der Hosting-Dienste. Zu den in den Protokolldateien gesammelten Informationen gehören Internetprotokolladressen (IP), Browsertyp, Internetdienstanbieter (ISP), Datums- und Zeitstempel, verweisende/verlassende Seiten und möglicherweise die Anzahl der Klicks. Diese Daten werden nicht mit Informationen verknüpft, die eine persönliche Identifizierung ermöglichen. Der Zweck dieser Informationen ist die Analyse von Trends, die Verwaltung der Website, die Verfolgung der Bewegungen der Benutzer auf der Website und die Erfassung demografischer Daten."]],
        pp5t: ["5 - Cookies und Web Beacons", ["Wie jede andere Website auch, verwendet Creativ-Network 'Cookies'. Diese Cookies werden verwendet, um Informationen zu speichern, einschließlich der Präferenzen der Besucher und der Seiten auf der Website, die der Besucher aufgerufen oder besucht hat. Diese Informationen werden verwendet, um die Benutzererfahrung zu optimieren, indem wir den Inhalt unserer Webseiten auf der Grundlage des Browsertyps der Besucher und/oder anderer Informationen anpassen."]],
        pp6t: ["6 - Datenschutzrichtlinien der Werbepartner", ["In dieser Liste finden Sie die Datenschutzrichtlinien der einzelnen Werbepartner von Creativ-Network.", "Werbeserver oder Werbenetzwerke von Drittanbietern verwenden Technologien wie Cookies, JavaScript oder Web Beacons, die in ihren jeweiligen Anzeigen und Links auf Creativ-Network verwendet werden, die direkt an den Browser der Nutzer gesendet werden. Dabei erhalten sie automatisch Ihre IP-Adresse. Diese Technologien werden verwendet, um die Effektivität ihrer Werbekampagnen zu messen und/oder die Werbeinhalte zu personalisieren, die Sie auf den von Ihnen besuchten Websites sehen.", "Beachten Sie, dass Creativ-Network keinen Zugriff auf oder Kontrolle über diese Cookies hat, die von Drittanbietern verwendet werden."]],
        pp7t: ["7 - Datenschutzrichtlinien Dritter", ["Die Datenschutzrichtlinien von Creativ-Network gelten nicht für andere Werbetreibende oder Websites. Wir raten Ihnen daher, die jeweiligen Datenschutzrichtlinien dieser Werbeserver Dritter zu konsultieren, um weitere Informationen zu erhalten. Diese können deren Praktiken und Anweisungen darüber enthalten, wie Sie bestimmte Optionen deaktivieren können. Eine vollständige Liste dieser Datenschutzrichtlinien und ihrer Links finden Sie hier: Links zu Datenschutzrichtlinien.", "Sie können Cookies über Ihre individuellen Browseroptionen deaktivieren. Ausführlichere Informationen über die Cookie-Verwaltung mit bestimmten Webbrowsern finden Sie auf den jeweiligen Websites der Browser. Was sind Cookies?"]],
        pp8t: ["8 - CCPA Privacy Policy (Do Not Sell My Personal Information)", ["Nach dem CCPA haben kalifornische Verbraucher neben anderen Rechten das Recht:", "Von einem Unternehmen, das personenbezogene Daten eines Verbrauchers sammelt, zu verlangen, dass es die Kategorien und spezifischen Teile der personenbezogenen Daten offenlegt, die es über den Verbraucher gesammelt hat", "von einem Unternehmen zu verlangen, dass es die von ihm erhobenen personenbezogenen Daten eines Verbrauchers löscht", "von einem Unternehmen, das personenbezogene Daten eines Verbrauchers verkauft, zu verlangen, dass es die personenbezogenen Daten des Verbrauchers nicht verkauft", "wenn Sie einen Antrag stellen, haben wir einen Monat Zeit, Ihnen zu antworten. Wenn Sie eines dieser Rechte ausüben möchten, nehmen Sie bitte Kontakt mit uns auf."]],
        pp9t: ["9 - GDPR Privacy Policy (Data Protection Rights)", ["Wir möchten sicherstellen, dass Sie über alle Ihre Datenschutzrechte informiert sind. Jeder Nutzer hat das Recht auf Folgendes:", "Das Recht auf Zugang - Sie haben das Recht, Kopien Ihrer personenbezogenen Daten anzufordern. Für diesen Service können wir Ihnen eine geringe Gebühr in Rechnung stellen.", "Das Recht auf Berichtigung - Sie haben das Recht, von uns die Berichtigung von Daten zu verlangen, die Sie für unrichtig halten. Sie haben auch das Recht zu verlangen, dass wir die Informationen vervollständigen, die Sie für unvollständig halten.", "Das Recht auf Löschung - Sie haben das Recht zu verlangen, dass wir Ihre personenbezogenen Daten unter bestimmten Bedingungen löschen.", "Das Recht auf Einschränkung der Verarbeitung - Sie haben das Recht zu verlangen, dass wir die Verarbeitung Ihrer personenbezogenen Daten unter bestimmten Bedingungen einschränken. Sie haben das Recht, unter bestimmten Bedingungen der Verarbeitung Ihrer personenbezogenen Daten zu widersprechen.", "Das Recht auf Datenübertragbarkeit - Sie haben das Recht, unter bestimmten Bedingungen zu verlangen, dass wir die von uns erhobenen Daten an eine andere Organisation oder direkt an Sie weitergeben.", "Wenn Sie eine Anfrage stellen, haben wir einen Monat Zeit, Ihnen zu antworten. Wenn Sie eines dieser Rechte ausüben möchten, nehmen Sie bitte Kontakt mit uns auf."]],
        pp10t: ["10 - Informationen für Kinder", ["Ein weiterer Teil unserer Priorität ist der Schutz von Kindern bei der Nutzung des Internets. Wir ermutigen Eltern und Erziehungsberechtigte, ihre Online-Aktivitäten zu beobachten, daran teilzunehmen und/oder sie zu überwachen und zu leiten.", "Creativ-Network sammelt nicht wissentlich personenbezogene Daten von Kindern unter 13 Jahren. Wenn Sie denken, dass Ihr Kind diese Art von Informationen auf unserer Website zur Verfügung gestellt hat, empfehlen wir Ihnen dringend, uns sofort zu kontaktieren, und wir werden unser Bestes tun, um solche Informationen umgehend zu entfernen."]],

        lastupdated: "Zuletzt aktualisiert",
        tcint: ["Bitte lesen Sie die folgenden Allgemeinen Geschäftsbedingungen sorgfältig durch, bevor Sie die Website von Creativ'Network nutzen."],
        tc1t: ["1. Akzeptanz der Bedingungen", ["Durch den Zugriff auf die Website von Creativ'Network erklären Sie sich mit diesen Bedingungen einverstanden und sind an sie gebunden. Wenn Sie mit einem Teil dieser Bedingungen nicht einverstanden sind, nutzen Sie unsere Website bitte nicht."]],
        tc2t: ["2. user eligibility", ["You must be at least 18 years old to use our website. Durch die Nutzung unserer Dienste bestätigen Sie, dass Sie diese Altersvoraussetzung erfüllen."]],
        tc3t: ["3. Nutzung der Website", ["- Sie verpflichten sich, die Website in Übereinstimmung mit allen geltenden Gesetzen und Vorschriften zu nutzen", "- Sie werden sich nicht an illegalen, schädlichen oder missbräuchlichen Aktivitäten auf unserer Plattform beteiligen", "- Sie verpflichten sich, sich nicht als andere auszugeben oder falsche Identitäten zu schaffen."]],
        tc4t: ["4. Datenschutz und Datenverarbeitung", ["Unsere Datenschutzrichtlinie beschreibt, wie wir Ihre persönlichen Daten sammeln, verwenden und schützen. Durch die Nutzung unserer Website erklären Sie sich mit den in unserer Datenschutzrichtlinie beschriebenen Datenpraktiken einverstanden."]],
        tc5t: ["5. Geistiges Eigentum", ["Der gesamte Inhalt unserer Website, einschließlich Text, Grafiken, Logos und Software, ist durch geistige Eigentumsrechte geschützt. Sie dürfen unsere Inhalte nicht ohne unsere ausdrückliche Genehmigung verwenden, vervielfältigen oder verbreiten."]], 
        tc6t: ["6. nutzergenerierte Inhalte", ["Wenn Sie Inhalte zu unserer Plattform beisteuern, gewähren Sie uns eine nicht-exklusive, lizenzgebührenfreie Lizenz zur Nutzung, Änderung und Verbreitung Ihrer Inhalte."]],
        tc7t: ["7. Änderungen der Bedingungen", ["Wir können diese Allgemeinen Geschäftsbedingungen jederzeit aktualisieren. Durch die fortgesetzte Nutzung der Website nach solchen Änderungen erklären Sie sich mit den überarbeiteten Bedingungen einverstanden."]],
        tc8t: ["8. Haftungsausschlüsse und Haftungsbeschränkungen", ["Wir stellen unsere Website \"wie besehen\" zur Verfügung und geben keine Garantien oder Zusicherungen in Bezug auf ihre Genauigkeit, Verfügbarkeit oder Leistung. Wir haften nicht für direkte, indirekte oder Folgeschäden, die sich aus der Nutzung unserer Website ergeben."]],
        tc9t: ["9. Governing Law and Jurisdiction", ["Diese Bedingungen und Ihre Nutzung unserer Website unterliegen den Gesetzen des Vereinigten Königreichs. Alle Streitigkeiten sind vor den Gerichten des Vereinigten Königreichs zu klären."]],
        tc10t: ["10. Kontaktinformationen", ["Wenn Sie Fragen oder Bedenken zu diesen Bedingungen haben, kontaktieren Sie uns bitte unter contact@creativ-network.net."]],

        jobsint1: "Wählen Sie den Job, der zu Ihren Fähigkeiten passt",
        jobsint2: "Erlebe eine tolle Erfahrung mit einem großartigen Team!",
        jobsat: "Jobs bei",

        hirenow: "JETZT EINSTELLEN!",
        closed: "GESCHLOSSEN!",
        detailsandapply: "Siehe Details und bewerben",
        appclosed: "Bewerbung geschlossen",

        jobco: ["Dating Chat Operator", "Ihre Leidenschaft ist das Chatten und der Betrieb von Dating-Seiten und Communities"],
        jobwdev: ["Web Developer", "Sie haben eine Leidenschaft für das Chatten und den Betrieb von Dating-Websites und Communities"],
        jobtl: ["Teamleiter", "Ihre Leidenschaft ist das Chatten und Betreiben von Dating-Websites und Communities."],
        jobdm: ["Digital Marketer", "Sie chatten leidenschaftlich gern und betreiben Dating-Websites und -Communities."],
        jobsup: ["Supervisor", "Sie chatten leidenschaftlich gern und betreiben Dating-Seiten und -Communities"],
        jobwdes: ["Web Designer", "Ihre Leidenschaft ist das Chatten und der Betrieb von Dating-Websites und Communities."],
        
        cotest: "Chat Operator Test",
        cotesttitle: "Test zum Dating Chat Operator bei Creativ'Network",
        cotestint1: `"Creativ'Network's Dating Chat Operator Test bewertet Kommunikationsfähigkeiten, Einfühlungsvermögen und Engagement Fähigkeiten für effektive und respektvolle Benutzer Interaktionen."`,
        cotestint2: "Nehmen Sie sich einen Moment Zeit, um auf alle Situationen zu antworten, bevor wir Ihre Bewerbung weiter bearbeiten.",
        goodluck: "Viel Glück!",
        number: "Nr.",
        youranswer: "Ihre Antwort",
        next: "Weiter",
        back: "Zurück",
        blog: "Blog",
        stat: "Statistik",
        at: "At",
        ourstat: ", unsere Statistiken zeichnen ein anschauliches Bild von unserem Erfolg und Wachstum. Mit einem engagierten Team, zahlreichen Projekten, einem ausgedehnten Netzwerk von Partnern und jahrelanger Erfahrung sind wir eine florierende Kraft in der Branche. Lassen Sie uns in die Details eintauchen, um unseren Weg zu entdecken",
        team: "Teammitglieder",
        projects: "Projekte",
        netpart: "Netzwerkpartner",
        years: "Jahre im Geschäft",
        testimonial: "Zeugnis",
        dedicated:"Engagierte Teammitglieder bei",
        sharing: ", die ihre Erfahrungen, Einsichten und ihren Enthusiasmus für einen lohnenden Arbeitsplatz teilen, an dem die Zusammenarbeit gedeiht",
        testimonialperson1: ["Marie C", "Dating Chat Operator", `"Die Arbeit als Dating Chat Operator bei Creativ'Network ist unglaublich. Ich habe die Möglichkeit, mit Menschen in Kontakt zu treten und jeden Tag etwas in ihrem Leben zu bewirken. Das ist lohnend und macht Spaß!"`],  
        testimonialperson2: ["Lucas P", "Dating Chat Operator", `"Creativ'Network ist der perfekte Ort, um seiner Leidenschaft nachzugehen, Menschen zu helfen, die sich nicht einsam fühlen. Das Team ist fantastisch, und die Atmosphäre ist unterstützend. Ich liebe, was ich tue."`],
        testimonialperson3: ["Sophie M", "Dating Chat Operator", `"Creativ'Network bietet eine einzigartige Plattform, um sinnvolle Verbindungen aufzubauen. Als Dating Chat Operator habe ich das Gefühl, Teil von etwas Besonderem zu sein, mit Menschen zu diskutieren und sie glücklich zu machen."`],
        testimonialperson4: ["Alex R", "Web Developer Front End", `"Creativ'Network fordert mich als Webentwickler heraus. Die innovativen Projekte und das unterstützende Team treiben meine Fähigkeiten zu neuen Höhen. Ich bin stolz darauf, Teil dieses dynamischen technischen Umfelds zu sein."`],
        testimonialperson5: ["Emma H", "Web Developer Back End", `"Ich habe meinen Traumjob als Webentwickler bei Creativ'Network gefunden. Das Engagement des Unternehmens für Spitzentechnologie und Wachstumschancen inspiriert und begeistert mich."`],
        testimonialperson6: ["Tommy J", "Web Designer", `"Als Web Designer bei Creativ'Network habe ich die kreative Freiheit, visuell ansprechende Plattformen zu gestalten. Es ist ein inspirierender Ort, der Wert auf Designästhetik und Benutzererfahrung legt, so dass die Arbeit wirklich Spaß macht."`],

        titlesuffix: " - Creativ-Network",
        titlejobs: "Jobs",
        titlechatoperator: "Chat-Betreiber",
        titletest: "Test ",
        titlepp: "Datenschutzbestimmungen",
        titleterms: "Bedingungen",

        wantto: "Willst du ein erfüllter Arbeitnehmer werden mit ",
        areyou: "Sind Sie leidenschaftlich über Web-Arbeit? Haben Sie Erfahrung in einem der folgenden Bereiche: Chat, Webentwicklung, digitales Marketing, Datenetikettierung, Teleassistenz und Kundenbeziehungen, Telesales, Copywriting, Teleprospecting, Social Media Management, HR, Buchhaltung, Webdesign... ? Werden Sie jetzt Mitglied unserer Community! ",
        check: 'Alle Jobs prüfen und bewerben',

        our: 'Unser',
        missions: 'Missionen',
        advantages: 'Vorteile',


        mission1: "Identifizierung und Anwerbung von Talenten: Das Unternehmen sucht und rekrutiert aktiv Personen mit Fachwissen in webbezogenen Bereichen. Dies beinhaltet die Identifizierung und Anwerbung von Spitzentalenten aus verschiedenen Quellen, einschließlich Stellenausschreibungen, Online-Netzwerken und Empfehlungen",
        mission2: "Bewertung und Screening von Fähigkeiten: Creativ'Network bewertet rigoros die Fähigkeiten und Qualifikationen potenzieller Kandidaten, um sicherzustellen, dass sie die spezifischen Anforderungen des Unternehmens für die zu besetzenden Stellen erfüllen.",
        mission3: "Kulturelle Passung und Werteübereinstimmung: Über die Fähigkeiten hinaus prüft das Unternehmen die Kandidaten auf ihre Übereinstimmung mit den Werten und der Kultur des Unternehmens, um ein kohärentes und produktives Arbeitsumfeld zu fördern",
        mission4: "Stellenanpassung: Creativ'Network schneidet die Positionen auf die Stärken und Fähigkeiten der eingestellten Talente zu und stellt sicher, dass die Rollen auf deren Fachwissen und Interessen abgestimmt sind.",
        mission5: "Talententwicklung und Bindung: Das Unternehmen ist bestrebt, die von ihm eingestellten Talente zu fördern und zu halten, indem es ihnen Möglichkeiten für Wachstum und Aufstieg innerhalb des Unternehmens bietet.",
        mission6: "Marktanalyse und Strategie: Creativ'Network behält den Puls der Branchentrends, der Gehaltsbenchmarks und der sich entwickelnden Qualifikationsanforderungen im Auge, um auf dem webbezogenen Arbeitsmarkt wettbewerbsfähig zu bleiben",
        mission7: "Vielfalt und Einbeziehung: Das Unternehmen kann Vielfalt und Integration in seinem eigenen Talentakquisitionsprozess aktiv fördern und strebt danach, eine Belegschaft zu schaffen, die ein breites Spektrum an Hintergründen und Perspektiven widerspiegelt.",
        mission8: "Zusammenarbeit und Cross-Training: Das Unternehmen fördert die Zusammenarbeit innerhalb seines Talentpools und bietet Möglichkeiten zur bereichsübergreifenden Weiterbildung, so dass die Mitarbeiter ihre Fähigkeiten diversifizieren und zu verschiedenen Aspekten des Unternehmens beitragen können.",
        mission9: "Anpassung an sich entwickelnde Bedürfnisse: Creativ'Network bleibt flexibel und passt sich an die sich ändernden Anforderungen der webbezogenen Bereiche an, in denen es tätig ist. Dazu gehört auch die Aneignung neuer Talente und Fähigkeiten, wenn sich die Technologie und die Branchentrends weiterentwickeln.",
        mission10:"Kontinuierliche Verbesserung: Das Unternehmen überprüft und verfeinert kontinuierlich seine Einstellungs- und Talentmanagementprozesse, um sicherzustellen, dass sie effizient und effektiv sind.",
        mission11: "Mitarbeiterzufriedenheit und Wohlbefinden: Creativ'Network legt großen Wert auf die Mitarbeiterzufriedenheit und bietet Sozialleistungen, Work-Life-Balance und Unterstützung, um das allgemeine Wohlbefinden der Belegschaft zu verbessern.",

        value1: "Exzellenz: Wir streben in allem, was wir tun, nach Spitzenleistungen, von der Rekrutierung von Spitzenkräften bis hin zur Erbringung außergewöhnlicher Dienstleistungen für unsere Kunden.",
        value2: "Kundenorientierter Ansatz: Wir stellen unsere Kunden in den Mittelpunkt unserer Tätigkeit, verstehen ihre individuellen Bedürfnisse und bieten maßgeschneiderte Lösungen, die ihren Erfolg fördern.",
        value3: "Befähigung von Talenten: Wir befähigen Web-Talente, sich beruflich zu entfalten, indem wir sie mit Möglichkeiten in Verbindung bringen, die ihren Fähigkeiten und Wünschen entsprechen.",
        value4: "Innovation und Anpassungsfähigkeit: Wir sind innovationsfreudig und passen uns der sich ständig weiterentwickelnden Landschaft der webbezogenen Bereiche an, so dass wir stets an der Spitze der Branchentrends stehen.",
        value5: "Vielfalt und Eingliederung: Wir würdigen die Vielfalt und fördern ein integratives Umfeld, in dem Menschen mit unterschiedlichem Hintergrund zu unserem gemeinsamen Erfolg beitragen können.",
        value6: "Transparenz und Integrität: Wir halten die höchsten Integritätsstandards ein und pflegen eine transparente Kommunikation mit unseren Kunden, Kandidaten und Teammitgliedern",
        value7: "Kollaboration und Synergie: Wir glauben an die Kraft der Zusammenarbeit und arbeiten zusammen, um gemeinsame Ziele zu erreichen und positive Ergebnisse zu erzielen.",
        value8: "Kontinuierliches Lernen: Wir fördern kontinuierliches Lernen und berufliche Weiterentwicklung und stellen sicher, dass unser Team mit den neuesten Fähigkeiten und Kenntnissen ausgestattet ist.",
        value9: "Branchenkenntnis: Wir sind Branchenexperten, die wertvolle Einblicke in den webbezogenen Arbeitsmarkt bieten und unser Wissen mit Kunden und Kandidaten teilen",
        value10: "Ethisches Verhalten: Wir arbeiten mit unerschütterlichem ethischem Verhalten und behandeln alle Personen mit Respekt, Fairness und Professionalität.",
        value11: "Gesellschaftliches Engagement: Wir engagieren uns aktiv in der Berufsgemeinschaft und tragen zu deren Wachstum durch Mentorenschaft, Bildung und Teilnahme an Branchenverbänden bei.",
        value12: "Wirkung und Verantwortung: Wir übernehmen die Verantwortung für die Auswirkungen, die wir auf die Karrieren von Einzelpersonen und den Erfolg der von uns betreuten Organisationen haben, und streben nach positiven, dauerhaften Ergebnissen",

        advantage1:"Wettbewerbsfähige Vergütungspakete: Bieten Sie überdurchschnittliche oder branchenführende Vergütungen und Zusatzleistungen an, um hochqualifizierte Talente anzuziehen und zu halten, wodurch sich Creativ'Network von der Konkurrenz abheben kann.",
        advantage2:"Leistungsabhängige Boni: Die Einführung leistungsbezogener Bonusstrukturen kann Mitarbeiter zu herausragenden Leistungen motivieren und sicherstellen, dass sie für ihre Beiträge belohnt werden.",
        advantage3:"Maßgeschneiderte Vergütungspläne: Die Anpassung der Vergütungspakete an die Fähigkeiten, Erfahrungen und Leistungen der einzelnen Mitarbeiter kann zu einer höheren Arbeitszufriedenheit und Loyalität führen.",
        advantage4:"Berufliche Entwicklungsmöglichkeiten: Klare Aufstiegs- und Entwicklungsmöglichkeiten innerhalb des Unternehmens, die ehrgeizige Fachkräfte anziehen und die interne Talentbindung fördern können.",
        advantage5:"Programme zur Mitarbeiteranerkennung: Die Anerkennung und Belohnung herausragender Leistungen und Erfolge von Mitarbeitern kann die Arbeitsmoral steigern und die Verbleibquote erhöhen.",
        advantage6:"Flexible Arbeitsregelungen: Das Angebot flexibler Arbeitsmöglichkeiten, wie Fernarbeit oder flexible Arbeitszeiten, kann die Work-Life-Balance verbessern und ein breiteres Spektrum von Bewerbern ansprechen.",
        advantage7:"Umfassende Leistungspakete: Die Bereitstellung wettbewerbsfähiger Leistungen wie Krankenversicherung, Altersvorsorge und Wellness-Programme kann Spitzenkräfte anziehen und das Wohlbefinden der Mitarbeiter fördern.",
        advantage8:"Investitionen in Ausbildung und Entwicklung: Die Unterstützung von kontinuierlichem Lernen und beruflichem Wachstum durch Schulungsprogramme und Bildungsmöglichkeiten kann hochqualifizierte und motivierte Arbeitskräfte fördern.",
        advantage9:"Transparente Vergütungspraktiken: Eine transparente Kommunikation über Vergütungspraktiken und Gehaltsrichtwerte kann Vertrauen und Transparenz im Personalmanagement schaffen.",
        advantage10:"Hilfsprogramme für Mitarbeiter: Das Angebot von Hilfsprogrammen für persönliche und berufliche Herausforderungen kann das Engagement für das Wohlbefinden der Mitarbeiter und die Zufriedenheit am Arbeitsplatz demonstrieren.",
        advantage11:"Leistungsbewertungen und Feedback: Regelmäßige Leistungsbeurteilungen und konstruktives Feedback können den Mitarbeitern helfen, ihre berufliche Entwicklung und die verbesserungswürdigen Bereiche zu verstehen.",
        advantage12:"Marktanalyse für wettbewerbsfähige Gehälter: Durchführung von Marktanalysen, um sicherzustellen, dass die Gehälter und Sozialleistungen wettbewerbsfähig bleiben und mit den Branchenstandards übereinstimmen.",
        advantage13:"Anreiz-Strukturen: Entwicklung kreativer Anreizstrukturen, die außergewöhnliche Leistungen, Innovationen und Beiträge zur Erreichung der Unternehmensziele belohnen.",
        
        blog2: "Hier finden Sie unsere Beiträge",
        ourposts: "Unsere Posten",
        blog3: "Viel Spaß beim Lesen unserer Beiträge",
        datepub: "15/10/2023",
        months: ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
        uncategorized: "Unkategorisiert",

        blognumber1: ["Der Weg zu einer auffälligen Landschaft", "Die neue gemeinsame Sprache wird einfacher und regelmäßiger sein als die bestehenden europäischen Sprachen. Sie wird so einfach sein wie das Abendländische, ja, sie wird sogar abendländisch sein. Einem Engländer wird sie wie vereinfachtes Englisch vorkommen, so wie mir ein skeptischer Freund aus Cambridge erklärt hat, was Okzidental ist. Die europäischen Sprachen gehören zur gleichen Familie."], 
        blognumber2: ["Schwerwiegende Probleme mit Kabeln in der Stadt", "Die neue gemeinsame Sprache wird einfacher und regelmäßiger sein als die bestehenden europäischen Sprachen. Sie wird so einfach sein wie das Abendländische, ja, sie wird sogar abendländisch sein. Einem Engländer wird sie wie vereinfachtes Englisch vorkommen, so wie mir ein skeptischer Freund aus Cambridge erklärt hat, was Okzidental ist. Die europäischen Sprachen gehören zur gleichen Familie."], 
        blognumber3: ["Montain und das kalte Winterwetter", "Wenn mehrere Sprachen miteinander verschmelzen, ist die Grammatik der entstehenden Sprache einfacher und regelmäßiger als die der einzelnen Sprachen. Die neue gemeinsame Sprache wird einfacher und regelmäßiger sein als die bestehenden europäischen Sprachen. Sie wird so einfach sein wie die abendländische Sprache, ja sie wird sogar abendländisch sein."], 

        by: "von",
        noc: "kein",
        comments: "Kommentare",
        comment: "Kommentar",
        readmore: "Mehr lesen",

        cookiestitle: "Verwaltung der Zustimmung zu Cookies",
        cookiescontent: "Die Cookie-Einwilligungserklärung von Creativ'Network ist für eine nahtlose und konforme Online-Erfahrung unerlässlich. Wir holen die Zustimmung der Nutzer auf klare Weise ein, indem wir detaillierte Informationen über unsere Cookie-Praktiken bereitstellen. Mit Personalisierungsoptionen, einer expliziten Kategorisierung von Cookies und einer Verpflichtung zum Datenschutz schafft Creativ'Network eine sichere und respektvolle digitale Erfahrung. Wir halten uns strikt an die Datenschutzstandards und gewährleisten so die Sicherheit und das Vertrauen der Nutzer in unsere Plattform",
        pref: "Einstellungen",
        cookiessm1i: "Funktional",
        cookiessm2i: "Statistik",
        cookiessm3i: "Marketing",
        cookiessm1c: `Die "Speicherung oder der technische Zugriff" auf die Creativ'Network-Website beinhaltet die Verwendung von Cookies, um die Benutzerfreundlichkeit zu verbessern. Dazu gehört auch die Erfassung technischer Daten, die für das Funktionieren der Website unerlässlich sind. Wir holen die Zustimmung der Nutzer ein und stellen transparente Informationen über diese Praktiken in Übereinstimmung mit den Datenschutzstandards und den geltenden Vorschriften zur Verfügung.`,
        cookiessm2c: `Die Statistiken in der Verwaltung der Cookie-Einwilligung für Creativ'Network ermöglichen es, analytische Daten zu sammeln. Sie spielen eine entscheidende Rolle bei der Auswertung der Surfgewohnheiten der Nutzer, wodurch die kontinuierliche Verbesserung der Website erleichtert wird. Diese Informationen dienen als Entscheidungshilfe bei der Optimierung der Benutzererfahrung unter Berücksichtigung der Datenschutzeinstellungen, wodurch eine personalisierte und konforme Plattform gewährleistet wird`,
        cookiessm3c: `Die Funktion "Marketing" in der Cookie-Zustimmungsverwaltung auf der Creativ'Network-Website ermöglicht es uns, Werbung an die Vorlieben der Nutzer anzupassen. Indem wir ihre Zustimmung einholen, gewährleisten wir eine zielgerichtete und relevante Werbeerfahrung. Dies gewährleistet eine effektivere Werbekommunikation, während gleichzeitig die Datenschutzstandards und die individuellen Entscheidungen der Nutzer respektiert werden.`,
        savepref: "Einstellungen speichern",
        accept: "Akzeptieren",
        alwyasactive: "Immer aktiv",
        error: "Fehler",

        tags: "Tags: Keine Tags",
        comment1: "Einen Kommentar hinzufügen",
        comment2: "Deine E-Mail Adresse wird nicht veröffentlicht.",
        comment3: "Name (erforderlich)",
        comment4: "E-Mail oder Benutzername",
        comment5: "Website",
        comment6: "Speichere meinen Namen, meine E-Mail und meine Website in diesem Browser, wenn ich das nächste Mal kommentiere.",
        comment7: "Kommentar",
        follow: "Folgen Sie ",
        us: "uns",
        creatememberspace: "Erstellen Sie Ihren Mitgliederbereich",
        createmsint11: "Bitte geben Sie den Code ein, den wir an",
        createmsint12: "geschickt haben, um Ihre E-Mail-Adresse zu bestätigen",
        createmsint2: "Wenn Sie Ihre E-Mail-Adresse bestätigen, wird Ihr Mitgliederbereich automatisch erstellt",
        createmsconsent: "Mit dem Absenden Ihres Bewerbungsformulars akzeptieren Sie unsere",
        confirm: "Bestätigen",
        codevalidationerror: "Der Validierungscode ist falsch",
        confirmpassword: "Passwort bestätigen",
        passwordlength: `Das Passwort muss mindestens ${mdpminlength} Zeichen enthalten`,
        passwordnotidentical: "Die Passwörter sind nicht identisch",
        welcomeback: "Willkommen zurück",
        signinint: "Um mit uns in Verbindung zu bleiben, melden Sie sich bitte mit Ihren persönlichen Daten an",
        signin: "Einloggen",
        createaccount: "Ein Konto erstellen",
        oruseyourpersonnalmail: "Oder verwenden Sie Ihre persönliche E-Mail für die Registrierung",
        email2: "E-Mail",
        signup: "Registrieren",
        memberarea: "Mitgliederbereich",
        yourmailpseudo: "Ihre E-Mail Adresse / Benutzername",
        yourpassword: "Ihr Passwort",
        idorpasswordforgot: "Klicken Sie hier, wenn Sie Ihr Passwort vergessen haben",
        settingupaccount: "Ihr Konto einrichten",
        settingupaccountint: "Um Ihr Konto einzurichten, müssen Sie die folgenden Informationen angeben:",
        yourfirstname: "Ihr Vorname",
        yourlastname: "Ihr Nachname",
        yourusername: "Ihr Benutzername",
        yourskypeid: "Ihre skype ID",
        yourbirthdate: "Ihr Geburtsdatum",
        yourgender: "Ihr Geschlecht",
        yourphonenumber: "Ihre Telefonnummer",
        yourmailaddress: "Ihre E-Mail Adresse",
        appartroad: "Wohnung / Straßennummer",
        yourcitydep: "Ihre Stadt / Abteilung",
        yourpobox: "Ihr Postfach",
        yourcountry: "Ihr Land",
        agreepp1: "Bevor Sie Ihre Daten übermitteln, müssen Sie unserem",
        agreepp2: "zustimmen",
        note: "Anmerkung",
        notecontent: "Um Ihr Konto zu aktivieren, überprüfen Sie bitte Ihren Posteingang",
        createmyaccount: "Mein Konto einrichten",
        male: "Männlich",
        female: "Weiblich",
        other: "Andere",
        mailexists: "Diese E-Mail-Adresse existiert bereits",
        usernameexists: "Dieser Benutzername existiert bereits",

        dashboard: "Dashboard", 
        dashboard1: "Verwalten Sie Ihr Creativ'Network-Konto. Ändern Sie Ihre Einstellungen und sehen Sie sich Berichte an",
        dashboard2: "Steuerkanal",
        
        information: "Ihre Informationen", 
        information1: "Verwalten Sie Ihre persönlichen Informationen",
        information2: "Ändern Sie Ihr Passwort",

        yourapplication: "Ihre Bewerbung",
        application1: "Lebenslauf hochladen/ändern ",
        application2: "Überprüfen Sie den Status Ihrer Bewerbung",

        integration: "Ihre Integration",
        integration1: "Hochladen / Ändern der erforderlichen Dokumente",
        integration2: "Hochladen / Ändern Ihrer Zahlungsinformationen",

        info11: "Ändern Sie Ihr Profilfoto",
        info12: "Laden Sie Ihr Foto vom Browser hoch",
        info13: "Browser",
        info14: "Laden Sie Ihr Foto von Ihrer Kamera hoch",

        save: "Speichern",
        admin: "Verwalter",
        blogger: "Blogger",
        teamleader: "Teamleiter",
        Anzeigetafel: "Admin-Dashboard",
        adboard: "Verwaltungs-Dashboard",
        tldboard: "Teamleiter-Dashboard",
        bdboard: "Blogger-Dashboard",
        notifs: 'Benachrichtigungen',
        logout: 'Abmelden',
        actualpassword: "Ihr aktuelles Passwort",
        newpassword: "Ihr neues Passwort",
        confirmnewpassword: "Bestätigen Sie Ihr Passwort",

        app11: "Laden Sie Ihren Lebenslauf hoch",
        app12: "Lebenslauf über den Browser hochladen",

        appstatus1: "Bewerbungsformular",
        submittedon:"Eingereicht am",
        appstatus2: "Test",
        notyet: "Noch nicht erledigt",
        reviewedon: "Rezensiert am",
        appstatus3: "Status der Überprüfung",
        reviewed: "Überprüft",
        appstatus4: "Entscheidung des Managements",
        hired: "Herzlichen Glückwunsch, Sie wurden eingestellt, bitte gehen Sie zum nächsten Schritt über: Ihre Integration",
        nothired: "Es tut uns leid, aber Ihre Bewerbung wurde nicht berücksichtigt." ,
        idtl: 'Teamleiter-ID',
        ida: 'Verwalter-ID',
        idb: 'Blogger-ID',
        positionoverview: 'Stellenübersicht',
        adminstaff: 'Verwaltungspersonal',
        jobposts: 'Stellenanzeigen',
        jobapplication: 'Stellenbewerbung',
        newemptraining: 'Schulung für neue Mitarbeiter',
        empintegration: 'Integration von Mitarbeitern',
        blogposts: "Blogbeiträge",
        details: "Details",
        cnmembers: "Mitglieder von Creativ-Network",
        sn: "Soziales Netzwerk",
        aas1: "Liste des Verwaltungspersonals",
        aas2: "Verwaltungspersonal hinzufügen",
        aja1: "Alle Bewerbungen",
        aja2: "Details der Bewerbungen prüfen",
        aja3: "Erfolgreiche Bewerbungen",
        anet1: "Ausbildungsplan",
        anet2: "Schulungsunterlagen hinzufügen",
        anet3: "Agenten warten auf die Anmeldung",
        anet4: "Login-Zuordnung",
        aei1: "Neue Agenten überprüfen",
        abp1: "Liste der Blogger",
        abp2: "Blogger hinzufügen",
        abp3: "Liste der Blogbeiträge",
        abp4: "Blogeinträge hinzufügen",
        ajp1: "Liste der Stellenanzeigen",
        ajp2: "Stellenanzeigen hinzufügen",
        acnm1: "Liste der Mitglieder",
        acnm2: "Mitglieder hinzufügen",
        anl1: "Liste der Abonnenten",
        anl2: "Alle Newsletters",
        anl3: "Newsletter hinzufügen",
        asn1: "Alle sozialen Netzwerke",
        passportcopy: "Kopie des Reisepasses, des Führerscheins oder des Personalausweises",
        proofofaddress: "Nachweis der Adresse",
        yourselfie: "Ihr Selfie mit Ihrem Ausweis",
        freelance: "Freiberuflicher Vertrag",
        integ1up1: "Laden Sie Ihren Identitätsnachweis über den Browser hoch",
        integ1up2: "Laden Sie Ihren Adressennachweis über den Browser hoch",
        integ1up3: "Laden Sie Ihr Selfie vom Browser hoch",
        integ1up4: "Laden Sie Ihr Selfie von Ihrer Kamera hoch",
        integ1download: "Freelance-Vertrag herunterladen, unterschreiben und zurücksenden",
        integ1up5: "Laden Sie Ihren unterzeichneten Freelance-Vertrag über den Browser hoch",
        download: "Herunterladen",

        
        paypalaccount: "Paypal-Konto",
        yourpaypal: "(Ihre Paypal-E-Mail-Adresse)",
        wiseaccount: "Wise-Konto",
        yourwise: "(Ihre Wise-E-Mail-Adresse)",
        bitesafeaccount: "Yoursafe Konto",
        yourbitesafe: "(Ihre Yoursafe-E-Mail-Adresse)",
        bankaccount: "Ihr Bankkonto",
        accountholder: "Name des Kontoinhabers:",
        holderaddress: "Adresse des Kontoinhabers:",
        banksholder: "Name der Inhaberbank:",
        bankaddress: "Adresse der Inhaberbank: ",
        iban: "IBAN / Kontonummer:",
        swiftcode: "Swiftcode / BIC: ",
        year: 'Jahr',
        month: 'Monat',
        date: 'Datum',
        week: 'Woche',
        today: 'Heute',
        startingdate: 'Anfangsdatum',
        closingdate: 'Abschlussdatum',
        adminstafflogin: 'Login des Verwaltungspersonals',
        adminstaffloginpassword: 'Login-Passwort des Verwaltungspersonals',
        actions: 'Aktionen',
        suspend: 'Suspendieren',
        fire: "Verbieten",
        candidateid: 'Kandidaten-ID',
        language: "Sprache",
        edit: "Bearbeiten",
        openingposition: "Öffnungsposition",
        jobtitle: "Titel der Stelle",
        jobdescription: "Beschreibung der Stelle",
        openclose: "Verfügbar / Nicht verfügbar",
        jobopen: "Verfügbar",
        jobclose: "Nicht verfügbar",
        delete: "Löschen",
        applicantfirstname: "Vorname des Antragstellers",
        applicantlastname: "Nachname des Bewerbers",
        applicantcountry: 'Land des Bewerbers',
        application: 'Bewerbung',
        checkthapplication: "Überprüfen Sie die Bewerbung",
        agentfirstname: "Vorname des Bearbeiters",
        agentlastname: "Nachname des Bearbeiters",
        agentcountry: "Land des Bearbeiters",
        agentemail: "E-Mail des Agenten",
        agentskypeid: "Skype-ID des Vertreters",
        trainingschedule: "Schulungsplan",
        sendconfirmationonemailtoagent: "Bestätigungs-E-Mail an den Agenten senden",
        memberopt1: "Verwalten Sie Ihre Informationen",
        memberopt2: "Überprüfen Sie Ihre Bewerbung",
        memberopt3: "Gehen Sie zu Ihrer Integration",
        awaitingforlogin: "Warten auf die Anmeldung",
        awaitingforemailconf: "Warten auf Bestätigungs-E-Mail",
        agentlogin: "Agent-Anmeldung",
        freelancecontract: "Freelance-Vertrag",
        trainingdocumentssigned: "Schulungsunterlagen unterzeichnet",
        proofofresidence: "Wohnsitznachweis",
        paymentmethod: "Zahlungsmethode",
        provided: "Bereitgestellt",
        notprovided: "Nicht vorgesehen",
        bank: "Bank",
        wise: "Wise",
        paypal: "Paypal",
        Yoursafe: "Yoursafe",
        activeblogger: "Aktiver Blogger",
        bloggerfirstname: "Vorname des Bloggers",
        bloggerlastname: "Nachname des Bloggers",
        bloggerloginid: "Blogger-Anmelde-ID",
        bloggerloginpassword: "Blogger-Login-Passwort",
        blogposttile: "Titel des Blogbeitrags",
        blogpostcategory: "Kategorie des Blogbeitrags",
        blogpostlink: "Link zum Blogbeitrag",
        category: "Kategorie",
        newmembers: "Neue Mitglieder",
        allmembers: "Alle Mitglieder",
        inscriptdate: "Einschreibedatum",
        memberfirstname: "Vorname des Mitglieds",
        memberlastname: "Nachname des Mitglieds",
        loginpassword: "Anmeldekennwort",
        emailaddressconfirmed: "E-Mail Adresse bestätigt",
        country: "Land",
        newsubscribers: "Neue Abonnenten",
        allsubscribers: "Alle Abonnenten",
        subscriptiondate: "Datum des Abonnements",
        topic: "Thema",
        allnewsletters: "Alle Newsletters",
        releasedate: "Freigabedatum",
        releaselanguage: "Sprache der Veröffentlichung",
        linktothenewsletter: "Link zum Newsletter",
        sendtoallsubscribers: "An alle Abonnenten senden",

        agentid: "Agenten-ID",
        trainingprocess: "Ausbildungsprozess",
        integrationprocess: "Integrationsprozess",
        jobposition: "Arbeitsstelle",
        training: "Ausbildung",
        integrationn: "Integration",
        inprogress: "In Arbeit",
        done: "Erledigt",
        role: "Rolle",
        skypeid: "Skype ID",
        createnewadminstaff: "Neues Verwaltungspersonal anlegen",
        jobid: "Job-ID",
        illustrationphoto: "Illustrationsfoto",
        location: "Standort",
        jobrequirement: "Beschäftigungsbedingungen",
        salaryscale: "Gehaltsskala",
        incentives: "Incentives",
        openingdate: "Datum der Eröffnung",
        telephonenumber: "Telefonnummer",
        createnewjobposts: "Neue Stellenanzeige erstellen",
        haveexperience: "Haben Erfahrung",
        howtheyfindus: "Wie sie uns gefunden haben",
        motivation: "Motivation",
        testresult: "Testergebnis",
        decision: "Entscheidung",
        hired2: "Eingestellt",
        rejected: "Abgelehnt",
        description: "Beschreibung",

        name: "Name",
        document: "Dokument",
        blogpostid: "Blogpost-ID",
        bloggerid: "Blogger-ID",
        blogposttitle: "Titel des Blogbeitrags",
        blogposttext: "Text des Blogbeitrags",
        publishnewblogpost: "Neuen Blogeintrag veröffentlichen",
        createnewblogger: "Neuen Blogger erstellen",
        createnewlogin: "Neues Login erstellen",
        addblogpostcategory: "Blogpost-Kategorie hinzufügen",
        blogpostcategoryid: "Blogpost-Kategorie-ID",
        memberid: "Mitglieds-ID",
        addnewmemberandsendnotification: "Neues Mitglied hinzufügen und Benachrichtigung senden",
        newsletterid: "Newsletter-ID",
        adminid: "Verwaltungs-ID",
        newslettercategory: "Newsletter-Kategorie",
        newslettertitle: "Titel des Newsletters",
        newslettertext: "Text des Newsletters",
        allsubscribersemail: "E-Mail Adresse aller Abonnenten",
        publishnewnewsletter: "Neuen Newsletter veröffentlichen",
        link: "Link",
        hello : "Hallo",

        tlintint1: "Möchten Sie als",
        tlintint2: "von Dating-Chat-Betreibern zu Hause arbeiten?",
        tlint1: "1. wirkungsvolle Führung: Leiten Sie ein Team, das Verbindungen herstellt, sinnvolle Beziehungen fördert und zu einer dynamischen Dating-Umgebung beiträgt.",
        tlint2: "2. Gelegenheit für Teamwachstum: Führen und entwickeln Sie ein zusammenhängendes Team, das persönliches und berufliches Wachstum in einer unterstützenden Arbeitsumgebung fördert.",
        tlint3: "3. innovativer Beitrag: Führen Sie kreative Lösungen ein, gestalten Sie die Zukunft des Online-Datings und verbessern Sie Chat-Dienste und tragen Sie so zur Innovation der Branche bei.",
        tlint4: "4. anerkennung und leistung: Führen Sie ein erfolgreiches Team, übertreffen Sie die Zielvorgaben und erhalten Sie Anerkennung, mit einem wettbewerbsfähigen Gehalt und verlockenden Anreizen.",
        tlint5: "5. Belohnungen für herausragende Leistungen: Genießen Sie ein gutes Gehalt, lukrative Anreize und Anerkennung für das Erreichen von Zielen, die einen bedeutenden Einfluss auf Creativ'Network haben.",
        tlabint1: "Worum geht es bei der Arbeit eines",
        tlabint2: "von Dating-Chat-Betreibern?",
        tlab1: "1. Führung und Koordinierung: Als Teamleiter beaufsichtigen Sie alle Dating-Chat-Betreiber, indem Sie eine starke Führungsrolle übernehmen, das Team leiten und für eine kohärente Kommunikation sorgen.",
        tlab2: "2. Leistungsmanagement: Festlegen und Überwachen von Leistungskennzahlen, wie z. B. Antwortzeiten und Nutzerzufriedenheit, um eine hohe Servicequalität zu erreichen und eine positive Nutzererfahrung zu fördern.",
        tlab3: "3. teamentwicklung: Förderung eines kohäsiven und motivierten Teams durch Anleitung, Mentorenschaft und Förderung einer unterstützenden Arbeitsumgebung für persönliches und berufliches Wachstum.",
        tlab4: "4. innovative Chat-Dienste: Einführung kreativer Ansätze zur Verbesserung von Chat-Diensten, Verfolgen von Branchentrends und Anpassen von Strategien zur Verbesserung der Nutzerbindung",
        tlab5: "5. qualitätssicherung: Sicherstellen, dass die Betreiber einen hohen Servicestandard einhalten, auf die Bedürfnisse der Nutzer eingehen und Probleme umgehend lösen, um ein positives und vertrauenswürdiges Nutzererlebnis zu gewährleisten.",
        tlab6: "6. Kommunikationsdrehscheibe: Dient als zentraler Punkt für die Kommunikation zwischen Betreibern, Management und anderen Abteilungen und erleichtert die Zusammenarbeit, um die Unternehmensziele im dynamischen Bereich des Online-Datings zu erreichen.",
        tlab7: "7. strategische Planung: Tragen Sie zur strategischen Planung des Teams bei, indem Sie die Ziele mit den Unternehmenszielen im dynamischen Bereich des Online-Datings in Einklang bringen.",
        tllsint1: "Welche Führungsqualitäten braucht man als",
        tllsint2: "von Dating-Chat-Betreibern?",
        tlls11: "Führungserfahrung",
        tlls12: "Nachgewiesene Erfahrung in der Leitung und Verwaltung von Teams, die effektive Führungs- und Mentorenfähigkeiten erkennen lassen.",
        tlls21: "Kommunikationsfähigkeiten",
        tlls22: "Ausgeprägte mündliche und schriftliche Kommunikationsfähigkeiten, die eine klare Kommunikation zwischen den Teammitgliedern und mit anderen Abteilungen ermöglichen",
        tlls31: "Branchenkenntnisse",
        tlls32: "Vertrautheit mit der Online-Dating-Branche, Verständnis der Nutzerdynamik, Trends und Herausforderungen bei der Bereitstellung von Dating-Chat-Diensten",
        tlls41: "Problemlösungsfähigkeiten",
        tlls42: "Nachgewiesene Fähigkeit, Probleme effizient zu analysieren und zu lösen, insbesondere bei der Behandlung von Nutzerproblemen und der Aufrechterhaltung einer positiven Nutzererfahrung",
        tlls51: "Leistungsmanagement",
        tlls52: "Erfahrung im Festlegen, Überwachen und Optimieren von Leistungskennzahlen, um einen hochwertigen Service, Benutzerzufriedenheit und Teameffizienz zu gewährleisten",
        tlls61: "Anpassungsfähigkeit und Innovation",
        tlls62: "Fähigkeit, sich an Veränderungen in der Branche anzupassen, innovative Strategien einzuführen und aufkommenden Trends bei Online-Dating-Diensten einen Schritt voraus zu sein.",
        tlls71: "Fähigkeiten zur Teamentwicklung",
        tlls72: "Nachgewiesene Fähigkeit, das Wachstum des Teams zu fördern, Anleitung und Mentoring zu bieten und ein positives Arbeitsumfeld für die persönliche und berufliche Entwicklung zu schaffen",
        tlls81: "Schwerpunkt Qualitätssicherung",
        tlls82: "Engagement für die Aufrechterhaltung eines hohen Servicestandards, Eingehen auf die Bedürfnisse der Nutzer und Durchführung von Qualitätssicherungsmaßnahmen für eine positive Nutzererfahrung.",
        tlls91: "Zusammenarbeit und Koordinierung",
        tlls92: "Ausgeprägte Fähigkeiten zur Zusammenarbeit, die als zentrale Anlaufstelle für die Kommunikation dienen und eine wirksame Koordinierung zwischen Betreibern, Management und anderen Abteilungen ermöglichen.",

        tlreint1: "Was sind die Anforderungen an einen",
        tlreint2: "für Creativ'Network?",
        tlre12: "Ein Teamleiter für Creativ'Network muss die wichtigsten Arbeitssprachen fließend beherrschen, mehrsprachig sein, sich klar artikulieren können, regionale Besonderheiten verstehen, gut schriftlich kommunizieren können und die Interaktion mit den Benutzern und die Zusammenarbeit mit anderen Abteilungen beherrschen.",
        tlre22: "Die Festlegung einer Altersgrenze von 25+ für einen Creativ'Network Team Leader kann ein Gleichgewicht von Erfahrung und Reife gewährleisten. Diese Politik fördert verschiedene Führungsqualitäten und erhält gleichzeitig ein dynamisches und effizientes Team.",
        tlre42: "Creativ'Network-Teamleiter müssen die Datenschutzerklärung und die Vertragsbedingungen anerkennen und akzeptieren, um die Einhaltung des Datenschutzes und der vertraglichen Verpflichtungen zu gewährleisten und eine sichere und transparente Arbeitsbeziehung zu fördern.",
        tlre61: "Berichte",
        tlre62: "Die Teamleiter von Creativ'Network erstellen detaillierte Berichte, in denen die Teamleistung, Metriken zur Nutzerbindung und behandelte Probleme zusammengefasst werden. Diese Berichte dienen als Grundlage für strategische Entscheidungen, zeigen verbesserungswürdige Bereiche auf und gewährleisten eine optimale Servicebereitstellung.",
        tlre72: "Creativ'Network-Teamleiter verpflichten sich, mindestens 40 Wochenstunden zu arbeiten und gelegentlich an Wochenenden und Feiertagen zur Verfügung zu stehen. Dies gewährleistet eine kontinuierliche Unterstützung des Teams und die Aufrechterhaltung optimaler Servicestandards.",
        tlre81: "Steuer",
        tlre82: "Als Selbständiger ist der Creativ'Network Team Leader für die Verwaltung und Zahlung seiner eigenen Steuern verantwortlich. Dies beinhaltet die Einhaltung der Steuervorschriften und die Erfüllung der finanziellen Verpflichtungen in den jeweiligen Ländern.",
        tlre92: "Creativ'Network Team Leader sorgen für die strikte Einhaltung von rechtlichen und regulatorischen Richtlinien und fördern ein konformes Umfeld. Dazu gehört die Einhaltung der Privatsphäre der Nutzer, des Datenschutzes und anderer branchenspezifischer Vorschriften zur Wahrung der Integrität der Plattform.",
        tlic1: "Altersüberprüfung: Creativ'Network überprüft das Alter der Teamleiter-Bewerber (25+), um sicherzustellen, dass die Kandidaten die Erfahrung, Reife und Stabilität besitzen, die für eine effektive Führung erforderlich sind. Dieser Ansatz steht im Einklang mit dem Ziel des Unternehmens, erfahrene Führungskräfte mit nachgewiesener Erfolgsbilanz zu haben, die zu einem erfolgreichen Teammanagement beitragen und ein positives Arbeitsumfeld fördern.",
        tlic2: "Sicherheit und Vertrauen: Die Altersüberprüfung für Creativ'Network Team Leader-Bewerber im Alter von 25+ wird eingeführt, um die Sicherheit und das Vertrauen zu erhöhen. Durch die Einhaltung dieses Kriteriums will die Plattform ein reifes und erfahrenes Führungsteam aufbauen und so ein sicheres und vertrauenswürdiges Umfeld für Nutzer und Teammitglieder fördern.",
        tlas1: "Besuchen Sie zunächst die offizielle Website oder das Karriereportal von Creativ'Network.|Suchen Sie die Stellenausschreibung für die Position des Teamleiters und klicken Sie auf \"Bewerben\".|Füllen Sie das Bewerbungsformular aus, in dem Sie Ihre persönlichen Daten, Kontaktdaten, relevante Erfahrungen und Ihre Motivation, mit uns zu arbeiten, angeben.|Sie werden aufgefordert, einen kurzen Kompetenztest zu absolvieren, der in der Regel etwa 10 Minuten dauert. In diesem Test werden Ihre Fähigkeiten in den Bereichen Führung, Kommunikation, Leistungsmanagement, Innovation, Teamentwicklung, Qualitätssicherung, Zusammenarbeit und Anpassungsfähigkeit bewertet.",
        tlas2: "Nach erfolgreichem Abschluss der Erstanmeldung und des Kompetenztests müssen Sie einen ID-Verifizierungsprozess durchlaufen, um Ihr Alter zu bestätigen.|Sie werden aufgefordert, eine Kopie eines gültigen amtlichen Ausweises vorzulegen, z. B. eines Reisepasses, Führerscheins oder Personalausweises. Anhand dieses Dokuments wird überprüft, ob Sie das für die Stelle vorgeschriebene Mindestalter erreichen.",
        tlas3: "Sobald Ihre Bewerbung und Ihr Alter bestätigt sind, werden Sie zu einer Online-Schulung via Skype eingeladen.|Während der Schulung werden die Manager von Creativ'Network Sie anleiten und Ihnen zeigen, wie Sie Ihre Rolle als Teamleiter effektiv ausfüllen können. Sie werden die Unternehmensrichtlinien, bewährte Praktiken sowie Führungs- und Kommunikationsstrategien besprechen, um in Ihrer Position zu glänzen.",
        appformfor: "Bewerbungsformular für",
        tofilltheapplicationform: "Um das Bewerbungsformular auszufüllen, melden Sie sich bitte an oder registrieren Sie sich auf unserer Website",
        supervisor: "Vorgesetzter",
        qualitysupervisor: "Qualitätsbeauftragter",
        sintint1: "Möchten Sie von zu Hause aus als",
        sintint2: "für Dating-Chat-Betreiber arbeiten?",
        sint1: "1. Qualitätsführerschaft: Gewährleisten Sie hervorragende Chat-Dienste, gestalten Sie positive Nutzererfahrungen und fördern Sie hochwertige Interaktionen, alles bequem von zu Hause aus.",
        sint2: "2. Wettbewerbsfähige Entlohnung: Sie erhalten ein großzügiges Gehalt, lukrative Anreize und die Flexibilität der Fernarbeit, die eine finanzielle Belohnung für die Aufrechterhaltung der Qualität bietet.",
        sint3: "3. Teamentwicklung: Betreuung und Anleitung der Mitarbeiter, Förderung der Servicequalität, des persönlichen Wachstums und der beruflichen Entwicklung in einer virtuellen Arbeitsumgebung.",
        sint4: "4. Innovative Beiträge: Einführung kreativer Strategien, Verbesserung der Chat-Dienste und Beitrag zur Brancheninnovation, um ein hochwertiges Nutzererlebnis im Creativ'Network zu gewährleisten.",
        sint5: "5. Anerkennung und Belohnungen: Führen Sie ein hochqualitatives Team, übertreffen Sie die Messwerte und erhalten Sie Anerkennung durch ein wettbewerbsfähiges Gehalt, das eine erfüllende und lohnende Remote-Karriere gewährleistet.",
        sint6: "6. Work-Life-Balance: Genießen Sie die Flexibilität, von zu Hause aus zu arbeiten und eine harmonische Work-Life-Balance zu erreichen, während Sie gleichzeitig die Qualitätsstandards bei Creativ'Network sicherstellen und verbessern.",
        sabint1: "Worum geht es im Job eines",
        sabint2: "für Dating-Chat-Betreiber?",
        sab1: "1. Leistungsüberwachung: Quality Supervisors überwachen die Betreiber genau und stellen sicher, dass die Leistungskennzahlen, die Reaktionszeiten und die Nutzerzufriedenheit eingehalten werden, was zu einer positiven Nutzererfahrung beiträgt.",
        sab2: "2. Feedback und Schulung: Geben Sie konstruktives Feedback, führen Sie Schulungen durch und leiten Sie die Mitarbeiter an, ihre Kommunikationsfähigkeiten kontinuierlich zu verbessern, um die Qualität der Interaktionen zu gewährleisten.",
        sab3: "3. Problemlösung: Umgehend auf Benutzeranliegen eingehen, Probleme lösen und einen hohen Servicestandard aufrechterhalten, um ein positives Benutzererlebnis zu gewährleisten.",
        sab4: "4. Qualitätssicherungsprotokolle: Implementierung und Durchsetzung von Qualitätssicherungsprotokollen, um die konsequente Einhaltung der festgelegten Standards für Chat-Dienste auf Creativ'Network zu gewährleisten.",
        sab5: "5. Innovation und Optimierung: Einführung innovativer Strategien zur Optimierung der Chat-Dienste, um den Branchentrends voraus zu sein und die Gesamtqualität der Benutzerinteraktionen zu verbessern.",
        sab6: "6. Teamzusammenarbeit: Fördern Sie eine kollaborative Teamumgebung, indem Sie die Kommunikation und Zusammenarbeit zwischen den Betreibern fördern und einen einheitlichen Ansatz für die Bereitstellung hochwertiger Dating-Chat-Dienste sicherstellen.",
        sskint1: "Welche Fähigkeiten braucht man als",
        sskint2: "für Dating-Chat-Betreiber?",
        leadership: "Führungsqualitäten",
        communication: "Kommunikation",
        analyticalskills: "Analytische Fähigkeiten",
        pbsolving: "Problemlösung",
        qualityassurance: "Qualitätssicherung",
        traininganddev: "Ausbildung und Entwicklung",
        innovation: "Innovation",
        collab: "Zusammenarbeit",
        adaptability: "Anpassungsfähigkeit",
        ssk1: "Außergewöhnliche Führungsqualitäten, um ein Team von Chat-Operatoren zu leiten und zu inspirieren und ein positives und produktives Arbeitsumfeld zu fördern",
        ssk2: "Ausgeprägte Kommunikationsfähigkeiten in Wort und Schrift, um den Operatoren klare Anweisungen zu geben, Feedback zu geben und sie zu schulen und so eine effektive Interaktion mit den Nutzern zu gewährleisten.",
        ssk3: "Fähigkeit, Leistungskennzahlen zu analysieren, verbesserungswürdige Bereiche zu ermitteln und Strategien zur Verbesserung der Gesamtqualität des Chat-Services umzusetzen.",
        ssk4: "Geschicktes Lösen von Benutzerproblemen und zeitnahe Bewältigung betrieblicher Herausforderungen, Aufrechterhaltung eines hohen Servicestandards und der Benutzerzufriedenheit.",
        ssk5: "Qualitätssicherung: Umfassendes Verständnis von Qualitätssicherungsprotokollen, Gewährleistung der konsequenten Einhaltung festgelegter Standards und Optimierung von Chat-Diensten",
        ssk6: "Schulung und Entwicklung: Fähigkeit, Schulungen zu konzipieren und durchzuführen, um die Fähigkeiten der Mitarbeiter weiterzuentwickeln und eine kontinuierliche Verbesserung der Kommunikation und der Servicequalität zu fördern",
        ssk7: "Innovation: Nachgewiesene Fähigkeit, innovative Strategien einzuführen, den Branchentrends voraus zu sein und die Chat-Dienste für ein dynamisches Online-Dating-Umfeld zu optimieren",
        ssk8: "Zusammenarbeit: Effektive Fähigkeiten zur Zusammenarbeit, um ein kohärentes Teamumfeld zu fördern und die Kommunikation und Zusammenarbeit zwischen den Mitarbeitern für eine nahtlose Leistungserbringung zu unterstützen",
        ssk9: "Anpassungsfähigkeit: Fähigkeit zur Anpassung an die sich verändernde Dynamik der Branche, an die Vorlieben der Nutzer und an aufkommende Trends, um sicherzustellen, dass die Chat-Dienste von Creativ'Network an der Spitze der Online-Dating-Erfahrung bleiben",
        langproficiency: "Deutsch-Kenntnisse",
        agelimit: "Altersgrenze",
        bankaccountpaymentaccount: "Bankkonto oder Zahlungskonto",
        acceptanceppterms: "Akzeptanz der Datenschutzerklärung und der Vertragsbedingungen",
        equipmentresponsibility: "Verantwortung für die Ausrüstung",
        reports: "Berichte",
        minweekly: "Mindestwochenstunden",
        tax: "Steuer",
        adhlegalregguidelines: "Einhaltung der gesetzlichen und regulatorischen Richtlinien",
        sre1: "Ein Creativ'Network Quality Supervisor muss die wichtigsten Arbeitssprachen fließend beherrschen, mehrsprachig sein, sich klar artikulieren können, regionale Besonderheiten verstehen, gut schriftlich kommunizieren können und die Interaktion mit den Benutzern und die Zusammenarbeit mit anderen Abteilungen beherrschen.",
        sre2: "Die Festlegung einer Altersgrenze von 25+ für einen Creativ'Network Quality Supervisor kann ein ausgewogenes Verhältnis von Erfahrung und Reife gewährleisten. Diese Politik fördert verschiedene Führungsqualitäten und erhält gleichzeitig ein dynamisches und effizientes Team.",
        sre3: "Sie sollten ein Bankkonto, PayPal-, Wise- oder Yoursafe-Konto auf Ihren Namen haben. Dies dient wahrscheinlich der Zahlungsabwicklung.",
        sre4: "Der Qualitätsbeauftragte von Creativ'Network muss die Datenschutzerklärung und die Vertragsbedingungen anerkennen und akzeptieren, um die Einhaltung des Datenschutzes und der vertraglichen Verpflichtungen zu gewährleisten und eine sichere und transparente Arbeitsbeziehung zu fördern.",
        sre5: "Es wird erwartet, dass Sie über die notwendige Ausrüstung verfügen, einschließlich eines Laptops oder PCs, einer zuverlässigen Internetverbindung und einer Backup-Strom- und Internetquelle, um eine unterbrechungsfreie Arbeit zu gewährleisten.",
        sre6: "Creativ's Network Quality Supervisor stellt detaillierte Berichte zusammen, in denen die Teamleistung, die Metriken zur Nutzerbindung und die behandelten Probleme zusammengefasst sind. Diese Berichte dienen als Richtschnur für strategische Entscheidungen, identifizieren verbesserungswürdige Bereiche und gewährleisten eine optimale Servicebereitstellung.",
        sre7: "Der Creativ'Network Quality Supervisor verpflichtet sich, mindestens 40 Wochenstunden zu arbeiten und gelegentlich an Wochenenden und Feiertagen zur Verfügung zu stehen. Dadurch wird eine kontinuierliche Unterstützung des Teams und die Aufrechterhaltung optimaler Servicestandards gewährleistet",
        sre8: "Als Selbstständiger ist der Creativ'Network Quality Supervisor für die Verwaltung und Abführung seiner Steuern selbst verantwortlich. Dies beinhaltet die Einhaltung der Steuervorschriften und die Erfüllung der finanziellen Verpflichtungen in seinem jeweiligen Zuständigkeitsbereich.",
        sre9: "Creativ's Network Quality Supervisor sorgt für die strikte Einhaltung gesetzlicher und behördlicher Richtlinien und fördert so eine konforme Umgebung. Dies beinhaltet die Einhaltung der Privatsphäre der Nutzer, des Datenschutzes und anderer branchenspezifischer Vorschriften zur Wahrung der Integrität der Plattform.",
        sic1: "1. Altersüberprüfung: Creativ'Network prüft das Alter der Bewerber für den Quality Supervisor (25+), um sicherzustellen, dass die Kandidaten die Erfahrung, Reife und Stabilität besitzen, die für eine effektive Führung erforderlich sind. Dieser Ansatz steht im Einklang mit dem Ziel des Unternehmens, erfahrene Führungskräfte mit nachgewiesener Erfolgsbilanz zu haben, die zu einem erfolgreichen Teammanagement beitragen und ein positives Arbeitsumfeld fördern.",
        sic2: "2. Sicherheit und Vertrauen: Die Altersüberprüfung für Creativ's Network Quality Supervisor-Bewerber im Alter von 25+ wird eingeführt, um die Sicherheit und das Vertrauen zu erhöhen. Durch die Einhaltung dieses Kriteriums will die Plattform ein reifes und erfahrenes Führungsteam aufbauen und so ein sicheres und vertrauenswürdiges Umfeld für Nutzer und Teammitglieder fördern. ",
        sic3: "3. Zu überprüfende Dokumente: Um die gesetzlichen Bestimmungen einzuhalten und sicherzustellen, dass die Kandidaten das entsprechende Alter haben, wird Creativ'Network eine Altersverifizierung verlangen. Dokumente wie Führerscheine, Personalausweise und Reisepässe enthalten in der Regel Angaben zum Geburtsdatum und sind daher geeignet, um zu bestätigen, dass Sie das von Creativ'Network festgelegte Mindestalter erfüllen.",   
        
        
        sas3: "Sobald Ihre Bewerbung und die Altersverifizierung genehmigt sind, werden Sie für eine Online-Schulung via Skype angemeldet.|Während der Schulung werden die Teamleiter von Creativ'Network Sie anleiten und Ihnen zeigen, wie Sie Ihre Rolle als Qualitätsbeauftragter effektiv ausüben können. Sie werden die Unternehmensrichtlinien, bewährte Praktiken sowie Führungs- und Kommunikationsstrategien behandeln, um in Ihrer Position zu glänzen.",
        tltext: "Führen, inspirieren und leiten Sie die Chat-Operatoren und fördern Sie außergewöhnliche Verbindungen und Erfahrungen im Bereich des Online-Datings bei Creativ'Network.",
        supervisortext: "Überwachen Sie die Qualität der Dating-Chat-Dienste bei Creativ'Network. Führen Sie die Operatoren zu Spitzenleistungen und gewährleisten Sie positive Nutzererfahrungen im Bereich Online-Dating.",

        webdevtext: "Kommen Sie zu Creativ'Network als Webentwickler und entwickeln Sie innovative digitale Lösungen. Tragen Sie zu unserem dynamischen Team bei und gestalten Sie die Zukunft der Online-Verbindungen.",
        digmartext: "Erhöhen Sie Ihre Online-Präsenz bei Creativ'Network als Digital Marketer. Fördern Sie das Engagement, gestalten Sie Strategien und tragen Sie zu dynamischen Marketinginitiativen bei. Join us!",
        webdestext: "Gestalten Sie digitale Erlebnisse bei Creativ'Network als Web Designer. Erwecken Sie Ihre Kreativität zum Leben und gestalten Sie visuell beeindruckende Websites für verbesserte Online-Verbindungen.",

        qstesttitle: "Testformular für Qualitätsbeauftragte",
        suptitle: "Test zum Qualitätsbeauftragten bei Creativ'Network",
        suptest: "Prüfung zum Qualitätsbeauftragten",
        tltest: "Teamleiter-Test",
        tltestform: ' Teamleiter-Testformular',
        tltesttitle: "Test zum Teamleiter bei Creativ'Network",
        tltestint1: "Der Test für Teamleiter von Creativ'Network bewertet Ihre Fähigkeiten in den Bereichen Führung, Kommunikation, Leistungsmanagement, Innovation, Teamentwicklung, Qualitätssicherung, Zusammenarbeit und Anpassungsfähigkeit.",
        suptestint1: "Der Test zum Qualitätsbeauftragten von Creativ'Network bewertet Ihre Fähigkeiten in den Bereichen Führung, Kommunikation, Problemlösung, Qualitätssicherung, Schulung, Innovation, Zusammenarbeit und Anpassungsfähigkeit.",
        thanktest1: "Wir danken Ihnen, dass Sie den Test durchgeführt haben",
        finish: "Beenden",
        all: "Alle",
        loginfailed: "Anmeldung fehlgeschlagen",
        
        usrnmpttrerr: "Der Benutzername darf keine Sonderzeichen enthalten",
        pobxpttrerr: "Das Postfach darf keine Sonderzeichen enthalten",
        phnpttrerr: "Die Telefonnummer muss im internationalen Format angegeben werden. Beispiel: +123456789012",
        skpidpttrerr: "Ungültige Skype-ID",
        brthdtpttrerr: "Ungültiges Format des Geburtsdatums",
        usrnmlngtherr: `Der Benutzername muss mindestens ${usernameminlength} Zeichen enthalten.`,
        smthngwntwrng: "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut",
        failed: "Fehlgeschlagen",    
        subsuccess: "Erfolgreich abonniert",
        subfailed: "Bereits abonniert",
        mlunqerr: "Diese E-Mail-Adresse wird bereits verwendet",
        usrnmunqerr: "Dieser Benutzername wird bereits verwendet",
        iderr: "Ungültige E-Mail / Benutzername",
        mdperr: "Ungültiges Passwort",
        svsucc: "Informationen erfolgreich aktualisiert.",
        
        mdpnid: "Das neue Passwort und das Bestätigungspasswort sind nicht identisch",
        curmdperr: "Das aktuelle Passwort ist falsch",
        entryrml: "Geben Sie Ihre E-Mail-Adresse ein",
        entrynwpss: "Geben Sie Ihr neues Passwort ein",
        rcpcpt: "Finden Sie Ihr Konto",

        chckrsm: "Siehe Ihren aktuellen Lebenslauf",
        nyrsm: "Noch kein Lebenslauf vorhanden",
        chckpi: "Sehen Sie Ihren aktuellen Identitätsnachweis",
        chckpa: "Sehen Sie Ihren aktuellen Adressnachweis",
        chcksl: "Sehen Sie Ihr aktuelles Selfie",
        chckc: "Sehen Sie Ihren aktuellen unterschriebenen Vertrag",
        nypi: "Es wurde noch kein Identitätsnachweis vorgelegt",
        nypa: "Es wurde noch kein Adressnachweis vorgelegt",
        nysl: "Es wurde noch kein Selfie gemacht",
        nyc: "Es wurde noch kein unterzeichneter aktueller Vertrag vorgelegt",
        rsm: "Lebenslauf",
        pi: "Identitätsnachweis",
        pa: "Nachweis des Wohnsitzes",
        slfi: "Selfie",
        opnngclsngdterr: "Das Eröffnungsdatum muss vor dem Abschlussdatum liegen",
        adddsccsflly: "Erfolgreich hinzugefügt",
        dltd: "Gelöscht",
        rstr: "Wiederherstellen",
        see: "Siehe", 

        succ: "Blog erfolgreich erstellt",
        cat: "Ungültige Kategorie",
        accref: "Zugriff verweigert",
        lang: "Sprache nicht gefunden",

        catsuccess: "Kategorie erfolgreich erstellt",
        catexst: "Kategorie bereits vorhanden",
        crtadminfrmmbr: "Aus bestehendem Mitglied erstellen",
        success: "Erfolg",
        tktst: "Den Test absolvieren",
        expteam: "Haben Sie Erfahrung in der Teamleitung?",
        expsup: "Haben Sie Erfahrung in der Betreuung von Chat-Operatoren?",
        avlbl: "Verfügbar",
        fllppfrm: "Füllen Sie das Bewerbungsformular aus",
        lljbs: "Alle Stellen",
        pnjbs: "Einstellungsangebote",
        dfndt: "Bestimmtes Datum",
        indfndt: "Unbestimmtes Datum",

        nonaut: "Nur der Autor des Blogs kann dieses Blog löschen und wiederherstellen",
        adminsuccess: "Diese Person ist jetzt Administrator",
        rolenonaut: "Unerlaubte Rolle",
        djadmin: "Diese Person ist bereits Administrator",
        usrntfnd: "Kein Benutzer gefunden",
        newbloggersucc: "Diese Person ist jetzt ein Blogger",

        nw: "Neu" ,
        bfrsbmttng: "Vor dem Einreichen",
        upldrsmpdf: "Laden Sie Ihren Lebenslauf hoch",
        umstupldyrcv: "Sie müssen Ihren Lebenslauf hochladen"   ,
    
        gndr: "Geschlecht",
        citydep: "Stadt / Abteilung",
        pobox: "PO BOX",

        crtd: "Erstellt",
        dcnnctd: "Unterbrochen",
        sbntfnd: "Teilnehmer nicht gefunden",
        sspndd: "Suspendiert",
        susp: "Ihr Konto ist derzeit suspendiert und wird am ", 
        reak: "reaktiviert",
        svd: "Gespeichert",
tstalrddn: "Sie haben sich bereits für diese Stelle beworben",
        gvlgn: "Attribut eine Anmeldung",
        frmtdcnntrv: "Schulungsunterlage nicht gefunden", 
        sndmaildocincomp: "E-Mail über unvollständige Dokumente senden",
        vldtinteg: "Zur Ausbildungsliste hinzufügen",
        nshdtrnng: "Beendete Ausbildung",
        cnfgtrnng: "Training konfigurieren",
        sndml: "E-Mail senden",
        mrksfnshd: "Als erledigt markieren",
        bn: "E-Mail verbannt",
        cypm: "Wählen Sie Ihre Zahlungsmethode",
        aas3: "Liste der Teamleiter",
        tllgn: "Anmeldung der Teamleiter",
        aas4: "Teamleiter hinzufügen",
        createnewtl: "Neuen Teamleiter erstellen",
strthr: "Anfangszeit",
        dnhr: "Ende der Zeit",
        chngpay: "Wenn Sie Ihre Zahlungsinformationen ändern möchten, wenden Sie sich bitte an Ihren Teamleiter oder Ihren Vorgesetzten.",
        idm: "Manager-ID",
        mdboard: "Manager Dashboard",
        manager: "Manager",
        mrk: "Als gelesen markieren",
agntslst: "Liste der Agenten",
aas5: "Liste der Manager",
aas6: "Manager hinzufügen",
createnewmn: "Neuen Manager erstellen",
pdf: "Es wird nur das PDF-Format akzeptiert",
agntnm: "Name des Agenten",
        agntpass: "Agent-Passwort",
        asn2: "Soziales Netzwerk hinzufügen",


},
]
        
   

const getKey = lang =>{
    switch (lang){
        case 'fr': return 0
        case 'it': return 2
        case 'nl': return 3
        case 'es': return 4
        case 'de': return 5
        default: return 1
    }
}
const getText = lang => { return text[getKey(lang)] }

export default getText