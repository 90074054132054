import React from "react";
import { Stack, Typography, Box } from "@mui/material";
import useLanguage from "../../hooks/useLanguage";
import IconText from "../../tools/IconText";
import AnimatedButton from "../../tools/AnimatedButton";
import CustomDivider from "../../tools/CustomDivider";
import { useParams } from "react-router-dom";

const Introduction = () =>{
    const { lang } = useParams()
    const { text } = useLanguage()
    const iconBaseUrl = "/images/chat-operator/1- Introduction/"
    return(
        <Stack overflow='hidden' position='relative' style={{width: '100%', display: 'flex', alignItems: 'center'}}>
            <Stack variant="semitransparentprimary" flexDirection='row'>
                <Box className="wrap-content" display='flex' width="100%">
                    <Stack className="co-int-stack">
                        <Stack spacing={2} style={{position:'relative', justifyContent:"space-evenly", display:"flex", alignItems:"center", maxWidth: "95%"}}>      
                            <Typography className="title-typo" variant="whitetitle" fontWeight='bold'>
                                { text.cointint } <Typography variant="secondarytitle" width='80%' className="title-typo-change">{text.cn}</Typography> ?
                            </Typography>
                            <CustomDivider color="white" alignCenter width='13%'/>
                            <Stack>
                                <IconText white range={1} iconSrc={iconBaseUrl + "1. Handsome payout.svg"}>{text.coint1}</IconText>
                                <IconText white range={2} iconSrc={iconBaseUrl + "2. Remote Work.svg"}>{text.coint2}</IconText>
                                <IconText white range={3} iconSrc={iconBaseUrl + "3. Communication Skills.svg"}>{text.coint3}</IconText>
                                <IconText white range={4} iconSrc={iconBaseUrl + "4. Work-Life Balance.svg"}>{text.coint4}</IconText>
                            </Stack>
                            <AnimatedButton to="apply-co">{text.applynow}</AnimatedButton>
                        </Stack>
                    </Stack>

                    <Stack className="no-exp-stack">
                        <img className="slideXY" src={"/images/chat-operator/noexp_"+ lang +".gif"} alt=""/>
                    </Stack>
                </Box>

            </Stack>
            <Stack sx={{ background: "#4B4998", position: "absolute", width: "100%", height: "100%", zIndex: -1}}><img src="/home2/co1.webp" alt="" style={{ height: "100%"}} /></Stack>
        </Stack>
    )
}

export default Introduction