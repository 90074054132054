
import React, { useEffect } from 'react';
import { Stack, Typography, Box } from "@mui/material";
import useLanguage from "../hooks/useLanguage";
import Missions from "./Missions";
import CompValues from "./CompValues";
import Advantages from "./Advantages";
import AnimatedButton from "../tools/AnimatedButton";
import Testimonial from "./Testimonial";
import Statistics from "./Statistics";
import CustomDivider from '../tools/CustomDivider';
import { useLocation, useNavigate } from 'react-router-dom';
import RoutesList from '../../helper/routes';
import { scroller } from "react-scroll"

const Home = () =>{
    const { text } = useLanguage()
    const navigate = useNavigate()
    const { state } = useLocation()

    useEffect(()=>{ if (state) scroller.scrollTo(state.link, {duration: 500, smooth: true, offset: -175}) }, [])
    
    return(
        <Stack id="home">
            <Stack variant='semitransparentprimary' position="relative">
                <Box className="wrap-content" display='flex' alignItems="center" position="absolute" height="100%">
                        <Stack spacing={5} padding='0 17px'>

                            <Stack className="title-typo" display='flex'  alignItems='center' >
                                <Typography variant='whitetitle' fontWeight="bold">{text.wantto} <Typography variant='secondarytitle' className="title-typo-change">{text.cn}  </Typography> <Typography variant='whitetitle'>?</Typography> </Typography>
                            </Stack>
                            <CustomDivider color="white" alignCenter width='17%'/>
                            <Stack><Typography variant='texthome'>{text.areyou}</Typography></Stack>
                            <Stack alignItems='center'><AnimatedButton onClick={()=> navigate(RoutesList.jobs)}>{text.check}</AnimatedButton></Stack>
                        </Stack>
                        
                    <Stack minWidth="45%"/>
                </Box>
                <Box component="video" sx={{width: "100%", minHeight: { xs: "calc(100vh-45px)", md: "calc(100vh-120px)", lg: "calc(100vh-175px)" }, zIndex: -1 }} src="/home2/home.mp4" autoPlay muted loop/>
                
            </Stack>
            <Stack sx={{ transform: "translateY(-100%)" }}><img src='/svg/waves4.svg' alt=""/></Stack>
            <Missions />
            <CompValues/>
            <Advantages/>
            <Testimonial/>
            <Statistics/>
        </Stack>
    )
}
export default Home