import { createTheme, ThemeProvider} from "@mui/material";

const h1fs = [45, 35, 18]
const h2fs = [40, 30, 15]
const h3fs = [30, 23, 12]
const h4fs = [20, 15, 10]
const pfs = [16, 13, 9]
const footerfs = [14, 12, 9]
const hhfs = [27, 25, 20, 13]

const popupsWidth = '500px'
const popupsHeight = '200px'
const inputsHeight = '30px'
const inputsWidth = '350px'
const colors = {
    primary: '#4B4998',
    darkprimary: '#34317a',
    secondary: '#FDC309',
    semitransparentprimary: '#4B499888',
    transparentprimary: '#807cff43',
    lightneutral: 'white',
    middleneutral: '#f8f8f8',
    darkmiddleneutral: '#e0e0e0',
    darkneutral: 'black',
    red: "#F00",
    green: "#0F0",
    blue: "#45c7c3",
    blue2: "#81DBDA",
}

const theme = createTheme({
    palette: { primary: { main: colors.primary }, secondary: { main: colors.secondary } },
    breakpoints: {
        values: {
            xs: 0,
            sm4: 300,
            sm3: 350,
            sm2: 450,   
            sm: 600,
            md: 900,
        }
    },
    typography:{ fontSize: pfs[0] },
    components: {
        MuiDivider: {
            variants: [
                {
                    props: { variant: 'dotted' },
                    style: { border: 'none', borderTop : '5px dotted ' + colors.primary,}
                }, {
                    props: { variant: "primarybold" },
                    style: { border: "none", borderTop: "10px solid " + colors.primary}
                },{
                    props: { variant: "threedotsprimary" },
                    style: { border: "none", borderTop: "5px dotted " + colors.primary, maxWidth: "27px", minWidth: "27px" }
                },
                {
                    props: { variant: "threedotssecondary" },
                    style: { border: "none", borderTop: "5px dotted " + colors.secondary, maxWidth: "27px", minWidth: "27px" }
                },
                {
                    props: { variant: "threedotswhite" },
                    style: { border: "none", borderTop: "5px dotted " + colors.lightneutral, maxWidth: "27px", minWidth: "27px" }
                },
                {
                    props: { variant: "primary" },
                    style: { border: "none", borderTop: "5px solid " + colors.primary, width: "100%", borderRadius: '10px' }
                },
                {
                    props: { variant: "secondary" },
                    style: { border: "none", borderTop: "5px solid " + colors.secondary, width: "100%", borderRadius: '10px' }
                },
                {
                    props: { variant: "white" },
                    style: { border: "none", borderTop: "5px solid " + colors.lightneutral, width: "100%", borderRadius: '10px' }
                },
                {
                    props: { variant: "dottedwhite" },
                    style: { border: "none", borderTop: "8px dotted " + colors.lightneutral, width: "30%" }
                },
            ]
        },
        MuiMenuItem: {
            variants: [
                {
                    props: { variant: "nav" },
                    style: { padding: '0px', height: "35px" }
                },{
                    props: { variant: "white" },
                    style: { color: colors.lightneutral }
                }
            ]
        },
        MuiGrid: {
            variants: [
                {
                    props: { variant: 'table-header' },
                    style: {backgroundColor: '#ffebcd', border: '1px solid #ffd697', justifyContent: 'center', alignItems: 'center', display: 'flex'}
                },
                {
                    props: { variant: 'table-body' },
                    style: {backgroundColor: '#fff', border: '1px solid #ffd697', justifyContent: 'center', alignItems: 'center', display: 'flex'}
                },
            ]
        },
        MuiTypography: {
            styleOverrides: {
                root: ({theme}) => {return { fontSize: pfs[0],  [theme.breakpoints.down('md')]: { fontSize: pfs[1] }, [theme.breakpoints.down('sm')]: { fontSize: pfs[2] } }
            }},
            variants: [
                {
                    props: { variant: 'admin-card-header' },
                    style: { textAlign:'center', fontWeight:'bold' }
                },{
                    props: { variant: 'success' },
                    style: ({theme}) => {return { fontSize: footerfs[0], color: colors.green, textAlign: 'center',  [theme.breakpoints.down('md')]: { fontSize: footerfs[1] }, [theme.breakpoints.down('sm')]: { fontSize: footerfs[2] } }
                }},{
                    props: { variant: 'headerhello'},
                    style: ({theme}) =>{ return { fontSize: hhfs[0], fontWeight: 'bold' , [theme.breakpoints.down('md')]: { fontSize: hhfs[0] }, [theme.breakpoints.down('sm2')]: { fontSize: hhfs[1] }, [theme.breakpoints.down('sm3')]: { fontSize: hhfs[2] }, [theme.breakpoints.down('sm4')]: { fontSize: hhfs[3] } } }    
                },
                {
                    props: { variant: 'table-head-text' },
                    style: ({theme}) => {return { fontSize: h4fs[0], textAlign: 'center', width: '100%',  [theme.breakpoints.down('md')]: { fontSize: h4fs[1] }, [theme.breakpoints.down('sm')]: { fontSize: h4fs[2] } }},
                },
                {
                    props: { variant: 'boldcenter' },
                    style: { fontWeight: 'bold', textAlign: 'center', width: '100%' }
                },
                {
                    props: { variant: 'center' },
                    style: { textAlign: 'center', width: '100%' }
                },
                {
                    props: { variant: 'datefilter' },
                    style: {
                        backgroundColor: '#decdff',
                        width: '75px',
                        height: '100%',
                        fontWeight: 'bold',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }
                },
                {
                    props: { variant: 'title' },
                    style: ({theme}) => {return { fontSize: h1fs[0], textAlign: 'center',  [theme.breakpoints.down('md')]: { fontSize: h1fs[1] }, [theme.breakpoints.down('sm')]: { fontSize: h1fs[2] } }},
                    
                },{
                    props: { variant: "xlwhitetitle" },
                    style: ({theme}) => {return  { color: colors.lightneutral, fontSize: h1fs[0] * 2, textAlign: 'center',  [theme.breakpoints.down('md')]: { fontSize: h1fs[1] *2 }, [theme.breakpoints.down('sm')]: { fontSize: h1fs[2]*2 } }
                }},{
                    props: { variant: "bold" },
                    style: { fontWeight: "bold" }
                },{
                    props: { variant: 'h2' },
                    style: ({theme}) => {return  { fontSize: h3fs[0],  [theme.breakpoints.down('md')]: { fontSize: h3fs[1] }, [theme.breakpoints.down('sm')]: { fontSize: h3fs[2] } }}
                },{
                    props: { variant: 'white' },
                    style: ({theme}) => {return  { color: colors.lightneutral, fontSize: pfs[0],  [theme.breakpoints.down('md')]: { fontSize: pfs[1] }, [theme.breakpoints.down('sm')]: { fontSize: pfs[2] } }}
                },{
                    props: { variant: 'secondary' },
                    style: { color: colors.secondary }
                },{
                    props: { variant: 'whitetitle' },
                    style: ({theme}) => {return  { color: colors.lightneutral, fontSize: h1fs[0], textAlign: 'center',  [theme.breakpoints.down('md')]: { fontSize: h1fs[1] }, [theme.breakpoints.down('sm')]: { fontSize: h1fs[2] } }
                }},{
                    props: { variant: 'secondarytitle' },
                    style: ({theme}) => {return  { color: colors.secondary, fontSize: h1fs[0], textAlign: 'center', fontWeight: 'bold', transition:'.5s',  [theme.breakpoints.down('md')]: { fontSize: h1fs[1] }, [theme.breakpoints.down('sm')]: { fontSize: h1fs[2] } }
                }},{
                    props: { variant: 'primarytitle' },
                    style: ({theme}) => {return  { color: colors.primary, fontSize: h1fs[0], textAlign: 'center', fontWeight: 'bold', transition: '.5s',  [theme.breakpoints.down('md')]: { fontSize: h1fs[1] }, [theme.breakpoints.down('sm')]: { fontSize: h1fs[2] } }
                }},{
                    props: { variant: 'primarytitle2' },
                    style: ({theme}) => {return  { color: colors.secondary, fontSize: h1fs[0], textAlign: 'center', fontWeight: 'bold', transition: '.5s',  [theme.breakpoints.down('md')]: { fontSize: h1fs[1] }, [theme.breakpoints.down('sm')]: { fontSize: h1fs[2] } }
                }},{
                    props: { variant: 'footertitle' },
                    style: ({theme}) => {return  { color: colors.lightneutral, fontSize: h3fs[0] ,  [theme.breakpoints.down('md')]: { fontSize: h3fs[1] }, [theme.breakpoints.down('sm')]: { fontSize: h3fs[2] }}
                }},{
                    props: { variant: 'secondaryfootertitle' },
                    style: ({theme}) => {return  { color: colors.secondary, fontSize: h3fs[0], transition: '.5s', fontWeight: 'bold' ,  [theme.breakpoints.down('md')]: { fontSize: h3fs[1] }, [theme.breakpoints.down('sm')]: { fontSize: h3fs[2] }}
                }}, {
                    props: { variant: 'footertext' },
                    style: ({theme}) => {return  { fontSize: footerfs[0], color: colors.lightneutral, textAlign: 'center' ,  [theme.breakpoints.down('md')]: { fontSize: footerfs[1] }, [theme.breakpoints.down('sm')]: { fontSize: footerfs[2] }}
                }},{
                    props: { variant: 'primarybg' },
                    style: { color: colors.darkmiddleneutral }
                },{
                    props: { variant: 'footerlabel' },
                    style: ({theme}) => {return  { color: colors.darkmiddleneutral, fontSize: footerfs[0] ,  [theme.breakpoints.down('md')]: { fontSize: footerfs[1] }, [theme.breakpoints.down('sm')]: { fontSize: footerfs[2] }}
                }},{
                    props: { variant: 'h3title' },
                    style: ({theme}) => {return  { fontSize: h4fs[0] ,  [theme.breakpoints.down('md')]: { fontSize: h4fs[1] }, [theme.breakpoints.down('sm')]: { fontSize: h4fs[2] }}
                }},{
                    props: { variant: 'innerbutton' },
                    style: { zIndex: 2, fontWeight: 'bold' }
                },{
                    props: { variant: 'bigwhitetitle' },
                    style: ({theme}) => {return  { color: colors.lightneutral, fontSize: h1fs[0], textTransform: 'uppercase', fontWeight: 'bold' ,  [theme.breakpoints.down('md')]: { fontSize: h1fs[1] }, [theme.breakpoints.down('sm')]: { fontSize: h1fs[2] }}
                }},{
                    props: { variant: 'bigprimarytitle' },
                    style: ({theme}) => {return  { color: colors.primary, fontSize: h1fs[0], textTransform: 'uppercase', fontWeight: 'bold',  [theme.breakpoints.down('md')]: { fontSize: h1fs[1] }, [theme.breakpoints.down('sm')]: { fontSize: h1fs[2] } }
                }},{
                    props: { variant: "whiteh1_2" },
                    style: ({theme}) => {return  { color: colors.lightneutral, fontSize: (h3fs[0] + 5),  [theme.breakpoints.down('md')]: { fontSize: (h3fs[1] + 3) }, [theme.breakpoints.down('sm')]: { fontSize: (h3fs[2] + 1) } }
                }},{
                    props: { variant: "secondaryh3" },
                    style: ({theme}) => {return  { color: colors.secondary, fontSize: h4fs[0] ,  [theme.breakpoints.down('md')]: { fontSize: h4fs[1] }, [theme.breakpoints.down('sm')]: { fontSize: h4fs[2] }}
                }},{
                    props: { variant: "secondaryh2" },
                    style: ({theme}) => {return  { color: colors.secondary, fontSize: h3fs[0] ,  [theme.breakpoints.down('md')]: { fontSize: h3fs[1] }, [theme.breakpoints.down('sm')]: { fontSize: h3fs[2] }}
                }},{
                    props: { variant: 'primaryh3' },
                    style: ({theme}) => {return  { color: colors.primary, fontSize: h4fs[0] ,  [theme.breakpoints.down('md')]: { fontSize: h4fs[1] }, [theme.breakpoints.down('sm')]: { fontSize: h4fs[2] }}
                }},{
                    props: { variant: "red" },
                    style: { backgroundColor: colors.red, color: colors.lightneutral, fontWeight: "bold", padding: "5px 10px" }
                },{
                    props: { variant: "green" },
                    style: { backgroundColor: colors.green, color: colors.red, fontWeight: "bold", padding: "5px 10px" }
                },{
                    props: { variant: 'primarysubtitleline' },
                    style: ({theme}) => {return  {fontSize: h2fs[0], color: colors.primary,  [theme.breakpoints.down('md')]: { fontSize: h2fs[1] }, [theme.breakpoints.down('sm')]: { fontSize: h2fs[2] }}
                }},{
                    props: { variant: "numberstatcardtypo" },
                    style: ({theme}) => {return  { fontSize: h3fs[0], color: colors.primary ,  [theme.breakpoints.down('md')]: { fontSize: h3fs[1] }, [theme.breakpoints.down('sm')]: { fontSize: h3fs[2] }}
                }},{
                    props: { variant: 'texthome' },
                    style: ({theme}) => {return  { fontSize: (footerfs[0] + 10), textAlign: 'center', color: colors.lightneutral, fontWeight: 'bold', paddingLeft: '7px', paddingRight: '10px'
                    ,  [theme.breakpoints.down('md')]: { fontSize: (footerfs[1] + 7) }, [theme.breakpoints.down('sm')]: { fontSize: (footerfs[2] + 4) }}
                }},{
                    props: { variant: 'blacksubtitle' },
                    style: ({theme}) => {return  {fontSize: h2fs[0] ,  [theme.breakpoints.down('md')]: { fontSize: h2fs[1] }, [theme.breakpoints.down('sm')]: { fontSize: h2fs[2] }}
                }},{
                    props: { variant: 'primarysubtitle' },
                    style: ({theme}) => {return  {fontSize: h2fs[0], fontWeight: 'bold', color: colors.primary, transition: '.5s',  [theme.breakpoints.down('md')]: { fontSize: h2fs[1] }, [theme.breakpoints.down('sm')]: { fontSize: h2fs[2] }}
                }},{
                    props: { variant: 'subtitleline' },
                    style: ({theme}) => {return  {fontSize: h2fs[0], color: colors.secondary,  [theme.breakpoints.down('md')]: { fontSize: h2fs[1] }, [theme.breakpoints.down('sm')]: { fontSize: h2fs[2] }}
                }},{
                    props: { variant: 'whitesubtitle' },
                    style: ({theme}) => {return  {fontSize: h2fs[0], color: colors.lightneutral ,  [theme.breakpoints.down('md')]: { fontSize: h2fs[1] }, [theme.breakpoints.down('sm')]: { fontSize: h2fs[2] }}
                }},{
                    props: { variant: 'secondarysubtitle' },
                    style: ({theme}) => {return  {fontSize: h2fs[0], fontWeight: 'bold', color: colors.secondary, transition: '.5s',  [theme.breakpoints.down('md')]: { fontSize: h2fs[1] }, [theme.breakpoints.down('sm')]: { fontSize: h2fs[2] }}
                
                }},{
                    props: { variant: 'white' },
                    style: {color: colors.lightneutral }
                },{
                    props: { variant: 'innerbutton' },
                    style: { zIndex: 2, fontWeight: 'bold' }
                },{
                    props: { variant: 'bold' },
                    style: { fontWeight: 'bold' }
                },{
                    props: { variant: 'primarysubtitleline' },
                    style: ({theme}) => {return  {fontSize: h2fs[0], color: colors.primary,  [theme.breakpoints.down('md')]: { fontSize: h2fs[1] }, [theme.breakpoints.down('sm')]: { fontSize: h2fs[2] }}
                }},{
                    props: { variant: 'numberstatcardtypo' },
                    style: ({theme}) => {return  {fontSize: h2fs[0], fontWeight: 'bold',  [theme.breakpoints.down('md')]: { fontSize: h2fs[1] }, [theme.breakpoints.down('sm')]: { fontSize: h2fs[2] }}
                }},{
                    props: { variant: 'nav' },
                    style:{
                        color: colors.lightneutral,
                        fontSize: pfs[0],
                        textTransform: 'capitalize',
                        transition: "color .3s",
                        '&:hover': {
                            color: colors.secondary,
                            backgroundColor: 'transparent'
                        }
                }},{
                    props: { variant: "menu-item-nav" },
                    style: { color: "#000", margin: "0 15px"}
                },{
                    props: { variant: 'whitetitlepp' },
                    style: { color: colors.lightneutral, fontSize: (h3fs[0] * 2), textTransform: 'uppercase', fontWeight: 'bold' }
                },{
                    props: { variant: "tiny" },
                    style: ({theme}) => {return  { fontSize: footerfs[0] ,  [theme.breakpoints.down('md')]: { fontSize: footerfs[1] }, [theme.breakpoints.down('sm')]: { fontSize: footerfs[2] }}
                }},{
                    props: { variant: 'error' },
                    style: ({theme}) => {return  { fontSize: footerfs[0], color: colors.red, textAlign: 'center',  [theme.breakpoints.down('md')]: { fontSize: footerfs[1] }, [theme.breakpoints.down('sm')]: { fontSize: footerfs[2] } }
                }},{
                    props: { variant: 'style-font-title' },
                    style: ({theme}) =>{ return { color: '#ff96d5', WebkitTextStroke: '1px #d940ac', textShadow: '0px 0px 15px #d940ac', fontSize: h2fs[0],  [theme.breakpoints.down('md')]: { fontSize: h2fs[1] }, [theme.breakpoints.down('sm')]: { fontSize: h2fs[2] } } }
                },{
                    props: { variant: 'style-font-secondary' },
                    style: ({theme}) =>{ return { color: colors.secondary, fontSize: h2fs[0],  [theme.breakpoints.down('md')]: { fontSize: h2fs[1] }, [theme.breakpoints.down('sm')]: { fontSize: h2fs[2] } } }
                },{
                    props: { variant: 'titlewithbg'},
                    style: ({theme}) =>{ return { fontSize: h4fs[0], textTransform: 'uppercase', fontWeight: 'bold', textShadow: '2px 2px 2px ' + colors.secondary , [theme.breakpoints.down('md')]: { fontSize: h4fs[1] }, [theme.breakpoints.down('sm')]: { fontSize: h4fs[2] } } }    
                },{
                    props: { variant: 'black' },
                    style: {color: colors.darkneutral }
                },{
                    props: { variant: 'textbutton' },
                    style: {
                        color: colors.lightneutral,
                        transition: "color .3s",
                        '&:hover': {
                            color: colors.secondary,
                            backgroundColor: 'transparent'
                    }
                }},{
                    props: { variant: 'secondaryitalic' },
                    style: ({theme}) => {return  { color: colors.secondary, fontWeight: "bold", fontStyle: "italic" }
                }}
            ]
        },
        MuiIconButton:{
            variants: [{
                props: { variant: "nav" },
                style: {
                    color: colors.lightneutral,
                    textTransform: 'capitalize',
                    transition: "color .3s",
                    '&:hover': {
                        color: colors.secondary,
                        backgroundColor: 'transparent'
                    }
                }
            }]
        },
        MuiLink: {
            styleOverrides: {
                root: { color: colors.secondary }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: ({theme}) => {return  {
                    minHeight: inputsHeight, 
                    fontSize: footerfs[0],
                    backgroundColor: colors.middleneutral      
                    ,  [theme.breakpoints.down('md')]: { fontSize: footerfs[1] }, [theme.breakpoints.down('sm')]: { fontSize: footerfs[2] }      
                }}
            },
            variants: [
                {
                    props: { variant: 'high' },
                    style: { minHeight: '100px' }
                }
            ]
        },
        MuiCheckbox: {
            variants: [
                {
                    props: { variant: 'primarybg' },
                    style: {
                        color: colors.middleneutral,
                        width: footerfs[0],
                        height: footerfs[0],
                        '&.Mui-checked': { color: colors.middleneutral }
                    }
                }
            ]
        },
        MuiButton: {
            variants: [
                {
                    props: { variant: "browser" },
                    style: {
                        transition: '.5s',
                        fontWeight: 'bold',
                        color: colors.lightneutral,
                        backgroundColor: colors.blue,
                        borderRadius: '25px',
                        textTransform: "capitalize",
                        maxHeight:"35px",
                        alignSelf: "center",
                        '&:hover': {
                            transform: 'scale(1.05)',
                            backgroundColor: colors.blue2,
                            color: colors.lightneutral,
                        }
                    }
                },
                {
                    props: { variant: 'submitCom' },
                    style: ({theme}) => {return  {
                        borderRadius: '0',
                        fontWeight: 'bold',
                        color: colors.lightneutral,
                        backgroundColor: colors.primary,
                        width: 'fit-content',
                        padding: '1% 4%',
                        fontSize: footerfs[0],
                        '&:hover': {backgroundColor: colors.secondary }
                        ,  [theme.breakpoints.down('md')]: { fontSize: footerfs[1] }, [theme.breakpoints.down('sm')]: { fontSize: footerfs[2] }
                    }
                }},
                {
                    props: { variant: 'testform' },
                    style: {
                        textTransform: "capitalize",
                        color: colors.primary,
                        border: '1px solid ' + colors.darkmiddleneutral,
                        borderBottom: '3px solid ' + colors.darkmiddleneutral,
                        borderTop: '1px solid ' + colors.middleneutral,
                        padding: '5px 20px'
                    }
                },{
                    props: { variant: "neutral" },
                    style: {
                        textTransform: "capitalize",
                        color: colors.darkneutral,
                        backgroundColor: colors.lightneutral,
                        border: "1px solid " + colors.darkneutral,
                        height: inputsHeight,
                        width: "fit-content",
                        padding: "18px",
                        borderRadius: "20px",
                        transition: ".5s",
                        '&:hover': {
                            backgroundColor: colors.darkneutral,
                            color: colors.lightneutral
                        }
                    }
                },
                {
                    props: { variant: "footernav" },
                    style: ({theme}) => {return  {
                        color: colors.lightneutral,
                        fontSize: pfs[0],
                        textTransform: 'capitalize'
                        ,  [theme.breakpoints.down('md')]: { fontSize: pfs[1] }, [theme.breakpoints.down('sm')]: { fontSize: pfs[2] }
                    }
                }},{
                    props: { variant: "outlined2" },
                    style:{
                        textTransform: "capitalize",
                        width: "50%",
                        borderRadius: "17px",
                        '&:hover': {
                            background: "transparent",
                            color: colors.secondary
                        }
                    }
                },
                ,{
                    props: { variant: "hiring" },
                    style: { color: colors.lightneutral, backgroundColor: colors.primary, borderRadius: '17px', width: '70%',
                        '&:hover': { backgroundColor: colors.secondary },
                        '&:disabled': { backgroundColor: colors.semitransparentprimary }
                    }
                },{
                    props: { variant: "appclosed" },
                    style: { color: colors.lightneutral, backgroundColor: colors.red, borderRadius: '17px', width: '70%',
                        '&:hover': { backgroundColor: colors.red }
                    }
                },
                {
                    props: { variant: 'footer' },
                    style: ({theme}) => {return  {
                        borderRadius: '0',
                        color: colors.secondary,
                        backgroundColor: colors.lightneutral,
                        width: 'fit-content',
                        textTransform: 'capitalize',
                        fontSize: footerfs[0],
                        '&:hover': { color: colors.primary, backgroundColor: colors.lightneutral }
                        ,  [theme.breakpoints.down('md')]: { fontSize: footerfs[1] }, [theme.breakpoints.down('sm')]: { fontSize: footerfs[2] }
                    }
                }},{
                    props: { variant: "rose" },
                    style: { backgroundColor: '#ba80ed',
                            color: '#fff',
                            fontWeight: 'bold',
                            padding: '7px 25px',
                            borderRadius: '20px',
                            boxShadow: 'inset -2px 2px 2px 2px #0003, inset 2px -2px 2px 2px #fff3',
                            '&:hover':{boxShadow: 'inset 2px -2px 1px 1px #0001, inset -2px 2px 2px 2px #fff3' }
                    }
                }
                    ,{
                        props: { variant: "action" },
                        style: {
                            fontWeight: 'bold',
                            backgroundColor: colors.secondary,
                            overflow: 'hidden',
                            padding: "12px 50px",
                            borderRadius: '25px',
                            '&:hover': {
                                backgroundColor: colors.secondary,
                                color: colors.primary,
                                boxShadow: '0px 0px 20px -5px ' + colors.darkneutral
                            }
                    }
                }
            ]
        },
        MuiAccordion: {
            variants: [
                {
                    props: { variant: 'big-container' },
                    style: {
                        border: 'none',
                        outline: 'none',
                        backgroundColor: 'transparent',
                    }
                },{
                    props: { variant: 'loginfo' },
                    style: { backgroundColor: colors.primary }
                }
            ]
        },
        MuiAccordionDetails: {
            variants: [
                {
                    props: { variant: 'big-container' },
                    style: {
                        border: 'none',
                        backgroundColor: 'transparent'
                    }
                }
            ]
        },
        MuiStack: {
            variants: [
                {
                    props: { variant: 'inputcomment' },
                    style: {width: "60%"}
                },{
                    props: { variant: 'dashboard' },
                    style: { 
                        backgroundColor: colors.primary, 
                        width: '100%',
                        padding: '20px 0px 50px 0px',
                    }
                },{
                    props: { variant: 'rightcontent' },
                    style: { 
                        backgroundColor: colors.primary, 
                        width: '100%',
                        minHeight: '800px',
                        padding: '20px 0px 20px 0px',
                        border: '8px solid #FFF',
                        gap: '20px',
                        minWidth: '100%',
                    }
                },
                {
                    props: { variant: 'white' },
                    style: {color: colors.lightneutral}
                },
                {
                    props: { variant: 'blogcard' },
                    style: {
                        padding: '0px 35px',
                        background: 'linear-gradient(to right,' +colors.primary +',' +colors.secondary +')',
                        borderRadius: '20px',
                        
                        
                    }
                },
                {
                    props: { variant: "bordered" },
                    style: {
                        border: '2px solid ' + colors.darkmiddleneutral,
                        borderRadius: '10px'
                    }
                },
                {
                    props: { variant: 'semitransparentprimary' },
                    style: { backgroundColor: colors.semitransparentprimary, top:'0', left:'0', width:'100%', height:'100%' }
                },{
                    props: { variant: 'curvy' },
                    style: { backgroundColor: colors.primary }
                },
                {
                    props: { variant: 'head' },
                    style: { 
                        backgroundColor: colors.primary,
                        position: 'sticky',
                        top: '0', 
                        zIndex: '3' ,
                        paddingLeft: '20px',
                        paddingRight: '20px'
                    }
                },{
                    props: { variant: 'primarybg' },
                    style: { backgroundColor: colors.primary }
                },
                ,{
                    props: { variant: 'home' },
                    style: { 
                        backgroundColor: colors.primary,
                        paddingLeft: '20px',
                        paddingRight: '20px',
                    }

                },{
                    props: { variant: 'compvalues' },
                    style: { 
                        margin: '100px 0px 0px 0px',
                        backgroundColor: colors.primary
                        
                    }
                },{
                    props: { variant: 'popup' },
                    style: { position:'fixed', top:'50%', left:'50%', transform: 'translate(-50%, -50%)' , backgroundColor: colors.primary, color: colors.lightneutral, width: popupsWidth, height: popupsHeight, display:'flex', alignItems:'center', justifyContent: 'space-evenly', borderRadius:'10px' }
                },{
                    props: { variant: 'footer' },
                    style: { backgroundColor: colors.primary, width: '100%', zIndex:"2"}
                },{
                    props: { variant: 'card' },
                    style: { borderRadius: '15px', backgroundColor: colors.darkmiddleneutral }
                },{
                    props: { variant: 'primarycard' },
                    style: { borderRadius: '15px', backgroundColor: colors.transparentprimary }
                },{
                    props: { variant: 'wavesstat' },
                    style: { backgroundColor: colors.secondary }
                },{
                    props: { variant: 'wavestrans' },
                    style: { backgroundColor: "transparent" }
                },
                {
                    props: { variant: 'waves2' },
                    style: { backgroundColor: colors.lightneutral }
                },{
                    props: { variant: "waves1" },
                    style: { backgroundColor: colors.lightneutral }
                },{
                    props: { variant: 'applyform' },
                    style: { backgroundColor: colors.primary, borderRadius: '35px', border: '2px double '+colors.secondary }
                },{
                    props: { variant: 'jobcard' },
                    style: {
                        position: 'relative',
                        backgroundColor: colors.lightneutral,
                        boxShadow: '0px 0px 25px -10px ' + colors.darkneutral,
                        width: "85%",
                        bottom: "0",
                        borderRadius: '10px',
                        transition: '.5s',
                        transform: "translateY(-40px)"
                    }
                },{
                    props: { variant: 'statistics' },
                    style: {
                        backgroundColor: colors.secondary,
                        alignItems: 'center',
                        padding: '0 40px '
                    }
                },{
                    props: { variant: 'statcard' },
                    style: {
                        boxShadow: '0px 0px 40px -5px ' + colors.darkneutral,
                        backgroundColor: colors.lightneutral,
                        height: '270px',
                        alignItems: 'center',
                        padding: '30px 10px',
                        borderRadius: '25px',
                        border: "3px solid " + colors.primary,      
                    }
                },{
                    props: { variant: 'testimonial' },
                    style: {
                        backgroundColor: colors.middleneutral,
                    }
                }
            ]
        }

    }

})

const WithThemeProvider = ({children}) => {
    return (
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    )
}

export default WithThemeProvider