import useLanguage from "./useLanguage"
import { FR, GB, IT, NL, ES, DE } from 'country-flag-icons/react/3x2'
import { roles } from "../../helper/roles"
import { jobs } from "../../helper/jobs"

const useData = () =>{
    const { text } = useLanguage()

    const lastUpdateTermsAndConditions = '11.01.2023'

    const snStaticDatas = [
        { 
            label: text.french, 
            bgcolor: '#004aad',
            fb: { language: "fr", snname: "Facebook", link: 'https://www.facebook.com/profile.php?id=61552577516238', login: 'creativnetwork.net@gmail.com', password: 'rxE6dMa6575ZyB' },
            tw: { language: "fr", snname: "Twitter", link: 'https://twitter.com/NetworkCrea', login: 'creativnetwork.net@gmail.com', password: 'rxE6dMa6575ZyB' },
            yt: { language: "fr", snname: "Youtube", link: 'https://www.youtube.com/channel/UCOqiEa0U2xb1QCMDdeEvy-Q', login: 'creativnetwork.net@gmail.com', password: 'rxE6dMa6575ZyB' },
            ig: { language: "fr", snname: "Instagram", link: 'https://www.instagram.com/creativ.networknet/', login: 'creativnetwork.net@gmail.com', password: 'rxE6dMa6575ZyB' },
            pi: { language: "fr", snname: "Pinterest", link: 'https://www.pinterest.fr/creativnetwork/', login: 'creativnetwork.net@gmail.com', password: 'rxE6dMa6575ZyB' },
            li: { language: "fr", snname: "Linkedin", link: 'https://www.linkedin.com/in/creativ-network-9364b0296/', login: 'creativnetwork.net@gmail.com', password: 'rxE6dMa6575ZyB' },
        },{ 
            label: text.english, 
            bgcolor: '#ff3131',
            fb: { language: "en", snname: "Facebook", link: 'https://www.facebook.com/profile.php?id=61555642684629', login: 'creativnetworkenglish@gmail.com', password: 'rxE6dMa6575ZyB' },
            tw: { language: "en", snname: "Twitter", link: 'https://twitter.com/CreativNetworkE', login: 'creativnetworkenglish@gmail.com', password: 'rxE6dMa6575ZyB' },
            yt: { language: "en", snname: "Youtube", link: 'https://www.youtube.com/channel/UC0og8U0r_sdw-pgJX0K-d_Q ', login: 'creativnetworkenglish@gmail.com', password: 'rxE6dMa6575ZyB'  },
            ig: { language: "en", snname: "Instagram", link: 'https://www.instagram.com/cnetworkenglish/?hl=fr', login: 'cnetwork.en@gmail.com', password: '9G968RgHtS6mcj' },
            pi: { language: "en", snname: "Pinterest", link: 'https://www.pinterest.fr/creativnetworke/', login: 'creativnetworkenglish@gmail.com', password: 'rxE6dMa6575ZyB' },
            li: { language: "en", snname: "Linkedin", link: 'https://www.linkedin.com/in/creativ-network-english-837a172aa/', login: 'creativnetwork.net@gmail.com', password: 'rxE6dMa6575ZyB' }, 
        },{ 
            label: text.italian, 
            bgcolor: '#1cb743',
            fb: { language: "it", snname: "Facebook", link: 'https://www.facebook.com/profile.php?id=61555813076011', login: 'creativnetworkitaliano@gmail.com', password: 'rxE6dMa6575ZyB' },
            tw: { language: "it", snname: "Twitter", link: 'https://twitter.com/CreativNetworkI', login: 'creativnetworkitaliano@gmail.com', password: 'rxE6dMa6575ZyB' },
            yt: { language: "it", snname: "Youtube", link: 'https://www.youtube.com/channel/UCHu-QiR7shwZRtsaOaMQYGA', login: 'creativnetworkitaliano@gmail.com', password: 'rxE6dMa6575ZyB' },
            ig: { language: "it", snname: "Instagram", link: 'https://www.instagram.com/cnetwork.it/', login: 'cnetwork.it@gmail.com', password: '9G968RgHtS6mcj' },
            pi: { language: "it", snname: "Pinterest", link: 'https://www.pinterest.fr/creativnetworki/', login: 'creativnetworkitaliano@gmail.com', password: 'rxE6dMa6575ZyB' },
            li: { language: "it", snname: "Linkedin", link: 'https://www.linkedin.com/in/creativ-network-italiano-286a202aa/', login: 'creativnetwork.net@gmail.com', password: 'rxE6dMa6575ZyB' }, 
        },{ 
            label: text.dutch, 
            bgcolor: '#de5b6d',
            fb: { language: "nl", snname: "Facebook", link: 'https://www.facebook.com/profile.php?id=61555712791100', login: 'creativnetworknederlands@gmail.com', password: 'rxE6dMa6575ZyB' },
            tw: { language: "nl", snname: "Twitter", link: 'https://twitter.com/CreativNetworkN', login: 'creativnetworknederlands@gmail.com', password: 'rxE6dMa6575ZyB' },
            yt: { language: "nl", snname: "Youtube", link: 'https://www.youtube.com/channel/UCpbiHlJBkAeE72VmYLUcDMg', login: 'creativnetworknederlands@gmail.com', password: 'rxE6dMa6575ZyB' },
            ig: { language: "nl", snname: "Instagram", link: 'https://www.instagram.com/creativnetworknederland/?next=%2F', login: 'creativnetworknl@gmail.com', password: '9G968RgHtS6mcj' },
            pi: { language: "nl", snname: "Pinterest", link: 'https://www.pinterest.fr/cnederlands/', login: 'creativnetworknederlands@gmail.com', password: 'rxE6dMa6575ZyB' },
            li: { language: "nl", snname: "Linkedin", link: 'https://www.linkedin.com/in/creativ-network-nederlands-b44a212aa/', login: 'creativnetwork.net@gmail.com', password: 'rxE6dMa6575ZyB' }, 
        },{ 
            label: text.spanish, 
            bgcolor: '#f4a100',
            fb: { language: "es", snname: "Facebook", link: 'https://www.facebook.com/profile.php?id=61555544948049', login: 'creativnetworkespanol@gmail.com', password: 'rxE6dMa6575ZyB' },
            tw: { language: "es", snname: "Twitter", link: 'https://twitter.com/CreativNetworkP', login: 'creativnetworkespanol@gmail.com', password: 'rxE6dMa6575ZyB' },
            yt: { language: "es", snname: "Youtube", link: 'https://www.youtube.com/channel/UClNmAT-YMfKj475H8JdtaLg', login: 'creativnetworkes@gmail.com', password: 'rxE6dMa6575ZyB' },
            ig: { language: "es", snname: "Instagram", link: 'https://www.instagram.com/creativnetworkes/', login: 'creativnetworkespanol@gmail.com', password: 'rxE6dMa6575ZyB' },
            pi: { language: "es", snname: "Pinterest", link: 'https://www.pinterest.fr/creativnetworkespanol/', login: 'creativnetworkespanol@gmail.com', password: 'rxE6dMa6575ZyB' },
            li: { language: "es", snname: "Linkedin", link: 'https://www.linkedin.com/in/creativ-network-espa%C3%B1ol-0782362ab/', login: 'creativnetwork.net@gmail.com', password: 'rxE6dMa6575ZyB' }, 
        },{ 
            label: text.german, 
            bgcolor: '#000000',
            fb: { language: "de", snname: "Facebook", link: 'https://www.facebook.com/profile.php?id=61555583618775', login: 'creativnetworkdeutsch@gmail.com', password: 'rxE6dMa6575ZyB' },
            tw: { language: "de", snname: "Twitter", link: 'https://twitter.com/CreativDeutsch', login: 'creativnetworkdeutsch@gmail.com', password: 'rxE6dMa6575ZyB' },
            yt: { language: "de", snname: "Youtube", link: 'https://www.youtube.com/channel/UCqD-ymoJ3Lgl8bkKXk5N4fA', login: 'creativnetworkdeutsch@gmail.com', password: 'rxE6dMa6575ZyB' },
            ig: { language: "de", snname: "Instagram", link: 'https://www.instagram.com/creativnetworkde/', login: 'creativnetworkdeutsch@gmail.com', password: 'rxE6dMa6575ZyB' },
            pi: { language: "de", snname: "Pinterest", link: 'https://www.pinterest.fr/creativnetworkd/', login: 'creativnetworkdeutsch@gmail.com', password: 'rxE6dMa6575ZyB' },
            li: { language: "de", snname: "Linkedin", link: 'https://www.linkedin.com/in/creativ-network-deutsch-20657a2aa/', login: 'creativnetwork.net@gmail.com', password: 'rxE6dMa6575ZyB' }, 
        },
    ]

    const jobOffers = {
        chatOperator: true,
        webDeveloper: false,
        teamLeader: true,
        digitalMarketer: false,
        supervisor: true,
        webDesigner: false
    }

    const jobName = job =>{
        switch (job){
            case jobs.chatoperator: return text.jobco[0]
            case jobs.digitalmarketer: return text.jobdm[0]
            case jobs.supervisor: return text.supervisor
            case jobs.teamleader: return text.teamleader
            case jobs.webdes: return text.jobwdes[0]
            case jobs.webdev: return text.jobwdev[0]
            default: return job
        }
    }

    const languageName = l =>{
        switch (l){
            case 'fr': return text.french
            case 'en': return text.english
            case 'it': return text.italian
            case 'nl': return text.dutch
            case 'es': return text.spanish
            case 'de': return text.german
            default: return ''
        }
    }
    
    const testLanguages = [
        { value : 'fr' , innerHTML: text.french},
        { value : 'en' , innerHTML: text.english},
        { value : 'it' , innerHTML: text.italian},
        { value : 'nl' , innerHTML: text.dutch},
        { value: 'es', innerHTML: text.spanish },
        { value: 'de', innerHTML: text.german }
    ]
    const filterLanguages = [
        { value: 'all', innerHTML: text.all },
        { value : 'fr' , innerHTML: text.french},
        { value : 'en' , innerHTML: text.english},
        { value : 'it' , innerHTML: text.italian},
        { value : 'nl' , innerHTML: text.dutch},
        { value: 'es', innerHTML: text.spanish },
        { value: 'de', innerHTML: text.german }
    ]

    const chatModExp = [
        { value: 'yes', innerHTML: text.yes },
        { value: 'no', innerHTML: text.no }
    ]

    const hearAboutUs = [
        {value: 'google', innerHTML: 'Google'},
        {value: 'bing', innerHTML: 'Bing'},
        {value: 'yahoo', innerHTML: 'Yahoo'},
        {value: 'facebook', innerHTML: 'Facebook'},
        {value: 'twitter', innerHTML: 'Twitter'},
        {value: 'friends', innerHTML: text.friends},
        {value: 'indeed', innerHTML: 'Indeed'},
        {value: 'studentenjob', innerHTML: 'Studentenjob'},
        {value: 'sonstige', innerHTML: 'Sonstige'},
    ]

    const languagesList = [
        { value: 'fr', innerHTML: text.french, icon: <FR/> },
        { value: 'en', innerHTML: text.english, icon: <GB/> },
        { value: 'it', innerHTML: text.italian, icon: <IT/> },
        { value: 'nl', innerHTML: text.dutch, icon: <NL/> },
        { value: 'es', innerHTML: text.spanish, icon: <ES/> },
        { value: 'de', innerHTML: text.german, icon: <DE/> }
    ]

    const testimonialCards = [
        { texts: text.testimonialperson1, imgFile: "MarieC.webp"},
        { texts: text.testimonialperson2, imgFile: "LucasP.webp"},
        { texts: text.testimonialperson3, imgFile: "SophieM.webp"},
        { texts: text.testimonialperson4, imgFile: "AlexR.webp"},
        { texts: text.testimonialperson5, imgFile: "EmmaH.webp"},
        { texts: text.testimonialperson6, imgFile: "TommyJ.webp"}
    ]

    const dataStat = [
        {value: 'teammembers', innerHTML: 1500},
        {value: 'projects', innerHTML: 150},
        {value: 'networkpartners', innerHTML: 26},
        {value: 'yearsinbusiness', innerHTML: 8},

    ]

    const dataBlog = [
        {id: "001",image_illustration: "/images/Blog/Road to Eye-catching Landscape.webp", created_at: "15/10/2023", category: {id: "01", name: text.uncategorized},  title_Post: text.blognumber1[0], content: text.blognumber1[1], authorId: 1, author: "creativ-network_admin", comments: [{ name: "Test1", content: "Beautiful" }, { name: "Test2", content: "Wonderful place" }]},
        {id: "002",image_illustration: "/images/Blog/Serious Problems with Cables in CIty.webp", created_at: "15/10/2023", category: {id: "01", name: text.uncategorized}, title_Post: text.blognumber2[0], content: text.blognumber2[1] , authorId: 1, author: "creativ-network_admin", comments: []},
        {id: "003",image_illustration: "/images/Blog/Montain and Winter Cold Weather.webp", created_at: "15/10/2023", category: {id: "01", name: text.uncategorized}, title_Post: text.blognumber3[0], content: text.blognumber3[1], authorId: 1, author: "creativ-network_admin", comments: [{name: "Test3", content: "Wow"}]},

    ]

    const websites = [
        { network: "facebook" ,url: "https://www.facebook.com/profile.php?id=61552577516238", iconSrc: "/images/Admin/sn/facebook.png", login: "creativnetwork.net@gmail.com", password: "rxE6dMa6575ZyB"  },
        { network: "x" , url: "https://twitter.com/NetworkCrea", iconSrc: "/images/Admin/sn/twitter.png", login: "creativnetwork.net@gmail.com", password: "rxE6dMa6575ZyB"  },
        { network: "youtube" , url: "https://www.youtube.com/channel/UCOqiEa0U2xb1QCMDdeEvy-Q", iconSrc: "/images/Admin/sn/youtube.png", login: "creativnetwork.net@gmail.com", password: "rxE6dMa6575ZyB"  },
        { network: "instagram" , url: "https://www.instagram.com/creativ.networknet/", iconSrc: "/images/Admin/sn/instagram.png", login: "creativnetwork.net@gmail.com", password: "rxE6dMa6575ZyB"  },
        { network: "pinterest" , url: "https://www.pinterest.fr/creativnetwork/", iconSrc: "/images/Admin/sn/pinterest.png", login: "creativnetwork.net@gmail.com", password: "rxE6dMa6575ZyB"  },
        { network: "linkedin" , url: "https://www.linkedin.com/in/creativ-network-9364b0296/", iconSrc: "/images/Admin/sn/linkedin.png", login: "creativnetwork.net@gmail.com", password: "rxE6dMa6575ZyB"  },
    ]

    const genders = [
        { value: 'Male', innerHTML: text.male },
        { value: 'Female', innerHTML: text.female },
        { value: 'Other', innerHTML: text.other },
    ]

    const rolesSelect = [
        { value: roles.admin, innerHTML: text.admin },
        { value: roles.teamleader, innerHTML: text.teamleader },
        { value: roles.blogger, innerHTML: text.blogger },
        { value: roles.manager, innerHTML: text.manager },
    ]

    const adminList = [
        {
            id: '3',
            date: '10/02/2023',
            staff: 'Staff',
            language: text.french,
            mail: 'andrew@test.com'
        }
    ]

    const jobList = [
        {
            id: "5",
            date: "25/11/2022",
            title: "Web developer",
            description: "",
            language: text.dutch,
            closingdate: "08/02/2024",
            open: true
        },
        {
            id: "6",
            date: "06/01/2023",
            title: "Chat operator",
            description: "",
            language: text.english,
            closingdate: "08/03/2024",
            open: false
        },
    ]

    const jobApplicationList = [
        {
            id: "8",
            date: "06/01/2022",
            title: "Web developer",
            description: "",
            language: text.german,
            applicantfirstname: 'John',
            applicantlastname: 'Wilson',
            applicantcountry: "Netherlands"
        }
    ]
    
    const trainingSchedule = [
        {
            id: "7",
            date: "09/10/2023",
            jobtitle: "Web developer",
            language: text.german,
            agentfirstname: "Tom",
            agentlastname: "",
            agentcountry: "Russia",
            agentemail: "tom@gmail.com",
            agentskypeID: "",
            teamleader: "TL",
        }
    ]

    const blogPostsList= [
        {
            id: '22',
            date: "20/11/2022",
            bloggerloginid: "BLID",
            language: text.english,
            blogposttitle: "Title",
            blogpostcategory: "Uncategorized",
        }
    ]

    const bloggerList = [
        {
            id: '5',
            date: '06/06/2023',
            bloggerfirstname: 'Matthew',
            bloggerlastname: 'Eric',
            language: text.german,
            bloggerloginid: 'mattheweric@gmail.com'
        },
    ]

    const agentsAwaitingLogin= [
        {
            id: '20',
            date: "20/01/2022",
            language: 'en',
            jobtitle: "web_developer",
            agentfirstname: "Tom",
            agentlastname: "Holl",
            agentcountry: "Russia",
            agentskypeID: "000",
            agentemail: "tom@gmail.com",
            teamleader: "TL2",
        }
    ]

    const agentsCheckUp = [
        { id: "7", date: "09/10/2023", jobtitle: "Web developer", agentfirstname: "Tom", agentlastname: "", agentlogin: "tom@gmail.com", agentid: true, freelancecontract: true, trainingdocumentssigned: false, proofofresidence: true, paymentmethod: text.bank },
        { id: "8", date: "10/11/2023", jobtitle: "Chat operation", agentfirstname: "John", agentlastname: "Doe", agentlogin: "johndoe06@gmail.com", agentid: false, freelancecontract: false, trainingdocumentssigned: false, proofofresidence: true, paymentmethod: text.paypal},
        { id: "9", date: "20/01/2023", jobtitle: "Web developer", agentfirstname: "Marc", agentlastname: "", agentlogin: "marc033@gmail.com", agentid: true, freelancecontract: false, trainingdocumentssigned: false, proofofresidence: false, paymentmethod: text.wise},
        { id: "10", date: "18/05/2023", jobtitle: "Chat operator", agentfirstname: "Liam", agentlastname: "", agentlogin: "liam@gmail.com", agentid: false, freelancecontract: true, trainingdocumentssigned: false, proofofresidence: true, paymentmethod: text.Yoursafe},
    ]

    const membersList = [
        {
            id: "9",
            inscriptdate: "09/09/2022",
            firstname: "Thomas",
            lastname: "Edison",
            mail: "thomasedison22@gmail.com",
            country: "USA",
            language: text.english,
            mailconfirmed: false,
        },
        {
            id: "10",
            inscriptdate: "03/05/2022",
            firstname: "Nikola",
            lastname: "Tesla",
            mail: "nikolatesla1@gmail.com",
            country: "",
            language: text.english,
            mailconfirmed: true,
        },
    ]
    
    const subscribersList = [
        {
            id: "50",
            subscdate: "03/05/2021",
            firstname: "Thomas",
            lastname: "Edison",
            mail: "thomasedison22@gmail.com",
            country: "USA",
            language: text.english,
            mailconfirmed: true,
        },
        {
            id: "51",
            subscdate: "03/05/2022",
            firstname: "Nikola",
            lastname: "Tesla",
            mail: "nikolatesla1@gmail.com",
            country: "",
            language: text.english,
            mailconfirmed: false,
        },
    ]

    const newslettersList = [
        {
            id: "32",
            reldate: "03/12/2022",
            rellanguage: text.french,
            topic: "Soccer",
            link: "",
            sendtoallsubscribers: true
        },
        {
            id: "33",
            reldate: "04/12/2022",
            rellanguage: text.german,
            topic: "Health",
            link: "",
            sendtoallsubscribers: false
        }
    ]

    const successfulApplications = [
        {
            id: "20",
            date: "18/02/2023",
            firstname: "Luke",
            lastname: "Anderson",
            jobposition: "Web developer",
            language: text.italian,
            country: "Italy",
            training: "20/03/2023",
            integration: "06/04/2023"
        },
        {
            id: "22",
            date: "10/12/2023",
            firstname: "Stefano",
            lastname: "",
            jobposition: "Web developer",
            language: text.spanish,
            country: "Spain",
            training: "",
            integration: ""
        },
    ]

    const errorsList = {
        usernamePattern: {
            message: "Le nom d'utilisateur ne peut contenir que des lettres, des chiffres et des underscores.",
            errorLabel: text.usrnmpttrerr
        },
        usernameLength: {
            message: "Le caractère doit être supérieur ou égale à 4 minimum",
            errorLabel: text.usrnmlngtherr,
        },
        poboxPattern: {
            message: "La boîte postale ne peut contenir que des lettres, des chiffres et des espaces.",
            errorLabel: text.pobxpttrerr
        },
        phonePattern: {
            message: "Le numéro de téléphone doit être au format international, par exemple: +123456789.",
            errorLabel: text.phnpttrerr
        },
        skypeIdPattern: {
            message: "Le nom d'utilisateur Skype ne peut contenir que des lettres, des chiffres, des underscores, des points, des arobas (@), des tirets (-) et des deux-points (:).",
            errorLabel: text.skpidpttrerr
        },
        mailUnique: {
            message: 'Cet e-mail est déjà utilisé.',
            errorLabel: text.mlunqerr
        },
        usernameUnique: {
            message: 'Ce nom d\'utilisateur est déjà utilisé.',
            errorLabel: text.usrnmunqerr
        }
    }
    const categoryList = [
        {value: "uncategorized", innerHTML: text.uncategorized}
    ]

    const filterCountries = [
        { value: 'all', innerHTML: text.all },
        { value: 'AF', innerHTML: 'Afghanistan - افغانستان', phone: '93' },
        { value: 'AL', innerHTML: 'Albania - Shqipëri', phone: '355' },
        { value: 'DZ', innerHTML: 'Algeria - الجزائر', phone: '213' },
        { value: 'AD', innerHTML: 'Andorra - Andorra', phone: '376' },
        { value: 'AO', innerHTML: 'Angola - Angola', phone: '244' },
        { value: 'AG', innerHTML: 'Antigua and Barbuda - Antigua and Barbuda', phone: '1' },
        { value: 'AR', innerHTML: 'Argentina - Argentina', phone: '54' },
        { value: 'AM', innerHTML: 'Armenia - Հայաստան', phone: '374' },
        { value: 'AU', innerHTML: 'Australia - Australia', phone: '61' },
        { value: 'AT', innerHTML: 'Austria - Österreich', phone: '43' },
        { value: 'AZ', innerHTML: 'Azerbaijan - Azərbaycan', phone: '994' },
        { value: 'BS', innerHTML: 'Bahamas - Bahamas', phone: '1' },
        { value: 'BH', innerHTML: 'Bahrain - البحرين', phone: '973' },
        { value: 'BD', innerHTML: 'Bangladesh - বাংলাদেশ', phone: '880' },
        { value: 'BB', innerHTML: 'Barbados - Barbados', phone: '1' },
        { value: 'BY', innerHTML: 'Belarus - Беларусь', phone: '375' },
        { value: 'BE', innerHTML: 'Belgium - België', phone: '32' },
        { value: 'BZ', innerHTML: 'Belize - Belize', phone: '501' },
        { value: 'BJ', innerHTML: 'Benin - Bénin', phone: '229' },
        { value: 'BT', innerHTML: 'Bhutan - འབྲུག་ཡུལ', phone: '975' },
        { value: 'BO', innerHTML: 'Bolivia - Bolivia', phone: '591' },
        { value: 'BA', innerHTML: 'Bosnia and Herzegovina - Bosna i Hercegovina', phone: '387' },
        { value: 'BW', innerHTML: 'Botswana - Botswana', phone: '267' },
        { value: 'BR', innerHTML: 'Brazil - Brasil', phone: '55' },
        { value: 'BN', innerHTML: 'Brunei - Brunei', phone: '673' },
        { value: 'BG', innerHTML: 'Bulgaria - България', phone: '359' },
        { value: 'BF', innerHTML: 'Burkina Faso - Burkina Faso', phone: '226' },
        { value: 'BI', innerHTML: 'Burundi - Burundi', phone: '257' },
        { value: 'CV', innerHTML: 'Cabo Verde - Cabo Verde', phone: '238' },
        { value: 'KH', innerHTML: 'Cambodia - កម្ពុជា', phone: '855' },
        { value: 'CM', innerHTML: 'Cameroon - Cameroun', phone: '237' },
        { value: 'CA', innerHTML: 'Canada - Canada', phone: '1' },
        { value: 'CF', innerHTML: 'Central African Republic - République centrafricaine', phone: '236' },
        { value: 'TD', innerHTML: 'Chad - تشاد', phone: '235' },
        { value: 'CL', innerHTML: 'Chile - Chile', phone: '56' },
        { value: 'CN', innerHTML: 'China - 中国', phone: '86' },
        { value: 'CO', innerHTML: 'Colombia - Colombia', phone: '57' },
        { value: 'KM', innerHTML: 'Comoros - Comores', phone: '269' },
        { value: 'CG', innerHTML: 'Congo (Congo-Brazzaville) - Congo', phone: '242' },
        { value: 'CR', innerHTML: 'Costa Rica - Costa Rica', phone: '506' },
        { value: 'HR', innerHTML: 'Croatia - Hrvatska', phone: '385' },
        { value: 'CU', innerHTML: 'Cuba - Cuba', phone: '53' },
        { value: 'CY', innerHTML: 'Cyprus - Κύπρος', phone: '357' },
        { value: 'CZ', innerHTML: 'Czechia (Czech Republic) - Česko', phone: '420' },
        { value: 'DK', innerHTML: 'Denmark - Danmark', phone: '45' },
        { value: 'DJ', innerHTML: 'Djibouti - جيبوتي', phone: '253' },
        { value: 'DM', innerHTML: 'Dominica - Dominica', phone: '1' },
        { value: 'DO', innerHTML: 'Dominican Republic - República Dominicana', phone: '1' },
        { value: 'TL', innerHTML: 'East Timor - Timor-Leste', phone: '670' },
        { value: 'EC', innerHTML: 'Ecuador - Ecuador', phone: '593' },
        { value: 'EG', innerHTML: 'Egypt - مصر', phone: '20' },
        { value: 'SV', innerHTML: 'El Salvador - El Salvador', phone: '503' },
        { value: 'GQ', innerHTML: 'Equatorial Guinea - Guinea Ecuatorial', phone: '240' },
        { value: 'ER', innerHTML: 'Eritrea - ኤርትራ', phone: '291' },
        { value: 'EE', innerHTML: 'Estonia - Eesti', phone: '372' },
        { value: 'SZ', innerHTML: 'Eswatini (fmr. Swaziland) - Eswatini', phone: '268' },
        { value: 'ET', innerHTML: 'Ethiopia - ኢትዮጵያ', phone: '251' },
        { value: 'FJ', innerHTML: 'Fiji - Fiji', phone: '679' },
        { value: 'FI', innerHTML: 'Finland - Suomi', phone: '358' },
        { value: 'FR', innerHTML: 'France - France', phone: '33' },
        { value: 'GA', innerHTML: 'Gabon - Gabon', phone: '241' },
        { value: 'GM', innerHTML: 'Gambia - Gambia', phone: '220' },
        { value: 'GE', innerHTML: 'Georgia - საქართველო', phone: '995' },
        { value: 'DE', innerHTML: 'Germany - Deutschland', phone: '49' },
        { value: 'GH', innerHTML: 'Ghana - Ghana', phone: '233' },
        { value: 'GR', innerHTML: 'Greece - Ελλάδα', phone: '30' },
        { value: 'GD', innerHTML: 'Grenada - Grenada', phone: '1' },
        { value: 'GT', innerHTML: 'Guatemala - Guatemala', phone: '502' },
        { value: 'GN', innerHTML: 'Guinea - Guinée', phone: '224' },
        { value: 'GW', innerHTML: 'Guinea-Bissau - Guiné-Bissau', phone: '245' },
        { value: 'GY', innerHTML: 'Guyana - Guyana', phone: '592' },
        { value: 'HT', innerHTML: 'Haiti - Haïti', phone: '509' },
        { value: 'HN', innerHTML: 'Honduras - Honduras', phone: '504' },
        { value: 'HU', innerHTML: 'Hungary - Magyarország', phone: '36' },
        { value: 'IS', innerHTML: 'Iceland - Ísland', phone: '354' },
        { value: 'IN', innerHTML: 'India - भारत', phone: '91' },
        { value: 'ID', innerHTML: 'Indonesia - Indonesia', phone: '62' },
        { value: 'IR', innerHTML: 'Iran - ایران', phone: '98' },
        { value: 'IQ', innerHTML: 'Iraq - العراق', phone: '964' },
        { value: 'IE', innerHTML: 'Ireland - Éire', phone: '353' },
        { value: 'IL', innerHTML: 'Israel - ישראל', phone: '972' },
        { value: 'IT', innerHTML: 'Italy - Italia', phone: '39' },
        { value: 'CI', innerHTML: 'Ivory Coast - Côte d\'Ivoire', phone: '225' },
        { value: 'JM', innerHTML: 'Jamaica - Jamaica', phone: '1' },
        { value: 'JP', innerHTML: 'Japan - 日本', phone: '81' },
        { value: 'JO', innerHTML: 'Jordan - الأردن', phone: '962' },
        { value: 'KZ', innerHTML: 'Kazakhstan - Қазақстан', phone: '7' },
        { value: 'KE', innerHTML: 'Kenya - Kenya', phone: '254' },
        { value: 'KI', innerHTML: 'Kiribati - Kiribati', phone: '686' },
        { value: 'KP', innerHTML: 'Korea, North - 북한', phone: '850' },
        { value: 'KR', innerHTML: 'Korea, South - 대한민국', phone: '82' },
        { value: 'XK', innerHTML: 'Kosovo - Kosovë', phone: '383' },
        { value: 'KW', innerHTML: 'Kuwait - الكويت', phone: '965' },
        { value: 'KG', innerHTML: 'Kyrgyzstan - Кыргызстан', phone: '996' },
        { value: 'LA', innerHTML: 'Laos - ລາວ', phone: '856' },
        { value: 'LV', innerHTML: 'Latvia - Latvija', phone: '371' },
        { value: 'LB', innerHTML: 'Lebanon - لبنان', phone: '961' },
        { value: 'LS', innerHTML: 'Lesotho - Lesotho', phone: '266' },
        { value: 'LR', innerHTML: 'Liberia - Liberia', phone: '231' },
        { value: 'LY', innerHTML: 'Libya - ليبيا', phone: '218' },
        { value: 'LI', innerHTML: 'Liechtenstein - Liechtenstein', phone: '423' },
        { value: 'LT', innerHTML: 'Lithuania - Lietuva', phone: '370' },
        { value: 'LU', innerHTML: 'Luxembourg - Luxembourg', phone: '352' },
        { value: 'MG', innerHTML: 'Madagascar - Madagascar', phone: '261' },
        { value: 'MW', innerHTML: 'Malawi - Malawi', phone: '265' },
        { value: 'MY', innerHTML: 'Malaysia - Malaysia', phone: '60' },
        { value: 'MV', innerHTML: 'Maldives - ދިވެހިރާއްޖެ', phone: '960' },
        { value: 'ML', innerHTML: 'Mali - Mali', phone: '223' },
        { value: 'MT', innerHTML: 'Malta - Malta', phone: '356' },
        { value: 'MH', innerHTML: 'Marshall Islands - Marshall Islands', phone: '692' },
        { value: 'MR', innerHTML: 'Mauritania - موريتانيا', phone: '222' },
        { value: 'MU', innerHTML: 'Mauritius - Maurice', phone: '230' },
        { value: 'MX', innerHTML: 'Mexico - México', phone: '52' },
        { value: 'FM', innerHTML: 'Micronesia - Micronesia', phone: '691' },
        { value: 'MD', innerHTML: 'Moldova - Moldova', phone: '373' },
        { value: 'MC', innerHTML: 'Monaco - Monaco', phone: '377' },
        { value: 'MN', innerHTML: 'Mongolia - Монгол улс', phone: '976' },
        { value: 'ME', innerHTML: 'Montenegro - Crna Gora', phone: '382' },
        { value: 'MA', innerHTML: 'Morocco - المغرب', phone: '212' },
        { value: 'MZ', innerHTML: 'Mozambique - Moçambique', phone: '258' },
        { value: 'MM', innerHTML: 'Myanmar (formerly Burma) - မြန်မာ', phone: '95' },
        { value: 'NA', innerHTML: 'Namibia - Namibia', phone: '264' },
        { value: 'NR', innerHTML: 'Nauru - Nauru', phone: '674' },
        { value: 'NP', innerHTML: 'Nepal - नेपाल', phone: '977' },
        { value: 'NL', innerHTML: 'Netherlands - Nederland', phone: '31' },
        { value: 'NZ', innerHTML: 'New Zealand - Aotearoa', phone: '64' },
        { value: 'NI', innerHTML: 'Nicaragua - Nicaragua', phone: '505' },
        { value: 'NE', innerHTML: 'Niger - Niger', phone: '227' },
        { value: 'NG', innerHTML: 'Nigeria - Nigeria', phone: '234' },
        { value: 'MK', innerHTML: 'North Macedonia - Северна Македонија', phone: '389' },
        { value: 'NO', innerHTML: 'Norway - Norge', phone: '47' },
        { value: 'OM', innerHTML: 'Oman - عُمان', phone: '968' },
        { value: 'PK', innerHTML: 'Pakistan - پاکستان', phone: '92' },
        { value: 'PW', innerHTML: 'Palau - Palau', phone: '680' },
        { value: 'PS', innerHTML: 'Palestine - فلسطين', phone: '970' },
        { value: 'PA', innerHTML: 'Panama - Panamá', phone: '507' },
        { value: 'PG', innerHTML: 'Papua New Guinea - Papua Niugini', phone: '675' },
        { value: 'PY', innerHTML: 'Paraguay - Paraguay', phone: '595' },
        { value: 'PE', innerHTML: 'Peru - Perú', phone: '51' },
        { value: 'PH', innerHTML: 'Philippines - Pilipinas', phone: '63' },
        { value: 'PL', innerHTML: 'Poland - Polska', phone: '48' },
        { value: 'PT', innerHTML: 'Portugal - Portugal', phone: '351' },
        { value: 'QA', innerHTML: 'Qatar - قطر', phone: '974' },
        { value: 'RO', innerHTML: 'Romania - România', phone: '40' },
        { value: 'RU', innerHTML: 'Russia - Россия', phone: '7' },
        { value: 'RW', innerHTML: 'Rwanda - Rwanda', phone: '250' },
        { value: 'KN', innerHTML: 'Saint Kitts and Nevis - Saint Kitts and Nevis', phone: '1869' },
        { value: 'LC', innerHTML: 'Saint Lucia - Saint Lucia', phone: '1758' },
        { value: 'VC', innerHTML: 'Saint Vincent and the Grenadines - Saint Vincent and the Grenadines', phone: '1784' },
        { value: 'WS', innerHTML: 'Samoa - Samoa', phone: '685' },
        { value: 'SM', innerHTML: 'San Marino - San Marino', phone: '378' },
        { value: 'ST', innerHTML: 'Sao Tome and Principe - São Tomé e Príncipe', phone: '239' },
        { value: 'SA', innerHTML: 'Saudi Arabia - السعودية', phone: '966' },
        { value: 'SN', innerHTML: 'Senegal - Sénégal', phone: '221' },
        { value: 'RS', innerHTML: 'Serbia - Србија', phone: '381' },
        { value: 'SC', innerHTML: 'Seychelles - Sesel', phone: '248' },
        { value: 'SL', innerHTML: 'Sierra Leone - Sierra Leone', phone: '232' },
        { value: 'SG', innerHTML: 'Singapore - Singapore', phone: '65' },
        { value: 'SK', innerHTML: 'Slovakia - Slovensko', phone: '421' },
        { value: 'SI', innerHTML: 'Slovenia - Slovenija', phone: '386' },
        { value: 'SB', innerHTML: 'Solomon Islands - Solomon Islands', phone: '677' },
        { value: 'SO', innerHTML: 'Somalia - Soomaaliya', phone: '252' },
        { value: 'ZA', innerHTML: 'South Africa - South Africa', phone: '27' },
        { value: 'SS', innerHTML: 'South Sudan - South Sudan', phone: '211' },
        { value: 'ES', innerHTML: 'Spain - España', phone: '34' },
        { value: 'LK', innerHTML: 'Sri Lanka - ශ්‍රී ලංකාව', phone: '94' },
        { value: 'SD', innerHTML: 'Sudan - السودان', phone: '249' },
        { value: 'SR', innerHTML: 'Suriname - Suriname', phone: '597' },
        { value: 'SE', innerHTML: 'Sweden - Sverige', phone: '46' },
        { value: 'CH', innerHTML: 'Switzerland - Schweiz', phone: '41' },
        { value: 'SY', innerHTML: 'Syria - سوريا', phone: '963' },
        { value: 'TW', innerHTML: 'Taiwan - 台灣', phone: '886' },
        { value: 'TJ', innerHTML: 'Tajikistan - Тоҷикистон', phone: '992' },
        { value: 'TZ', innerHTML: 'Tanzania - Tanzania', phone: '255' },
        { value: 'TH', innerHTML: 'Thailand - ประเทศไทย', phone: '66' },
        { value: 'TG', innerHTML: 'Togo - Togo', phone: '228' },
        { value: 'TO', innerHTML: 'Tonga - Tonga', phone: '676' },
        { value: 'TT', innerHTML: 'Trinidad and Tobago - Trinidad and Tobago', phone: '1868' },
        { value: 'TN', innerHTML: 'Tunisia - تونس', phone: '216' },
        { value: 'TR', innerHTML: 'Turkey - Türkiye', phone: '90' },
        { value: 'TM', innerHTML: 'Turkmenistan - Türkmenistan', phone: '993' },
        { value: 'TV', innerHTML: 'Tuvalu - Tuvalu', phone: '688' },
        { value: 'UG', innerHTML: 'Uganda - Uganda', phone: '256' },
        { value: 'UA', innerHTML: 'Ukraine - Україна', phone: '380' },
        { value: 'AE', innerHTML: 'United Arab Emirates - الإمارات العربية المتحدة', phone: '971' },
        { value: 'GB', innerHTML: 'United Kingdom - United Kingdom', phone: '44' },
        { value: 'US', innerHTML: 'United States of America - United States of America', phone: '1' },
        { value: 'UY', innerHTML: 'Uruguay - Uruguay', phone: '598' },
        { value: 'UZ', innerHTML: 'Uzbekistan - Oʻzbekiston', phone: '998' },
        { value: 'VU', innerHTML: 'Vanuatu - Vanuatu', phone: '678' },
        { value: 'VA', innerHTML: 'Vatican City (Holy See) - Città del Vaticano', phone: '379' },
        { value: 'VE', innerHTML: 'Venezuela - Venezuela', phone: '58' },
        { value: 'VN', innerHTML: 'Vietnam - Việt Nam', phone: '84' },
        { value: 'YE', innerHTML: 'Yemen - اليمن', phone: '967' },
        { value: 'ZM', innerHTML: 'Zambia - Zambia', phone: '260' },
        { value: 'ZW', innerHTML: 'Zimbabwe - Zimbabwe', phone: '263' },
    ]

    const payment = [
        { value : 'paypal' , innerHTML: text.paypal},
        { value : 'wise' , innerHTML: text.wise},
        { value : 'yoursafe' , innerHTML: text.Yoursafe},
        { value : 'bank' , innerHTML: text.bank},
    ]

    return {payment, errorsList, categoryList, filterCountries, snStaticDatas, adminList, subscribersList, filterLanguages, successfulApplications, jobName, languageName, newslettersList, bloggerList, membersList, agentsCheckUp, blogPostsList, agentsAwaitingLogin, trainingSchedule, jobApplicationList, jobList, rolesSelect, genders, dataBlog, websites, testimonialCards, dataStat , testLanguages, chatModExp, hearAboutUs, languagesList, lastUpdateTermsAndConditions, jobOffers }
}
export default useData