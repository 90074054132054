import { Stack, Typography, Box } from "@mui/material";
import React from "react";

const TestimonialCard = ({texts, imgFile}) =>{
    const baseUrl = "/home2/"
    return(
        <Stack width="100%" className="testimonial" variant="primarybg" padding="0 30px" boxSizing="border-box">
            <Box maxWidth="100%" height="100%" className="w-c-large" overflow="hidden" display="flex" alignItems="center" justifyContent="center">
                
                <Stack height="60%" display="flex" alignItems="center" justifyContent="space-between"
                padding="0 40px">
                    <Typography variant="white" zIndex="2">{texts[2]}</Typography>
                    <Typography variant="white" zIndex="2">____</Typography>
                    <Stack style={{width: "100%"}} zIndex="2">
                        <Typography color="#00ff80;" fontWeight="bold">{texts[0]}</Typography>
                        <Typography color="#F00">{texts[1]}</Typography>
                    </Stack>
                </Stack>
                <Stack alignItems="center" padding="15px 0" position="relative"><Stack sx={{ height: "100%", zIndex: 3, position: "absolute", width: "260px", "&::before": {position: "absolute", content: "''", height: "0%", width: "100%", bottom: "30px", transition: "0.2s", left: "50%", transform: "translateX(-50%)", background: "linear-gradient(to bottom, #00000000, #00000077)"}, "&:hover::before": { height: "calc(70% - 30px)" },}}/><Box component="img" sx={{ zIndex:"2", height: "350px" }} src={baseUrl + imgFile} alt=""/></Stack>
            </Box>
        </Stack>
    )
}

export default TestimonialCard