import React from "react";
import { Stack, Typography, Box} from "@mui/material";
import useLanguage from "../hooks/useLanguage";
import IconText from "../tools/IconText";
import AnimatedButton from "../tools/AnimatedButton";
import CustomDivider from "../tools/CustomDivider";
import { useNavigate } from "react-router-dom";
import RoutesList from "../../helper/routes";

const CompValues = () =>{
    const { text } = useLanguage()
    const navigate = useNavigate()
    return(
        <Stack variant='compvalues'>
            
            <Stack variant='waves2'><img src='/svg/waves2.svg' alt=""/></Stack>
            <Box className="wrap-content" padding="100px 20px 0px 20px" id="compvalues">
                <Stack>
                    <Stack spacing={3} >
                        <Stack spacing={3} padding='0px 20px'>
                            <Stack className="title-typo" style={{width: 'fit-content'}}><Typography variant='whitesubtitle'>{text.our} <Typography variant='secondarysubtitle' className="title-typo-change">{text.company}</Typography></Typography></Stack>
                            <CustomDivider color="secondary" width='17%'/>
                            <Stack><IconText white range='1' iconSrc='/icones/2- Our company values/1. Excellence.svg' children={text.value1} /></Stack>
                            <Stack><IconText white range='2' iconSrc='/icones/2- Our company values/2. Client-Centric Approach.svg' children={text.value2} /></Stack>
                            <Stack><IconText white range='3' iconSrc='/icones/2- Our company values/3. Talent Empowerment.svg' children={text.value3} /></Stack>
                            <Stack><IconText white range='4' iconSrc='/icones/2- Our company values/4. Innovation and Adaptability.svg' children={text.value4} /></Stack>
                            <Stack><IconText white range='5' iconSrc='/icones/2- Our company values/5. Diversity and Inclusion.svg' children={text.value5} /></Stack>
                            <Stack><IconText white range='6' iconSrc='/icones/2- Our company values/6. Transparency and Integrity.svg' children={text.value6} /></Stack>
                            <Stack><IconText white range='7' iconSrc='/icones/2- Our company values/7. Collaboration and Synergy.svg' children={text.value7} /></Stack>
                            <Stack><IconText white range='8' iconSrc='/icones/2- Our company values/8. Continuous Learning.svg' children={text.value8} /></Stack>
                            <Stack><IconText white range='9' iconSrc='/icones/2- Our company values/9. Industry Expertise.svg' children={text.value9} /></Stack>
                            <Stack><IconText white range='10' iconSrc='/icones/2- Our company values/10. Ethical Conduct.svg' children={text.value10} /></Stack>
                            <Stack><IconText white range='11' iconSrc='/icones/2- Our company values/11. Community Engagement.svg' children={text.value11} /></Stack>
                            <Stack><IconText white range='12' iconSrc='/icones/2- Our company values/12. Impact and Responsibility.svg' children={text.value12} /></Stack>
                            
                        </Stack>
                        <Stack alignItems='center'><AnimatedButton onClick={()=> navigate(RoutesList.jobs)}>{text.check}</AnimatedButton></Stack>
                    </Stack>
                </Stack>
                <Stack display='flex' justifyContent='center' alignItems='center' minWidth="42%" className="wrap-img-container"> <img width="100%" alt="" src='/home2/values.webp'/></Stack>
            </Box>
        </Stack>
    )
}
export default CompValues