import { Stack, Box, Typography } from "@mui/material";
import React from "react";
import IconText from "../../tools/IconText";
import useLanguage from "../../hooks/useLanguage";
import AnimatedButton from "../../tools/AnimatedButton";
import CustomDivider from "../../tools/CustomDivider";

const About = () =>{
    const {text} = useLanguage()
    const iconBaseUrl = "/images/chat-operator/2- What is a dating chat operator job about/"
    
    return(
        <Stack>
            <Box className="wrap-content" padding='15px' marginBottom='50px'>

                <Stack alignItems='center' maxWidth="40%" display='flex' justifyContent='center'><img width='90%' src="/home2/co3.webp" alt=""/></Stack>
                
                <Stack>
                    <Stack spacing={3}>
                        <Typography className="title-typo" variant="title" textAlign='left'>
                            {text.coabint1} <Typography variant="primarytitle" className="title-typo-change-light">{text.chatoperator}</Typography> {text.coabint2}
                        </Typography>
                        <CustomDivider color="secondary" width='14%'/>
                        <Stack spacing={1} paddingRight='35px'>
                            <IconText range={1} iconSrc={iconBaseUrl + "1. Connecting with Lonely Individuals.svg"}>{text.coab1}</IconText>
                            <IconText range={2} iconSrc={iconBaseUrl + "2. Text-Based Communication.svg"}>{text.coab2}</IconText>
                            <IconText range={3} iconSrc={iconBaseUrl + "3. Varied Conversational Topics.svg"}>{text.coab3}</IconText>
                            <IconText range={4} iconSrc={iconBaseUrl + "4. Open-Minded Approach.svg"}>{text.coab4}</IconText>
                            <IconText range={5} iconSrc={iconBaseUrl + "5. Providing Companionship.svg"}>{text.coab5}</IconText>
                            <IconText range={6} iconSrc={iconBaseUrl + "6. Anonymous.svg"}>{text.coab6}</IconText>
                            <IconText range={7} iconSrc={iconBaseUrl + "7. Independence and flexibility.svg"}>{text.coab7}</IconText>
                        </Stack>
                        <AnimatedButton to="apply-co">{text.applynow}</AnimatedButton>
                    </Stack>
                </Stack>
            </Box>
        </Stack>
    )
}
export default About