import React from "react";
import { Stack, Typography } from "@mui/material";
import CustomDivider from "../../tools/CustomDivider";
import useLanguage from "../../hooks/useLanguage";
import IconText from "../../tools/IconText";
import AnimatedButton from "../../tools/AnimatedButton";

const Introduction = () =>{
    const {text} = useLanguage()
    const iconBaseUrl = "/images/TeamLeader/icons/1_Introduction/"
    return(
        <Stack position='relative'>
            <Stack variant="primarybg" style={{zIndex: -2, position: 'absolute', width: '100%', height: '100%', overflow: 'hidden'}}>
                <img src="/home2/tl1.webp" alt="" style={{ position: "absolute", zIndex: -1, height: "100%", left: '50%', transform: 'translateX(-50%)'}} />
            </Stack>
            <Stack variant="semitransparentprimary" className="min-height-resize-tlint" style={{overflow: 'hidden', display: 'flex', justifyContent: 'center'}}>  
                <Stack spacing={3} className="width-resize-tlint">
                    <Stack alignItems='center'>
                        <Typography className="title-typo width-resize-tlint-typo" variant="whitetitle" fontWeight='bold'>
                                { text.tlintint1 } <Typography variant="secondarytitle" width='80%' className="title-typo-change">{text.teamleader}</Typography> { text.tlintint2 }
                        </Typography>
                    </Stack>
                    <CustomDivider color='white' alignCenter width="10%"/>
                    <Stack className="padding-resize-tlint" spacing={2}>
                        <IconText height="60px" white iconSrc={iconBaseUrl + "1_ImpactfulLeadership.svg"}>{text.tlint1}</IconText>
                        <IconText height="60px" white iconSrc={iconBaseUrl + "2_TeamGrowthOpportunity.svg"}>{text.tlint2}</IconText>
                        <IconText height="60px" white iconSrc={iconBaseUrl + "3_InnovativeContribution.svg"}>{text.tlint3}</IconText>
                        <IconText height="60px" white iconSrc={iconBaseUrl + "4_RecognitionAndAchievement.svg"}>{text.tlint4}</IconText>
                        <IconText height="60px" white iconSrc={iconBaseUrl + "5_RewardsForExcellence.svg"}>{text.tlint5}</IconText>
                    </Stack>
                    <AnimatedButton to="apply-tl">{text.applynow}</AnimatedButton>
                </Stack>
            </Stack>
        </Stack>
    )
}

export default Introduction